import React, { useState } from "react";
import { Text, Spacer, Icon, Button } from "../common";
import { TextField, InputAdornment } from "@material-ui/core";
import useDispatchError from "../../shared/hooks/useDispatchError";
import {
  StoreAppErrorStyleSuccess,
  StoreAppErrorTypeForm,
  StoreAppError_SuccessServerResponse,
} from "../../store/app";
import APIService, { API_PASS_RESET } from "../../services/api.service";

const RecoverCard = ({ onLogin, recoveryCode, onChangeCode }) => {
  const [setError] = useDispatchError();
  const [disableActions, setDisableActions] = useState(false);
  const onSetDisableActions = () => {
    if (!disableActions) {
      setDisableActions(true);
    }
  };
  const onResetDisableActions = () => {
    if (disableActions) {
      setDisableActions(false);
    }
  };

  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");

  const onChangePasswordHandler = async () => {
    if (!newPassword?.length) {
      setError("Введите новый пароль", StoreAppErrorTypeForm);
      return;
    }
    if (!repeatNewPassword?.length) {
      setError("Повторите новый пароль", StoreAppErrorTypeForm);
      return;
    }

    onSetDisableActions();

    const resp = await APIService.postDataHandler(
      API_PASS_RESET,
      {
        code: recoveryCode,
        password: newPassword,
        passwordConfirmation: repeatNewPassword,
      },
      () =>
        setError(
          "Не удалось изменить пароль. Убедитесь что вы ввели все данные корректно или повторите попытку позже.",
        ),
    );

    onResetDisableActions();
    if (resp) {
      onLogin();
      setError(
        "Вы успешно изменили пароль.",
        StoreAppError_SuccessServerResponse,
        StoreAppErrorStyleSuccess,
      );
    } else {
      onChangeCode();
    }
  };

  return (
    <React.Fragment>
      <Text type='h4'>Введите новый пароль</Text>
      <Spacer top='4.25rem' />
      <TextField
        value={newPassword}
        onChange={e => setNewPassword(e.target.value)}
        disabled={disableActions}
        placeholder='Пароль'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <Icon icon='lock' size='3rem' />
            </InputAdornment>
          ),
        }}
        type='password'
        variant='outlined'
      />
      <Spacer top='1.5rem' />
      <TextField
        value={repeatNewPassword}
        onChange={e => setRepeatNewPassword(e.target.value)}
        disabled={disableActions}
        placeholder='Повторите пароль'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <Icon icon='lock' size='3rem' />
            </InputAdornment>
          ),
        }}
        type='password'
        variant='outlined'
      />
      <Spacer top='3rem' />
      <Button
        type='primary'
        disabled={!newPassword?.length || !repeatNewPassword?.length}
        onClick={onChangePasswordHandler}>
        <Text isCenter type='md' color='white'>
          Сохранить
        </Text>
      </Button>

      <Spacer top='7.5rem' />
      <div
        onClick={e => {
          e.preventDefault();
          if (!disableActions) {
            onLogin();
          }
        }}
        className={`auth-link${disableActions ? " auth-link__disabled" : ""}`}>
        Войти в систему
      </div>

      <Spacer top='2.5rem' />
      <div
        onClick={e => {
          e.preventDefault();
          if (!disableActions) {
            onChangeCode();
          }
        }}
        className={`auth-link${disableActions ? " auth-link__disabled" : ""}`}>
        Исправить код восстановления
      </div>
    </React.Fragment>
  );
};

export default RecoverCard;
