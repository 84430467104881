import queryStringify from "qs-stringify";
import React, { useEffect, useState } from "react";
import APIService, {
  API_DATA_AGENTLINES,
  API_DATA_CITIES,
  API_DATA_COMMANDS,
  API_DATA_SHOP_CATEGORIES,
  API_DATA_USERS,
  API_DATA_USERS_QUERY_MED_MEMBERS,
} from "../../../../../../services/api.service";
import useAPIData from "../../../../../../shared/hooks/useAPIData";
import useDispatchError from "../../../../../../shared/hooks/useDispatchError";
import { useFormDataPropSelectDefault } from "../../../../../../shared/hooks/useFormData";
import { StoreAppErrorTypeForm } from "../../../../../../store/app";
import {
  Spacer,
  Input,
  Select,
  Table,
  Text,
  Checkbox,
  Button,
} from "../../../../../common";
import { usersDataNew } from "../../_taskModalProps";
import TaskTablesSelectedUsers from "./TaskTablesSelectedUsers";

const maxRepeatsCount = parseInt(
  process.env.REACT_APP_API_LIMIT_TASK_USERS_REPEATS,
);

const MedicalMemberTable = ({ generalFormData, onChangeGeneralFormData }) => {
  const [setError] = useDispatchError();
  const [uAgents, pagination, updateAgents, , onChangePage] = useAPIData(
    API_DATA_USERS,
    API_DATA_USERS_QUERY_MED_MEMBERS,
    100,
  );

  const [filters, setFilters] = useState({
    [API_DATA_CITIES]: {
      query: "agent.city.name",
      data: API_DATA_CITIES,
      options: [],
      value: "",
    },
    [API_DATA_COMMANDS]: {
      query: "agent.command.name",
      data: API_DATA_COMMANDS,
      options: [],
      value: "",
    },
    [API_DATA_AGENTLINES]: {
      query: "agent.agentline.name",
      data: API_DATA_AGENTLINES,
      options: [],
      value: "",
    },
  });

  const [repeatsCount, setRepeatsCount] = useState("");
  const onChangeRepeatsCount = value => {
    if (!value?.length) {
      setRepeatsCount("");
    } else {
      const newValue = Math.abs(parseInt(value));
      if (maxRepeatsCount > newValue) {
        setRepeatsCount(newValue);
      } else {
        setRepeatsCount(15);
      }
    }
  };

  const onClickApplyRepeats = () => {
    const newAgents = [];
    const newRepeats = parseInt(repeatsCount);

    if (
      (typeof repeatsCount === "string" && repeatsCount?.length) ||
      1 > newRepeats ||
      maxRepeatsCount < newRepeats
    ) {
      setError(
        `Введите число от 1 до ${maxRepeatsCount} в поле "Редактировать выбранные" что-бы применить.`,
      );
      return;
    }

    for (const a of generalFormData?.users?.value?.agents) {
      newAgents.push({ ...a, repeats: newRepeats });
    }

    onChangeGeneralFormData(newAgents, "users", "agents");
  };

  const getSelectedUser = user => {
    if (generalFormData?.users?.value?.agents?.length) {
      return generalFormData?.users?.value?.agents.find(
        u => u?.id === user?.id,
      );
    }
  };
  const isUserSelected = user => !!getSelectedUser(user);
  const isAllPaginatedUsersSelected = () => {
    if (uAgents?.length) {
      let selectedCount = 0;
      for (const up of uAgents) {
        if (isUserSelected(up)) {
          ++selectedCount;
        }
      }

      return selectedCount === uAgents?.length;
    }
    return false;
  };
  const __isAllPaginatedUsersSelected = isAllPaginatedUsersSelected();

  const onCheckUser = user => {
    let _selectedUsers = generalFormData?.users?.value?.agents?.length
      ? [...generalFormData?.users?.value?.agents]
      : [];
    const uid = user?.id;
    const uidIndex = _selectedUsers?.findIndex(_u => _u.id === uid);
    if (-1 !== uidIndex) {
      _selectedUsers.splice(uidIndex, 1);
    } else {
      _selectedUsers.push(usersDataNew(uid));
    }

    onChangeGeneralFormData(_selectedUsers, "users", "agents");
  };
  const onCheckAllUsers = () => {
    if (!uAgents?.length) {
      setError("Нет пользователей для выбора.", StoreAppErrorTypeForm);
      return;
    }

    const formIDs = generalFormData?.users?.value?.agents?.length
      ? [...generalFormData?.users?.value?.agents]
      : [];
    let newUIDs = [...formIDs];

    if (__isAllPaginatedUsersSelected) {
      for (const up of uAgents) {
        const idIdx = newUIDs.findIndex(u => u.id === up?.id);
        if (-1 < idIdx) {
          newUIDs.splice(idIdx, 1);
        }
      }
    } else {
      let appendUIDs = [];
      for (const up of uAgents) {
        if (!isUserSelected(up)) {
          const upid = up?.id;
          appendUIDs.push(usersDataNew(upid));
        }
      }

      newUIDs = [...newUIDs, ...appendUIDs];
    }

    onChangeGeneralFormData(newUIDs, "users", "agents");
  };

  const getFiltersQuery = (_f = filters) => {
    let qsQueries = {};
    for (const fKey of Object.keys(_f)) {
      const fData = _f[fKey];
      if (fData.value.length) {
        qsQueries[fData.query] = fData.value;
      }
    }
    const queryRequest = queryStringify(qsQueries);

    let resQuery = API_DATA_USERS_QUERY_MED_MEMBERS;
    if (queryRequest?.length) {
      resQuery += `&${queryRequest}`;
    }

    return resQuery;
  };
  const onChangeFilter = async (value, filterName) => {
    const oldValue = filters[filterName].value;
    if (oldValue === value) {
      return;
    }

    const newFilters = {
      ...filters,
      [filterName]: { ...filters[filterName], value },
    };

    let resQuery = getFiltersQuery(newFilters);
    const isSuccess = await updateAgents(resQuery);
    if (isSuccess) {
      setFilters(newFilters);
    }
  };

  const onChangePageHandler = newPage => {
    onChangePage(newPage, getFiltersQuery(filters));
  };

  useEffect(() => {
    const fetchFiltersOptions = async () => {
      let isNoErr = true;
      let newFilters = { ...filters };

      for (const fKey of Object.keys(filters)) {
        const fData = filters[fKey];
        if (!fData?.data) {
          continue;
        }

        const fOptions = await APIService.getDataHandler(fData.data, () =>
          setError(`Не удалось получить опции(${fData.data}) для фильтров.`),
        );
        isNoErr = !!fOptions;

        if (!isNoErr || typeof fOptions === "string") {
          break;
        }

        newFilters[fKey].options = [
          useFormDataPropSelectDefault,
          ...fOptions?.map(f => ({
            value: f.name,
            text: f.name,
          })),
        ];
      }

      if (isNoErr) {
        setFilters(newFilters);
      }
    };

    fetchFiltersOptions();
  }, []);

  const headers = [
    {
      title: (
        <div className='d-flex justify-center flex-1'>
          <Checkbox
            checked={__isAllPaginatedUsersSelected}
            onClick={() => onCheckAllUsers()}
          />
        </div>
      ),
      isSortable: false,
    },
    { title: "Имя", isSortable: false },
    { title: "Линия МП", isSortable: false },
    { title: "Команда", isSortable: false },
    { title: "Город", isSortable: false },
    { title: "Телефон", isSortable: false },
    { title: "Количество задач", isSortable: false },
  ];

  const row = item => {
    let userName = "Не указано";
    if (item?.firstname && item?.lastname) {
      userName = `${item?.firstname} ${item?.lastname}`;
    }

    let selectedUserData = getSelectedUser(item);

    return (
      <React.Fragment>
        <div className='table-row__cell'>
          <div className='d-flex justify-center flex-1'>
            <Checkbox
              checked={!!selectedUserData}
              onChange={() => onCheckUser(item)}
            />
          </div>
        </div>
        <div className='table-row__cell'>
          <Text>{userName}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{item?.agent?.agentline?.name ?? "Неизвестно"}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{item?.agent?.command?.name ?? "Без команды"}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{item?.agent?.city?.name ?? "Неизвестно"}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{item?.username ?? "Неизвестно"}</Text>
        </div>
        <div className='table-row__cell'>
          <Input value={selectedUserData?.repeats ?? 1} disabled={true} />
        </div>
      </React.Fragment>
    );
  };

  return (
    <>
      <Spacer top='3rem' />
      <div className='d-flex'>
        <Select
          style={{ width: "30rem" }}
          label='Линия МП'
          disabled={!filters[API_DATA_AGENTLINES]?.options?.length}
          value={filters[API_DATA_AGENTLINES].value}
          onChange={e => onChangeFilter(e.target.value, API_DATA_AGENTLINES)}
          variants={filters[API_DATA_AGENTLINES].options}
        />
        <Spacer left='3rem' />
        <Select
          style={{ width: "30rem" }}
          label='Команда'
          disabled={!filters[API_DATA_COMMANDS]?.options?.length}
          value={filters[API_DATA_COMMANDS].value}
          onChange={e => onChangeFilter(e.target.value, API_DATA_COMMANDS)}
          variants={filters[API_DATA_COMMANDS].options}
        />
        <Spacer left='3rem' />
        <Select
          style={{ width: "30rem" }}
          label='Город'
          disabled={!filters[API_DATA_CITIES]?.options?.length}
          value={filters[API_DATA_CITIES].value}
          onChange={e => onChangeFilter(e.target.value, API_DATA_CITIES)}
          variants={filters[API_DATA_CITIES].options}
        />
      </div>

      <TaskTablesSelectedUsers
        pharmacies={generalFormData?.users?.value?.pharmacies}
        agents={generalFormData?.users?.value?.agents}
      />

      {generalFormData?.users?.value?.agents?.length ? (
        <>
          <Spacer top='3rem' />

          <div className='d-flex f-column'>
            <Text
              color='secondary'
              type='md'>{`Редактировать выбранные (${generalFormData?.users?.value?.agents?.length})`}</Text>

            <Spacer top='1rem' />

            <div className='d-flex'>
              <Input
                style={{ width: "31.5rem" }}
                placeholder={`Количество задач(макс.${maxRepeatsCount})`}
                disabled={!generalFormData?.users?.value?.agents?.length}
                value={repeatsCount}
                onChange={e => onChangeRepeatsCount(e.target.value)}
              />

              <Spacer right='3rem' />

              <Button type={"primary"} onClick={() => onClickApplyRepeats()}>
                <Text>Подтвердить</Text>
              </Button>
            </div>
          </div>
        </>
      ) : null}

      <Spacer top='3rem' />

      <Table
        columns={["4rem", "27rem", "18rem", "20rem", "25rem", "20rem", "18rem"]}
        headers={headers}
        pagination={{ ...pagination, onChange: onChangePageHandler }}
        row={row}
        list={uAgents}
        isLoading={!uAgents}
      />
    </>
  );
};

export default MedicalMemberTable;
