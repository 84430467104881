import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router";
import ApprovementPage from "./pages/ApprovementPage";
import AuthPage from "./pages/AuthPage";
import DataPage from "./pages/DataPage";
import FAQPage from "./pages/FAQPage";
import MainPage from "./pages/MainPage";
import NewsPage from "./pages/NewsPage";
import NotificationsPage from "./pages/NotificationsPage";
import ProfilePage from "./pages/ProfilePage";
import ShopPage from "./pages/ShopPage";
import TasksPage from "./pages/TasksPage";
import UsersPage from "./pages/UsersPage";
import { selectUserPermissions } from "./store/user";

export const ROUTE_HOME = "/";

export const ROUTE_AUTH = "/auth";

export const ROUTE_TASKS = "/tasks";
export const ROUTE_TASKS_PARAMS = `${ROUTE_TASKS}/:status?`;
export const ROUTE_TASKS_DRAFTS = `/tasks/draft`;
export const ROUTE_TASKS_ACTIVES = `/tasks/active`;
export const ROUTE_TASKS_NEW = `${ROUTE_TASKS}_new`;
export const ROUTE_TASKS_EDIT = `${ROUTE_TASKS_NEW}/:id?`;

export const ROUTE_APPROVEMENT = "/approvement";
export const ROUTE_APPROVEMENT_BUY = `${ROUTE_APPROVEMENT}/buy`;
export const ROUTE_APPROVEMENT_SELL = `${ROUTE_APPROVEMENT}/sell`;
export const ROUTE_APPROVEMENT_MERCH = `${ROUTE_APPROVEMENT}/merch`;
export const ROUTE_APPROVEMENT_STUDY = `${ROUTE_APPROVEMENT}/study`;

export const ROUTE_USERS = "/users";
export const ROUTE_USERS_MEDICAL_MEMBERS = `${ROUTE_USERS}/medical-members`;
export const ROUTE_USERS_PHARMACY = `${ROUTE_USERS}/pharmacy`;
export const ROUTE_USERS_ADMIN = `${ROUTE_USERS}/admin`;
export const ROUTE_USERS_NEW = `${ROUTE_USERS}/new`;
export const ROUTE_USERS_EDIT = `${ROUTE_USERS_NEW}/:id?`;

export const ROUTE_SHOP_ = "/shop";
export const ROUTE_SHOP = "/shop/:section";
export const ROUTE_SHOP_SECTION_PRODUCTS = "products";
export const ROUTE_SHOP_PRODUCTS = `/shop/${ROUTE_SHOP_SECTION_PRODUCTS}`;
export const ROUTE_SHOP_PRODUCTS_NEW = `${ROUTE_SHOP_PRODUCTS}/new`;
export const ROUTE_SHOP_PRODUCTS_EDIT = `${ROUTE_SHOP_PRODUCTS_NEW}/:id?`;
export const ROUTE_SHOP_SECTION_ORDERS = "orders";
export const ROUTE_SHOP_ORDERS = `/shop/${ROUTE_SHOP_SECTION_ORDERS}`;
export const ROUTE_SHOP_SECTION_GROUPS = "groups";
export const ROUTE_SHOP_GROUPS = `/shop/${ROUTE_SHOP_SECTION_GROUPS}`;
export const ROUTE_SHOP_GROUPS_NEW = `${ROUTE_SHOP_GROUPS}/new`;
export const ROUTE_SHOP_GROUPS_EDIT = `${ROUTE_SHOP_GROUPS_NEW}/:id?`;

export const ROUTE_DATA = "/data";
export const ROUTE_DATA_PHARMACY = `${ROUTE_DATA}/pharmacy`;
export const ROUTE_DATA_MEDICAL_MEMBERS = `${ROUTE_DATA}/medical-members`;
export const ROUTE_DATA_DISTRIBUTORS = `${ROUTE_DATA}/distributors`;
export const ROUTE_DATA_MEDICAL_MEMBERS_LINES = `${ROUTE_DATA}/medical-members-lines`;
export const ROUTE_DATA_TEAMS = `${ROUTE_DATA}/teams`;

export const ROUTE_NOTIFICATIONS = "/notifications";
export const ROUTE_NOTIFICATIONS_NEW = `${ROUTE_NOTIFICATIONS}/new`;
export const ROUTE_NOTIFICATIONS_EDIT = `${ROUTE_NOTIFICATIONS_NEW}/:id?`;

export const ROUTE_NEWS = "/news";
export const ROUTE_NEWS_NEW = `${ROUTE_NEWS}/new`;
export const ROUTE_NEWS_EDIT = `${ROUTE_NEWS_NEW}/:id?`;

export const ROUTE_FAQ = "/faq";
export const ROUTE_FAQ_NEW = `${ROUTE_FAQ}/new`;
export const ROUTE_FAQ_EDIT = `${ROUTE_FAQ_NEW}/:id?`;

export const ROUTE_PROFILE = "/profile";

export const LoginRoutes = () => {
  return (
    <Switch>
      <Route path={ROUTE_AUTH}>
        <AuthPage />
      </Route>

      <Route>
        <Redirect to={ROUTE_AUTH} />
      </Route>
    </Switch>
  );
};

const _getUserPermissionRouteReDirections = (routeName, id) => {
  return {
    [ROUTE_APPROVEMENT]: (
      <Route exact path={ROUTE_APPROVEMENT} key={`app-route-redir-${id}`}>
        <Redirect to={ROUTE_APPROVEMENT_BUY} />
      </Route>
    ),
    [ROUTE_USERS]: (
      <Route exact path={ROUTE_USERS} key={`app-route-redir-${id}`}>
        <Redirect to={ROUTE_USERS_MEDICAL_MEMBERS} />
      </Route>
    ),
    [ROUTE_SHOP_]: (
      <Route exact path={ROUTE_SHOP_} key={`app-route-redir-${id}`}>
        <Redirect to={ROUTE_SHOP_PRODUCTS} />
      </Route>
    ),
    [ROUTE_DATA]: (
      <Route exact path={ROUTE_DATA} key={`app-route-redir-${id}`}>
        <Redirect to={ROUTE_DATA_PHARMACY} />
      </Route>
    ),
  }[routeName];
};
const _getUserPermissionRoute = (routeName, id) => {
  return {
    [ROUTE_TASKS]: (
      <Route
        key={`app-route-${id}`}
        path={[ROUTE_TASKS_PARAMS, ROUTE_TASKS_EDIT]}>
        <TasksPage />
      </Route>
    ),
    [ROUTE_APPROVEMENT]: (
      <Route path={ROUTE_APPROVEMENT} key={`app-route-${id}`}>
        <ApprovementPage />
      </Route>
    ),
    [ROUTE_USERS]: (
      <Route path={ROUTE_USERS} key={`app-route-${id}`}>
        <UsersPage />
      </Route>
    ),
    [ROUTE_SHOP_]: (
      <Route path={ROUTE_SHOP} key={`app-route-${id}`}>
        <ShopPage />
      </Route>
    ),
    [ROUTE_DATA]: (
      <Route path={ROUTE_DATA} key={`app-route-${id}`}>
        <DataPage />
      </Route>
    ),
    [ROUTE_NEWS]: (
      <Route path={ROUTE_NEWS} key={`app-route-${id}`}>
        <NewsPage />
      </Route>
    ),
    [ROUTE_NOTIFICATIONS]: (
      <Route path={ROUTE_NOTIFICATIONS} key={`app-route-${id}`}>
        <NotificationsPage />
      </Route>
    ),
    [ROUTE_FAQ]: (
      <Route path={ROUTE_FAQ} key={`app-route-${id}`}>
        <FAQPage />
      </Route>
    ),
  }[routeName];
};

export const MainApplicationRoutes = () => {
  const userPermissions = useSelector(selectUserPermissions);

  return (
    <Switch>
      {userPermissions?.length
        ? userPermissions.map(up => {
            const RDRoute = _getUserPermissionRouteReDirections(up.name, up.id);
            return RDRoute;
          })
        : null}

      {userPermissions?.length
        ? userPermissions.map(up => {
            const PermissionRoute = _getUserPermissionRoute(up.name, up.id);
            return PermissionRoute;
          })
        : null}
      <Route path={ROUTE_PROFILE}>
        <ProfilePage />
      </Route>

      {/* <Route path='/isolation'>
        <Isolation />
      </Route> */}

      <Route exact path={ROUTE_HOME}>
        <MainPage />
      </Route>

      <Route path={ROUTE_AUTH}>
        <Redirect to={ROUTE_HOME} />
      </Route>

      <Route>
        <Redirect to={ROUTE_HOME} />
      </Route>
    </Switch>
  );
};
