import React from "react";
import { TextField, InputAdornment } from "@material-ui/core";
import { Icon, Spacer } from "../common";
import ListElement from "./ListElement";

const Search = ({
  options,
  searchQuery,
  onChangeQuery,
  disabled,
  required = true,
}) => {
  return (
    <div className='search-wrapper'>
      <TextField
        disabled={disabled}
        placeholder='Поиск'
        value={searchQuery}
        onChange={e => onChangeQuery(e.target.value)}
        InputProps={{
          required: required,
          startAdornment: (
            <InputAdornment position='start'>
              <Icon icon='search' size='3rem' />
            </InputAdornment>
          ),
        }}
        variant='outlined'
      />

      <Spacer top='1.5rem' />

      <div className='results'>
        {options?.length
          ? options.map((el, index) => (
              <ListElement
                key={`slenu-${index}`}
                primaryText={el?.primaryText}
                secondaryText={el?.secondaryText}
                actionSlot={el?.action}
              />
            ))
          : null}
      </div>
    </div>
  );
};

export default Search;
