import React, { useState, useRef } from "react";
import Text from "../Text";
import Spacer from "../Spacer";
import Pagination from "./Pagination";
import Skeleton from "@material-ui/lab/Skeleton";

// headers - [{ title, isSortable}]
// row - function row(listItem) return row template for table
// list - table data
// columns - array of columns width (better use 'fr') to form table
// handleRow - row onClick
// collapseSlot - is table collapsable
// childrenRow - template for child row
function Table({
  headers,
  row,
  list,
  columns,
  handleRow,
  collapseSlot,
  onCloseCollapseSlot = () => {},
  onOpenCollapseSlot = () => {},
  childrenRow,
  hidePagination,
  pagination,
  isLoading,
}) {
  const gridStyles = {
    display: "grid",
    gridTemplateColumns: columns.join(" "),
  };
  const tableRef = useRef(null);
  const [activeFilter, setActiveFilter] = useState([]);
  const [collapsed, setCollapsed] = useState(null);

  const changeFilter = i => {
    let filters = [...activeFilter];
    if (filters.includes(i)) {
      filters.splice(filters.indexOf(i), 1);
    } else {
      filters.push(i);
    }
    setActiveFilter(filters);
  };

  let tableData = null;

  if (isLoading) {
    tableData = new Array(9).fill(0).map((item, i) => (
      <div className='table-row' style={gridStyles} key={i + "skeleton"}>
        {new Array(headers.length).fill(0).map((item, i) => (
          <div className='table-row__cell' key={i + "skeleton-cell"}>
            <Skeleton width='100%' height='5rem' />
          </div>
        ))}
      </div>
    ));
  } else if (!isLoading && list && 0 < list.length) {
    tableData = (
      <React.Fragment>
        {list.map((item, i) => {
          const _collapseSlotID = item?.id ?? i;

          return (
            <React.Fragment key={`table-item-${i}`}>
              <div
                onClick={() => {
                  if (handleRow) handleRow(item);
                }}
                className={`table-row ${handleRow ? "clickable" : ""}`}
                style={{ ...gridStyles }}>
                {row(item, i)}
                {collapseSlot && (
                  <div
                    className='table-row__cell-clickable'
                    onClick={() => {
                      if (collapsed === _collapseSlotID) {
                        onCloseCollapseSlot();
                        setCollapsed(null);
                      } else {
                        onOpenCollapseSlot(item);
                        setCollapsed(_collapseSlotID);
                      }
                    }}>
                    {collapseSlot}
                  </div>
                )}
              </div>
              {collapseSlot && collapsed === _collapseSlotID && (
                <div className='table-children-row' key={i}>
                  {childrenRow(item)}
                </div>
              )}
            </React.Fragment>
          );
        })}

        {!hidePagination && pagination ? <Pagination {...pagination} /> : null}
      </React.Fragment>
    );
  }

  return (
    <div className='table' ref={tableRef}>
      <div className='table-header'>
        <div className='table-header-row' style={{ ...gridStyles }}>
          {headers.map((item, i) => (
            <div
              key={`table-header-${i}-${item.title}`}
              onClick={() => {
                if (item.isSortable) changeFilter(i);
              }}
              className={`table-header__item ${
                activeFilter.includes(i) ? "active" : ""
              }`}>
              <Text color='black'>{item.title}</Text>
              {item.isSortable && <Spacer left='1rem' />}
              {item.isSortable && (
                <svg
                  viewBox='0 0 14 14'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M7.15995 11.5209V2.77087'
                    stroke='#1A1919'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M10.6742 7.99182L7.16021 11.521L3.64563 7.99182'
                    stroke='#1A1919'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              )}
            </div>
          ))}
        </div>
      </div>

      {tableData}
    </div>
  );
}

export default Table;
