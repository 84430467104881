const prefixZero = s => (10 > s ? `0${s}` : s);

export const convertDate = dateStr => {
  const date = new Date(dateStr);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear().toString().slice(-2);

  return `${prefixZero(day)}.${prefixZero(month)}.${year}`;
};

export const convertDateTime = dateStr => {
  const date = new Date(dateStr);

  return `(${date.toLocaleTimeString("en-GB")})`;
};
