import React, { useState } from 'react';
import Text from './Text';
import Spacer from './Spacer';
import { Switch} from "react-router-dom";

function Tabs({ header, tabs, topSlot, isRouterTab }) {
    const [activeTab, setActiveTab] = useState(0);
    return (
        <div className="tabs">
            <div className="tabs-header">
                {header.map((item, i) => (
                    <div
                        key={i}
                        onClick={() => !isRouterTab && setActiveTab(i)}
                        className={`tabs-header_item ${!isRouterTab && i === activeTab ? 'active' : ''}`}>
                        <Text isBold color={!isRouterTab && activeTab === i ? 'secondary' : 'grey' }>{item}</Text>
                    </div>
                ))}

                {topSlot ? <React.Fragment>
                    <Spacer left="auto" />
                    {topSlot}
                </React.Fragment> : null}
            </div>
            {isRouterTab ?
                <Switch>
                    {tabs}
                </Switch>
                :
                tabs.filter((tab, index) => {
                    return activeTab === index && tab                
                })[0]
            }
        </div>
    )
}

export default Tabs;