import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Text,
  Spacer,
  Input,
  Select,
  TextArea,
  FileInput,
  Switch,
  Button,
  Icon,
  Modal,
} from "../../common";
import { ROUTE_SHOP_PRODUCTS } from "../../../AppRoutes";
import useFormData, {
  useFormDataPropSelectDefault,
  useFormDataTextRequired,
} from "../../../shared/hooks/useFormData";
import APIService, {
  API_DATA_SHOP_PRODUCTS,
  API_DATA_SHOP_GROUPS,
} from "../../../services/api.service";
import useDispatchError from "../../../shared/hooks/useDispatchError";

const ProductModal = ({ isNew }) => {
  const [setError] = useDispatchError();
  const history = useHistory();
  const params = useParams();
  const { id } = params;

  const _isNew = isNew && !id;

  const [disabledActions, setDisabledActions] = useState(false);

  const [formData, onChangeData, onSubmit, product] = useFormData({
    id: id,
    dataName: API_DATA_SHOP_PRODUCTS,
  });
  const onChangeSelect = (value, valueName, options) => {
    let opt = options.find(o => o?.value === value);
    if (!opt?.value) {
      opt = { ...useFormDataPropSelectDefault };
    }
    onChangeData(opt, valueName);
  };
  const onChangeInput = (value, valueName) => {
    onChangeData(value, valueName);
  };

  const [status, setStatus] = useState(false);
  const [groupsOptions, setGroupsOptions] = useState([]);

  useEffect(() => {
    const fetchOptions = async () => {
      if (!!product) {
        const productStatus = !!product?.status;
        if (productStatus) {
          setStatus(productStatus);
        }
      }

      if (!groupsOptions?.length) {
        const groups = await APIService.getDataHandler(
          API_DATA_SHOP_GROUPS,
          () => setError("Не удалось получить данные о группах."),
        );

        if (groups) {
          setGroupsOptions([
            { ...useFormDataPropSelectDefault },
            ...groups.map(o => ({ id: o.id, value: o.name, text: o.name })),
          ]);
        }
      }
    };

    fetchOptions();
  }, [product]);

  const _isNewsTitle = _isNew ? "Создание товара" : "Редактировать товар";
  let modalTitle = (
    <div className='d-flex align-center flex-1'>
      {_isNewsTitle}
      <Spacer left='auto' />
      <Switch
        value={status}
        onChange={async v => {
          if (typeof id !== "undefined") {
            const isSuccess = await APIService.putDataByIDHandler(
              API_DATA_SHOP_PRODUCTS,
              id,
              {
                status: v,
              },
              () => setError(`Не удалось изменить статус Товара(${id})`),
            );
            if (isSuccess) {
              setStatus(v);
            }
          } else {
            setStatus(v);
          }
        }}
      />
    </div>
  );

  const onSubmitHandler = async e => {
    if (disabledActions) {
      return;
    } else {
      setDisabledActions(true);
    }

    e.preventDefault();
    const newObj = await onSubmit();

    if (newObj) {
      if (status !== newObj?.status) {
        await APIService.putDataByIDHandler(
          API_DATA_SHOP_PRODUCTS,
          newObj.id,
          {
            status,
          },
          () =>
            setError(
              `Не удалось обновить статус продукта(${newObj.name} | ${newObj.id})`,
            ),
        );
      }

      history.push(ROUTE_SHOP_PRODUCTS);
    } else {
      setDisabledActions(false);
    }
  };
  const onDelete = async e => {
    e.preventDefault();
    if (!_isNew) {
      const isDeleted = await APIService.delDataByIDHandler(
        API_DATA_SHOP_PRODUCTS,
        id,
        () => setError(`Не удалось удалить продукт(${id})`),
      );
      if (isDeleted) {
        history.push(ROUTE_SHOP_PRODUCTS);
      }
    } else {
      history.push(ROUTE_SHOP_PRODUCTS);
    }
  };

  const _isNewSubmitText = _isNew ? "Готово" : "Сохранить";

  return (
    <Modal back={() => history.push(ROUTE_SHOP_PRODUCTS)} title={modalTitle}>
      <form onSubmit={onSubmitHandler}>
        <div className='d-flex'>
          <div className='flex-50 form-full-width'>
            <Text color='secondary' type='md'>
              {useFormDataTextRequired(
                formData?.name?.label,
                formData?.name?.required,
              )}
            </Text>
            <Spacer top='1.5rem' />
            <Input
              placeholder='Название нового товара'
              value={formData?.name?.value}
              required={formData?.name?.required}
              onChange={e => onChangeInput(e.target.value, "name")}
            />
            <Spacer top='3rem' />
            <Text color='secondary' type='md'>
              {useFormDataTextRequired(
                formData?.description?.label,
                formData?.description?.required,
              )}
            </Text>
            <Spacer top='1.5rem' />
            <TextArea
              value={formData?.description?.value}
              required={formData?.description?.required}
              rowsMin='10'
              style={{ width: "100%" }}
              onChange={e => onChangeInput(e.target.value, "description")}
            />
          </div>

          <Spacer left='4rem' />

          <div className='flex-50'>
            <Spacer top='4.5rem' />
            <div className='d-flex align-center'>
              <Text color='secondary' type='md'>
                {useFormDataTextRequired(
                  formData?.costscores?.label,
                  formData?.costscores?.required,
                )}
              </Text>
              <Spacer left='auto' />
              <Input
                placeholder='000,000,00'
                value={formData?.costscores?.value}
                required={formData?.costscores?.required}
                onChange={e => onChangeInput(e.target.value, "costscores")}
              />
            </div>
            <Spacer top='3rem' />
            <div className='d-flex align-center'>
              <Text color='secondary' type='md'>
                {useFormDataTextRequired(
                  formData?.costmoney?.label,
                  formData?.costmoney?.required,
                )}
              </Text>
              <Spacer left='auto' />
              <Input
                placeholder='000,000,00 KZT'
                value={formData?.costmoney?.value}
                required={formData?.costmoney?.required}
                onChange={e => onChangeInput(e.target.value, "costmoney")}
              />
            </div>
            <Spacer top='3rem' />
            <div className='d-flex align-center'>
              <Text color='secondary' type='md'>
                {useFormDataTextRequired(
                  formData?.group?.label,
                  formData?.group?.required,
                )}
              </Text>
              <Spacer left='auto' />
              <Select
                label='Укажите группу'
                value={formData?.group?.value}
                required={formData?.group?.required}
                style={{ width: "26.5rem" }}
                onChange={e => {
                  onChangeSelect(e.target.value, "group", groupsOptions);
                }}
                variants={groupsOptions}
              />
            </div>
          </div>
        </div>

        <Spacer top='3rem' />

        <div className='d-flex'>
          <div className='flex-50'>
            <Text color='secondary' type='md'>
              {useFormDataTextRequired(
                formData?.picture?.label,
                formData?.picture?.required,
              )}
            </Text>
            <Spacer top='1.5rem' />
            <FileInput
              value={formData?.picture?.value}
              required={formData?.picture?.required}
              onChange={f => onChangeData(f, "picture")}
            />
          </div>

          <Spacer left='4rem' />

          <div className='flex-50' />
        </div>

        <div className='flex-1'></div>
        <Spacer top='3rem' />

        <div className='d-flex'>
          <Button type='outline' onClick={onDelete} disabled={disabledActions}>
            <Icon icon='remove' size='2rem' />
            <Spacer left='1.5rem' />
            <Text type='md'>Удалить</Text>
          </Button>
          <Spacer left='auto' />
          <Button type='primary' btnType='submit' disabled={disabledActions}>
            <Text type='md'>{_isNewSubmitText}</Text>
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ProductModal;
