import { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import { Route, NavLink, useHistory } from "react-router-dom";
import {
  Tabs,
  Button,
  Spacer,
  FileInput,
  Text,
  Icon,
  Dialog,
  Autocomplete,
} from "../components/common";

import PharmacyTable from "../components/data/PharmacyTable";
import MedicalMembersTable from "../components/data/MedicalMembersTable";
import DistributorsTable from "../components/data/DistributorsTable";
import MedicalMemberLinesTable from "../components/data/MedicalMemberLinesTable";
import TeamsTable from "../components/data/TeamsTable";

import {
  ROUTE_DATA_PHARMACY,
  ROUTE_DATA_MEDICAL_MEMBERS,
  ROUTE_DATA_DISTRIBUTORS,
  ROUTE_DATA_MEDICAL_MEMBERS_LINES,
  ROUTE_DATA_TEAMS,
} from "../AppRoutes";
import { FileInputAcceptFilesExcel } from "../components/common/inputs/FileInput";
import APIService, {
  API_DATA_PROMO,
  API_UPLOAD_DATA_MAX_SIZE,
} from "../services/api.service";
import useDispatchError from "../shared/hooks/useDispatchError";
import { StoreAppErrorTypeApp, StoreAppErrorTypeForm } from "../store/app";
import queryStringify from "qs-stringify";
import useOnHistoryChange from "../shared/hooks/useOnHistoryChange";

const DataPage = () => {
  const [setError, clearError] = useDispatchError();
  const history = useHistory();

  const [monthsOptions, setMonthsOptions] = useState([]);
  const [monthSelected, setMonthSelected] = useState(undefined);
  const onChangeMonth = newMonth => {
    let monthObj = monthsOptions?.find(o => o?.value === newMonth);
    if (monthObj?.id !== monthSelected?.id) {
      setMonthSelected(monthObj ? { ...monthObj } : undefined);
    }
  };

  const __isMonthSelectorAndUploadDataAvailable = () => {
    const pagePath = history.location.pathname;

    switch (pagePath) {
      case ROUTE_DATA_PHARMACY:
      case ROUTE_DATA_MEDICAL_MEMBERS:
        return true;
      default:
        return false;
    }
  };
  const [
    isMonthSelectorAndUploadDataAvailable,
    setIsMonthSelectorAndUploadDataAvailable,
  ] = useState(__isMonthSelectorAndUploadDataAvailable());

  const getRouteSpecificQueries = () => {
    const pagePath = history.location.pathname;

    let pageTypeData = "";
    let pageQueryObj = {};

    switch (pagePath) {
      case ROUTE_DATA_PHARMACY:
        pageTypeData = "pharmacy";
        break;

      case ROUTE_DATA_MEDICAL_MEMBERS:
        pageTypeData = "agent";
        break;

      default:
        setError("Таблица ещё не готова для этих данных", StoreAppErrorTypeApp);
        return;
    }

    let _monthQuery = "";
    if (pageTypeData?.length) {
      pageQueryObj["role.type"] = pageTypeData;
      _monthQuery = queryStringify(pageQueryObj);
    }

    return _monthQuery;
  };
  const fetchMonthSpecificOptions = async () => {
    if (!isMonthSelectorAndUploadDataAvailable) {
      return;
    }

    const _mQuery = getRouteSpecificQueries();
    if (!_mQuery) {
      return;
    }

    const months = await APIService.getDataHandler(
      API_DATA_PROMO,
      () => setError("Невозможно получить данные по месяцам"),
      0,
      100,
      _mQuery,
    );

    if (months) {
      let newMonths = [];
      for (const m of months) {
        if (m?.name?.length) {
          newMonths.push({ value: m?.name, id: m?.id });
        }
      }

      setMonthsOptions(newMonths);
    }
  };

  useOnHistoryChange(() => {
    const _upd = async () => {
      setMonthSelected(undefined);
      setMonthsOptions([]);
      await fetchMonthSpecificOptions();
    };

    clearError();

    const __ismsauda = __isMonthSelectorAndUploadDataAvailable();
    setIsMonthSelectorAndUploadDataAvailable(__ismsauda);

    if (__ismsauda) {
      _upd();
    }
  });

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const openDialog = () => {
    if (monthSelected?.id) {
      setIsOpenDialog(true);
    } else {
      setError(
        "Пожалуйста выберите месяц к которому нужно загрузить Данные.",
        StoreAppErrorTypeApp,
      );
    }
  };
  const closeDialog = () => setIsOpenDialog(false);

  const [
    isUpdateChildTableComponent,
    setIsUpdateChildTableComponent,
  ] = useState(false);
  const resetUpdateChildTableComponent = () =>
    setIsUpdateChildTableComponent(false);

  const [file, setFile] = useState(undefined);
  const onChangeFile = f => {
    if (f?.size > API_UPLOAD_DATA_MAX_SIZE) {
      setError("Максимальный размер файла 5Мб.", StoreAppErrorTypeForm);
      return;
    }
    setFile(f);
  };

  const onSubmitDataFile = async () => {
    if (!monthSelected?.id) {
      setError(
        "Выберете месяц к которому нужно прикрепить данные.",
        StoreAppErrorTypeApp,
      );
      return;
    }

    let isErrSet = false;
    const uploadedData = await APIService.postUpload(file).then(
      resp => resp.data,
      err => {
        isErrSet = true;
        setError("Не удалось загрузить Данные.");
      },
    );

    if (uploadedData?.length && uploadedData[0]) {
      const monthUploadData = { document: uploadedData[0].id };
      const isSuccess = await APIService.putDataByIDHandler(
        API_DATA_PROMO,
        monthSelected?.id,
        monthUploadData,
        () =>
          setError(
            `Не удалось прикрепить загруженные Данные к выбранному месяцу.`,
          ),
      );
      if (isSuccess) {
        setIsUpdateChildTableComponent(true);
        closeDialog();
      }
    } else {
      if (!isErrSet) {
        setError("Не удалось получить загруженные Данные.");
      }
    }
  };

  useEffect(() => fetchMonthSpecificOptions(), []);

  const tabHeaders = [
    <NavLink to={ROUTE_DATA_PHARMACY}>Аптеки</NavLink>,
    <NavLink to={ROUTE_DATA_MEDICAL_MEMBERS}>Мед. представители</NavLink>,
    <NavLink to={ROUTE_DATA_DISTRIBUTORS}>Дистрибуторы</NavLink>,
    <NavLink to={ROUTE_DATA_MEDICAL_MEMBERS_LINES}>
      Линии мед. представителей
    </NavLink>,
    <NavLink to={ROUTE_DATA_TEAMS}>Команды</NavLink>,
    <>
      {isMonthSelectorAndUploadDataAvailable ? (
        <Autocomplete
          style={{ width: "32.2rem", marginLeft: "18rem" }}
          placeholder='01.06.21'
          id='months'
          disabled={!monthsOptions?.length}
          value={monthSelected?.value ?? ""}
          onValueChange={option => onChangeMonth(option)}
          options={monthsOptions?.map(o => o.value)}
          getOptionLabel={option => option}
        />
      ) : null}
    </>,
  ];

  return (
    <Layout
      topSlot={
        <>
          {isMonthSelectorAndUploadDataAvailable ? (
            <Button type='primary-outline' onClick={openDialog}>
              <Icon icon='download' size='2rem' />
              <Spacer left='0.5rem' />
              <Text type='sm' color='white'>
                Загрузить акционные товары
              </Text>
            </Button>
          ) : null}
        </>
      }>
      <div className='data-page'>
        <Tabs
          isRouterTab
          header={tabHeaders}
          tabs={[
            <Route path={ROUTE_DATA_PHARMACY} key={ROUTE_DATA_PHARMACY}>
              <PharmacyTable
                queryMonthID={monthSelected?.id}
                isUpdateChildTableComponent={isUpdateChildTableComponent}
                resetUpdateChildTableComponent={resetUpdateChildTableComponent}
              />
            </Route>,

            <Route
              path={ROUTE_DATA_MEDICAL_MEMBERS}
              key={ROUTE_DATA_MEDICAL_MEMBERS}>
              <MedicalMembersTable
                queryMonthID={monthSelected?.id}
                isUpdateChildTableComponent={isUpdateChildTableComponent}
                resetUpdateChildTableComponent={resetUpdateChildTableComponent}
              />
            </Route>,

            <Route path={ROUTE_DATA_DISTRIBUTORS} key={ROUTE_DATA_DISTRIBUTORS}>
              <DistributorsTable />
            </Route>,

            <Route
              path={ROUTE_DATA_MEDICAL_MEMBERS_LINES}
              key={ROUTE_DATA_MEDICAL_MEMBERS_LINES}>
              <MedicalMemberLinesTable />
            </Route>,

            <Route path={ROUTE_DATA_TEAMS} key={ROUTE_DATA_TEAMS}>
              <TeamsTable />
            </Route>,
          ]}
        />
      </div>

      <Dialog show={isOpenDialog} close={closeDialog}>
        <form style={{ width: "67.5rem" }}>
          <Text type='md' color='secondary'>
            Добавление документа
          </Text>

          <Spacer top='1.5rem' />

          <FileInput
            accept={FileInputAcceptFilesExcel}
            value={file}
            onChange={onChangeFile}
            required={true}
          />

          <Spacer top='1.5rem' />

          <Text isCenter>Разрешенные типы файлов: .xls и .xlsx</Text>

          <Spacer top='1.5rem' />

          <div className='d-flex justify-center'>
            <Button
              type='primary'
              onClick={e => {
                e?.preventDefault();
                onSubmitDataFile();
              }}>
              <Text type='sm' color='white'>
                Загрузить
              </Text>
            </Button>
          </div>
        </form>
      </Dialog>
    </Layout>
  );
};

export default DataPage;
