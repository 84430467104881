import React from "react";
import {
  useFormData_TaskStepInfo,
  useFormData_TaskStepTest,
  useFormData_TaskStepUsers,
} from "../../../shared/hooks/useFormData";

const TaskModalTop = ({ openTab, tab, isTestTab }) => {
  const renderSelectedArrow = isActive =>
    isActive ? (
      <svg viewBox='0 0 187 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M0 4C0 1.79086 1.79086 0 4 0H168.698C170.028 0 171.271 0.661366 172.015 1.76453L185.493 21.7645C186.404 23.1157 186.404 24.8843 185.493 26.2355L172.015 46.2355C171.271 47.3386 170.028 48 168.698 48H4C1.79086 48 0 46.2091 0 44V24V4Z'
          fill='url(#paint0_linear)'
        />
        <defs>
          <linearGradient
            id='paint0_linear'
            x1='187'
            y1='48'
            x2='45.8526'
            y2='-59.9522'
            gradientUnits='userSpaceOnUse'>
            <stop stopColor='#B3CDFE' />
            <stop offset='0.0001' stopColor='#B3CEFE' />
            <stop offset='1' stopColor='#BFE9FC' />
          </linearGradient>
        </defs>
      </svg>
    ) : (
      <svg viewBox='18 0 187 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M0.5 4C0.5 2.067 2.067 0.5 4 0.5H186.921C188.085 0.5 189.173 1.07894 189.823 2.04454L203.1 21.7528C203.887 22.9218 203.897 24.4491 203.124 25.6277L189.816 45.9195C189.169 46.9058 188.068 47.5 186.889 47.5H4.00001C2.06701 47.5 0.5 45.933 0.5 44V24V4Z'
          fill='white'
          stroke='#ECECEC'
        />
      </svg>
    );

  return (
    <div className='tasks-modal-top'>
      <div
        className={`tasks-modal-top__btn ${tab === useFormData_TaskStepInfo}`}
        onClick={() => openTab(useFormData_TaskStepInfo)}>
        <div>1. Информация</div>
        {renderSelectedArrow(tab === useFormData_TaskStepInfo)}
      </div>

      <div
        className={`tasks-modal-top__btn ${tab === useFormData_TaskStepUsers}`}
        onClick={() => openTab(useFormData_TaskStepUsers)}>
        <div>2. Пользователи</div>
        {renderSelectedArrow(tab === useFormData_TaskStepUsers)}
      </div>

      {isTestTab ? (
        <div
          className={`tasks-modal-top__btn ${tab === useFormData_TaskStepTest}`}
          onClick={() => openTab(useFormData_TaskStepTest)}>
          <div>3. Тест</div>
          {renderSelectedArrow(tab === useFormData_TaskStepTest)}
        </div>
      ) : null}
    </div>
  );
};

export default TaskModalTop;
