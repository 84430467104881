import React from "react";
import { API_DATA_DISTRIBUTORS } from "../../services/api.service";
import getShortenedText from "../../shared/functions/getShortenedText";
import useAPIData from "../../shared/hooks/useAPIData";
import { Table, Text, Button, Icon } from "../common";

const DistributorsTable = () => {
  const [distributors, pagination] = useAPIData(API_DATA_DISTRIBUTORS);

  const data = [
    {
      id: 1,
      uniqID: "KZ03454P",
      originalName: "Акридерм Гента мазь 0,05%+ 0,1% туба 15гр.",
      name: "Акридерм мазь 0,05% туба 15 г",
      brand: "AKRIDERM",
      distributor: 10,
      count: 1,
      mark: 10,
      superMark: 20,
    },
    {
      id: 2,
      uniqID: "KZ03454P",
      originalName: "Акридерм Гента мазь 0,05%+ 0,1% туба 15гр.",
      name: "Акридерм мазь 0,05% туба 15 г",
      brand: "AKRIDERM",
      distributor: 10,
      count: 1,
      mark: 10,
      superMark: 20,
    },
  ];
  const headers = [
    { title: "ID", isSortable: false },
    { title: "Название", isSortable: false },
    { title: "Бин", isSortable: false },
    { title: "Номер накладной", isSortable: false },
    { title: "", isSortable: false },
  ];

  const row = item => (
    <React.Fragment>
      <div className='table-row__cell'>
        <Text>
          <a title={item?.id}>{getShortenedText(item?.id, 16)}</a>
        </Text>
      </div>
      <div className='table-row__cell'>
        <Text>{item?.name ?? "Неизвестно"}</Text>
      </div>
      <div className='table-row__cell'>
        <Text>{item?.bin ?? "Неизвестно"}</Text>
      </div>
      <div className='table-row__cell'>
        {item?.invoicerules?.length ? (
          <Text type='md'>
            {item?.invoicerules?.map(
              (ir, idx) =>
                `${ir?.name}${
                  idx + 1 !== item?.invoicerules?.length ? ", " : ""
                }`,
            )}
          </Text>
        ) : (
          <Text color='grey'>Нет накладных</Text>
        )}
      </div>
      <div className='table-row__cell'>
        <Button
          type='flat'
          onClick={e => {
            e?.preventDefault();
            onDelete(item.id);
          }}>
          <Icon icon='remove' size='2rem' />
        </Button>
        <Button
          type='flat'
          onClick={e => {
            e?.preventDefault();
            onToEdit(item.id);
          }}>
          <Icon icon='edit' size='2rem' />
        </Button>
      </div>
    </React.Fragment>
  );

  return (
    <Table
      columns={["20rem", "28rem", "18rem", "65rem", "10rem"]}
      headers={headers}
      row={row}
      pagination={pagination}
      list={distributors}
      isLoading={!distributors}
    />
  );
};

export default DistributorsTable;
