import { ROUTE_USERS, ROUTE_USERS_MEDICAL_MEMBERS } from "../../AppRoutes";
import { Icon, Spacer, Text } from "../common";
import LinkCard from "./LinkCard";

const Card = () => {
  return (
    <div className='dashboard-card'>
      <div className='d-flex align-center'>
        <div className='icon-card'>
          <Icon icon='user' size='3.5rem' />
        </div>
        <Spacer left='2rem' />
        <Text color='black' type='h4'>
          Пользователи
        </Text>
      </div>

      <Spacer top='2rem' />

      <LinkCard count='0' label='Все' link={ROUTE_USERS_MEDICAL_MEMBERS} />

      <Spacer top='2rem' />

      <div className='dashboard-text-row'>
        <div className='d-flex'>
          <Text type='md'>Контрактные сети</Text>
          <Spacer left='auto' />
          <Text type='md' isBold>
            0
          </Text>
        </div>
      </div>

      <div className='dashboard-text-row'>
        <div className='d-flex'>
          <Text type='md'>Аптеки</Text>
          <Spacer left='auto' />
          <Text type='md' isBold>
            0
          </Text>
        </div>
      </div>

      <div className='dashboard-text-row'>
        <div className='d-flex'>
          <Text type='md'>Мед. представители</Text>
          <Spacer left='auto' />
          <Text type='md' isBold>
            0
          </Text>
        </div>
      </div>

      <div className='dashboard-text-row'>
        <div className='d-flex'>
          <Text type='md'>Адреса</Text>
          <Spacer left='auto' />
          <Text type='md' isBold>
            0
          </Text>
        </div>
      </div>
      <hr className='line' />
      <div className='dashboard-text-row'>
        <div className='d-flex'>
          <Text type='md'>Ждут подтверждения</Text>
          <Spacer left='auto' />
          <Text type='md' isBold>
            0
          </Text>
        </div>
      </div>
    </div>
  );
};

export default Card;
