import React from 'react';
import { Text, Spacer, Table, Button, Icon } from '../common';

const TableChildRowHand = () => {
    const headers = [
        { title: 'Название', isSortable: false },
        { title: 'Минимальное количество', isSortable: false },
        { title: 'Количество', isSortable: false },
        { title: 'За 1 единицу', isSortable: false },
        { title: 'Баллы', isSortable: false },
    ]
    const row = item => (
        <React.Fragment>
            <div className="table-row__cell">
                <Text>Мукалтин® 0,05 № 10</Text>
            </div>
            <div className="table-row__cell">
                <Text>1</Text>
            </div>
            <div className="table-row__cell">
                <Text>2</Text>
            </div>
            <div className="table-row__cell">
                <Text>36 UAH</Text>
            </div>
            <div className="table-row__cell">
                <Text>85</Text>
            </div>
        </React.Fragment>
    )

    return (
        <div>
            <div className="d-flex">
                <Spacer left="2.25rem" />

                <div className="flex-30">
                    <div className="d-flex align-center">
                        <Text color="grey">Номер телефона:</Text>
                        <Spacer left="auto" />
                        <Text>+380 (93) 000 00 00</Text>
                    </div>
                    <Spacer top="2rem" />
                    <div className="d-flex align-center">
                        <Text color="grey">Название организации:</Text>
                        <Spacer left="auto" />
                        <Text>Название</Text>
                    </div>
                    <Spacer top="2rem" />
                    <div className="d-flex align-center">
                        <Text color="grey">Номер договора:</Text>
                        <Spacer left="auto" />
                        <Text>#98873</Text>
                    </div>
                    <Spacer top="2rem" />
                    <div className="d-flex align-center">
                        <Text color="grey">Бин дистрибьютера:</Text>
                        <Spacer left="auto" />
                        <Text>1111</Text>
                    </div>
                </div>

                <Spacer left="15rem" />

                <div className="flex-30">
                    <div className="d-flex align-center">
                        <Text color="grey">Номер накладной:</Text>
                        <Spacer left="auto" />
                        <Text>№31</Text>
                    </div>
                    <Spacer top="2rem" />
                    <div className="d-flex align-center">
                        <Text color="grey">Дата ввода в систему накладной:</Text>
                        <Spacer left="auto" />
                        <Text>00.00.00</Text>
                    </div>
                    <Spacer top="2rem" />
                    <div className="d-flex align-center">
                        <Text color="grey">Дата обработки накладной:</Text>
                        <Spacer left="auto" />
                        <Text>00.00.00</Text>
                    </div>
                </div>

                <Spacer left="auto" />
            </div>

            <Spacer top="3rem" />

            <Table
                hidePagination
                columns={['1.5fr', '1fr', '1fr', '1fr', '1fr']}
                headers={headers}
                row={row}
                list={[0, 1]}>
            </Table>
            
            <Spacer top="3rem" />

            <div className="d-flex">
                <Spacer left="auto" />
                <Button type="outline">
                    <Icon icon="close-outline" size="2.25rem" />
                    <Spacer left="1rem" />
                    <Text type="md">Отклонить</Text>
                </Button>
                <Spacer left="1.5rem" />
                <Button type="primary">
                    <Text type="md">Подтвердить</Text>
                </Button>
            </div>
        </div>
    )
}

export default TableChildRowHand;