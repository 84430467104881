import React, { useState } from "react";
import { Text, Spacer, Icon, Button } from "../common";
import { TextField, InputAdornment } from "@material-ui/core";
import useDispatchError from "../../shared/hooks/useDispatchError";
import { StoreAppErrorTypeForm } from "../../store/app";
import APIService from "../../services/api.service";

const RecoveryCodeCard = ({ recoveryCode, onAcceptCode, onForgotCode }) => {
  const [setError] = useDispatchError();

  const [code, setCode] = useState(recoveryCode);
  const onChangeCode = newCode => setCode(newCode);

  const onSetRecoveryCodeHandler = () => {
    if (!code?.length) {
      setError("Введите код.", StoreAppErrorTypeForm);
      return;
    }

    onAcceptCode(code);
  };

  return (
    <React.Fragment>
      <Text type='h4'>Восстановление пароля</Text>
      <Spacer top='1rem' />
      <Text type='md' color='grey'>
        Введите код с письма:
      </Text>
      <Spacer top='5.5rem' />
      <TextField
        value={code}
        onChange={e => onChangeCode(e.target.value)}
        placeholder='Код'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <Icon icon='lock' size='3rem' />
            </InputAdornment>
          ),
        }}
        type='text'
        variant='outlined'
      />
      <Spacer top='3rem' />
      <Button
        type='primary'
        onClick={onSetRecoveryCodeHandler}
        disabled={!code?.length}>
        <Text isCenter type='md' color='white'>
          Далее
        </Text>
      </Button>
      <Spacer top='7.5rem' />
      <div onClick={onForgotCode} className='auth-link'>
        Ввести другой E-mail
      </div>
    </React.Fragment>
  );
};

export default RecoveryCodeCard;
