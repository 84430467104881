/**
 * {@link https://tools.ietf.org/html/rfc7519#section-4.1 | IANA "JSON Web Token Claims"}
 */
type JWTClaim = {
  iss?: string; // Issuer
  sub?: string; // Subject
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  aud?: any; // Audience
  exp?: number; // Expiration Time
  nbf?: number; // Not Before
  iat?: number; // Issued At
  jti?: string; // JWT ID
};

/**
 * @function isJWTExpired
 *
 * @description
 * Function-helper that will read a JWT token payload
 * and get a .exp param to compare it with current time.
 * <br/>
 * read more about .exp {@link https://tools.ietf.org/html/rfc7519#section-4.1.4 | here}
 *
 * @param {string} token a JWT token string
 *
 * @returns true if token is expired, otherwise - false, even if token is undefined-data.
 */
const isJWTExpired = (token: string): boolean => {
  if (!token?.length) {
    return false;
  }

  const tokenClaim: JWTClaim = JSON.parse(atob(token.split(".")[1]));
  const tokenExpiredTime = (tokenClaim.exp as number) * 1000;

  return Date.now() >= tokenExpiredTime;
};

export default isJWTExpired;
