import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { LocationListener } from "history";

const useOnHistoryChange = (onHistoryChange: LocationListener): void => {
  const history = useHistory();

  useEffect(() => history.listen(onHistoryChange), [history, onHistoryChange]);
};

export default useOnHistoryChange;
