import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

import TaskInfo from "./tabs/info/TaskInfo";
import TaskUsers from "./tabs/users/TaskUsers";
import TaskTest from "./tabs/test/TaskTest";
import {
  usersDataNew,
  _defaultFormTypes,
  _formOptionsDefault,
  _formOptionsFetchProps,
} from "./tabs/_taskModalProps";
import { ROUTE_TASKS } from "../../../AppRoutes";
import useFormData, {
  useFormData_TaskGeneralInfo,
  useFormData_TaskTypeMerch,
  useFormData_TaskTypesValid,
  useFormData_TaskStepInfo,
  useFormData_TaskStepUsers,
  useFormData_TaskStepTest,
  useFormDataPropNameRoot,
  useFormDataPropSelectDefault,
  useFormData_TaskTypeTraining,
  useFormDataPropTypeMultiSelect,
  useFormDataPropTypeImage,
  useFormDataPropTypeDate,
  useFormDataPropTypeTaskUsers,
  useFormDataPropTypeTaskTests,
  useFormDataPropTypeDateSingle,
  useFormData_TaskTypePurchase,
  useFormData_TaskTypeSale,
  useFormDataPropDateNestedDateName,
  useFormDataPropDateNestedTimeName,
  useFormData_UserRoleAgent,
  useFormData_UserRolePharmacy,
  useFormDataPropTypeInput,
  useFormDataPropTypeSwitch,
} from "../../../shared/hooks/useFormData";
import APIService, {
  API_DATA_BRANDS,
  API_DATA_TASKS,
  API_DATA_TASKS_TYPES,
  API_UPLOAD_MAX_SIZE,
} from "../../../services/api.service";
import useDispatchError from "../../../shared/hooks/useDispatchError";
import {
  StoreAppErrorTypeApp,
  StoreAppErrorTypeForm,
} from "../../../store/app";
import TaskModalTop from "./TaskModalTop";
import { useSelector } from "react-redux";
import { selectUserData } from "../../../store/user";

const TaskModal = () => {
  const [setError] = useDispatchError();
  const history = useHistory();
  const userData = useSelector(selectUserData);
  const { id } = useParams();

  const [uploadStatus, setUploadStatus] = useState("active");
  const onChangeUploadStatus = (newStatus = "draft") =>
    setUploadStatus(newStatus);

  const [typeOptions, setTypeOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [formData, onChangeFormData, , fetchedTaskData] = useFormData({
    id: id,
    dataName: API_DATA_TASKS,
  });

  const [formOptions, setFormOptions] = useState({ ..._formOptionsDefault });
  const __onChangeRoleFormOptions = (opts, optsName, type) => {
    const typeOpts = formOptions[type];
    setFormOptions({
      ...formOptions,
      [type]: { ...typeOpts, [optsName]: [...opts] },
    });
  };

  const [disableActions, setIsDisableActions] = useState(false);

  let isFetchData = !!formData;
  if (id && isFetchData) {
    isFetchData = !!fetchedTaskData;
  }

  useEffect(() => {
    const fetchTypesIDs = async () => {
      const taskTypes = await APIService.getDataHandler(
        API_DATA_TASKS_TYPES,
        () => setError("Не удалось получить типы задач."),
      );
      if (!taskTypes) {
        return;
      } else if (!taskTypes?.length) {
        setError("Нет типов заданий.");
        return;
      }

      const newTypeOptions = [];
      for (const t of taskTypes) {
        if (!useFormData_TaskTypesValid.includes(t.name)) {
          continue;
        }

        const defaultOpt = _defaultFormTypes.find(_t => _t.value === t.name);
        const newOpt = { ...defaultOpt, id: t.id };
        newTypeOptions.push(newOpt);
      }

      if (
        !newTypeOptions?.length ||
        newTypeOptions?.length !== useFormData_TaskTypesValid.length
      ) {
        setError("Что-то не так с типами задач.", StoreAppErrorTypeApp);
      } else {
        setTypeOptions([useFormDataPropSelectDefault, ...newTypeOptions]);
      }
    };
    const fetchOptions = async () => {
      const defaultOptsMapper = (opts, isDefaultOpt = true) => {
        const __mapper = o => ({ value: o.name, text: o.name, id: o.id });

        let __resOpts = [];
        if (opts.length) {
          __resOpts = opts.map(__mapper);
        }
        if (isDefaultOpt) {
          __resOpts = [useFormDataPropSelectDefault, ...__resOpts];
        }

        return __resOpts;
      };

      let newFormOptions = {};
      const addNewTypeOptions = (typeName, optsName, opts) => {
        if (!newFormOptions[typeName]) {
          newFormOptions[typeName] = {};
        }

        const prevFormOpts = {
          ...formOptions[typeName],
          ...newFormOptions[typeName],
        };

        newFormOptions[typeName] = {
          ...prevFormOpts,
          [optsName]: [...opts],
        };
      };

      let __fetchedOpts = {};
      for (const optsTypeName of Object.keys(_formOptionsFetchProps)) {
        const optsTypeData = _formOptionsFetchProps[optsTypeName];
        for (const optsName of Object.keys(optsTypeData)) {
          const optsData = optsTypeData[optsName];
          let newOpts = [];

          const __fetchedOptsName = optsData?.APIDataName + optsData?.APIQuery;
          if (__fetchedOpts[__fetchedOptsName]) {
            newOpts = __fetchedOpts[__fetchedOptsName];
          } else {
            newOpts = await APIService.getDataHandler(
              optsData?.APIDataName,
              () =>
                setError(`Не удалось получить опции для '${optsData?.label}'.`),
              0,
              100,
              optsData?.APIQuery,
            );

            if (!newOpts) {
              return;
            }
            __fetchedOpts[__fetchedOptsName] = newOpts;
          }

          switch (optsTypeName) {
            case useFormData_TaskTypePurchase:
            case useFormData_TaskTypeMerch: {
              switch (optsName) {
                case "promopharmacies": {
                  const promosDates = newOpts.map(o => ({
                    id: o.id,
                    value: o.name,
                    text: o.name,
                  }));

                  addNewTypeOptions(optsTypeName, optsName, [
                    { ...useFormDataPropSelectDefault },
                    ...promosDates,
                  ]);
                  break;
                }
                case "areas":
                  addNewTypeOptions(
                    optsTypeName,
                    optsName,
                    defaultOptsMapper(newOpts, false),
                  );
                  break;

                default:
                  addNewTypeOptions(
                    optsTypeName,
                    optsName,
                    defaultOptsMapper(newOpts),
                  );
                  break;
              }
              break;
            }

            default:
              addNewTypeOptions(
                optsTypeName,
                optsName,
                defaultOptsMapper(newOpts),
              );
              break;
          }
        }
      }

      setFormOptions({ ...formOptions, ...newFormOptions });

      // fetch BRAND options
      const brands = await APIService.getDataHandler(API_DATA_BRANDS, () =>
        setError("Не удалось получить опции для 'Бренды'."),
      );
      if (brands) {
        const newBrandsOptions = [
          ...brands.map(b => ({ id: b.id, value: b.name, text: b.name })),
        ];
        setBrandOptions(newBrandsOptions);
      }
    };

    const _parseTaskGeneralData = () => {
      let taskGeneralData = {};

      for (const gdKey of Object.keys(generalFormData)) {
        if (gdKey === "step") {
          continue;
        }

        const gd = generalFormData[gdKey];
        let value = gd?.value;
        let additional = {};

        switch (gdKey) {
          case "startdate":
          case "enddate": {
            const _date = fetchedTaskData[gdKey]?.length
              ? new Date(fetchedTaskData[gdKey])
              : null;

            value = {
              ...value,
              [useFormDataPropDateNestedDateName]: _date,
              [useFormDataPropDateNestedTimeName]: _date,
            };
            break;
          }

          case "type": {
            const _ftd = fetchedTaskData[gdKey];
            additional = { id: _ftd?.id, text: _ftd?.description };
            value = _ftd?.name;
            break;
          }

          case "brands":
            value = fetchedTaskData[gdKey]?.map(b => ({
              id: b?.id,
              value: b?.name,
            }));
            break;

          case "users":
            if (fetchedTaskData[gdKey]?.length) {
              let _pharmacies = [];
              let _agents = [];
              for (const _u of fetchedTaskData[gdKey]) {
                switch (_u?.role?.type) {
                  case useFormData_UserRolePharmacy:
                    _pharmacies?.push(usersDataNew(_u?.id));
                    break;
                  case useFormData_UserRoleAgent:
                    _agents?.push(usersDataNew(_u?.id));
                    break;
                  default:
                    break;
                }
              }

              value = {
                ...value,
                pharmacies: _pharmacies,
                agents: _agents,
              };
            }
            break;

          default:
            value = fetchedTaskData[gdKey] ?? value;
            break;
        }

        taskGeneralData[gdKey] = { ...gd, value, ...additional };
        additional = {};
      }

      return taskGeneralData;
    };
    const _parseTaskMerchInfo = () => {
      const __taskMerchData = fetchedTaskData?.merchandising;
      if (!__taskMerchData) {
        return;
      }

      const __merchData = formData[useFormData_TaskTypeMerch];
      let taskInfo = {};

      for (const mdKey of Object.keys(__merchData)) {
        const md = __merchData[mdKey];
        let value = md?.value;
        let additional = {};

        switch (mdKey) {
          case "areas":
            value = __taskMerchData[mdKey]?.map(a => ({
              id: a.id,
              value: a.name,
            }));
            break;

          default:
            value = __taskMerchData[mdKey] ?? value;
            break;
        }

        taskInfo[mdKey] = { ...md, value, ...additional };
        additional = {};
      }

      return taskInfo;
    };
    const _parseTaskPurchaseInfo = () => {
      const __taskPurchaseData = fetchedTaskData?.purchase;
      if (!__taskPurchaseData) {
        return;
      }

      const __purchaseData = formData[useFormData_TaskTypePurchase];
      let purchaseInfo = {};

      for (const pdKey of Object.keys(__purchaseData)) {
        const pd = __purchaseData[pdKey];
        let value = pd?.value;
        let additional = {};

        switch (pdKey) {
          case "month": {
            const _mdata = __taskPurchaseData[pdKey];
            value = _mdata?.name;
            additional = { text: _mdata?.name, id: _mdata?.id };
            break;
          }
          default:
            value = __taskPurchaseData[pdKey] ?? value;
            break;
        }

        purchaseInfo[pdKey] = { ...pd, value, ...additional };
        additional = {};
      }

      return purchaseInfo;
    };
    const _parseTaskSaleInfo = () => {
      const __taskSaleData = fetchedTaskData?.sale;
      if (!__taskSaleData) {
        return;
      }

      const __saleData = formData[useFormData_TaskTypeSale];
      let saleInfo = {};

      for (const pdKey of Object.keys(__saleData)) {
        const pd = __saleData[pdKey];
        let value = pd?.value;
        let additional = {};

        switch (pdKey) {
          case "month": {
            const _mdata = __taskSaleData[pdKey];
            value = _mdata?.name;
            additional = { text: _mdata?.name, id: _mdata?.id };
            break;
          }
          default:
            value = __taskSaleData[pdKey] ?? value;
            break;
        }

        saleInfo[pdKey] = { ...pd, value, ...additional };
        additional = {};
      }

      return saleInfo;
    };
    const _parseTaskTrainingInfo = () => {
      const __taskTrainingData = fetchedTaskData?.training;
      if (!__taskTrainingData) {
        return;
      }

      const __trainingData = formData[useFormData_TaskTypeTraining];
      let trainingInfo = {};

      for (const tdKey of Object.keys(__trainingData)) {
        const td = __trainingData[tdKey];
        let value = td?.value;
        let additional = {};

        switch (tdKey) {
          case "timetopass":
            const _minutesData = parseInt(__taskTrainingData[tdKey] ?? "1");
            let _hours = _minutesData / 60;
            const _mins = parseInt((_hours % 1) * 100);
            const _date = new Date();
            _date.setHours(parseInt(_hours));
            _date.setMinutes(_mins);

            value = _date;
            break;

          case "studiings":
            const _sdata = __taskTrainingData[tdKey];
            value = _sdata?.map(s => ({
              description: {
                label: "Текст",
                required: true,
                type: useFormDataPropTypeInput,
                value: s?.description,
              },
              picture: {
                label: "Изображение",
                required: true,
                type: useFormDataPropTypeImage,
                value: s?.picture,
              },
              tests: {
                label: "Варианты ответов",
                required: true,
                type: useFormDataPropTypeMultiSelect,
                value:
                  s?.tests?.map(t => ({
                    question: {
                      label: "Текст вопроса",
                      required: true,
                      type: useFormDataPropTypeInput,
                      value: t?.question,
                    },
                    picture: {
                      label: "Изображение",
                      required: true,
                      type: useFormDataPropTypeInput,
                      value: t?.picture,
                    },
                    answers: {
                      label: "Варианты ответов",
                      required: true,
                      type: useFormDataPropTypeMultiSelect,
                      value: t?.answers?.map(a => ({
                        answer: {
                          label: "Текст варианта ответа",
                          required: true,
                          type: useFormDataPropTypeInput,
                          value: a?.answer,
                        },
                        correct: {
                          label: "Правильный ответ",
                          required: true,
                          type: useFormDataPropTypeSwitch,
                          value: a?.correct,
                        },
                      })),
                    },
                  })) ?? [],
              },
            }));
            break;

          default:
            value = __taskTrainingData[tdKey] ?? value;
            break;
        }

        trainingInfo[tdKey] = { ...td, value, ...additional };
        additional = {};
      }

      return trainingInfo;
    };
    const parseTaskData = () => {
      const newGeneralData = _parseTaskGeneralData();

      if (newGeneralData) {
        const _typedParsers = {
          [useFormData_TaskTypeMerch]: _parseTaskMerchInfo,
          [useFormData_TaskTypePurchase]: _parseTaskPurchaseInfo,
          [useFormData_TaskTypeSale]: _parseTaskSaleInfo,
          [useFormData_TaskTypeTraining]: _parseTaskTrainingInfo,
        };

        const _formType = newGeneralData?.type?.value;
        if (_formType) {
          const _typedData = _typedParsers[_formType]();

          if (_typedData) {
            const _upd = {
              ...formData,
              [useFormData_TaskGeneralInfo]: {
                ...generalFormData,
                ...newGeneralData,
              },
              [_formType]: {
                ...formData[_formType],
                ..._typedData,
              },
            };
            onChangeFormData(_upd, useFormDataPropNameRoot);
            return true;
          }
        }
      }
    };

    if (isFetchData) {
      fetchTypesIDs();
      fetchOptions();
      if (fetchedTaskData) {
        parseTaskData();
      }
    }
  }, [isFetchData, fetchedTaskData]);

  const __onChangeFormData = (value, valueName, typeName, nestedValueName) => {
    const typeData = formData[typeName];
    const prevValueData = typeData[valueName];
    let newData = undefined;

    switch (prevValueData?.type) {
      case useFormDataPropTypeDateSingle:
      case useFormDataPropTypeTaskTests:
      case useFormDataPropTypeMultiSelect:
        newData = { value };
        break;

      case useFormDataPropTypeTaskUsers:
      case useFormDataPropTypeDate:
        newData = {
          ...prevValueData,
          value: {
            ...prevValueData.value,
            [nestedValueName]: value,
          },
        };
        break;

      case useFormDataPropTypeImage:
        if (value?.size && value?.size > API_UPLOAD_MAX_SIZE) {
          setError(
            "Файл слишком большой. Максимальный размер: 1Мб.",
            StoreAppErrorTypeForm,
          );
          return;
        }
        newData = { value };
        break;

      default:
        newData = typeof value === "object" ? value : { value };
        break;
    }

    if (newData) {
      const newFormData = {
        ...formData,
        [typeName]: {
          ...typeData,
          [valueName]: { ...prevValueData, ...newData },
        },
      };

      onChangeFormData({ ...newFormData }, useFormDataPropNameRoot);
    }
  };

  const generalFormData = formData
    ? formData[useFormData_TaskGeneralInfo]
    : null;
  const onChangeGeneralFormData = (value, valueName, nestedValueName = null) =>
    __onChangeFormData(
      value,
      valueName,
      useFormData_TaskGeneralInfo,
      nestedValueName,
    );
  const onChangeTrainingFormData = (value, valueName, nestedValueName = null) =>
    __onChangeFormData(
      value,
      valueName,
      useFormData_TaskTypeTraining,
      nestedValueName,
    );

  const onChangeFormStep = value => onChangeGeneralFormData(value, "step");
  const tab = generalFormData?.step?.value ?? useFormData_TaskStepInfo;
  const isTestTab =
    generalFormData?.type?.value === useFormData_TaskTypeTraining;

  const renderTab = () => {
    switch (generalFormData?.step?.value) {
      case useFormData_TaskStepInfo:
        return (
          <TaskInfo
            disableActions={disableActions}
            onChangeUploadStatus={onChangeUploadStatus}
            brandOptions={brandOptions}
            typeOptions={typeOptions}
            formOptions={formOptions}
            onChangeFormOptions={__onChangeRoleFormOptions}
            formData={formData}
            onChangeFormData={__onChangeFormData}
            generalFormData={generalFormData}
            onChangeGeneralFormData={onChangeGeneralFormData}
            back={() => history.push(ROUTE_TASKS)}
            next={() => onChangeFormStep(useFormData_TaskStepUsers)}
          />
        );
      case useFormData_TaskStepUsers:
        return (
          <TaskUsers
            disableActions={disableActions}
            taskType={generalFormData?.type?.value}
            onChangeUploadStatus={onChangeUploadStatus}
            generalFormData={generalFormData}
            onChangeGeneralFormData={onChangeGeneralFormData}
            back={() => onChangeFormStep(useFormData_TaskStepInfo)}
            next={
              isTestTab
                ? () => onChangeFormStep(useFormData_TaskStepTest)
                : null
            }
          />
        );
      case useFormData_TaskStepTest:
        return (
          <TaskTest
            disableActions={disableActions}
            onChangeUploadStatus={onChangeUploadStatus}
            tasksData={formData[useFormData_TaskTypeTraining]}
            onChangeTasksData={onChangeTrainingFormData}
            back={() => onChangeFormStep(useFormData_TaskStepUsers)}
          />
        );

      default:
        return null;
    }
  };

  const onSubmitMerch = merchData => {
    let uploadData = {};
    let isNoErr = true;

    for (const mdKey of Object.keys(merchData)) {
      const md = merchData[mdKey];
      if (!md?.required && !md?.value?.length) {
        continue;
      }

      if (!md?.value?.length) {
        setError(`Пропущено поле '${md?.label}'.`, StoreAppErrorTypeForm);
        isNoErr = false;
        break;
      }

      switch (mdKey) {
        case "areas":
          uploadData[mdKey] = md?.value?.map(a => a.id);
          break;

        case "rewardagent":
        case "rewardpharmacy":
          uploadData[mdKey] = parseFloat(md?.value);
          break;

        default:
          uploadData[mdKey] = md?.value;
          break;
      }
    }

    if (isNoErr) {
      return uploadData;
    }
  };

  const onSubmitPurchaseSale = psData => {
    if (!psData?.month?.id || !psData?.month?.value?.length) {
      setError(
        `Пропущен поле '${psData?.month?.label}'.`,
        StoreAppErrorTypeForm,
      );
      return;
    }

    return { month: psData?.month?.id };
  };

  const __onSubmitTrainingStudiingsTestsAnswers = (
    answersData,
    studyIdx,
    testIdx,
  ) => {
    let uploadDataArr = [];
    let isNoErr = true;

    const checkIsAtLeastOneIsMarkedCorrect = () => {
      for (const a of answersData?.value) {
        if (a?.correct?.value) {
          return true;
        }
      }
      return false;
    };
    if (!checkIsAtLeastOneIsMarkedCorrect()) {
      isNoErr = false;
      return;
    }

    let idx = 0;
    for (const answer of answersData?.value) {
      let uploadData = {};

      for (const aKey of Object.keys(answer)) {
        const a = answer[aKey];

        switch (aKey) {
          case "answer":
            //   if (!a?.value) {
            //     // setError(
            //     //   `Пропущен текст Варианта ответа №${idx + 1} в Тесте №${
            //     //     testIdx + 1
            //     //   } Вопроса №${studyIdx + 1}`,
            //     //   StoreAppErrorTypeForm,
            //     // );
            //     isNoErr = false;
            //   }

            if (isNoErr) {
              uploadData[aKey] = a?.value;
            }
            break;

          default:
            uploadData[aKey] = a?.value;
            break;
        }

        if (!isNoErr) {
          return;
        }
      }

      uploadDataArr.push(uploadData);
      ++idx;
    }

    if (isNoErr) {
      return uploadDataArr;
    }
  };
  const __onSubmitTrainingStudiingsTests = async (testsData, studyIdx) => {
    let uploadDataArr = [];
    let isNoErr = true;

    let idx = 0;
    for (const test of testsData?.value) {
      let uploadData = {};

      for (const tKey of Object.keys(test)) {
        const t = test[tKey];

        // if (!t?.value?.length && tKey !== "picture") {
        //   setError(
        //     `Пропущено поле '${t?.label}' в Тесте №${idx + 1} Вопроса №${
        //       studyIdx + 1
        //     }`,
        //     StoreAppErrorTypeForm,
        //   );
        //   isNoErr = false;
        //   return;
        // }

        switch (tKey) {
          case "picture": {
            if (!t?.value) {
              break;
              // isNoErr = false;
              // setError(
              //   `Пропущено поле '${t?.label}' в Тесте №${idx + 1} Вопроса №${
              //     studyIdx + 1
              //   }`,
              //   StoreAppErrorTypeForm,
              // );
              // return;
            }

            if (t?.value?.id) {
              uploadData.picture = t?.value?.id;
            } else {
              const uFile = await APIService.postUpload(t?.value).then(
                resp => resp.data,
                err => {
                  isNoErr = false;
                  setError(
                    `Для Теста №${idx + 1}-Вопроса №${
                      studyIdx + 1
                    } не удалось загрузить Изображение: '${t?.value.name}' (${
                      err.message
                    })`,
                  );
                },
              );
              if (!uFile || !isNoErr) {
                return;
              }

              if (uFile?.length && uFile[0]) {
                uploadData.picture = uFile[0].id;
              } else {
                if (isNoErr) {
                  isNoErr = false;
                  setError("Не удалось получить 'Изображение' от сервера.");
                }
                return;
              }
            }
            break;
          }

          case "answers": {
            const _as = __onSubmitTrainingStudiingsTestsAnswers(
              t,
              studyIdx,
              idx,
            );
            isNoErr = !!_as;
            if (isNoErr) {
              uploadData[tKey] = _as;
            }
            break;
          }

          default:
            uploadData[tKey] = t?.value;
            break;
        }
      }

      uploadDataArr.push(uploadData);
      ++idx;
    }

    if (isNoErr) {
      return uploadDataArr;
    }
  };
  const __onSubmitTrainingStudiings = async studiingsData => {
    let uploadDataArr = [];
    let isNoErr = true;

    let idx = 0;
    for (const study of studiingsData?.value) {
      let uploadData = {};

      for (const sKey of Object.keys(study)) {
        const s = study[sKey];

        // if (!s?.value?.length && sKey !== "picture") {
        //   isNoErr = false;
        //   setError(
        //     `Пропущено поле '${s?.label}' в Вопросе №${idx + 1}`,
        //     StoreAppErrorTypeForm,
        //   );
        //   return;
        // }

        switch (sKey) {
          case "picture": {
            if (!s?.value) {
              break;
            }

            if (s?.value?.id) {
              uploadData.picture = s?.value?.id;
            } else {
              const uFile = await APIService.postUpload(s?.value).then(
                resp => resp.data,
                err => {
                  isNoErr = false;
                  setError(
                    `Для Вопроса №${
                      idx + 1
                    } не удалось загрузить Изображение: '${s?.value.name}' (${
                      err.message
                    })`,
                  );
                },
              );
              if (!uFile || !isNoErr) {
                return;
              }

              if (uFile?.length && uFile[0]) {
                uploadData.picture = uFile[0].id;
              } else {
                if (isNoErr) {
                  isNoErr = false;
                  setError("Не удалось получить 'Изображение' от сервера.");
                }
                return;
              }
            }
            break;
          }

          case "tests":
            const tests = await __onSubmitTrainingStudiingsTests(s, idx);
            if (!!tests) {
              uploadData[sKey] = tests;
            }
            break;

          default:
            uploadData[sKey] = s?.value;
            break;
        }

        if (!isNoErr) {
          return;
        }
      }

      uploadDataArr.push(uploadData);

      ++idx;
    }

    if (isNoErr) {
      return uploadDataArr;
    }
  };
  const onSubmitTraining = async trainingData => {
    let uploadData = {};
    let isNoErr = true;

    for (const tdKey of Object.keys(trainingData)) {
      const td = trainingData[tdKey];

      if (td?.value?.required && !td?.value?.length && tdKey !== "timetopass") {
        setError(`Пропущено поле '${td?.label}'.`, StoreAppErrorTypeForm);
        isNoErr = false;
        break;
      }

      switch (tdKey) {
        case "timetopass": {
          if (!td?.value) {
            setError(`Пропущено поле '${td?.label}'.`, StoreAppErrorTypeForm);
            isNoErr = false;
            return;
          }

          const _date = new Date(td?.value);
          const _hh = _date.getHours();
          const _mm = _date.getMinutes();

          uploadData[tdKey] = _hh * 60 + _mm; // time to pass the training in minutes
          break;
        }

        case "studiings": {
          uploadData[tdKey] = await __onSubmitTrainingStudiings(td);
          isNoErr = !!uploadData[tdKey];
          break;
        }

        default:
          uploadData[tdKey] = parseFloat(td?.value);
          break;
      }

      if (!isNoErr) {
        return;
      }
    }

    if (isNoErr) {
      return {
        training: { ...uploadData },
      };
    }
  };

  const onSubmitGeneralData = async () => {
    let uploadData = { author: userData?.id, status: uploadStatus };
    let isNoErr = true;

    for (const gdKey of Object.keys(generalFormData)) {
      const gd = generalFormData[gdKey];
      if (!gd?.required && !gd?.value?.length && gdKey !== "users") {
        continue;
      }

      switch (gdKey) {
        case "picture":
          if (!gd?.value) {
            setError(`Пропущено поле '${gd?.label}'.`, StoreAppErrorTypeForm);
            isNoErr = false;
            return;
          }
          break;

        case "enddate":
        case "startdate":
          break;

        case "users":
          if (!gd?.value?.agents?.length && !gd?.value?.pharmacies?.length) {
            setError(
              `Выберите пользователей на шаге '2. Пользователи'.`,
              StoreAppErrorTypeForm,
            );
            isNoErr = false;
            return;
          }
          break;

        default:
          if (!gd?.value?.length) {
            setError(`Пропущено поле '${gd?.label}'.`, StoreAppErrorTypeForm);
            isNoErr = false;
            return;
          }
          break;
      }

      switch (gdKey) {
        case "type":
          uploadData[gdKey] = gd?.id;
          break;

        case "brands":
          uploadData[gdKey] = gd?.value?.map(v => v.id);
          break;

        case "startdate":
        case "enddate": {
          if (!gd?.value?.date) {
            setError(
              `Пропущено поле '${gd?.label}'-'Дата'`,
              StoreAppErrorTypeForm,
            );
            return;
          }
          if (!gd?.value?.time) {
            setError(
              `Пропущено поле '${gd?.label}'-'Время'`,
              StoreAppErrorTypeForm,
            );
            return;
          }

          const _date = new Date(gd?.value?.date);
          const _time = new Date(gd?.value?.time);

          const _hh = _time.getHours();
          const _mm = _time.getMinutes();
          const _ss = _time.getSeconds();

          _date.setHours(_hh, _mm, _ss);

          uploadData[gdKey] = _date;
          break;
        }

        case "users": {
          const _mapRepeatsUsers = _users => {
            if (!_users?.length) {
              return [];
            }

            let _res = [];
            for (const _u of _users) {
              _res = [..._res, ...Array(_u.repeats).fill(_u.id)];
            }
            return _res;
          };

          const _agents = _mapRepeatsUsers(gd?.value?.agents);
          const _pharmacies = _mapRepeatsUsers(gd?.value?.pharmacies);
          const _uids = [..._agents, ..._pharmacies];

          uploadData[gdKey] = _uids;
          break;
        }

        case "picture":
          // uploading picture as the last one because of checking all other data
          break;

        case "step":
          break;

        default:
          uploadData[gdKey] = gd?.value;
          break;
      }

      if (!isNoErr) {
        return;
      }
    }

    if (isNoErr) {
      if (generalFormData?.picture?.value?.id) {
        uploadData.picture = generalFormData?.picture?.value?.id;
      } else {
        const uFile = await APIService.postUpload(
          generalFormData?.picture?.value,
        ).then(
          resp => resp.data,
          err => {
            isNoErr = false;
            setError(
              `Не удалось загрузить Изображение: '${generalFormData?.picture?.value.name}' (${err.message})`,
            );
          },
        );
        if (!uFile) {
          return;
        }

        if (uFile?.length && uFile[0]) {
          uploadData.picture = uFile[0].id;
        } else {
          if (isNoErr) {
            isNoErr = false;
            setError("Не удалось получить 'Изображение' от сервера.");
          }
          return;
        }
      }
    }

    if (isNoErr) {
      return uploadData;
    }
  };

  const onSubmitHandler = async () => {
    if (disableActions) {
      return;
    } else {
      setIsDisableActions(true);
    }

    let generalUploadData = {};
    let typedUploadData = {};
    let isNoErr = true;

    const formType = generalFormData?.type?.value;
    if (!generalFormData?.type?.id) {
      setError("Пропущен 'Тип' задачи.", StoreAppErrorTypeForm);
      setIsDisableActions(false);
      return;
    }

    switch (formType) {
      case useFormData_TaskTypeMerch:
        typedUploadData = onSubmitMerch(formData[useFormData_TaskTypeMerch]);
        break;

      case useFormData_TaskTypePurchase:
        typedUploadData = onSubmitPurchaseSale(
          formData[useFormData_TaskTypePurchase],
        );
        break;

      case useFormData_TaskTypeSale:
        typedUploadData = onSubmitPurchaseSale(
          formData[useFormData_TaskTypeSale],
        );
        break;

      case useFormData_TaskTypeTraining:
        typedUploadData = await onSubmitTraining(
          formData[useFormData_TaskTypeTraining],
        );
        break;

      default:
        setError(
          `Не возможно создать задачу с выбранным Типом(${formType}).`,
          StoreAppErrorTypeForm,
        );
        setIsDisableActions(false);
        return;
    }

    isNoErr = !!typedUploadData;
    if (isNoErr) {
      generalUploadData = await onSubmitGeneralData();
      isNoErr = !!generalUploadData;
    }

    if (isNoErr) {
      const _ud = { ...generalUploadData, ...typedUploadData };
      let isSuccess = false;
      if (!id) {
        isSuccess = await APIService.postDataHandler(API_DATA_TASKS, _ud, () =>
          setError("Не удалось создать Задание."),
        );
      } else {
        isSuccess = await APIService.putDataByIDHandler(
          API_DATA_TASKS,
          id,
          _ud,
          () => setError("Не удалось обновить данные Задания."),
        );
      }

      if (isSuccess) {
        history.push(ROUTE_TASKS);
      } else {
        setIsDisableActions(false);
      }
    } else {
      setIsDisableActions(false);
    }
  };

  return (
    <form
      className='tasks-page'
      onSubmit={e => {
        e.preventDefault();
        onSubmitHandler();
      }}>
      <TaskModalTop
        openTab={onChangeFormStep}
        tab={tab}
        isTestTab={isTestTab}
      />

      {renderTab()}
    </form>
  );
};

export default TaskModal;
