export const shortenedTitleMaxLength = 24;
export const shortenedDescriptionMaxLength = 140;

export const getShortenedTitle = (title: string): string =>
  getShortenedText(title, shortenedTitleMaxLength);
export const getShortenedDescription = (title: string): string =>
  getShortenedText(title, shortenedDescriptionMaxLength);

const getShortenedText = (text: string, limit = 50): string =>
  text?.length && text.length > limit ? `${text.slice(0, limit)}...` : text;

export default getShortenedText;
