import { Text, Icon, Button, Spacer } from "../../common";
import Skeleton from "@material-ui/lab/Skeleton";
import useAPIData from "../../../shared/hooks/useAPIData";
import {
  API_DATA_SHOP_GROUPS,
  getImageURL,
} from "../../../services/api.service";
import { useHistory } from "react-router";
import { ROUTE_SHOP_GROUPS_NEW } from "../../../AppRoutes";
import Pagination from "../../common/table/Pagination";

/**
 * Some classes are still names as categories instead of groups
 * because originally it's all was named as categories
 */

const GroupsTable = () => {
  const history = useHistory();
  const [groups, pagination] = useAPIData(API_DATA_SHOP_GROUPS);

  const types = {
    products: "Товары",
  };

  const onToEdit = id => {
    history.push(`${ROUTE_SHOP_GROUPS_NEW}/${id}`);
  };

  if (!groups) {
    return (
      <div className='categories-card'>
        {new Array(4).fill(0).map((el, i) => (
          <div className='category-card' key={i}>
            <Skeleton variant='circle' width='12.75rem' height='12.75rem' />
            <div className='category-card-content'>
              <Skeleton width='50%' height='4rem' />
              <Spacer top='1.5rem' />
              <Skeleton height='2.5rem' />
              <Skeleton height='2.5rem' />
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className='categories-card'>
      {groups.map(item => {
        const imgURL =
          item?.picture?.formats?.thumbnail?.url ?? item?.picture?.url;

        return (
          <div className='category-card' key={item.id}>
            {imgURL ? (
              <img
                src={getImageURL(imgURL)}
                alt={item.name}
                className='category-card__img'
              />
            ) : (
              <Spacer left='18.75rem' />
            )}
            <div className='category-card-content'>
              <Text type='lg' isBold>
                {item.name}
              </Text>
              <Spacer top='1.5rem' />
              <div className='d-flex align-center'>
                <div className='flex-50'>
                  <Text type='md' isBold>
                    {types[item.type]}
                  </Text>
                </div>
              </div>
              <div className='d-flex align-center'>
                <div className='d-flex flex-50'>
                  <Icon icon='users' size='2.25rem' />
                  <Spacer left='0.75rem' />
                  <Text type='md'>Доступно: </Text>
                </div>
                <div className='flex-50'>
                  <Text type='md' isBold>
                    {item?.products?.length ?? 0}
                  </Text>
                </div>
              </div>
              <Spacer top='1.5rem' />
              <Button
                type='primary-outline'
                onClick={e => {
                  e?.preventDefault();
                  onToEdit(item.id);
                }}>
                <Icon icon='edit-square' size='2rem' />
                <Spacer left='0.5rem' />
                <Text type='sm' color='white'>
                  Редактировать
                </Text>
              </Button>
            </div>
          </div>
        );
      })}

      {pagination && pagination.total > pagination.limit ? (
        <div className='d-flex align-center' style={{ width: "100%" }}>
          <Pagination {...pagination} />
        </div>
      ) : null}
    </div>
  );
};

export default GroupsTable;
