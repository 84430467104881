import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Text,
  Spacer,
  Input,
  Select,
  FileInput,
  Button,
  Icon,
  Modal,
  Autocomplete,
} from "../../common";
import { ROUTE_SHOP_GROUPS } from "../../../AppRoutes";
import APIService, {
  API_DATA_ROLES,
  API_DATA_SHOP_CATEGORIES,
  API_DATA_SHOP_GROUPS,
} from "../../../services/api.service";
import useFormData, {
  useFormDataPropSelectDefault,
  useFormDataTextRequired,
} from "../../../shared/hooks/useFormData";
import useDispatchError from "../../../shared/hooks/useDispatchError";

const CategoryModal = ({ isNew }) => {
  const [setError] = useDispatchError();
  const history = useHistory();
  const { id } = useParams();

  const _isNew = isNew && !id;

  const [disabledActions, setDisabledActions] = useState(false);

  const [formData, onChangeData, onSubmit] = useFormData({
    id: id,
    dataName: API_DATA_SHOP_GROUPS,
  });
  const onChangeSelect = (value, valueName, options) => {
    let opt = options.find(o => o?.value === value);
    if (!opt?.value) {
      opt = { ...useFormDataPropSelectDefault };
    }
    onChangeData(opt, valueName);
  };
  const onChangeMultiSelect = (value, valueName, options) => {
    const newValues = [];
    for (const v of value) {
      const defaultOpt = options?.find(o => o.value === v);
      if (defaultOpt?.id) {
        newValues.push(defaultOpt);
      }
    }
    onChangeData({ value: newValues }, valueName);
  };
  const onSelectAllOptions = (valueName, options) => {
    const newValue = JSON.parse(JSON.stringify(options));
    if (newValue?.length !== formData[valueName]?.value?.length) {
      onChangeData({ value: newValue }, valueName);
    }
  };

  const [options, setOptions] = useState({
    roles: [],
    categories: [],
  });

  useEffect(() => {
    const fetchOptions = async () => {
      const roles = await APIService.getDataHandler(API_DATA_ROLES, () =>
        setError("Не удалось получить опции для 'Роль'."),
      ).then(data => data?.roles);

      const categories = await APIService.getDataHandler(
        API_DATA_SHOP_CATEGORIES,
        () => {
          setError("Не удалось получить опции для 'Тип'.");
        },
      );

      let newOptions = { ...options };
      if (roles?.length) {
        const filteredDefaults = roles.filter(
          r => r.type !== "authenticated" && r.type !== "public",
        );
        newOptions.roles = filteredDefaults.map(o => ({
          id: o.id,
          value: o.description,
        }));
      }

      if (categories?.length) {
        newOptions.categories = [
          { ...useFormDataPropSelectDefault },
          ...categories.map(o => ({ id: o.id, value: o.name, text: o.name })),
        ];
      }

      setOptions({ ...newOptions });
    };

    fetchOptions();
  }, []);

  const onSubmitHandler = async e => {
    if (disabledActions) {
      return;
    } else {
      setDisabledActions(true);
    }

    e.preventDefault();
    const newObj = await onSubmit();

    if (newObj) {
      history.push(ROUTE_SHOP_GROUPS);
    } else {
      setDisabledActions(false);
    }
  };
  const onDelete = async e => {
    e.preventDefault();
    if (!_isNew) {
      await APIService.delDataByIDHandler(API_DATA_SHOP_GROUPS, id, () =>
        setError(`Не удалось удалить группу(${id})`),
      );
    }
    history.push(ROUTE_SHOP_GROUPS);
  };

  return (
    <Modal
      back={() => history.push(ROUTE_SHOP_GROUPS)}
      title={_isNew ? "Добавление группы" : "Редактирование группы"}>
      <form onSubmit={onSubmitHandler}>
        <div className='d-flex'>
          <div className='flex-50'>
            <Text color='secondary' type='md'>
              {useFormDataTextRequired(
                formData?.name?.label,
                formData?.name?.required,
              )}
            </Text>
            <Spacer top='1.5rem' />
            <Input
              value={formData?.name?.value}
              required={formData?.name?.required}
              onChange={e => onChangeData(e.target.value, "name")}
              style={{ width: "100%" }}
              placeholder='Название новой группы'
            />

            <Spacer top='3rem' />

            <Text color='secondary' type='md'>
              {useFormDataTextRequired(
                formData?.picture?.label,
                formData?.picture?.required,
              )}
            </Text>
            <Spacer top='1.5rem' />
            <FileInput
              value={formData?.picture?.value}
              required={formData?.picture?.required}
              onChange={f => onChangeData(f, "picture")}
            />
          </div>

          <Spacer left='4rem' />

          <div className='flex-50'>
            <Text color='secondary' type='md'>
              {useFormDataTextRequired(
                formData?.roles?.label,
                formData?.roles?.required,
              )}
            </Text>
            <Spacer top='1.5rem' />

            <div className='d-flex align-center'>
              <Autocomplete
                style={{ width: "61rem" }}
                multiple={true}
                id='roles'
                placeholder='Выберите роли'
                disabled={!options?.roles?.length}
                value={
                  formData?.roles?.value?.length
                    ? formData?.roles?.value?.map(v => v.value)
                    : []
                }
                onValueChange={option =>
                  onChangeMultiSelect(option, "roles", options?.roles)
                }
                options={options?.roles?.map(c => c.value)}
                getOptionLabel={o => o}
              />

              <Spacer left='1.5rem' />

              <Icon
                icon={`plus-outline${options?.roles?.length ? "_blue" : ""}`}
                size='4rem'
                onClick={() => {
                  if (options?.roles?.length) {
                    onSelectAllOptions("roles", options?.roles);
                  }
                }}
              />
            </div>
            <Spacer top='3rem' />

            <Text color='secondary' type='md'>
              {useFormDataTextRequired(
                formData?.category?.label,
                formData?.category?.required,
              )}
            </Text>
            <Spacer top='1.5rem' />
            <Select
              disabled={!options.categories?.length}
              label='Укажите тип'
              value={formData?.category?.value}
              required={formData?.category?.required}
              style={{ width: "100%" }}
              onChange={e =>
                onChangeSelect(e.target.value, "category", options.categories)
              }
              variants={options.categories}
            />
          </div>
        </div>
        <div className='flex-1'></div>
        <Spacer top='3rem' />

        <div className='d-flex'>
          <Button type='outline' onClick={onDelete} disabled={disabledActions}>
            <Icon icon='remove' size='2rem' />
            <Spacer left='1.5rem' />
            <Text type='md'>Удалить</Text>
          </Button>
          <Spacer left='auto' />
          <Button type='primary' btnType='submit' disabled={disabledActions}>
            <Text type='md'>{_isNew ? "Готово" : "Сохранить"}</Text>
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default CategoryModal;
