import { TextareaAutosize } from "@material-ui/core";

const TextArea = ({
  placeholder,
  value,
  onChange,
  type,
  style,
  rowsMin,
  required,
  disabled = false,
}) => {
  return (
    <TextareaAutosize
      required={required}
      rowsMin={rowsMin || 4}
      placeholder={placeholder}
      className='custom-text-area'
      style={{ ...style, resize: "none" }}
      type={type}
      value={value}
      disabled={disabled}
      onChange={onChange ? onChange : () => { }}
    />
  );
};

export default TextArea;
