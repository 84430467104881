import { TextField, InputAdornment } from "@material-ui/core";

const CustomInput = ({
  placeholder,
  value,
  onChange,
  onBlur = () => {},
  leftIcon,
  rightIcon,
  type,
  style,
  required,
  className = "",
  disabled = false,
}) => {
  return (
    <TextField
      onBlur={onBlur}
      disabled={disabled}
      className={className}
      required={required}
      placeholder={placeholder}
      InputProps={{
        startAdornment: leftIcon ? (
          <InputAdornment position='start'>{leftIcon}</InputAdornment>
        ) : null,
        endAdornment: rightIcon ? (
          <InputAdornment position='end'>{rightIcon}</InputAdornment>
        ) : null,
      }}
      style={style}
      type={type}
      value={value}
      onChange={e => {
        if (onChange) {
          onChange(e);
        }
      }}
      variant='outlined'
    />
  );
};

export default CustomInput;
