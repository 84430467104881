import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import queryStringify from "qs-stringify";

import APIService, {
  API_DATA_AGENTLINES,
  API_DATA_CITIES,
  API_DATA_COMMANDS,
  API_DATA_USERS,
  API_DATA_USERS_QUERY_MED_MEMBERS,
} from "../../services/api.service";
import useAPIData from "../../shared/hooks/useAPIData";
import { convertDate } from "../../shared/functions/convertDate";
import { StoreAppErrorTypeApp } from "../../store/app";
import {
  Table,
  Text,
  Select,
  Spacer,
  Icon,
  Button,
  Checkbox,
  Input,
} from "../common";
import useDispatchError from "../../shared/hooks/useDispatchError";
import getShortenedText from "../../shared/functions/getShortenedText";
import { ROUTE_USERS_NEW } from "../../AppRoutes";
import { useFormDataPropSelectDefault } from "../../shared/hooks/useFormData";

const MedicalMembersTable = ({ searchQuery }) => {
  const history = useHistory();
  const [setError] = useDispatchError();

  const [filters, setFilters] = useState({
    [API_DATA_AGENTLINES]: {
      query: "agent.agentline.id",
      data: API_DATA_AGENTLINES,
      options: [],
      value: "",
    },
    [API_DATA_COMMANDS]: {
      query: "agent.command.id",
      data: API_DATA_COMMANDS,
      options: [],
      value: "",
    },
    status: {
      query: "status",
      options: [
        { ...useFormDataPropSelectDefault },
        { value: "active", text: "Активирован" },
        { value: "new", text: "Новая регистрация" },
      ],
      value: "",
    },
    [API_DATA_CITIES]: {
      query: "agent.city.id",
      data: API_DATA_CITIES,
      options: [],
      value: "",
    },
  });
  const getFiltersQuery = (_f = filters) => {
    let qsQueries = { _q: searchQuery };

    for (const fKey of Object.keys(_f)) {
      const fData = _f[fKey];
      if (fData.value?.length) {
        switch (fKey) {
          case "status":
            qsQueries[fData.query] = fData.value;
            break;

          default:
            qsQueries[fData.query] = fData.options.find(
              o => o.value === fData.value,
            ).id;
            break;
        }
      }
    }

    const queryRequest = queryStringify(qsQueries);

    let resQuery = API_DATA_USERS_QUERY_MED_MEMBERS;
    if (queryRequest?.length) {
      resQuery += `&${queryRequest}`;
    }

    return resQuery;
  };
  const onChangeFilter = async (value, filterName) => {
    const oldValue = filters[filterName].value;
    if (oldValue === value) {
      return;
    }

    const newFilters = {
      ...filters,
      [filterName]: {
        ...filters[filterName],
        value,
      },
    };

    let resQuery = getFiltersQuery(newFilters);

    await updateAgents(resQuery);
    setFilters(newFilters);
  };

  const [agents, pagination, updateAgents, deleteAgents, onChangePage] =
    useAPIData(API_DATA_USERS, getFiltersQuery());

  useEffect(() => {
    if (agents) {
      updateAgents(getFiltersQuery());
    }
  }, [searchQuery]);

  const [selectedAgents, setSelectedAgents] = useState([]);
  const onClickAppendAgent = agent => {
    if (agent?.agent?.command?.name) {
      setError(
        `Пользователь(${agent.firstname} ${agent.lastname}) уже состоит в команде(${agent?.agent?.command?.name}).`,
        StoreAppErrorTypeApp,
      );
      return;
    }

    let _sa = [...selectedAgents];
    const aid = agent.id;
    const aidIndex = _sa.findIndex(_id => _id === agent.id);
    if (-1 !== aidIndex) {
      _sa.splice(aidIndex, 1);
    } else {
      _sa.push(aid);
    }

    setSelectedAgents([..._sa]);
  };
  const isAgentSelected = aid => selectedAgents.includes(aid);

  const [newTeamName, setNewTeamName] = useState("");
  const onCreateNewTeam = async () => {
    if (!newTeamName?.length) {
      setError(
        "Невозможно создать команду без названия.",
        StoreAppErrorTypeApp,
      );
      return;
    }
    if (!selectedAgents?.length) {
      setError(
        "Выберите пользователей что-бы создать команду.",
        StoreAppErrorTypeApp,
      );
      return;
    }

    let isNoErr = true;

    const newCommand = await APIService.postDataHandler(
      API_DATA_COMMANDS,
      {
        name: newTeamName,
      },
      () =>
        setError(
          "Произошла ошибка при создании новой команды или команда с таким названием уже существует.",
        ),
    );
    isNoErr = !!newCommand;
    if (!isNoErr) {
      return;
    }

    const newCommandID = newCommand.id;

    for (const aid of selectedAgents) {
      isNoErr = !!(await APIService.putDataByIDHandler(
        API_DATA_USERS,
        aid,
        {
          command: newCommandID,
        },
        () =>
          setError(
            `Не удалось добавить пользователя(${aid}) к команде(${newCommandID})`,
          ),
      ));

      if (!isNoErr) {
        return;
      }
    }

    if (isNoErr) {
      await updateAgents(getFiltersQuery(filters));
      setSelectedAgents([]);
      setNewTeamName("");
    }
  };

  const onDelete = async id => {
    await deleteAgents(id, getFiltersQuery(filters));
  };
  const onToEdit = id => {
    history.push(`${ROUTE_USERS_NEW}/${id}`);
  };

  const onChangePageHandler = newPage => {
    onChangePage(newPage, getFiltersQuery(filters));
  };

  useEffect(() => {
    const fetchFiltersOptions = async () => {
      let isNoErr = true;
      let newFilters = { ...filters };
      for (const fKey of Object.keys(filters)) {
        const fData = filters[fKey];
        if (!fData?.data) {
          continue;
        }

        const fOptions = await APIService.getDataHandler(
          fData.data,
          () => `Не удалось получить опции(${fData.data}) для фильтров.`,
        );
        isNoErr = !!fOptions;

        if (!isNoErr || typeof fOptions === "string") {
          break;
        }

        newFilters[fKey].options = [
          { ...useFormDataPropSelectDefault },
          ...fOptions?.map(f => ({
            id: f.id,
            value: f.name,
            text: f.name,
          })),
        ];
      }

      if (isNoErr) {
        setFilters(newFilters);
      }
    };

    fetchFiltersOptions();
  }, []);

  const headers = [
    {
      title: (
        <div className='d-flex justify-center flex-1'>
          <Checkbox
            checked={selectedAgents?.length}
            onClick={() => {
              if (selectedAgents?.length) {
                setSelectedAgents([]);
              } else if (agents?.length) {
                const nonTeamMemberAgents = [];
                for (const a of agents) {
                  if (!a?.agent?.command?.name) {
                    nonTeamMemberAgents.push(a.id);
                  }
                }

                if (nonTeamMemberAgents?.length) {
                  setSelectedAgents(nonTeamMemberAgents);
                } else {
                  setError(
                    "На текущей странице нет пользователей без команды.",
                    StoreAppErrorTypeApp,
                  );
                }
              }
            }}
          />
        </div>
      ),
      isSortable: false,
    },
    { title: "Имя", isSortable: false },
    { title: "Город", isSortable: false },
    { title: "Линия МП", isSortable: false },
    { title: "Телефон", isSortable: false },
    { title: "Статус", isSortable: false },
    { title: "Команда", isSortable: false },
    { title: "Последняя активность", isSortable: false },
    { title: "", isSortable: false },
  ];
  const statuses = {
    active: {
      icon: "done-outline-green",
      text: "Активирован",
    },
    new: {
      icon: "logout-red",
      text: "Новая регистрация",
    },
  };
  const row = item => {
    let statusData = statuses[item?.status];
    if (!statusData) {
      statusData = { text: "Ошибка", icon: "close-outline" };
    }
    return (
      <React.Fragment>
        <div className='table-row__cell'>
          <div className='d-flex justify-center flex-1'>
            <Checkbox
              checked={isAgentSelected(item.id)}
              onChange={() => onClickAppendAgent(item)}
            />
          </div>
        </div>
        <div className='table-row__cell'>
          <Text>{`${item.firstname} ${item.lastname}`}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{item?.agent?.city?.name ?? "Не указано"}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{item?.agent?.agentline?.name ?? "Не указано"}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{item.username ?? "Не указано"}</Text>
        </div>
        <div className='table-row__cell'>
          <Icon icon={statusData.icon} size='1.75rem' />
          <Spacer left='0.25rem' />
          <Text>{statusData.text}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>
            {getShortenedText(item?.agent?.command?.name, 15) ?? "Без команды"}
          </Text>
        </div>
        <div className='table-row__cell'>
          <Text>{convertDate(item.updatedAt)}</Text>
        </div>
        <div className='table-row__cell'>
          <Button
            type='flat'
            onClick={e => {
              e?.preventDefault();
              onDelete(item.id);
            }}>
            <Icon icon='remove' size='2rem' />
          </Button>
          <Button
            type='flat'
            onClick={e => {
              e?.preventDefault();
              onToEdit(item.id);
            }}>
            <Icon icon='edit' size='2rem' />
          </Button>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className='card flex-1 checkbox-table'>
      <div className='table-filters'>
        <Select
          label='Выберите линию МП'
          value={filters[API_DATA_AGENTLINES].value}
          onChange={e => onChangeFilter(e.target.value, API_DATA_AGENTLINES)}
          variants={filters[API_DATA_AGENTLINES].options}
        />

        <Spacer left='1.5rem' />

        <Select
          label='Выберите команду'
          value={filters[API_DATA_COMMANDS].value}
          onChange={e => onChangeFilter(e.target.value, API_DATA_COMMANDS)}
          variants={filters[API_DATA_COMMANDS].options}
        />

        <Spacer left='1.5rem' />

        <Select
          label='Выберите статус'
          value={filters.status.value}
          onChange={e => onChangeFilter(e.target.value, "status")}
          variants={filters.status.options}
        />

        <Spacer left='1.5rem' />

        <Select
          label='Выберите город'
          value={filters[API_DATA_CITIES].value}
          onChange={e => onChangeFilter(e.target.value, API_DATA_CITIES)}
          variants={filters[API_DATA_CITIES].options}
        />
      </div>

      {selectedAgents?.length ? (
        <div className='card'>
          <Spacer top='1.5rem' />

          <div className='d-flex'>
            <Spacer left='2rem' />
            <Text
              color='secondary'
              type='md'>{`Редактировать выбранные (${selectedAgents.length})`}</Text>
          </div>

          <div className='table-filters'>
            <Input
              className='input-new-team-name'
              label='Команда'
              placeholder='Введите название команды'
              value={newTeamName}
              onChange={e => {
                e?.preventDefault();
                setNewTeamName(e.target.value);
              }}
            />

            <Spacer left='1.5rem' />
            <Button
              type='primary'
              onClick={e => {
                e.preventDefault();
                onCreateNewTeam();
              }}>
              <Text type='md'>{"Подтвердить"}</Text>
            </Button>
          </div>
        </div>
      ) : null}

      <Table
        // columns={['0.8fr', '1fr', '1fr', ' 0.75fr', '0.5fr', '0.5fr', '0.9fr', '0.9fr']}
        columns={[
          "5rem",
          "20rem",
          "18rem",
          "14rem",
          "18rem",
          "21rem",
          "20rem",
          "14rem",
          "8rem",
        ]}
        headers={headers}
        pagination={{ ...pagination, onChange: onChangePageHandler }}
        row={row}
        list={agents}
        isLoading={!agents}
      />
    </div>
  );
};

export default MedicalMembersTable;
