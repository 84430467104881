import React, { useState } from "react";
import { Text, Spacer, Icon, Button } from "../common";
import { TextField, InputAdornment } from "@material-ui/core";
import useDispatchError from "../../shared/hooks/useDispatchError";
import { StoreAppErrorTypeForm } from "../../store/app";
import APIService, { API_PASS_FORGOT } from "../../services/api.service";

const ForgetCard = ({ onNext, onLogin }) => {
  const [setError] = useDispatchError();
  const [disableActions, setDisableActions] = useState(false);
  const onSetDisableActions = () => {
    if (!disableActions) {
      setDisableActions(true);
    }
  };
  const onResetDisableActions = () => {
    if (disableActions) {
      setDisableActions(false);
    }
  };

  const [email, setEmail] = useState("");
  const onChangeEmail = newEmail => setEmail(newEmail);

  const onResetHandler = async () => {
    if (!email?.length) {
      setError("Введите e-mail.", StoreAppErrorTypeForm);
      return;
    }

    onSetDisableActions();

    const resp = await APIService.postDataHandler(
      API_PASS_FORGOT,
      { email },
      () =>
        setError(
          "Не удалось отправить Ваш E-mail на восстановления пароля. Убедитесь что вы ввели корректный E-mail адрес или повторите попытку позже.",
        ),
    );

    onResetDisableActions();
    if (resp) {
      onNext();
    }
  };

  return (
    <>
      <Text type='h4'>Восстановление пароля</Text>

      <Spacer top='1rem' />

      <Text type='md' color='grey'>
        Введите свой e-mail
      </Text>

      <Spacer top='5.5rem' />

      <TextField
        value={email}
        onChange={e => onChangeEmail(e.target.value)}
        disabled={disableActions}
        placeholder='Е-мейл'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <Icon icon='mail' size='3rem' />
            </InputAdornment>
          ),
        }}
        type='email'
        variant='outlined'
      />

      <Spacer top='3rem' />

      <Button
        type='primary'
        onClick={onResetHandler}
        disabled={!email?.length || disableActions}>
        <Text isCenter type='md' color='white'>
          Отправить
        </Text>
      </Button>

      <Spacer top='7.5rem' />

      <div
        onClick={e => {
          e.preventDefault();
          if (!disableActions) {
            onLogin();
          }
        }}
        className={`auth-link${disableActions ? " auth-link__disabled" : ""}`}>
        Войти в систему
      </div>

      {!disableActions && !email?.length ? (
        <>
          <Spacer top='2.5rem' />
          <div onClick={onNext} className={`auth-link`}>
            У меня уже есть код доступа
          </div>
        </>
      ) : null}
    </>
  );
};

export default ForgetCard;
