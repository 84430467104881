function Button({
  btnType = "button",
  type,
  children,
  onClick,
  disabled,
  className = "",
  style = {},
}) {
  // let isIconBtn = type && type === 'icon';
  const btnClass = "btn-" + type;
  // primary / icon / icon-black / icon-small
  return (
    <button
      className={className ?? ""}
      style={style ?? {}}
      type={btnType}
      disabled={disabled}
      onClick={e => {
        if (onClick) onClick(e);
      }}
      className={`btn ${btnClass}`}>
      {children}
    </button>
  );
}

export default Button;
