import React from 'react';
import Icon from './Icon';
const Dialog = ({ show, close, children }) => {
    if (!show) return null;
    return (
        <div className="dialog-wrapper">
            <div className="dialog">
                <div className="dialog__close-btn" onClick={close}>
                    <Icon icon="close" size="2rem" />
                </div>
                {children}
            </div>
        </div>
    );
};


export default Dialog