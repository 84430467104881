import React from "react";
import { API_UPLOAD_MAX_SIZE } from "../../../../../services/api.service";
import getShortenedText from "../../../../../shared/functions/getShortenedText";
import useDispatchError from "../../../../../shared/hooks/useDispatchError";
import {
  useFormDataTextRequired,
  useFormData_TaskNewStudyTest,
} from "../../../../../shared/hooks/useFormData";
import { StoreAppErrorTypeForm } from "../../../../../store/app";
import {
  Spacer,
  Text,
  FileInput,
  Input,
  Modal,
  Icon,
  Button,
} from "../../../../common";
import TestVariants from "./TestVariants";

const TaskTestTestsTab = ({
  idx,
  data,
  allBlocks,
  onChangeData,
  onChangeTestObjIdxClear,
}) => {
  const [setError] = useDispatchError();

  if (!data?.tests?.value?.length) {
    return null;
  }

  const onChangeHandler = (value, valueName, nestedValueName = null) => {
    onChangeData(value, valueName, nestedValueName);
  };
  const onChangeDataByIdx = (value, valueName, tIdx) => {
    if (valueName === "picture" && value?.size > API_UPLOAD_MAX_SIZE) {
      setError(
        "Файл слишком большой. Максимальный размер: 1Мб.",
        StoreAppErrorTypeForm,
      );
      return;
    }

    const newTestsData = [...data?.tests?.value];
    const newTestObj = {
      ...newTestsData[tIdx],
      [valueName]: {
        ...newTestsData[tIdx][valueName],
        value,
      },
    };
    newTestsData.splice(tIdx, 1, { ...newTestObj });

    const updateBlocks = [...allBlocks];
    const newObj = {
      ...allBlocks[idx],
      tests: { ...allBlocks[idx].tests, value: [...newTestsData] },
    };
    updateBlocks.splice(idx, 1, newObj);

    onChangeHandler(updateBlocks, "studiings");
  };

  const onClickAddNewBlock = () => {
    const newTests = [
      ...data?.tests?.value,
      { ...useFormData_TaskNewStudyTest() },
    ];

    const updateBlocks = [...allBlocks];
    const newObj = {
      ...allBlocks[idx],
      tests: { ...allBlocks[idx].tests, value: [...newTests] },
    };

    updateBlocks.splice(idx, 1, newObj);

    onChangeHandler(updateBlocks, "studiings");
  };
  const onClickRemoveBlock = tIdx => {
    const newTests = [...data?.tests?.value];
    newTests.splice(tIdx, 1);

    const updateBlocks = [...allBlocks];
    const newObj = {
      ...allBlocks[idx],
      tests: { ...allBlocks[idx].tests, value: [...newTests] },
    };
    updateBlocks.splice(idx, 1, newObj);
    onChangeHandler(updateBlocks, "studiings");
  };

  const modalTitle = `Тесты для вопроса №${idx + 1}${
    data?.description?.value?.length
      ? `: '${getShortenedText(data?.description?.value, 42)}'`
      : ""
  }`;

  return (
    <>
      <Modal title={modalTitle}>
        <div className='test-toggle-btns'>
          <div
            className={`test-toggle-btn`}
            onClick={() => onChangeTestObjIdxClear()}>
            Обучение
          </div>

          <div className={`test-toggle-btn active`} onClick={() => {}}>
            Тест
          </div>
        </div>

        {data?.tests?.value.map((tData, tIdx) => {
          return (
            <React.Fragment key={`ufd-task_new-study_tab-test_${tIdx}`}>
              {tIdx ? <Spacer bottom='2rem' /> : null}

              <div className='d-flex'>
                <Text color='secondary' type='h4'>
                  {`№${tIdx + 1}`}
                </Text>

                {tIdx ? (
                  <>
                    <Spacer left='auto' />

                    <Button
                      type='outline'
                      onClick={e => {
                        e?.preventDefault();
                        onClickRemoveBlock(tIdx);
                      }}>
                      <Icon icon='remove' size='2rem' />
                      <Spacer left='1.5rem' />
                      <Text type='md'>Удалить</Text>
                    </Button>
                  </>
                ) : null}
              </div>

              <Spacer top='2.25rem' />

              <Text color='secondary' type='md'>
                {useFormDataTextRequired(
                  tData?.question?.label,
                  tData?.question?.required,
                )}
              </Text>
              <Spacer top='1.5rem' />
              <Input
                placeholder='Введите вопрос'
                required={tData?.question?.required}
                value={tData?.question?.value}
                onChange={e =>
                  onChangeDataByIdx(e.target.value, "question", tIdx)
                }
              />

              <Spacer top='3rem' />

              <div className='d-flex'>
                <div style={{ width: "66rem" }}>
                  <Text color='secondary' type='md'>
                    {useFormDataTextRequired(
                      tData?.picture?.label,
                      tData?.picture?.required,
                    )}
                  </Text>
                  <Spacer top='1.5rem' />
                  <FileInput
                    required={tData?.picture?.required}
                    value={tData?.picture?.value}
                    onChange={f => onChangeDataByIdx(f, "picture", tIdx)}
                  />
                </div>

                <Spacer left='3.25rem' />

                <div className='flex-1'>
                  <Text color='secondary' type='md'>
                    {useFormDataTextRequired(
                      tData?.answers?.label,
                      tData?.answers?.required,
                    )}
                  </Text>
                  <Spacer top='1.5rem' />

                  <TestVariants
                    testsIdx={idx}
                    answers={tData?.answers?.value}
                    onChangeAnswer={a => onChangeDataByIdx(a, "answers", tIdx)}
                  />
                </div>
              </div>

              <Spacer bottom='8rem' />
            </React.Fragment>
          );
        })}
      </Modal>

      <Spacer top='4.5rem' />

      <div
        className='test-new-block_btn'
        onClick={e => {
          e.preventDefault();
          onClickAddNewBlock();
        }}>
        <Icon icon='plus-outline_blue' size='3rem' />
        <Spacer left='1rem' />
        <Text color='grey' type='lg'>
          Добавить блок
        </Text>
      </div>

      <Spacer bottom='3.5rem' />
    </>
  );
};

export default TaskTestTestsTab;
