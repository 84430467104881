import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import APIService, {
  API_DATA_TASKS,
  API_DATA_USERS,
  API_UPLOAD_MAX_SIZE,
} from "../../../services/api.service";
import { StoreAppErrorTypeForm } from "../../../store/app";
import { selectUserData } from "../../../store/user";
import useDispatchError from "../useDispatchError";
import {
  useFormDataPropTypeImage,
  useFormDataPropTypeSelect,
  useFormDataPropTypeAuthor,
  __dataPropsDefault,
  useFormDataPropTypeMultiSelect,
} from "./formDataProps";

export const useFormDataTextRequired = (text, isRequired = true) =>
  `${text ?? "Подождите..."}${isRequired ? "*" : ""}`;

export const useFormDataPropNameRoot = "__ufd/root_update";

const useFormData = ({ dataName, id }) => {
  const isEditForm = !!(typeof id !== "undefined");
  const [setError, clearError] = useDispatchError();
  const userData = useSelector(selectUserData);

  const [origObj, setOrigObj] = useState(undefined);

  const [editData, setEditData] = useState(undefined);
  const onChangeEditData = (dataProp, dataPropName) => {
    if (useFormDataPropNameRoot === dataPropName) {
      setEditData({ ...dataProp });
      return;
    }

    const _ed = editData ?? {};
    if (!_ed[dataPropName]) {
      _ed[dataPropName] = {};
    }

    let _data = dataProp;
    if (typeof dataProp !== "object") {
      _data = { value: dataProp };
    } else if (_ed[dataPropName]?.type === useFormDataPropTypeImage) {
      if (dataProp?.size && dataProp?.size > API_UPLOAD_MAX_SIZE) {
        setError(
          "Файл слишком большой. Максимальный размер: 1Мб.",
          StoreAppErrorTypeForm,
        );
        return;
      }
      _data = { value: dataProp };
    }

    setEditData({
      ..._ed,
      [dataPropName]: {
        ..._ed[dataPropName],
        ..._data,
      },
    });
  };

  useEffect(() => {
    const fetchOrigObj = async () => {
      const fetchedObj = await APIService.getDataByIDHandler(
        dataName,
        id,
        () =>
          setError(`Не удалось получить данные '${dataName}' по ID: '${id}'.`),
        resp => {
          if (!resp.data?.id) {
            setError(`Нет данных '${dataName}' по ID: '${id}'.`);
          }
          return resp.data;
        },
      );

      if (fetchedObj) {
        setOrigObj(fetchedObj);

        if (API_DATA_USERS === dataName || API_DATA_TASKS === dataName) {
          return;
        }

        let editableDataObj = {};
        for (const edKey of Object.keys(editData)) {
          const ed = editData[edKey];
          const fd = fetchedObj[edKey];

          let _newData = { ...ed };

          if (!fd) {
            editableDataObj[edKey] = _newData;
            continue;
          }

          switch (ed.type) {
            case useFormDataPropTypeSelect:
              _newData.value = fd?.name ?? "";
              _newData.text = fd?.name ?? "";
              _newData.id = fd?.id;
              break;

            case useFormDataPropTypeAuthor:
              _newData.value = fd?.id;
              break;

            case useFormDataPropTypeMultiSelect:
              _newData.value = fd?.map(o => ({
                id: o.id,
                value: o.description,
              }));
              break;

            default:
              _newData.value = fd;
              break;
          }

          editableDataObj[edKey] = _newData;
        }

        setEditData({ ...editableDataObj });
      }
    };

    if (!editData) {
      const def = __dataPropsDefault[dataName] ?? {};
      setEditData({ ...def });
    } else if (isEditForm && !origObj) {
      fetchOrigObj();
    }
  }, [id, editData]);

  const checkIsAllRequiredDataPresent = () => {
    for (const edKey of Object.keys(editData)) {
      const _ed = editData[edKey];
      if (_ed?.required) {
        let isInvalid = false;

        switch (_ed.type) {
          case useFormDataPropTypeImage:
          case useFormDataPropTypeSelect:
            if (!_ed?.id) {
              isInvalid = true;
            }
            break;

          default:
            if (!_ed?.value?.length) {
              isInvalid = true;
            }
            break;
        }

        if (isInvalid) {
          setError(`Пропущено поле '${_ed.label}'.`, StoreAppErrorTypeForm);
          return false;
        }
      }
    }

    return true;
  };
  const transformDataToUploadable = async () => {
    const data = {};
    let isErrSet = false;

    for (const edKey of Object.keys(editData)) {
      const _ed = editData[edKey];
      switch (_ed.type) {
        case useFormDataPropTypeImage: {
          if (!_ed?.value || _ed?.value?.id) {
            break;
          }

          const uploadedImg = await APIService.postUpload(_ed.value).then(
            resp => resp.data,
            err => {
              isErrSet = true;
              setError(
                `Не удалось загрузить Изображение: '${_ed.value.name}' (${err.message})`,
              );
            },
          );

          if (uploadedImg?.length && uploadedImg[0]) {
            data[edKey] = uploadedImg[0].id;
          } else {
            if (!isErrSet) {
              setError("Не удалось получить Изображение.");
            }
            return undefined;
          }
          break;
        }

        case useFormDataPropTypeMultiSelect:
          data[edKey] = _ed?.value?.map(ev => ev?.id);
          break;

        case useFormDataPropTypeAuthor:
          if (_ed.value?.length) {
            continue;
          }

          data[edKey] = userData?.id;

          break;

        case useFormDataPropTypeSelect:
          data[edKey] = _ed.id;
          break;

        default:
          data[edKey] = _ed.value;
          break;
      }
    }
    return data;
  };

  const onSubmitNew = async () => {
    const isValidSubmit = checkIsAllRequiredDataPresent();

    if (isValidSubmit) {
      if (API_DATA_USERS === dataName || API_DATA_TASKS === dataName) {
        return true;
      }

      const newData = await transformDataToUploadable();
      if (!newData) {
        return undefined;
      }

      const out = await APIService.postDataHandler(dataName, newData, () =>
        setError(`Не удалось создать новый объект(${dataName}).`),
      );

      return out;
    }
  };
  const onSubmitUpdate = async () => {
    const isValidSubmit = checkIsAllRequiredDataPresent();

    if (isValidSubmit) {
      if (API_DATA_USERS === dataName || API_DATA_TASKS == dataName) {
        return true;
      }

      const updateData = await transformDataToUploadable();
      if (!updateData) {
        return undefined;
      }

      const out = await APIService.putDataByIDHandler(
        dataName,
        id,
        updateData,
        () => setError("Не удалось обновить объект."),
      );

      return out;
    }
  };
  const onSubmit = async () => {
    const stateSubmitter = isEditForm ? onSubmitUpdate : onSubmitNew;

    await clearError();

    return await stateSubmitter();
  };

  return [editData, onChangeEditData, onSubmit, origObj];
};

export default useFormData;
