import React, { useEffect, useState } from "react";
import APIService, {
  API_DATA_AGENTLINES,
  API_DATA_BRANDS,
} from "../../services/api.service";
import getShortenedText from "../../shared/functions/getShortenedText";
import useAPIData from "../../shared/hooks/useAPIData";
import useDispatchError from "../../shared/hooks/useDispatchError";
import { Table, Text, Button, Icon, Spacer, Autocomplete } from "../common";

const MedicalMemberLinesTable = () => {
  const [setError] = useDispatchError();
  const [agentlines, pagination, updateAgentlines] =
    useAPIData(API_DATA_AGENTLINES);

  const [editableID, setEditableID] = useState(null);
  const onChangeEditableID = _ei => {
    setSelectedBrands([]);
    setEditableID(_ei);
  };
  const clearEditableID = () => {
    setSelectedBrands([]);
    setEditableID(null);
  };

  const [brands, setBrands] = useState([]);

  const [selectedBrands, setSelectedBrands] = useState([]);
  const onChangeMultiSelect = value => {
    const newSelectedBrands = [];
    for (const v of value) {
      const defaultUserOpt = brands?.find(o => !!o?.value?.includes(v));
      if (defaultUserOpt?.id) {
        newSelectedBrands.push(defaultUserOpt);
      }
    }
    setSelectedBrands(newSelectedBrands);
  };
  const onAssignBrandsToSelectedAgentLine = async _ridx => {
    if (!selectedBrands?.length) {
      setError(
        "Выберите какие бренды нужно добавить к Линии МП.",
        StoreAppErrorTypeApp,
      );
      return;
    }

    let oldBrands = [];
    if (agentlines[_ridx]?.brands?.length) {
      oldBrands = agentlines[_ridx]?.brands?.map(_a => _a.id);
    }
    const selectedBrandsIDs = selectedBrands.map(sb => sb.id);

    const newBrands = [...oldBrands, ...selectedBrandsIDs];

    const isSuccess = APIService.putDataByIDHandler(
      API_DATA_AGENTLINES,
      agentlines[_ridx].id,
      { brands: newBrands },
      () => setError("Не удалось добавить бренды к Линии МП."),
    );
    if (!isSuccess) {
      return;
    }

    await updateAgentlines();
    setSelectedBrands([]);
  };
  const onRemoveBrand = async (alIdx, bIdx) => {
    const updateBrands = agentlines[alIdx]?.brands;
    updateBrands?.splice(bIdx, 1);

    const isSuccess = await APIService.putDataByIDHandler(
      API_DATA_AGENTLINES,
      agentlines[alIdx]?.id,
      { brands: updateBrands },
      () => setError("Не удалось убрать бренд с Линии Мп."),
    );

    if (isSuccess) {
      await updateAgentlines();
    }
  };

  useEffect(() => {
    const fetchOptions = async () => {
      const fetchedBrands = await APIService.getDataHandler(
        API_DATA_BRANDS,
        () =>
          setError("Не удалось получить данные для редактирования брендов."),
      );
      if (!fetchedBrands?.length) {
        return;
      }

      const newBrands = fetchedBrands.map(fb => ({
        value: fb?.name,
        id: fb?.id,
      }));
      setBrands(newBrands);
    };

    fetchOptions();
  }, []);

  const headers = [
    { title: "Название", isSortable: false },
    { title: "Бренд", isSortable: false },
  ];

  const row = (item, _ridx) => (
    <React.Fragment>
      <div className='table-row__cell'>
        <Text type='md' isBold={true}>
          {item?.name ?? "Неизвестно"}
        </Text>
      </div>

      <div className='table-row__cell'>
        <div className='d-flex f-column'>
          <div className='data-page-agentlines-brands'>
            {item?.brands?.length ? (
              item?.brands.map((n, idx) => {
                return (
                  <div
                    className='data-page-agentlines-brands-name'
                    key={`${idx}-dpab__`}>
                    <Text type='md'>
                      {getShortenedText(n?.name, 15) ?? "Неизвестно"}
                    </Text>
                    {editableID === item?.id ? (
                      <>
                        <Spacer left='1.5rem' />
                        <Icon
                          icon='close'
                          size='2rem'
                          onClick={() => {
                            onRemoveBrand(_ridx, idx);
                          }}
                        />
                      </>
                    ) : null}
                  </div>
                );
              })
            ) : (
              <Text type='md' color='grey'>
                Нет брендов
              </Text>
            )}
          </div>

          {editableID === item?.id ? (
            <>
              <Spacer top='3rem' />
              <div className='d-flex'>
                <Autocomplete
                  id='brands'
                  placeholder='Название бренда'
                  style={{ width: "35rem" }}
                  multiple={true}
                  disabled={!brands?.length}
                  value={
                    selectedBrands?.length
                      ? selectedBrands?.map(v => v.value)
                      : []
                  }
                  options={brands?.length ? brands?.map(b => b.value) : []}
                  getOptionLabel={o => o}
                  onValueChange={option => onChangeMultiSelect(option)}
                />

                <Spacer left='4rem' />

                <Button
                  type={selectedBrands?.length ? "primary" : "outline"}
                  onClick={e => {
                    e?.preventDefault();
                    onAssignBrandsToSelectedAgentLine(_ridx);
                  }}>
                  <Text type='sm'>Добавить</Text>
                </Button>
              </div>
            </>
          ) : null}
        </div>
      </div>

      {editableID === item?.id ? (
        <div className='table-row__cell'>
          <Spacer left='5rem' />
          <Button
            type='flat'
            onClick={e => {
              e?.preventDefault();
              clearEditableID();
            }}>
            <Icon icon='close-outline' size='2rem' />
          </Button>
        </div>
      ) : (
        <div className='table-row__cell'>
          <Button
            type='flat'
            onClick={e => {
              e?.preventDefault();
            }}>
            <Icon icon='remove' size='2rem' />
          </Button>

          <Button
            type='flat'
            onClick={e => {
              e?.preventDefault();
              onChangeEditableID(item?.id);
            }}>
            <Icon icon='edit' size='2rem' />
          </Button>
        </div>
      )}
    </React.Fragment>
  );
  return (
    <Table
      // columns={['0.8fr', '1fr', '1fr', ' 0.75fr', '0.5fr', '0.5fr', '0.9fr', '0.9fr']}
      columns={["25rem", "105rem", "10rem"]}
      headers={headers}
      row={row}
      pagination={pagination}
      isLoading={!agentlines}
      list={agentlines}
    />
  );
};

export default MedicalMemberLinesTable;
