import React, { useEffect } from "react";
import { Table, Text, Icon, Spacer, Button } from "../../common";
import {
  API_DATA_SHOP_ORDERS,
  getImageURL,
} from "../../../services/api.service";
import useAPIData from "../../../shared/hooks/useAPIData";
import {
  convertDate,
  convertDateTime,
} from "../../../shared/functions/convertDate";
import getShortenedText, {
  getShortenedDescription,
} from "../../../shared/functions/getShortenedText";
import OrderInfo from "./OrderInfo";
import queryStringify from "qs-stringify";

const OrdersTable = ({ searchQuery }) => {
  const _getFiltersQueries = () => {
    let _filters = {
      _where: {
        _or: [
          [{ "product.description_contains": searchQuery }],
          [{ "product.name_contains": searchQuery }],
          [{ "user.firstname_contains": searchQuery }],
          [{ "user.lastname_contains": searchQuery }],
        ],
      },
    };

    let _default = "_sort=createdAt:DESC&status_ne=draft";
    let qs = queryStringify(_filters);

    let out = _default;
    if (qs?.length) {
      out += `&${qs}`;
    }
    return out;
  };
  const [orders, pagination, updateOrders, , onChangePage, changeOrders] =
    useAPIData(API_DATA_SHOP_ORDERS, _getFiltersQueries());

  useEffect(() => {
    if (orders) {
      updateOrders(_getFiltersQueries());
    }
  }, [searchQuery]);

  let _pagination = {
    ...pagination,
    onChange: page => onChangePage(page, _getFiltersQueries()),
  };

  const onChangeOrderByID = (id, obj) => {
    const newOrders = [...orders];
    const oIdx = newOrders.findIndex(o => o.id === id);
    const newOrderObj = { ...newOrders[oIdx], ...obj };
    newOrders.splice(oIdx, 1, newOrderObj);
    changeOrders(newOrders);
  };

  const statuses = {
    confirmed: {
      icon: "done-outline-green",
      text: "Подтверждено",
    },
    new: {
      icon: "new-order",
      text: "Новый заказ",
    },
    rejected: {
      icon: "order-cancel",
      text: "Отклонено",
    },
  };
  const headers = [
    { title: "", isSortable: false },
    { title: "Название", isSortable: false },
    { title: "Баллы", isSortable: false },
    { title: "Стоимость (₸)", isSortable: false },
    { title: "Пользователь", isSortable: false },
    { title: "Статус", isSortable: false },
    { title: "Создана", isSortable: false },
  ];

  const row = item => {
    const imgURL =
      item?.product?.picture?.formats?.thumbnail?.url ??
      item?.product?.picture?.url;

    return (
      <React.Fragment>
        <div className='table-row__cell'>
          {imgURL ? <img src={getImageURL(imgURL)} alt={item.name} /> : null}
        </div>
        <div className='table-row__cell'>
          <div>
            <Text>{getShortenedText(item?.product?.name ?? "Неизвестно")}</Text>
            <Text type='caption' isThin>
              {getShortenedDescription(
                item?.product?.description ?? "Неизвестно",
              )}
            </Text>
          </div>
        </div>
        <div className='table-row__cell'>
          <Text>{item?.product?.costscores ?? "Неизвестно"}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{item?.product?.costmoney ?? "Неизвестно"}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>
            {(item?.user?.firstname ?? "Не") +
              " " +
              (item?.user?.lastname ?? "указан")}
          </Text>
        </div>
        <div className='table-row__cell'>
          <Icon icon={statuses[item?.status]?.icon} size='1.75rem' />
          <Spacer left='0.5rem' />
          <Text>{statuses[item?.status]?.text}</Text>
        </div>
        <div className='table-row__cell'>
          <div>
            <Text isBold>{convertDate(item?.createdAt)}</Text>
            <Spacer top='0.25rem' />
            <Text>{convertDateTime(item?.createdAt)}</Text>
          </div>
        </div>
      </React.Fragment>
    );
  };
  return (
    <div className='card flex-1'>
      <Table
        columns={[
          "17rem",
          "30rem",
          "15rem",
          "15rem",
          "15rem",
          "18rem",
          "12rem",
          "18rem",
        ]}
        headers={headers}
        pagination={_pagination}
        collapseSlot={
          <Button type='primary-outline'>
            Проверить
            <Spacer left='1.5rem' />
            <Icon icon='arrow-down' size='2.5rem' />
          </Button>
        }
        childrenRow={item => (
          <OrderInfo order={item} onChangeOrderByID={onChangeOrderByID} />
        )}
        row={row}
        list={orders}
        isLoading={!orders}
      />
    </div>
  );
};

export default OrdersTable;
