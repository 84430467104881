import React, { useEffect, useState } from "react";
import {
  Text,
  Spacer,
  Table,
  Input,
  Button,
  Icon,
  Autocomplete,
  Dialog,
} from "../common";
import APIService, {
  API_DATA_APPROVEMENT_BUY,
  API_DATA_INVOICES,
  getImageURL,
} from "../../services/api.service";
import useDispatchError from "../../shared/hooks/useDispatchError";
import { StoreAppErrorTypeApp } from "../../store/app";
import { convertDate } from "../../shared/functions/convertDate";

const TableChildRowPhoto = ({ data, updateBuys, invoice }) => {
  const [setError] = useDispatchError();

  const __isInvoiceRecordsExist = !!invoice?.invoicerecords?.length;

  const _defaultInputRecordData = {
    product: "",
    amount: "0",
    minamount: "1",
    scores: "0",
    disabledProduct: false,
    disabledAmount: false,
    disabledMinamout: true,
    disabledScores: false,
  };
  const _newInputRecord = (_newData = _defaultInputRecordData) => {
    const __newInputRecordInitter = (_value, _disabled) => ({
      value: typeof _value === undefined ? "" : _value,
      disabled: !!_disabled,
    });

    let __data = { ..._defaultInputRecordData };
    if (typeof _newData === "object") {
      __data = { ...__data, ..._newData };
    }

    let _out = {
      product: __newInputRecordInitter(__data.product, __data.disabledProduct),
      amount: __newInputRecordInitter(__data.amount, __data.disabledAmount),
      minamount: __newInputRecordInitter(
        __data.minamount,
        __data.disabledMinamout,
      ),
      scores: __newInputRecordInitter(__data.scores, __data.disabledScores),
    };

    if (__data?.productID) {
      _out.product.id = __data.productID;
    }
    if (__data?.id) {
      _out.id = __data?.id;
    }

    return _out;
  };
  const _mapInvoicesToInputRecords = () => {
    const invoicerecords = invoice?.invoicerecords;
    let res = [];

    for (const ir of invoicerecords) {
      const __irData = {
        id: ir?.id,
        product: ir?.product?.name,
        amount: ir?.amount,
        minamount: ir?.product?.minamount,
        scores: ir?.scores,
        disabledProduct: true,
        disabledAmount: true,
        disabledMinamout: true,
        disabledScores: true,
      };
      const _newData = _newInputRecord(__irData);

      res.push(_newData);
    }

    return res;
  };
  const [inputRecords, setInputRecords] = useState(
    __isInvoiceRecordsExist
      ? _mapInvoicesToInputRecords()
      : [{ ..._newInputRecord() }],
  );

  useEffect(() => {
    if (__isInvoiceRecordsExist) {
      setInputRecords(_mapInvoicesToInputRecords());
    }
  }, [__isInvoiceRecordsExist, invoice]);

  const _mapDataProductsToOptions = () => {
    const products = data?.task?.purchase?.month?.products;
    if (!products?.length) {
      return [];
    }

    let res = [];
    for (const p of products) {
      const newOpt = {
        value: p?.name,
        id: p?.id,
        scores: p?.scores,
        minamount: p?.minamount,
      };

      res.push(newOpt);
    }

    return res;
  };
  const productNames = _mapDataProductsToOptions();

  const [isUnsavedChanges, setIsUnsavedChanges] = useState(false);

  const onChangeInputRecord = (idx, value, valueName) => {
    let _value = value;
    const prevValue = inputRecords[idx][valueName];
    if (typeof _value !== "object") {
      if (prevValue === value) {
        return;
      }

      _value = { value };
    }

    const newData = {
      ...inputRecords[idx],
      [valueName]: { ...prevValue, ..._value },
    };

    inputRecords.splice(idx, 1, newData);
    setInputRecords([...inputRecords]);

    if (!isUnsavedChanges) {
      setIsUnsavedChanges(true);
    }
  };
  const onChangeInputNumberRecord = (idx, value, valueName) => {
    let newValue = Math.abs(parseInt(value));
    if (!newValue || !value?.length) {
      newValue = "0";
    }
    onChangeInputRecord(idx, newValue, valueName);
  };
  const onChangeSelect = (idx, value, valueName, options) => {
    let opt = options.find(o => o?.value === value);
    if (!opt?.id) {
      opt = { value: "" };
    }

    onChangeInputRecord(idx, opt, valueName);
  };
  const onSelectProduct = (idx, value) => {
    let opt = productNames.find(o => o?.value === value);
    if (!opt?.id) {
      opt = { value: "" };
      onChangeInputRecord(idx, opt, valueName);
      return;
    }

    const newScores = opt?.scores;
    const newMinAmount = opt?.minamount;
    const newProduct = opt?.value;
    const newProductID = opt?.id;

    const prevData = inputRecords[idx];
    const newData = {
      ...prevData,
      product: { ...prevData.product, value: newProduct, id: newProductID },
      minamount: { ...prevData.minamount, value: newMinAmount },
      scores: { ...prevData.scores, value: newScores, disabled: true },
      amount: { ...prevData?.amount, value: 1 },
    };

    inputRecords.splice(idx, 1, newData);
    setInputRecords([...inputRecords]);

    if (!isUnsavedChanges) {
      setIsUnsavedChanges(true);
    }
  };

  const onClickAddNewInputRecord = () => {
    setInputRecords([...inputRecords, { ..._newInputRecord() }]);
  };
  const onClickRemoveInputRecord = idx => {
    inputRecords.splice(idx, 1);
    setInputRecords([...inputRecords]);
  };

  const onChangeStatus = async (newStatus = "fail") => {
    const isSuccess = await APIService.putDataByIDHandler(
      API_DATA_APPROVEMENT_BUY,
      data?.id,
      { status: newStatus },
      () => setError(`Не удалось изменить статус.`),
    );

    if (isSuccess) {
      await updateBuys(`status_ne=new`);
    }
  };
  const onSaveProducts = async () => {
    let invoiceRecordsData = [];

    let idx = 0;
    for (const ird of inputRecords) {
      if (ird?.id?.length) {
        continue;
      }

      if (!ird?.product?.id) {
        setError(
          `Пропущен продукт в строчке №${idx + 1}.`,
          StoreAppErrorTypeApp,
        );
        return;
      }

      const __ird = {
        product: ird.product?.id,
        amount: parseInt(ird.amount.value),
        scores: parseInt(ird.scores.value),
      };

      invoiceRecordsData.push(__ird);

      ++idx;
    }

    const _ird = {
      invoicerecords: invoiceRecordsData,
    };

    const isSuccess = await APIService.putDataByIDHandler(
      API_DATA_INVOICES,
      invoice?.id,
      _ird,
      () => setError("Не удалось добавить список продуктов."),
    );
    if (isSuccess) {
      // await onChangeStatus("done");
      await updateBuys(`status_ne=new`);
    }
  };

  const headers = [
    { title: "#", isSortable: false },
    { title: "Название", isSortable: false },
    { title: "Количество", isSortable: false },
    { title: "За минимальное количество", isSortable: false },
    { title: "Баллы", isSortable: false },
  ];

  const row = (item, idx) => (
    <React.Fragment>
      <div className='table-row__cell'>
        <Text type='md'>{idx + 1}</Text>
      </div>
      <div className='table-row__cell'>
        <Autocomplete
          id={`productNames-${idx}`}
          style={{ width: "100%", height: "6rem" }}
          disabled={!productNames?.length}
          placeholder='Выберите название'
          value={item?.product?.value ?? ""}
          disabled={item?.product?.disabled}
          onValueChange={option => onSelectProduct(idx, option)}
          options={productNames?.map(c => c.value)}
          getOptionLabel={o => o}
        />
      </div>

      <div className='table-row__cell'>
        <Input
          type={"number"}
          placeholder='0'
          value={item?.amount?.value ?? "0"}
          disabled={item?.amount?.disabled}
          onChange={e =>
            onChangeInputNumberRecord(idx, e.target.value, "amount")
          }
        />
      </div>

      <div className='table-row__cell'>
        <Input
          type={"number"}
          disabled={true}
          placeholder='0'
          value={item?.minamount?.value}
          disabled={item?.minamount?.disabled}
          onChange={e =>
            onChangeInputNumberRecord(idx, e.target.value, "minamount")
          }
        />
      </div>

      <div className='table-row__cell'>
        <Input
          type={"number"}
          placeholder='0'
          value={item?.scores?.value * item?.amount?.value}
          disabled={item?.scores?.disabled}
          onChange={e =>
            onChangeInputNumberRecord(idx, e.target.value, "scores")
          }
        />
      </div>

      <div className='table-row__cell'>
        {idx && !item?.id?.length ? (
          <Icon
            icon={"close-outline"}
            size='2rem'
            onClick={() => onClickRemoveInputRecord(idx)}
          />
        ) : (
          <Spacer left='2rem' />
        )}
      </div>
    </React.Fragment>
  );

  const photosLength = invoice?.photos?.length;
  const [imageSelector, setImageSelector] = useState(0);
  const nextImage = () => {
    if (imageSelector + 1 < photosLength) {
      setImageSelector(imageSelector + 1);
    }
  };
  const prevImage = () => {
    if (imageSelector) {
      setImageSelector(imageSelector - 1);
    }
  };

  const imgData = invoice?.photos[imageSelector];
  const imgThumbnail = imgData
    ? getImageURL(imgData?.formats?.thumbnail?.url)
    : null;
  const imgRaw = imgData ? getImageURL(imgData?.url) : null;

  const [imagePreview, setImagePreview] = useState(null);
  const openImagePreview = () => setImagePreview(imgRaw);
  const closeImagePreview = () => setImagePreview(null);

  const _isButtonsDisabledUntilChangesIsNotSaved = !!isUnsavedChanges;

  return (
    <>
      <div className='approvement-child-row'>
        {imgData ? (
          <div className='imgWrapper'>
            {imageSelector ? (
              <Icon size='5rem' icon='arrow-left' onClick={() => prevImage()} />
            ) : (
              <Spacer left='5rem' />
            )}

            <img
              className='__approvement_img'
              src={imgThumbnail}
              alt='Product Preview'
              onClick={e => {
                e.preventDefault();
                openImagePreview();
              }}
            />

            {imageSelector + 1 !== photosLength ? (
              <Icon
                size='5rem'
                icon='arrow-right'
                onClick={() => nextImage()}
              />
            ) : (
              <Spacer right='5rem' />
            )}
          </div>
        ) : null}

        <Spacer left='3rem' />

        <div className='flex-1'>
          <div className='d-flex'>
            <div className='flex-1'>
              <div className='d-flex align-center'>
                <Text>Дата ввода в систему накладной:</Text>
                <Spacer left='1.5rem' />
                <Text type={"md"} isBold>
                  {convertDate(invoice?.createdAt)}
                </Text>
              </div>

              <Spacer top={"1rem"} />

              <div className='d-flex align-center'>
                <Text>Номер накладной</Text>
                <Spacer left='1.5rem' />
                <Text
                  color={invoice?.number?.length ? "" : "grey"}
                  isBold={!!invoice?.number?.length}>
                  {invoice?.number ?? "Неизвестно"}
                </Text>
              </div>
            </div>

            <Spacer left='1rem' />

            <div className='flex-50'>
              <div className='d-flex align-center'>
                <Text>Тип подтверждения:</Text>
                <Spacer left='1.5rem' />
                <Text type={"md"} isBold>
                  {invoice?.photos?.length ? "Фото" : "Ручной ввод"}
                </Text>
              </div>
              <Spacer top={"1rem"} />

              <div className='d-flex align-center'>
                <Text>Дистрибьютор</Text>
                <Spacer left='1.5rem' />
                <Text
                  color={invoice?.distributor?.name?.length ? "" : "grey"}
                  isBold={!!invoice?.distributor?.name?.length}>
                  {invoice?.distributor?.name ?? "Неизвестно"}
                </Text>
              </div>
            </div>

            <Spacer right='1rem' />
          </div>

          <Spacer top='2rem' />

          <Table
            hidePagination
            columns={["0.7fr", "4fr", "1.25fr", "2.42fr", "1.25fr", "0.35fr"]}
            headers={headers}
            row={row}
            isLoading={!inputRecords?.length}
            list={inputRecords}
          />

          <div>
            <Button
              type='flat'
              onClick={e => {
                e?.preventDefault();
                onClickAddNewInputRecord();
              }}>
              <Icon icon='plus-outline' size='2.25rem' />
              <Spacer left='1rem' />
              <Text type='sm'>Добавить строку</Text>
            </Button>
          </div>

          <div className='d-flex'>
            <Spacer left='auto' />

            <Button
              type='outline'
              disabled={
                true ||
                _isButtonsDisabledUntilChangesIsNotSaved ||
                data?.status === "fail"
              }
              onClick={e => {
                e?.preventDefault();
                // onChangeStatus();
              }}>
              <Icon icon='close-outline' size='2.25rem' />
              <Spacer left='1rem' />
              <Text type='md'>Отклонить</Text>
            </Button>

            <Spacer left='1.5rem' />

            <Button
              type={isUnsavedChanges ? "primary" : "white"}
              onClick={e => {
                e?.preventDefault();
                onSaveProducts();
              }}>
              <Text type='md'>Сохранить</Text>
            </Button>
          </div>

          <Spacer top='3rem' />
        </div>
      </div>

      <Dialog show={!!imagePreview} close={closeImagePreview}>
        <img
          src={imagePreview}
          alt={"Превью картинка"}
          className='approvement-page-preview-image'
          onClick={e => {
            e.preventDefault();
            closeImagePreview();
          }}
        />
      </Dialog>
    </>
  );
};

export default TableChildRowPhoto;
