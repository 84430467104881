import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Text from "../Text";

const CustomAutocomplete = ({
  id,
  value = "",
  onValueChange,
  onInputChange = () => {},
  required,
  disabled = false,
  options = [],
  getOptionLabel,
  placeholder,
  multiple = false,
  style = {},
}) => {
  return (
    <Autocomplete
      id={id}
      style={style}
      disableClearable
      disabled={disabled}
      renderOption={option => <Text>{option}</Text>}
      options={options}
      value={value}
      getOptionLabel={getOptionLabel}
      multiple={multiple}
      onChange={(event, newValue) => {
        onValueChange(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        onInputChange(newInputValue);
      }}
      noOptionsText={<Text>Нет результатов</Text>}
      renderInput={params => (
        <TextField
          {...params}
          variant='outlined'
          required={required}
          placeholder={placeholder}
        />
      )}
      // renderInput={(params) => <Input {...params} required placeholder={placeholder} />}
    />
  );
};

export default CustomAutocomplete;
