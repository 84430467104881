function Spacer({ x, y, left, right, top, bottom }) {
    const mt = top ? top : y ? y : 0;
    const mb = bottom ? bottom : y ? y : 0;
    const ml = left ? left : x ? x : 0;
    const mr = right ? right : x ? x : 0;
    return (
        <div
            style={{
                margin: `${mt} ${mr} ${mb} ${ml}`
            }}
            className="spacer">

        </div>
    )
}

export default Spacer;