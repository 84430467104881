import {
  ROUTE_DATA_DISTRIBUTORS,
  ROUTE_DATA_MEDICAL_MEMBERS,
  ROUTE_DATA_PHARMACY,
} from "../../AppRoutes";
import { Icon, Spacer, Text } from "../common";
import LinkCard from "./LinkCard";

const Card = () => {
  return (
    <div className='dashboard-card'>
      <div className='d-flex align-center'>
        <div className='icon-card'>
          <Icon icon='data-chart' size='3.5rem' />
        </div>
        <Spacer left='2rem' />
        <Text color='black' type='h4'>
          Данные
        </Text>
      </div>

      <Spacer top='2rem' />

      <LinkCard
        count='0'
        label='Дистрибьюторы'
        link={ROUTE_DATA_DISTRIBUTORS}
        date='10.02.21'
      />

      <Spacer top='2rem' />

      <LinkCard
        count='0'
        label='Товары'
        subLabel='(мед. представители)'
        link={ROUTE_DATA_MEDICAL_MEMBERS}
        date='10.02.21'
      />

      <Spacer top='2rem' />

      <LinkCard
        count='0'
        label='Товары'
        subLabel='(аптеки)'
        link={ROUTE_DATA_PHARMACY}
        date='10.02.21'
      />
    </div>
  );
};

export default Card;
