import { useEffect, useState } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

const RadioCustom = ({ value, required, onChange, variants = [] }) => {
  const handleChange = event => {
    if (onChange) onChange(event.target.value);
  };
  const v = variants?.find(_v => _v.value === value) ?? { value: "" };

  return (
    <FormControl
      className='custom-radio'
      component='fieldset'
      required={required}>
      <RadioGroup value={v.value} onChange={handleChange}>
        {variants.length > 0 &&
          variants.map((variant, index) => (
            <FormControlLabel
              disabled={
                typeof variant?.disabled !== "undefined"
                  ? variant?.disabled
                  : false
              }
              value={variant.value}
              key={index}
              control={<Radio />}
              label={variant.label}
            />
          ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioCustom;
