import { AxiosError } from "axios";

import APIService, {
  APILoginResponse,
  clearAPIToken,
  updateAPIToken,
} from "../../services/api.service";
import {
  appErrorClear,
  appErrorSet,
  StoreAppErrorTypeLogin,
  StoreAppErrorTypeServer,
} from "../app";
import { LOCAL_USER_TOKEN_NAME } from "../../services/api.service";
import { StoreThunkAction } from "../storeTypes";

import { StoreActUserLogout, USER_LOGIN, USER_LOGOUT } from "./userTypes";

export const userLogin =
  (login: string, password: string): StoreThunkAction =>
  async dispatch => {
    dispatch(appErrorClear());

    const resp = await APIService.login(login, password).then(
      resp => {
        const respData = resp.data;
        if (respData?.status) {
          return resp.data;
        } else if (respData?.error) {
          dispatch(
            appErrorSet(
              `Ошибка ответа от сервер: ${JSON.stringify(respData.error)}`,
              StoreAppErrorTypeServer,
            ),
          );
        }
        return resp;
      },
      (err: AxiosError) => {
        const errMessagePrefix = "Ошибка: ";
        let errMessage = "Неверный логин или пароль.";

        let _msg: string | undefined = undefined;
        if (
          err?.response?.data?.message?.length &&
          err?.response?.data?.message[0]?.messages?.length &&
          err?.response?.data?.message[0]?.messages[0]?.id?.length
        ) {
          _msg = err.response.data.message[0].messages[0].id;
        }

        switch (_msg) {
          case "Auth.form.error.invalid":
            errMessage = "Неверный пароль";
            break;
          case "Auth.form.error.blocked":
            errMessage = "Ваш аккаунт заблокирован Администратором";
            break;

          default:
            break;
        }

        dispatch(
          appErrorSet(errMessagePrefix + errMessage, StoreAppErrorTypeLogin),
        );
      },
    );

    if (resp) {
      const r = resp as APILoginResponse;
      const userData = r?.data?.user;

      updateAPIToken(r?.data?.jwt as string);

      localStorage.setItem(
        LOCAL_USER_TOKEN_NAME,
        JSON.stringify({ jwt: r?.data?.jwt, user: userData }),
      );
      dispatch({ type: USER_LOGIN, payload: userData });
    }
  };

export const userLogout = (): StoreActUserLogout => {
  clearAPIToken();
  localStorage.removeItem("user");
  return { type: USER_LOGOUT };
};
