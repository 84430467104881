import React, { useState } from "react";
import { Modal, Icon, Text, Spacer, Button } from "../../../../common";
import PharmacyTable from "./tables/PharmacyTable";
import MedicalMemberTable from "./tables/MedicalMemberTable";
import {
  useFormDataTextRequired,
  useFormData_TaskTypePurchase,
  useFormData_TaskTypeSale,
  useFormData_UserRoleAgent,
  useFormData_UserRolePharmacy,
} from "../../../../../shared/hooks/useFormData";

const TaskUsers = ({
  disableActions,
  taskType,
  onChangeUploadStatus,
  back,
  next,
  generalFormData,
  onChangeGeneralFormData,
}) => {
  const [userType, setUserType] = useState(
    taskType !== useFormData_TaskTypeSale
      ? useFormData_UserRolePharmacy
      : useFormData_UserRoleAgent,
  );
  const onChangeUserType = newType => {
    if (newType !== userType) {
      setUserType(newType);
    }
  };

  const renderUserTable = () => {
    switch (userType) {
      case useFormData_UserRolePharmacy:
        return (
          <PharmacyTable
            taskType={taskType}
            generalFormData={generalFormData}
            onChangeGeneralFormData={onChangeGeneralFormData}
          />
        );

      case useFormData_UserRoleAgent:
        return (
          <MedicalMemberTable
            generalFormData={generalFormData}
            onChangeGeneralFormData={onChangeGeneralFormData}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Modal back={back} title='Выберите пользователей'>
      <div className='d-flex align-center'>
        <Text color='secondary' type='md'>
          {useFormDataTextRequired(
            generalFormData?.users?.label,
            generalFormData?.users?.required,
          ) + " :"}
        </Text>
        <Spacer left='3rem' />
        {taskType !== useFormData_TaskTypeSale ? (
          <Button
            type={
              userType === useFormData_UserRolePharmacy ? "primary" : "white"
            }
            onClick={() => onChangeUserType(useFormData_UserRolePharmacy)}>
            <Text type='md'>Аптека</Text>
          </Button>
        ) : null}

        {taskType !== useFormData_TaskTypePurchase ? (
          <>
            <Spacer left='3rem' />

            <Button
              type={
                userType === useFormData_UserRoleAgent ? "primary" : "white"
              }
              onClick={() => onChangeUserType(useFormData_UserRoleAgent)}>
              <Text type='md'>Медицинский представитель</Text>
            </Button>
          </>
        ) : null}
      </div>

      {renderUserTable()}

      <Spacer top='2rem' />

      <div className='d-flex'>
        <Button type='outline' onClick={back}>
          <Icon icon='remove' size='2rem' />
          <Spacer left='1.5rem' />
          <Text type='md'>Удалить</Text>
        </Button>
        <Spacer left='auto' />
        <Button
          type='white'
          btnType={"submit"}
          disabled={disableActions}
          onClick={() => {
            onChangeUploadStatus("draft");
          }}>
          <Text type='md'>Сохранить как черновик</Text>
        </Button>
        <Spacer left='3rem' />
        <Button
          type='primary'
          btnType={next ? "button" : "submit"}
          disabled={disableActions}
          onClick={e => {
            onChangeUploadStatus("active");
            if (next) {
              next();
            }
          }}>
          <Text type='md'>{next ? "Далее" : "Готово"}</Text>
        </Button>
      </div>
    </Modal>
  );
};

export default TaskUsers;
