import {
  StoreUserActs,
  StoreUserDataState,
  StoreUserDataStateName,
  StoreUserState,
  StoreUserStateDefault,
  USER_LOGIN,
  USER_LOGOUT,
} from "./userTypes";

const userStoreInitState: StoreUserState = {
  ...StoreUserStateDefault,
};

const userReducer = (
  state = userStoreInitState,
  action: StoreUserActs,
): StoreUserState => {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
        [StoreUserDataStateName]: action.payload as StoreUserDataState,
      };

    case USER_LOGOUT:
      return { ...state, [StoreUserDataStateName]: null };

    default:
      return state;
  }
};

export default userReducer;
