import { NavLink } from "react-router-dom";
import { ROUTE_SHOP_PRODUCTS } from "../../AppRoutes";
import { Icon, Spacer, Text } from "../common";
import LinkCard from "./LinkCard";

const Card = () => {
  return (
    <div className='dashboard-card'>
      <div className='d-flex align-center'>
        <div className='icon-card'>
          <Icon icon='shop' size='3.5rem' />
        </div>
        <Spacer left='2rem' />
        <Text color='black' type='h4'>
          Магазин
        </Text>
      </div>

      <Spacer top='2rem' />

      <LinkCard count='0' label='Все' link={ROUTE_SHOP_PRODUCTS} />

      <Spacer top='2rem' />

      <div className='dashboard-text-row'>
        <div className='d-flex'>
          <Text type='md'>Опубликoвано</Text>
          <Spacer left='auto' />
          <Text type='md' isBold>
            0
          </Text>
        </div>
      </div>

      <div className='dashboard-text-row'>
        <div className='d-flex'>
          <Text type='md'>Черновики</Text>
          <Spacer left='auto' />
          <Text type='md' isBold>
            0
          </Text>
        </div>
      </div>

      <div className='dashboard-text-row'>
        <div className='d-flex'>
          <Text type='md'>Заказы</Text>
          <Spacer left='auto' />
          <Text type='md' isBold>
            0
          </Text>
        </div>
      </div>
    </div>
  );
};

export default Card;
