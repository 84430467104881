import { BrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";
import {
  selectUserIsHasPermissions,
  selectUserIsLoggedIn,
  userLogout,
} from "./store/user";
import { selectAppState, StoreAppErrorTypeApp } from "./store/app";
import useDispatchError from "./shared/hooks/useDispatchError";
import { LoginRoutes, MainApplicationRoutes } from "./AppRoutes";

function App() {
  const dispatch = useDispatch();
  const { error } = useSelector(selectAppState);
  const [setError, clearError] = useDispatchError();
  const onCloseError = () => clearError();

  const isUserLoggedIn = useSelector(selectUserIsLoggedIn);
  const isUserHasPermissions = useSelector(selectUserIsHasPermissions);
  let AppRoutes =
    isUserLoggedIn && isUserHasPermissions
      ? MainApplicationRoutes
      : LoginRoutes;
  if (isUserLoggedIn && !isUserHasPermissions) {
    localStorage.removeItem("user");
    dispatch(userLogout());
    setError(
      "У Вас нет нужных прав доступа для использования системы, пожалуйста перезайдите в систему снова или свяжитесь с администратором.",
      StoreAppErrorTypeApp,
    );
  }

  return (
    <>
      <Snackbar
        key={error?.msg}
        open={!!error?.msg}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Alert severity={error?.style} onClose={onCloseError}>
          <AlertTitle>{error?.msgTitle}</AlertTitle>
          <p className='app-error-msg'>{error?.msg}</p>
        </Alert>
      </Snackbar>

      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </>
  );
}

export default App;
