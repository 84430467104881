import ApproveSellTable from "../components/approvement/ApproveSellTable";
import ApproveBuyTable from "../components/approvement/ApproveBuyTable";
import ApproveMerchTable from "../components/approvement/ApproveMerchTable";
import ApproveStudyTable from "../components/approvement/ApproveStudyTable";
import Layout from "../components/layout/Layout";
import { Tabs, Input, Icon, Button, Spacer, Text } from "../components/common";
import { Route, NavLink, useLocation } from "react-router-dom";
import {
  ROUTE_APPROVEMENT_BUY,
  ROUTE_APPROVEMENT_SELL,
  ROUTE_APPROVEMENT_MERCH,
  ROUTE_APPROVEMENT_STUDY,
} from "../AppRoutes";
import { useState } from "react";
import useOnHistoryChange from "../shared/hooks/useOnHistoryChange";
import APIService, {
  API_DATA_APPROVEMENT_BUY_REPORT,
  API_DATA_APPROVEMENT_SELL_REPORT,
} from "../services/api.service";
import useDispatchError from "../shared/hooks/useDispatchError";
import {
  StoreAppErrorStyleSuccess,
  StoreAppError_SuccessServerResponse,
} from "../store/app";

const ApprovementPage = () => {
  const location = useLocation();
  const [setError] = useDispatchError();

  const tabHeaders = [
    <NavLink to={ROUTE_APPROVEMENT_BUY} key={ROUTE_APPROVEMENT_BUY}>
      Закупка
    </NavLink>,
    <NavLink to={ROUTE_APPROVEMENT_SELL} key={ROUTE_APPROVEMENT_SELL}>
      Продажа
    </NavLink>,
    <NavLink to={ROUTE_APPROVEMENT_MERCH} key={ROUTE_APPROVEMENT_MERCH}>
      Мерчендайзинг
    </NavLink>,
    <NavLink to={ROUTE_APPROVEMENT_STUDY} key={ROUTE_APPROVEMENT_STUDY}>
      Обучение
    </NavLink>,
  ];

  const [searchQuery, setSearchQuery] = useState("");
  const onChangeSearchQuery = _e => {
    _e?.preventDefault();
    const _value = _e?.target?.value;

    if (_value !== searchQuery) {
      setSearchQuery(_value);
    }
  };
  useOnHistoryChange(location => {
    setSearchQuery("");
    onChangeUploadButtonStatus(location.pathname);
  });

  const _getUploadButtonData = pathname => {
    let data = {
      text: undefined,
      url: undefined,
    };

    switch (pathname) {
      case ROUTE_APPROVEMENT_SELL:
        data.text = "Отчет МП";
        data.url = API_DATA_APPROVEMENT_SELL_REPORT;
        break;

      case ROUTE_APPROVEMENT_BUY:
        data.text = "Отчет для Аптек";
        data.url = API_DATA_APPROVEMENT_BUY_REPORT;
        break;

      default:
        break;
    }

    return data;
  };
  const [uploadButton, setUploadButton] = useState(
    _getUploadButtonData(location.pathname),
  );
  const onChangeUploadButtonStatus = pathname => {
    const ubData = _getUploadButtonData(pathname);
    if (uploadButton.text !== ubData.text) {
      setUploadButton(ubData);
    }
  };

  const getApprovementReport = async url => {
    if (!url?.length) {
      setError("Не возможно сделать отчет этих данных.");
      return;
    }

    const resp = await APIService.getDataHandler(
      url,
      () => setError("Не удалось получить отчет."),
      0,
      10,
      "",
      resp =>
        setError(
          resp?.data?.status ??
            "Начат сбор информации, отчет будет отправлен на Вашу почту.",
          StoreAppError_SuccessServerResponse,
          StoreAppErrorStyleSuccess,
        ),
    );
  };

  return (
    <Layout>
      <div className='approvement-page'>
        <Tabs
          isRouterTab
          header={tabHeaders}
          tabs={[
            <Route
              exact
              path={ROUTE_APPROVEMENT_BUY}
              key={ROUTE_APPROVEMENT_BUY}>
              <ApproveBuyTable searchQuery={searchQuery} />
            </Route>,
            <Route
              exact
              path={ROUTE_APPROVEMENT_SELL}
              key={ROUTE_APPROVEMENT_SELL}>
              <ApproveSellTable searchQuery={searchQuery} />
            </Route>,
            <Route
              exact
              path={ROUTE_APPROVEMENT_MERCH}
              key={ROUTE_APPROVEMENT_MERCH}>
              <ApproveMerchTable searchQuery={searchQuery} />
            </Route>,
            <Route
              exact
              path={ROUTE_APPROVEMENT_STUDY}
              key={ROUTE_APPROVEMENT_STUDY}>
              <ApproveStudyTable searchQuery={searchQuery} />
            </Route>,
          ]}
          topSlot={
            <div
              className='d-flex'
              style={{
                width: "100%",
                justifyContent: uploadButton ? "space-between" : "flex-end",
              }}>
              {uploadButton?.text?.length ? (
                <>
                  <Button
                    type='primary'
                    onClick={e => {
                      e?.preventDefault();
                      getApprovementReport(uploadButton.url);
                    }}>
                    <Icon icon='download_white' size='2rem' className='grey' />
                    <Spacer left='0.5rem' />
                    <Text type='sm' color='white'>
                      {uploadButton.text}
                    </Text>
                  </Button>

                  <Spacer right='2rem' />
                </>
              ) : null}
              <Input
                value={searchQuery}
                onChange={onChangeSearchQuery}
                placeholder='Поиск'
                leftIcon={<Icon icon='search' size='2rem' />}
              />
            </div>
          }
        />
      </div>
    </Layout>
  );
};

export default ApprovementPage;
