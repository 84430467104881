import React from "react";
import {
  useFormDataTextRequired,
  useFormData_TaskTypeMerch,
} from "../../../../../../shared/hooks/useFormData";
import {
  Text,
  Spacer,
  Input,
  TextArea,
  Autocomplete,
  Button,
  Icon,
} from "../../../../../common";

const MerchFrom = ({
  formData,
  onChangeFormData,
  formOptions,
  onChangeFormOptions,
}) => {
  const onChangeMerchFormData = (value, valueName) =>
    onChangeFormData(value, valueName, useFormData_TaskTypeMerch);

  const onChangeHandler = (value, valueName) => {
    onChangeMerchFormData(value, valueName);
  };
  const onChangeMultiSelect = (value, valueName, options) => {
    const newValues = [];
    for (const v of value) {
      const defaultOpt = options?.find(o => o.value === v);
      if (defaultOpt?.id) {
        newValues.push(defaultOpt);
      }
    }
    onChangeHandler(newValues, valueName);
  };
  const onChangeNumberInput = (value, valueName) => {
    let prevValue = formData[valueName]?.value;
    let newValue = prevValue;

    if (!value?.length) {
      newValue = "";
    } else {
      const vlen = value.length;
      if (value[vlen - 1] === "." && vlen > 1 && value[vlen - 2] !== ".") {
        newValue = value;
      } else {
        const _num_pattern = /-?\d*\.?\d+/g;
        const _matchers = value?.match(_num_pattern);

        if (_matchers?.length) {
          newValue = _matchers?.join("");
        }
      }
    }

    if (newValue !== prevValue) {
      onChangeHandler(newValue, valueName);
    }
  };
  const onSelectAllOptions = (valueName, options) => {
    const newValue = JSON.parse(JSON.stringify(options));
    if (newValue?.length !== formData[valueName]?.value?.length) {
      onChangeHandler(newValue, valueName);
    }
  };

  return (
    <>
      <div className='d-flex align-center'>
        <Text color='secondary' type='md'>
          {useFormDataTextRequired(
            formData?.rewardagent?.label,
            formData?.rewardagent?.required,
          )}
        </Text>
        <Spacer left='auto' />
        <Input
          style={{ width: "23rem" }}
          placeholder='000,000,00'
          value={formData?.rewardagent?.value}
          required={formData?.rewardagent?.required}
          onChange={e => onChangeNumberInput(e.target.value, "rewardagent")}
        />
      </div>

      <Spacer top='2rem' />

      <div className='d-flex align-center'>
        <Text color='secondary' type='md'>
          {useFormDataTextRequired(
            formData?.rewardpharmacy?.label,
            formData?.rewardpharmacy?.required,
          )}
        </Text>
        <Spacer left='auto' />
        <Input
          style={{ width: "23rem" }}
          placeholder='000,000,00'
          value={formData?.rewardpharmacy?.value}
          required={formData?.rewardpharmacy?.required}
          onChange={e => onChangeNumberInput(e.target.value, "rewardpharmacy")}
        />
      </div>

      <Spacer top='2rem' />

      <div className='d-flex align-center'>
        <Text color='secondary' type='md'>
          {useFormDataTextRequired(
            formData?.areas?.label,
            formData?.areas?.required,
          )}
        </Text>

        <Spacer left='auto' />
        <Icon
          icon={`plus-outline${formOptions?.areas?.length ? "_blue" : ""}`}
          size='3.2rem'
          onClick={() => {
            if (formOptions?.areas?.length) {
              onSelectAllOptions("areas", formOptions?.areas);
            }
          }}
        />
        <Spacer left='2rem' />

        <Autocomplete
          style={{ width: "60%" }}
          multiple={true}
          id={"areas"}
          disabled={!formOptions?.areas}
          placeholder='Выберите зоны выкладки'
          value={
            formData?.areas?.value?.length
              ? formData?.areas?.value?.map(v => v.value)
              : []
          }
          options={formOptions?.areas?.map(a => a.value)}
          getOptionLabel={o => o}
          onValueChange={option =>
            onChangeMultiSelect(option, "areas", formOptions?.areas)
          }
        />
      </div>

      <Spacer top='2rem' />

      <Text color='secondary' type='md'>
        {useFormDataTextRequired(
          formData?.requirements?.label,
          formData?.requirements?.required,
        )}
      </Text>
      <Spacer top='2rem' />
      <TextArea
        value={formData?.requirements?.value}
        required={formData?.requirements?.required}
        onChange={e => onChangeHandler(e.target.value, "requirements")}
        style={{ width: "100%" }}
        rowsMin='5'
        placeholder='Введите требования'
      />
    </>
  );
};

export default MerchFrom;
