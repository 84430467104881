import React, { useState } from "react";
import { useFormData_TaskNewStudy } from "../../../../../shared/hooks/useFormData";

import { Modal, Spacer, Text, Button, Icon } from "../../../../common";

import TaskTestStudyTab from "./TaskTestStudyTab";
import TaskTestTestsTab from "./TaskTestTestsTab";
import TestPreview from "./TestPreview";

const TaskTest = ({
  disableActions,
  back,
  onChangeUploadStatus,
  tasksData,
  onChangeTasksData,
}) => {
  const [isPreview, setIsPreview] = useState(false);

  const [testsObjIdx, setTestsObjIdx] = useState(null);
  const onChangeTestObjIdx = _idx => setTestsObjIdx(_idx);
  const onChangeTestObjIdxClear = () => setTestsObjIdx(null);

  const onChangeHandler = (value, valueName, nestedValueName = null) => {
    onChangeTasksData(value, valueName, nestedValueName);
  };

  const onClickAddNewBlock = () => {
    const newTasks = [
      ...tasksData?.studiings?.value,
      { ...useFormData_TaskNewStudy() },
    ];

    onChangeHandler(newTasks, "studiings");
  };
  const onClickRemoveBlock = idx => {
    const newTasks = [...tasksData?.studiings?.value];
    newTasks.splice(idx, 1);
    onChangeHandler(newTasks, "studiings");
  };

  const renderTasksTests = () => {
    if (typeof testsObjIdx === "number") {
      return (
        <TaskTestTestsTab
          data={tasksData?.studiings?.value[testsObjIdx]}
          idx={testsObjIdx}
          allBlocks={tasksData?.studiings?.value}
          onChangeData={onChangeTasksData}
          onChangeTestObjIdxClear={onChangeTestObjIdxClear}
        />
      );
    }

    if (tasksData?.studiings?.value?.length) {
      return (
        <>
          {tasksData?.studiings?.value?.map((s, idx) => {
            return (
              <React.Fragment key={`ufd-task_new-study_tab-${idx}`}>
                <Modal title={`№${idx + 1}`}>
                  <div className='test-toggle-btns'>
                    {0 !== idx ? (
                      <div
                        className='test-toggle-btn delete'
                        onClick={e => {
                          e.preventDefault();
                          onClickRemoveBlock(idx);
                        }}>
                        Удалить
                      </div>
                    ) : null}

                    <div
                      className={`test-toggle-btn active`}
                      onClick={() => {}}>
                      Обучение
                    </div>

                    <div
                      className={`test-toggle-btn`}
                      onClick={() => onChangeTestObjIdx(idx)}>
                      Тест
                    </div>
                  </div>

                  <TaskTestStudyTab
                    idx={idx}
                    allBlocks={tasksData?.studiings?.value}
                    data={s}
                    onChangeData={onChangeTasksData}
                  />

                  <Spacer bottom='2rem' />
                </Modal>

                <Spacer bottom='3rem' />
              </React.Fragment>
            );
          })}

          <Spacer top='1.5rem' />

          <div
            className='test-new-block_btn'
            onClick={e => {
              e.preventDefault();
              onClickAddNewBlock();
            }}>
            <Icon icon='plus-outline_blue' size='3rem' />
            <Spacer left='1rem' />
            <Text color='grey' type='lg'>
              Добавить блок
            </Text>
          </div>

          <Spacer bottom='3.5rem' />
        </>
      );
    }

    return null;
  };

  return (
    <div className='tasks-t__modal'>
      {isPreview ? (
        <TestPreview
          close={() => setIsPreview(false)}
          studies={tasksData?.studiings?.value}
        />
      ) : (
        <>
          <Modal back={back} title='Создание обучения'>
            {renderTasksTests()}
          </Modal>

          <Spacer bottom='5rem' />

          <div className='d-flex'>
            <Button type='outline' onClick={back}>
              <Icon icon='remove' size='2rem' />
              <Spacer left='1.5rem' />
              <Text type='md'>Удалить</Text>
            </Button>

            <Spacer left='auto' />

            <Button
              type='outline'
              onClick={() => setIsPreview(true)}
              disabled={disableActions}>
              <Icon icon='eye' size='2rem' />
              <Spacer left='0.5rem' />
              <Text type='md'>Предпросмотр</Text>
            </Button>

            <Spacer left='3rem' />

            <Button
              type='white'
              btnType='submit'
              disabled={disableActions}
              onClick={() => {
                onChangeUploadStatus("draft");
              }}>
              <Text type='md'>Сохранить как черновик</Text>
            </Button>

            <Spacer left='3rem' />

            <Button
              type='primary'
              btnType='submit'
              disabled={disableActions}
              onClick={() => {
                onChangeUploadStatus("active");
              }}>
              <Text type='md'>Готово</Text>
            </Button>
          </div>

          <Spacer bottom='4.2rem' />
        </>
      )}
    </div>
  );
};

export default TaskTest;
