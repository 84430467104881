export const headers = [
  { title: "#", isSortable: false },
  { title: "Название", isSortable: false },
  { title: "Статус", isSortable: false },
  { title: "Тип", isSortable: false },
  { title: "Публикация", isSortable: true },
  { title: "Конец", isSortable: true },
  { title: "Выполнение", isSortable: false },
  { title: "Автор", isSortable: false },
  { title: "", isSortable: false },
];
export const tasksFilterStatusesSelect = [
  { value: "", text: "Все" },
  { value: "active", text: "Активная" },
  { value: "expired", text: "Закрыто" },
  { value: "draft", text: "Черновик" },
];
export const tasksStatuses = {
  ["active"]: {
    icon: "task-active",
    text: "Активная",
  },
  ["expired"]: {
    icon: "task-done",
    text: "Закрыто",
  },
  ["draft"]: {
    icon: "task-draft",
    text: "Черновик",
  },
};
