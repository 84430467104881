import React from "react";
import { Switch, Route, Link } from "react-router-dom";
import Layout from "../components/layout/Layout";
import { Icon, Button, Spacer, Text } from "../components/common";
import {
  ROUTE_NOTIFICATIONS,
  ROUTE_NOTIFICATIONS_NEW,
  ROUTE_NOTIFICATIONS_EDIT,
} from "../AppRoutes";
import NotificationsModal from "../components/notifications/NotificationsModal";
import NotificationsTable from "../components/notifications/NotificationsTable";

const NotificationsPage = () => {
  return (
    <Layout
      topSlot={
        <Link to={ROUTE_NOTIFICATIONS_NEW}>
          <Button type='primary'>
            <Icon icon='add' size='2rem' />
            <Spacer left='0.5rem' />
            <Text type='sm' color='white'>
              Создать
            </Text>
          </Button>
        </Link>
      }>
      <div className='faq-page'>
        <Switch>
          <Route exact path={ROUTE_NOTIFICATIONS}>
            <NotificationsTable open={() => setModal("edit")} />
          </Route>
          <Route path={ROUTE_NOTIFICATIONS_EDIT}>
            <NotificationsModal back={() => setModal(null)} isNew />
          </Route>
        </Switch>
      </div>
    </Layout>
  );
};

export default NotificationsPage;
