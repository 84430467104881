import React, { useState } from "react";
import { Switch, Route, NavLink } from "react-router-dom";

import Layout from "../components/layout/Layout";
import { Tabs, Button, Spacer, Text, Icon, Input } from "../components/common";

import PharmacyTable from "../components/users/PharmacyTable";
import MedicalMembersTable from "../components/users/MedicalMembersTable";
import AdminTable from "../components/users/AdminTable";
import UserModal from "../components/users/UserModal";

import {
  ROUTE_USERS,
  ROUTE_USERS_MEDICAL_MEMBERS,
  ROUTE_USERS_PHARMACY,
  ROUTE_USERS_ADMIN,
  ROUTE_USERS_NEW,
  ROUTE_USERS_EDIT,
} from "../AppRoutes";
import useOnHistoryChange from "../shared/hooks/useOnHistoryChange";
import APIService, {
  API_DATA_USERS_REPORT,
  API_DATA_USERS_REPORT_PHARMACIES,
} from "../services/api.service";
import useDispatchError from "../shared/hooks/useDispatchError";
import {
  StoreAppErrorStyleSuccess,
  StoreAppError_SuccessServerResponse,
} from "../store/app";

const UsersPage = () => {
  const [setError] = useDispatchError();
  const [searchQuery, setSearchQuery] = useState("");
  const onChangeSearchQuery = _e => {
    _e?.preventDefault();
    const _value = _e?.target?.value;

    if (_value !== searchQuery) {
      setSearchQuery(_value);
    }
  };

  useOnHistoryChange(location => {
    setSearchQuery("");
    onChangeUpload(location.pathname);
  });

  const _uploadURLS = {
    [ROUTE_USERS_MEDICAL_MEMBERS]: API_DATA_USERS_REPORT_PHARMACIES,
    [ROUTE_USERS_PHARMACY]: API_DATA_USERS_REPORT,
  };
  const _uploadTexts = {
    [ROUTE_USERS_MEDICAL_MEMBERS]: "Отчет Аптеки - МП",
    [ROUTE_USERS_PHARMACY]: "Отчет Пользователи",
  };

  const [upload, setUpload] = useState(
    _uploadURLS[location.pathname]
      ? {
          path: location.pathname,
          URL: _uploadURLS[location.pathname],
          text: _uploadTexts[location.pathname],
        }
      : null,
  );
  const onChangeUpload = pathname => {
    if (upload?.path !== pathname && !!_uploadURLS[pathname]) {
      setUpload({
        path: pathname,
        URL: _uploadURLS[pathname],
        text: _uploadTexts[pathname],
      });
    } else if (!!upload) {
      setUpload(null);
    }
  };

  const tabHeaders = [
    <NavLink to={ROUTE_USERS_MEDICAL_MEMBERS}>Мед.представители</NavLink>,
    <NavLink to={ROUTE_USERS_PHARMACY}>Аптеки</NavLink>,
    <NavLink to={ROUTE_USERS_ADMIN}>Администраторы</NavLink>,
  ];

  const getUsersReport = async () => {
    const resp = await APIService.getDataHandler(
      upload.URL,
      () => setError("Не удалось получить отчет по пользователям."),
      0,
      10,
      "",
      resp =>
        setError(
          resp?.data?.status ??
            "Начат сбор информации, отчет будет отправлен на Вашу почту.",
          StoreAppError_SuccessServerResponse,
          StoreAppErrorStyleSuccess,
        ),
    );
  };

  return (
    <Layout
      topSlot={
        <NavLink to={ROUTE_USERS_NEW}>
          <Button type='primary'>
            <Icon icon='add' size='2rem' />
            <Spacer left='0.5rem' />
            <Text type='sm' color='white'>
              Добавить пользователя
            </Text>
          </Button>
        </NavLink>
      }>
      <div className='users-page'>
        <Switch>
          <Route path={ROUTE_USERS_EDIT}>
            <UserModal />
          </Route>
          <Route path={ROUTE_USERS}>
            <Tabs
              isRouterTab
              header={tabHeaders}
              tabs={
                <React.Fragment>
                  <Route
                    path={ROUTE_USERS_MEDICAL_MEMBERS}
                    key={ROUTE_USERS_MEDICAL_MEMBERS}>
                    <MedicalMembersTable searchQuery={searchQuery} />
                  </Route>
                  <Route path={ROUTE_USERS_PHARMACY} key={ROUTE_USERS_PHARMACY}>
                    <PharmacyTable searchQuery={searchQuery} />
                  </Route>
                  <Route path={ROUTE_USERS_ADMIN} key={ROUTE_USERS_ADMIN}>
                    <AdminTable searchQuery={searchQuery} />
                  </Route>
                </React.Fragment>
              }
              topSlot={
                <div
                  className='d-flex'
                  style={{
                    width: "100%",
                    justifyContent: upload ? "space-between" : "flex-end",
                  }}>
                  {upload ? (
                    <>
                      <Button
                        type='primary'
                        onClick={e => {
                          e?.preventDefault();
                          getUsersReport();
                        }}>
                        <Icon
                          icon='download_white'
                          size='2rem'
                          className='grey'
                        />
                        <Spacer left='0.5rem' />
                        <Text type='sm' color='white'>
                          {upload.text}
                        </Text>
                      </Button>

                      <Spacer right='2rem' />
                    </>
                  ) : null}
                  <Input
                    value={searchQuery}
                    onChange={onChangeSearchQuery}
                    placeholder='Поиск'
                    leftIcon={<Icon icon='search' size='2rem' />}
                  />
                </div>
              }
            />
          </Route>
        </Switch>
      </div>
    </Layout>
  );
};

export default UsersPage;
