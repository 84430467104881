import queryStringify from "qs-stringify";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { ROUTE_NOTIFICATIONS } from "../../AppRoutes";
import APIService, {
  API_DATA_NOTIFICATIONS,
  API_DATA_USERS,
} from "../../services/api.service";
import useDispatchError from "../../shared/hooks/useDispatchError";
import useFormData, {
  useFormDataPropSelectDefault,
  useFormDataTextRequired,
} from "../../shared/hooks/useFormData";
import { StoreAppErrorTypeForm } from "../../store/app";

import {
  Text,
  Spacer,
  TextArea,
  Button,
  Icon,
  Modal,
  Radio,
  Autocomplete,
} from "../common";

const NotificationsModal = ({ isNew }) => {
  let history = useHistory();
  const [setError] = useDispatchError();
  const { id } = useParams();
  const _isNew = isNew && !id;

  const [disabledActions, setDisabledActions] = useState(false);

  const [formData, onChangeData, onSubmit, fetchedUser] = useFormData({
    id: id,
    dataName: API_DATA_NOTIFICATIONS,
  });
  const onChangeInput = (value, valueName) => {
    onChangeData(value, valueName);
  };

  const _mapUserDataToFormData = userData => {
    let _name = "Неизвестно";
    if (userData?.firstname?.length) {
      _name = userData?.firstname;
      if (userData?.lastname?.length) {
        _name = `${_name} ${userData?.lastname}`;
      }
    }

    return {
      text: `${_name} - ${userData?.username}`,
      value: userData?.id,
      id: userData?.id,
    };
  };
  useEffect(() => {
    if (fetchedUser?.user && formData?.description?.value?.length) {
      const _ud = _mapUserDataToFormData(fetchedUser?.user);
      onChangeData(_ud, "user");
    }
  }, [formData, fetchedUser]);

  const onDelete = async e => {
    e.preventDefault();

    if (!_isNew) {
      await APIService.delDataByIDHandler(API_DATA_NOTIFICATIONS, id, () =>
        setError(`Не удалось удалить Уведомление(${id})`),
      );
    }

    history.push(ROUTE_NOTIFICATIONS);
  };

  const [usersOptions, setUsersOptions] = useState([]);
  const [usersSearchQuery, setUsersSearchQuery] = useState("");
  const _getUsersQueries = () => {
    let _filters = {};
    if (usersSearchQuery?.length) {
      _filters["_q"] = usersSearchQuery;
    }

    const _qs = queryStringify(_filters);
    return _qs?.length ? _qs : "";
  };

  const _type = formData?.type?.value;

  useEffect(() => {
    const _fetchUsers = async () => {
      const newUsersOptions = await APIService.getDataHandler(
        API_DATA_USERS,
        () => setError(`Не удалось получить список пользователей.`),
        0,
        25,
        _getUsersQueries(),
      );

      if (!newUsersOptions) {
        return;
      }

      const _newOpts = newUsersOptions?.map(uo => _mapUserDataToFormData(uo));
      setUsersOptions(_newOpts);
    };

    if (_type === "private") {
      _fetchUsers();
    }
  }, [_type, usersSearchQuery]);

  const onSubmitHandler = async e => {
    if (disabledActions) {
      return;
    } else {
      setDisabledActions(true);
    }

    if (formData?.type?.value === "private" && !formData?.user?.value?.length) {
      setError(
        "Выберите пользователя для приватного уведомления.",
        StoreAppErrorTypeForm,
      );
      return;
    }

    e.preventDefault();
    const newNotification = await onSubmit();

    if (newNotification) {
      history.push(ROUTE_NOTIFICATIONS);
    } else {
      setDisabledActions(false);
    }
  };

  const notificationTypesOptions = [
    { label: "Все", value: "general" },
    { label: "Аптеки", value: "pharmacy" },
    { label: "Мед. представители", value: "agent" },
    { label: "Приватные", value: "private" },
  ];

  return (
    <Modal
      back={() => history.push(ROUTE_NOTIFICATIONS)}
      title={isNew ? "Создать уведомление" : "Редактировать уведомление"}>
      <form onSubmit={onSubmitHandler}>
        <Text color='secondary' type='md'>
          {useFormDataTextRequired(
            formData?.type?.label,
            formData?.type?.required,
          )}
        </Text>
        <Spacer top='1.5rem' />
        <Radio
          value={formData?.type?.value}
          required={formData?.type?.required}
          onChange={val => onChangeInput(val, "type")}
          variants={notificationTypesOptions}
        />

        <Spacer top='2.25rem' />

        {formData?.type?.value === "private" && (
          <>
            <Text color='secondary' type='md' noWrap>
              {useFormDataTextRequired(formData?.user?.label, true)}
            </Text>
            <Spacer top='1.5rem' />
            <Autocomplete
              id='user'
              disabled={!usersOptions?.length && !usersSearchQuery?.length}
              placeholder={
                !usersOptions?.length
                  ? "Идет загрузка пользователей ..."
                  : "Выберите пользователя"
              }
              value={formData?.user?.text === "Все" ? "" : formData?.user?.text}
              onInputChange={input => setUsersSearchQuery(input)}
              onValueChange={option => {
                let opt = usersOptions?.find(uo => uo.text === option);
                if (!opt) {
                  opt = { ...useFormDataPropSelectDefault };
                }
                onChangeData(opt, "user");
              }}
              required={true}
              options={usersOptions?.map(c => c.text)}
              getOptionLabel={option => option}
            />

            <Spacer top='2.25rem' />
          </>
        )}

        <Text color='secondary' type='md'>
          {useFormDataTextRequired(
            formData?.name?.label,
            formData?.name?.required,
          )}
        </Text>
        <Spacer top='1.5rem' />
        <TextArea
          value={formData?.name?.value}
          required={formData?.name?.required}
          onChange={e => onChangeInput(e.target.value, "name")}
          rowsMin='1'
          placeholder='Введите заголовок'
          style={{ width: "100%" }}
        />

        <Spacer top='2.25rem' />

        <Text color='secondary' type='md'>
          {useFormDataTextRequired(
            formData?.description?.label,
            formData?.description?.required,
          )}
        </Text>
        <Spacer top='1.5rem' />
        <TextArea
          value={formData?.description?.value}
          required={formData?.description?.required}
          onChange={e => onChangeInput(e.target.value, "description")}
          rowsMin='9'
          placeholder='Введите текст'
          style={{ width: "100%" }}
        />

        <Spacer top='4rem' />

        <div className='d-flex'>
          <Button type='outline' onClick={onDelete} disabled={disabledActions}>
            <Icon icon='remove' size='2rem' />
            <Spacer left='1.5rem' />
            <Text type='md'>Удалить</Text>
          </Button>
          <Spacer left='auto' />
          <Button type='primary' btnType='submit' disabled={disabledActions}>
            <Text type='md'>Сохранить</Text>
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default NotificationsModal;
