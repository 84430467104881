function Text({
    type,
    children,
    color,
    isLink,
    isThin,
    isBold,
    isExtraBold,
    isCenter,
    isPointer,
    isInline,
    noWrap,
    onClick = () => { }
}) {
    const types = {
        'caption': 'caption-text',
        'sm': 'sm-text',
        'md': 'md-text',
        'lg': 'lg-text',
        'h4': 'h4-text',
        'h3': 'h3-text',
        'h2': 'h2-text',
        'h1': 'h1-text',
    };
    const colors = {
        'primary': '#65A5DC',
        'secondary': '#253872',
        'black': '#1A1919',
        'grey': '#7D7D7D',
        'white': '#FFFFFF',
        'link': '#4A91DB',
    };
    return (
        <div
            onClick={onClick}
            className={(type ? types[type] : 'sm-text') + ' textCmp ' + (isLink ? 'text-link' : '')}
            style={{
                whiteSpace: noWrap ? 'nowrap' : 'unset',
                textAlign: isCenter ? 'center' : 'left',
                color: color && colors[color] ? colors[color] : '#383535',
                fontWeight: isExtraBold ? '700' : isBold ? '600' : isThin ? '300' : 'inherit',
                cursor: isPointer ? 'pointer' : 'unset',
                display: isInline ? 'inline' : 'block'
            }}
        >
            {children}
        </div>
    );
}

export default Text;
