import React from "react";
import { useHistory } from "react-router";
import { ROUTE_NOTIFICATIONS_NEW } from "../../AppRoutes";
import { API_DATA_NOTIFICATIONS } from "../../services/api.service";
import getShortenedText from "../../shared/functions/getShortenedText";
import useAPIData from "../../shared/hooks/useAPIData";
import { Table, Text, Icon, Button } from "../common";

const NotificationsTable = () => {
  const history = useHistory();
  const [notifications, pagination, , deleteNotification] = useAPIData(
    API_DATA_NOTIFICATIONS,
  );

  const onDelete = async id => {
    await deleteNotification(id);
  };
  const onToEdit = id => {
    history.push(`${ROUTE_NOTIFICATIONS_NEW}/${id}`);
  };

  const headers = [
    { title: "ID", isSortable: false },
    { title: "Тип пользователя", isSortable: false },
    { title: "Заголовок", isSortable: false },
    { title: "", isSortable: false },
  ];
  const notificationTypes = {
    general: "Все",
    agent: "Мед. представитель",
    pharmacy: "Аптека",
    private: "Приватное",
  };

  const row = item => {
    let typeName = "Неизвестно";
    if (notificationTypes[item.type]) {
      typeName = notificationTypes[item.type];
    }

    return (
      <React.Fragment>
        <div className='table-row__cell'>
          <Text>{item.id?.slice(0, 4)}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{typeName}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{getShortenedText(item?.name, 100) ?? "Неизвестно"}</Text>
        </div>
        <div className='table-row__cell'>
          <Button
            type='flat'
            onClick={e => {
              e?.preventDefault();
              onDelete(item.id);
            }}>
            <Icon icon='remove' size='2rem' />
          </Button>
          <Button
            type='flat'
            onClick={e => {
              e?.preventDefault();
              onToEdit(item.id);
            }}>
            <Icon icon='edit' size='2rem' />
          </Button>
        </div>
      </React.Fragment>
    );
  };

  return (
    <Table
      pagination={pagination}
      columns={["12rem", "25rem", "93rem", "11rem"]}
      headers={headers}
      row={row}
      list={notifications}
      isLoading={!notifications}
    />
  );
};

export default NotificationsTable;
