import React from 'react';
import { Spacer, Text } from '../common';
const ListElement = ({ primaryText, secondaryText, actionSlot }) => {

    return (
        <div className="list-element-wrapper">
            <Text>{primaryText}</Text>
            <Spacer left="auto" />
            <Text>{secondaryText}</Text>
            <Spacer left="10rem" />
            {actionSlot}
        </div>
    );
};


export default ListElement