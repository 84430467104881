import React from "react";
import { API_DATA_COMMANDS } from "../../services/api.service";
import getShortenedText from "../../shared/functions/getShortenedText";
import useAPIData from "../../shared/hooks/useAPIData";
import { Table, Text, Icon } from "../common";
import TeamsTableInfo from "./TeamsTableInfo";

const TeamsTable = () => {
  const [teams, pagination, updateTeams] = useAPIData(API_DATA_COMMANDS);

  const headers = [
    { title: "Команда", isSortable: false },
    { title: "Пользователей", isSortable: false },
  ];

  const row = item => {
    const countUsers = () =>
      item?.agents?.length
        ? item?.agents?.filter(a => !!a?.user?.id?.length)?.length
        : 0;

    const _usersCount = countUsers();

    return (
      <React.Fragment>
        <div className='table-row__cell'>
          <Text>{getShortenedText(item.name, 33)}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{_usersCount ?? 0}</Text>
        </div>
      </React.Fragment>
    );
  };
  return (
    <Table
      // columns={['0.8fr', '1fr', '1fr', ' 0.75fr', '0.5fr', '0.5fr', '0.9fr', '0.9fr']}
      columns={["30rem", "105rem", "5rem"]}
      collapseSlot={<Icon icon='arrow-right' size='3rem' />}
      childrenRow={item => (
        <TeamsTableInfo team={item} updateTeams={updateTeams} />
      )}
      headers={headers}
      row={row}
      pagination={pagination}
      isLoading={!teams}
      list={teams}
    />
  );
};

export default TeamsTable;
