import { StoreSelectorBase } from "..";

import {
  StoreUserState,
  StoreUserName,
  StoreUserDataState,
  StoreUserDataStateName,
  StoreUserDataPermissions,
} from "./userTypes";

export const selectUserState: StoreSelectorBase<StoreUserState> = s =>
  s && s[StoreUserName];
export const selectUserData: StoreSelectorBase<StoreUserDataState> = s => {
  const userState = selectUserState(s);
  return userState && userState[StoreUserDataStateName]
    ? userState[StoreUserDataStateName]
    : null;
};
export const selectUserPermissions: StoreSelectorBase<
  StoreUserDataPermissions
> = (s): StoreUserDataPermissions => selectUserData(s)?.permissions;
export const selectUserIsLoggedIn: StoreSelectorBase<boolean> = (s): boolean =>
  !!selectUserData(s);
export const selectUserIsHasPermissions: StoreSelectorBase<boolean> = (
  s,
): boolean => !!selectUserPermissions(s)?.length;
