import { useEffect, useState } from "react";
import APIService from "../../../services/api.service";
import useDispatchError from "../useDispatchError";

const paginationPageDefault = 1;
const paginationLimitDefault = 10;
const paginationTotalDefaults = 10;
const paginationDefaultState = {
  page: paginationPageDefault,
  limit: paginationLimitDefault,
  total: paginationTotalDefaults,
};

/**
 * @param {string} dataName an any API data url starts by API_DATA_***
 * @param {string} query an additional queries to request
 * @param {number} limit a data limit to fetch for 1 page
 * @param {boolean} isDataRequiredToFetch a flag to make fetching data optional to fetch on hook initialization
 *
 * @returns [ data, pagination, updateData, deleteDataByID, onChangePage, manuallyUpdateObjects ]
 */
const useAPIData = (
  dataName,
  query = "",
  limit = paginationLimitDefault,
  isDataRequiredToFetch = true,
) => {
  const [setError] = useDispatchError();

  const [data, setData] = useState({
    ...paginationDefaultState,
    objects: undefined,
    limit,
  });

  const getDataCount = (_q = "") => {
    return APIService.getDataCountHandler(
      dataName,
      () => setError(`Не удалось получить кол-во данных(${dataName}).`),
      _q,
    );
  };
  const getData = (start = 0, limit = paginationLimitDefault, _q = "") => {
    return APIService.getDataHandler(
      dataName,
      () => setError(`Не удалось получить данные(${dataName}).`),
      start,
      limit,
      _q,
    );
  };

  const fetchData = async (start, limit, _q = "") => {
    const total = await getDataCount(_q);
    if (typeof total === "undefined") {
      return;
    }

    let objects = await getData(start, limit, _q);
    if (objects) {
      // after deleting the last one object on the last page
      // - retry to fetch data for previous page
      if (!objects.length && total && data.page > 1) {
        let __retryPage =
          total % data.limit === 0
            ? total / data.limit
            : total / data.limit + 1;
        __retryPage = parseInt(__retryPage);
        if (1 > __retryPage) {
          __retryPage = 1;
        }

        const __retryStart = (__retryPage - 1) * limit;
        objects = await getData(__retryStart, limit, _q);
        if (objects) {
          setData({ ...data, page: __retryPage, total, objects });
        }
      } else {
        setData({ ...data, total, objects });
      }
    }

    return { objects, total };
  };

  const updateData = async (_q = "") => {
    const start = (data.page - 1) * data.limit;
    return await fetchData(start, data.limit, _q);
  };

  const onChangePage = async (value, _q = "") => {
    const _newPage = value ?? paginationPageDefault;
    const start = (_newPage - 1) * data.limit;

    const fetched = await fetchData(start, data.limit, _q, _newPage);
    if (fetched?.objects) {
      setData({ ...data, ...fetched, page: _newPage });
    }
  };

  const deleteDataByID = async (_id, _q = "") => {
    const isDeleted = await APIService.delDataByIDHandler(dataName, _id, () =>
      setError(`Не удалось удалить объект(${id})`),
    );
    if (isDeleted) {
      updateData(_q);
    }
  };

  useEffect(() => {
    if (isDataRequiredToFetch) {
      fetchData(0, data.limit, query);
    }
  }, []);

  const pagination = {
    page: data.page,
    limit: data.limit,
    total: data.total,
    onChange: onChangePage,
  };

  const manuallyUpdateObjects = newObjects => {
    setData({ ...data, objects: newObjects ? [...newObjects] : newObjects });
  };

  return [
    data?.objects,
    pagination,
    updateData,
    deleteDataByID,
    onChangePage,
    manuallyUpdateObjects,
  ];
};

export default useAPIData;
