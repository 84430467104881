import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import appReducer, { StoreAppName } from "./app";
import userReducer, { StoreUserName } from "./user";

export const StoreRootReducer = combineReducers({
  [StoreAppName]: appReducer,
  [StoreUserName]: userReducer,
});

const store = createStore(
  StoreRootReducer,
  process.env.NODE_ENV === "production"
    ? applyMiddleware(thunk)
    : composeWithDevTools(applyMiddleware(thunk)),
);

export default store;
