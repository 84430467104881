import { Icon, Spacer, Text } from '../common';
import { Link, NavLink } from 'react-router-dom';
const LinkCard = ({ count, label, link, subLabel, date }) => {

    return (
        <NavLink to={link} style={{ width: "100%" }}>
            <div className="link-card">
                <Text color="primary" type="h3">{count}</Text>
                <Spacer left="3.5rem" />
                <div>
                    <Text color="black" type="lg" isBold>{label}</Text>
                    {subLabel && <Text color="black" type="sm">{subLabel}</Text>}
                </div>
                <Spacer left="auto" />
                <Icon icon="primary-forward" size="3rem" />
                {date && <div className="link-card__date-label">{date}</div>}
            </div>
        </NavLink>
    );
};

export default LinkCard;
