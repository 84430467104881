import React, { useEffect } from "react";
import { API_DATA_PROMO_PRODUCTS } from "../../services/api.service";
import useAPIData from "../../shared/hooks/useAPIData";
import { Table, Text } from "../common";

const MedicalMembersTable = ({
  queryMonthID,
  isUpdateChildTableComponent,
  resetUpdateChildTableComponent,
}) => {
  const _getQueries = () => {
    if (queryMonthID?.length) {
      return `month.id=${queryMonthID}`;
    }
    return "";
  };
  const _queries = _getQueries();
  const _isFetchData = !!_queries?.length;

  const [products, pagination, updateProducts, , onChangePage] = useAPIData(
    API_DATA_PROMO_PRODUCTS,
    _queries,
    10,
    _isFetchData,
  );
  const onChangePageHandler = newPage => onChangePage(newPage, _queries);

  useEffect(() => {
    if (isUpdateChildTableComponent) {
      onChangePageHandler(1);
      resetUpdateChildTableComponent();
    }
  }, [isUpdateChildTableComponent]);

  useEffect(() => {
    const upd = async () => {
      if (_isFetchData) {
        await updateProducts(_queries);
      }
    };
    upd();
  }, [queryMonthID, _isFetchData]);

  const headers = [
    { title: "#", isSortable: false },
    { title: "Название", isSortable: false },
    { title: "Бренд", isSortable: false },
    { title: "Дистрибьюторы", isSortable: false },
    { title: "Количество", isSortable: false },
    { title: "Баллы", isSortable: false },
    { title: "Супер баллы", isSortable: false },
  ];

  const row = item => {
    return (
      <React.Fragment>
        <div className='table-row__cell'>
          <Text>{item?.mc ?? "Неизвестно"}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{item?.name ?? "Неизвестно"}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{item?.brand?.name}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{item?.distributor ?? "Неизвестно"}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{item?.minamount ?? 0}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{item?.scores ?? 0}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{item?.scorespromo ?? 0}</Text>
        </div>
      </React.Fragment>
    );
  };
  return (
    <Table
      // columns={['0.8fr', '1fr', '1fr', ' 0.75fr', '0.5fr', '0.5fr', '0.9fr', '0.9fr']}
      columns={["12rem", "35rem", "24rem", "25rem", "15rem", "12rem", "13rem"]}
      headers={headers}
      row={row}
      pagination={{ ...pagination, onChange: onChangePageHandler }}
      list={products}
      isLoading={!products}
    />
  );
};

export default MedicalMembersTable;
