import { StoreActBase } from "..";

export const StoreAppName = "app";

export const StoreAppLoaderStateName = "loader";
export const StoreAppErrorStateName = "error";
export type StoreAppStateNames =
  | typeof StoreAppLoaderStateName
  | typeof StoreAppErrorStateName;

export type StoreAppLoader = boolean;
export const StoreAppLoaderDefault = false;
export const StoreAppLoaderShowing = true;
export const StoreAppLoaderHidden = false;

export const StoreAppErrorTypeLogin = "login";
export const StoreAppErrorTypeServer = "server";
export const StoreAppErrorTypeForm = "form/missing_field";
export const StoreAppErrorTypeApp = "app";
export const StoreAppError_SuccessServerResponse = "_ssr";
export type StoreAppErrorType =
  | typeof StoreAppErrorTypeForm
  | typeof StoreAppErrorTypeServer
  | typeof StoreAppErrorTypeLogin
  | typeof StoreAppErrorTypeApp;

export const StoreAppErrorStyleError = "error";
/*
  yeah, permanently the notifications was created as error only,
  so success message are like a church, there only naming may confuse.
 */
export const StoreAppErrorStyleSuccess = "success";
export type StoreAppErrorStyle =
  | typeof StoreAppErrorStyleError
  | typeof StoreAppErrorStyleSuccess;

export type StoreAppError =
  | {
      msg: string;
      type: StoreAppErrorType;
      style: StoreAppErrorStyle;
      msgTitle: string;
    }
  | undefined
  | null;
export const StoreAppErrorDefault = null;

export interface StoreAppState {
  [StoreAppLoaderStateName]: StoreAppLoader;
  [StoreAppErrorStateName]: StoreAppError;
}
export const StoreAppStateDefault: StoreAppState = {
  [StoreAppLoaderStateName]: StoreAppLoaderDefault,
  [StoreAppErrorStateName]: StoreAppErrorDefault,
};

export const APP_LOADER_SHOW = "loader/show";
export type StoreActAppLoaderShow = StoreActBase<typeof APP_LOADER_SHOW>;
export const APP_LOADER_HIDE = "loader/hide";
export type StoreActAppLoaderHide = StoreActBase<typeof APP_LOADER_HIDE>;

export const APP_ERROR_SET = "error/set";
export type StoreActAppErrorSet = StoreActBase<
  typeof APP_ERROR_SET,
  StoreAppError
>;
export const APP_ERROR_CLEAR = "error/clear";
export type StoreActAppErrorClear = StoreActBase<typeof APP_ERROR_CLEAR>;

export type StoreAppActs =
  | StoreActAppLoaderShow
  | StoreActAppLoaderHide
  | StoreActAppErrorSet
  | StoreActAppErrorClear;
