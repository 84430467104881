import React from 'react';
import { Icon, Text, Spacer } from '../common/';

const Modal = ({ back, title, children }) => {

    return (
        <div className="modal">
            <div className="modal-header">
                {back && <div className="modal-header__btn" onClick={back}>
                    <Icon icon="arrow-left" size="5rem" />
                </div>}
                <Spacer left="1rem" />
                <Text color="secondary" type="h3">{title}</Text>
            </div>
            <div className="modal-content">
                {children}
            </div>
        </div>
    );
}

export default Modal;