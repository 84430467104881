import React from "react";
import { Link, Route, Switch } from "react-router-dom";
import TasksTable from "../components/tasks/TasksTable";
import TaskModal from "../components/tasks/modal/TaskModal";
import Layout from "../components/layout/Layout";
import { Icon, Button, Spacer, Text } from "../components/common";
import {
  ROUTE_TASKS_PARAMS,
  ROUTE_TASKS_EDIT,
  ROUTE_TASKS_NEW,
} from "../AppRoutes";

const TasksPage = () => {
  return (
    <Layout
      topSlot={
        <div className='d-flex'>
          <Link to={ROUTE_TASKS_NEW}>
            <Button type='primary'>
              <Icon icon='add' size='2rem' />
              <Spacer left='0.5rem' />
              <Text type='sm' color='white'>
                Создать
              </Text>
            </Button>
          </Link>
        </div>
      }>
      <div className='faq-page'>
        <Switch>
          <Route path={ROUTE_TASKS_EDIT}>
            <TaskModal />
          </Route>
          <Route path={ROUTE_TASKS_PARAMS}>
            <TasksTable />
          </Route>
        </Switch>
      </div>
    </Layout>
  );
};

export default TasksPage;
