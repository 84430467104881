import React from "react";
import Logo from "../../assets/logo-dark.svg";
import { Text, Spacer, Icon, Avatar } from "../common";
import { NavLink, Link } from "react-router-dom";

import {
  ROUTE_HOME,
  ROUTE_TASKS,
  ROUTE_USERS_MEDICAL_MEMBERS,
  ROUTE_DATA_PHARMACY,
  ROUTE_SHOP_PRODUCTS,
  ROUTE_APPROVEMENT_BUY,
  ROUTE_APPROVEMENT,
  ROUTE_USERS,
  ROUTE_SHOP,
  ROUTE_DATA,
  ROUTE_NOTIFICATIONS,
  ROUTE_NEWS,
  ROUTE_FAQ,
  ROUTE_SHOP_,
} from "../../AppRoutes";
import { useSelector } from "react-redux";
import { selectUserData } from "../../store/user";

const Menu = () => {
  const userData = useSelector(selectUserData);
  const userPermissions = userData.permissions ?? [];

  let userName = "Неизвестно";
  if (userData?.firstname && userData?.lastname) {
    userName = `${userData?.firstname} ${userData?.lastname}`;
  }

  const _getUserPermissionNav = (routeName, id) => {
    return {
      [ROUTE_TASKS]: (
        <NavLink
          key={`app-nav-${id}`}
          to={ROUTE_TASKS}
          className='menu-item'
          isActive={(match, location) => {
            return location.pathname.startsWith("/tasks");
          }}>
          <Icon icon='document' size='2.5rem' />
          <Spacer left='2rem' />
          <div>Задания</div>
        </NavLink>
      ),
      [ROUTE_APPROVEMENT]: (
        <NavLink
          key={`app-nav-${id}`}
          to={ROUTE_APPROVEMENT_BUY}
          isActive={(match, location) => {
            return location.pathname.split("/")[1] === "approvement";
          }}
          className='menu-item'>
          <Icon icon='done-outline' size='2.5rem' />
          <Spacer left='2rem' />
          <div>Подтверждение</div>
        </NavLink>
      ),
      [ROUTE_USERS]: (
        <NavLink
          key={`app-nav-${id}`}
          to={ROUTE_USERS_MEDICAL_MEMBERS}
          isActive={(match, location) => {
            return location.pathname.split("/")[1] === "users";
          }}
          className='menu-item'>
          <Icon icon='user' size='2.5rem' />
          <Spacer left='2rem' />
          <div>Пользователи</div>
        </NavLink>
      ),
      [ROUTE_SHOP_]: (
        <NavLink
          key={`app-nav-${id}`}
          to={ROUTE_SHOP_PRODUCTS}
          isActive={(match, location) => {
            return location.pathname.split("/")[1] === "shop";
          }}
          className='menu-item'>
          <Icon icon='shop' size='2.5rem' />
          <Spacer left='2rem' />
          <div>Магазин</div>
        </NavLink>
      ),
      [ROUTE_DATA]: (
        <NavLink
          key={`app-nav-${id}`}
          to={ROUTE_DATA_PHARMACY}
          isActive={(match, location) => {
            return location.pathname.split("/")[1] === "data";
          }}
          className='menu-item'>
          <Icon icon='data-chart' size='2.5rem' />
          <Spacer left='2rem' />
          <div>Управление данными</div>
        </NavLink>
      ),
    }[routeName];
  };
  const _getUserPermissionAdditionalNav = (routeName, id) => {
    return {
      [ROUTE_NOTIFICATIONS]: (
        <NavLink
          to={ROUTE_NOTIFICATIONS}
          exact
          className='menu-item'
          key={`app-route-add-nav-${id}`}>
          <Icon icon='bell' size='2.5rem' />
          <Spacer left='2rem' />
          <div>Уведомления</div>
        </NavLink>
      ),
      [ROUTE_NEWS]: (
        <NavLink
          to={ROUTE_NEWS}
          exact
          className='menu-item'
          key={`app-route-add-nav-${id}`}>
          <Icon icon='news' size='2.5rem' />
          <Spacer left='2rem' />
          <div>Новости</div>
        </NavLink>
      ),
      [ROUTE_FAQ]: (
        <NavLink
          to={ROUTE_FAQ}
          exact
          className='menu-item'
          key={`app-route-add-nav-${id}`}>
          <Icon icon='info' size='2.5rem' />
          <Spacer left='2rem' />
          <div>FAQ</div>
        </NavLink>
      ),
    }[routeName];
  };

  return (
    <div className='page-menu'>
      <div className='logo-wrapper'>
        <img className='logo' src={Logo} alt='Logo' />
      </div>

      <div className='menu-card'>
        <NavLink to={ROUTE_HOME} exact className='menu-item'>
          <Icon icon='menu' size='2.5rem' />
          <Spacer left='2rem' />
          <div>Главная</div>
        </NavLink>

        {userPermissions?.length
          ? userPermissions.map(up => {
              const UPNav = _getUserPermissionNav(up.name, up.id);
              return UPNav;
            })
          : null}
      </div>

      <div className='menu-bottom'>
        {userPermissions?.length
          ? userPermissions.map(up => {
              const UPANav = _getUserPermissionAdditionalNav(up.name, up.id);
              return UPANav;
            })
          : null}
        <div className='bottom-card'>
          <Avatar />
          <Spacer left='1rem' />
          <div>
            <Text type='sm' color='black'>
              {userName}
            </Text>
            <Text type='caption' color='primary'>
              {userData?.role?.description ?? "Неизвестно"}
            </Text>
          </div>
          {/* <Spacer left='auto' />
          <Link to='/profile'>
            <Icon icon='arrow-right' size='3rem' />
          </Link> */}
        </div>
      </div>
    </div>
  );
};

export default Menu;
