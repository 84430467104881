import {
  APP_ERROR_CLEAR,
  APP_ERROR_SET,
  APP_LOADER_HIDE,
  APP_LOADER_SHOW,
  StoreAppActs,
  StoreAppErrorDefault,
  StoreAppErrorStateName,
  StoreAppLoaderHidden,
  StoreAppLoaderShowing,
  StoreAppLoaderStateName,
  StoreAppState,
  StoreAppStateDefault,
} from "./appTypes";

const appStoreInitState: StoreAppState = {
  ...StoreAppStateDefault,
};

const appReducer = (
  state = appStoreInitState,
  action: StoreAppActs,
): StoreAppState => {
  switch (action.type) {
    case APP_LOADER_SHOW:
      return { ...state, [StoreAppLoaderStateName]: StoreAppLoaderShowing };
    case APP_LOADER_HIDE:
      return { ...state, [StoreAppLoaderStateName]: StoreAppLoaderHidden };

    case APP_ERROR_SET: {
      const error = action.payload;
      return { ...state, error };
    }

    case APP_ERROR_CLEAR:
      return { ...state, [StoreAppErrorStateName]: StoreAppErrorDefault };

    default:
      return state;
  }
};

export default appReducer;
