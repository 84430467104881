import { StoreSelectorBase } from "../storeTypes";

import {
  StoreAppState,
  StoreAppName,
  StoreAppError,
  StoreAppErrorStateName,
  StoreAppLoader,
  StoreAppLoaderStateName,
} from "./appTypes";

export const selectAppState: StoreSelectorBase<StoreAppState> = s =>
  s[StoreAppName];
export const selectAppLoader: StoreSelectorBase<StoreAppLoader> = s =>
  selectAppState(s)[StoreAppLoaderStateName];
export const selectAppError: StoreSelectorBase<StoreAppError> = s =>
  selectAppState(s)[StoreAppErrorStateName];
