import queryStringify from "qs-stringify";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { ROUTE_TASKS_NEW } from "../../AppRoutes";
import APIService, {
  API_DATA_TASKS,
  API_DATA_TASKS_TYPES,
} from "../../services/api.service";
import { convertDate } from "../../shared/functions/convertDate";
import useAPIData from "../../shared/hooks/useAPIData";
import useDispatchError from "../../shared/hooks/useDispatchError";
import { useFormDataPropSelectDefault } from "../../shared/hooks/useFormData";
import useOnHistoryChange from "../../shared/hooks/useOnHistoryChange";
// import { useParams } from "react-router";

import {
  Table,
  Text,
  Icon,
  Spacer,
  Select,
  Input,
  DatePicker,
  Button,
  Dialog,
} from "../common";

import { headers, tasksFilterStatusesSelect, tasksStatuses } from "./taskProps";

const TasksTable = () => {
  const [setError] = useDispatchError();
  const { status } = useParams();
  const history = useHistory();

  const _defaultRequestQueries = `_sort=createdAt:DESC`;
  const [
    tasks,
    pagination,
    updateTasks,
    ,
    onChangePage,
    manualTasksUpdate,
  ] = useAPIData(
    API_DATA_TASKS,
    `${_defaultRequestQueries}${status ? `&status=${status}` : ""}`,
  );

  const _pagination = {
    ...pagination,
    onChange: v => {
      if (isTasksCountsFetched) {
        setIsTasksCountsFetched(false);
      } else {
        onChangePage(v, getFiltersQuery());
      }
    },
  };

  const [isTasksCountsFetched, setIsTasksCountsFetched] = useState(false);

  useEffect(() => {
    const _fetchTasksProgressData = async () => {
      const _newTasksData = [...tasks];
      const _taskcountersurls = {
        training: "tasktrainingusers",
        purchase: "taskpurchaseusers",
        sale: "tasksaleusers",
        merchandising: "taskmerchandisingusers",
      };

      let _idx = 0;
      for (const _t of tasks) {
        const _counturl = _taskcountersurls[_t?.type?.name];
        if (!_counturl) {
          continue;
        }

        const _totalCount = await APIService.getDataCountHandler(
          _counturl,
          () =>
            setError(
              `Не удалось получить общее кол-во задач для Выполнение(${_t.id})`,
            ),
          `task.id=${_t.id}`,
        );
        if (typeof _totalCount !== "number") {
          break;
        }

        const _doneCount = await APIService.getDataCountHandler(
          _counturl,
          () =>
            setError(
              `Не удалось получить кол-во выполненных задач для Выполнение(${_t.id})`,
            ),
          `task.id=${_t.id}&status=done`,
        );
        if (typeof _doneCount !== "number") {
          break;
        }

        const _newT = { ..._t, _totalCount, _doneCount };
        _newTasksData.splice(_idx, 1, _newT);
        ++_idx;
      }

      setIsTasksCountsFetched(true);
      manualTasksUpdate(_newTasksData);
    };

    if (tasks?.length && !isTasksCountsFetched) {
      _fetchTasksProgressData();
    }
  }, [tasks]);

  const [searchQuery, setSearchQuery] = useState("");
  const onChangeSearchQuery = _e => {
    _e?.preventDefault();
    const _value = _e?.target?.value;

    if (_value !== searchQuery) {
      setSearchQuery(_value);
    }
  };
  useOnHistoryChange(() => setSearchQuery(""));

  useEffect(() => {
    if (tasks) {
      setIsTasksCountsFetched(false);
      updateTasks(getFiltersQuery());
    }
  }, [searchQuery]);

  const [filters, setFilters] = useState({
    status: {
      query: "status",
      value: status ?? "",
      options: tasksFilterStatusesSelect,
    },
    [API_DATA_TASKS_TYPES]: {
      value: "",
      query: "type.description",
      options: [],
      data: API_DATA_TASKS_TYPES,
    },
    startdate: {
      query: "startdate",
      value: {
        gt: null,
        lt: null,
      },
    },
  });

  const getFiltersQuery = (_f = filters) => {
    let qsQueries = {};

    if (searchQuery?.length) {
      qsQueries._where = {
        _or: [
          [{ name_contains: searchQuery }],
          [{ description_contains: searchQuery }],
          [{ "author.firstname_contains": searchQuery }],
          [{ "author.lastname_contains": searchQuery }],
          [{ "author.username_contains": searchQuery }],
          [{ "author.email_contains": searchQuery }],
        ],
      };
    }

    for (const fKey of Object.keys(_f)) {
      const fData = _f[fKey];

      switch (fKey) {
        case "startdate":
          if (!fData?.value) {
            continue;
          }

          const selectedDate = fData.value;

          if (selectedDate?.gt) {
            const startDate = new Date(selectedDate.gt);
            startDate.setHours(0, 0, 0);
            const _offset = startDate.getTimezoneOffset();
            const _shift =
              (0 > _offset ? Math.abs(_offset) : _offset) * 60 * 1000;
            let date_gt = startDate.getTime();
            if (0 > _offset) {
              date_gt += _shift;
            } else {
              date_gt -= _shift;
            }

            qsQueries[`${fData.query}_gt`] = date_gt;
          }

          if (selectedDate?.lt) {
            const endDate = new Date(selectedDate.lt);
            endDate.setHours(23, 59, 59);
            const _shift =
              (0 > _offset ? Math.abs(_offset) : _offset) * 60 * 1000;
            let date_lt = endDate.getTime();
            if (0 > _offset) {
              date_lt += _shift;
            } else {
              date_lt -= _shift;
            }

            qsQueries[`${fData.query}_lt`] = date_lt;
          }

          break;

        default:
          if (fData?.value?.length) {
            qsQueries[fData.query] = fData.value;
          }
          break;
      }
    }

    const queryRequest = queryStringify(qsQueries);

    let resQuery = _defaultRequestQueries;
    if (queryRequest?.length) {
      resQuery += `&${queryRequest}`;
    }

    return resQuery;
  };
  const onChangeFilter = async (value, filterName) => {
    const oldValue = filters[filterName].value;
    if (oldValue === value) {
      return;
    }

    const newFilters = {
      ...filters,
      [filterName]: {
        ...filters[filterName],
        value,
      },
    };

    let resQuery = getFiltersQuery(newFilters);

    setIsTasksCountsFetched(false);
    await updateTasks(resQuery);
    setFilters(newFilters);
  };
  const onChangeDateFilter = async (value, filterName) => {
    const _dateFilterName = "startdate";

    const oldValue = filters[_dateFilterName].value;
    const newValue = {
      ...oldValue,
      [filterName]: value,
    };

    onChangeFilter(newValue, _dateFilterName);
  };

  const onToEdit = id => {
    history.push(`${ROUTE_TASKS_NEW}/${id}`);
  };

  useEffect(() => {
    const fetchFiltersOptions = async () => {
      let isNoErr = true;
      let newFilters = { ...filters };
      for (const fKey of Object.keys(filters)) {
        const fData = filters[fKey];
        if (!fData?.data) {
          continue;
        }

        const fOptions = await APIService.getDataHandler(fData.data, () => {
          isNoErr = false;
          setError(`Не удалось получить опции(${fData.data}) для фильтров.`);
        });
        if (!isNoErr) {
          break;
        }

        let mapper = f => ({ value: f.name, text: f.name });
        switch (fKey) {
          case API_DATA_TASKS_TYPES:
            mapper = f => ({
              value: f.description,
              text: f.description,
            });
            break;
          default:
            break;
        }

        newFilters[fKey].options = [
          { ...useFormDataPropSelectDefault },
          ...fOptions.map(mapper),
        ];
      }

      if (isNoErr) {
        setFilters(newFilters);
      }
    };

    fetchFiltersOptions();
  }, []);

  const [deleteTaskDialog, setDeleteTaskDialog] = useState(undefined);
  const onChangeDeleteTaskDialog = (id, name) => {
    if (id !== deleteTaskDialog) {
      setDeleteTaskDialog({ id, name });
    }
  };
  const closeDeleteTaskDialog = () => {
    if (deleteTaskDialog && !deleteInProgress) {
      setDeleteTaskDialog(undefined);
    }
  };
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const onDeleteTask = async id => {
    if (deleteInProgress) {
      return;
    }

    setDeleteInProgress(true);
    const isDeleted = await APIService.delDataByIDHandler(
      API_DATA_TASKS,
      id,
      () => setError("Не удалось удалить Задачу."),
    );

    if (isDeleted) {
      setIsTasksCountsFetched(false);
      updateTasks(getFiltersQuery());
    }
    setDeleteInProgress(false);
    closeDeleteTaskDialog();
  };

  const row = item => {
    let statusData = tasksStatuses[item?.status];
    if (!statusData) {
      statusData = { text: "Ошибка", icon: "close-outline" };
    }
    let authorName = "Неизвестно";
    if (item?.author?.firstname && item?.author?.lastname) {
      authorName = `${item?.author?.firstname} ${item?.author?.lastname}`;
    }

    return (
      <React.Fragment>
        <div className='table-row__cell'>
          <Text>{item?.id?.slice(item.id.length - 5, item.id.length)}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{item?.name}</Text>
        </div>
        <div className='table-row__cell'>
          <Icon icon={statusData.icon} size='1.75rem' />
          <Spacer left='0.25rem' />
          <Text>{statusData.text}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{item?.type?.description}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{convertDate(item?.startdate)}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{convertDate(item?.enddate)}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{`${item?._doneCount ?? "-"}/${
            item?._totalCount ?? "-"
          }`}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{authorName}</Text>
        </div>
        <div className='table-row__cell'>
          <Button
            type='flat'
            onClick={e => {
              e?.preventDefault();
              onChangeDeleteTaskDialog(item.id, item?.name);
            }}>
            <Icon icon='remove' size='2rem' />
          </Button>

          {item?.status === "draft" ? (
            <div
              className='table-row__cell-clickable'
              onClick={e => {
                e?.preventDefault();
                onToEdit(item?.id);
              }}>
              <Icon icon='arrow-right' size='3rem' />
            </div>
          ) : null}
        </div>
      </React.Fragment>
    );
  };

  return (
    <>
      <div className='tasks-page'>
        <div className='d-flex'>
          <Spacer left='auto' />
          <Input
            value={searchQuery}
            onChange={onChangeSearchQuery}
            placeholder='Поиск'
            leftIcon={<Icon icon='search' size='2rem' />}
          />
        </div>

        <Spacer top='2rem' />

        <div className='card flex-1'>
          <div className='table-filters'>
            <Select
              label='Статус'
              value={filters?.status?.value}
              onChange={e => onChangeFilter(e.target.value, "status")}
              variants={filters?.status?.options}
            />

            <Spacer left='1.5rem' />

            <Select
              label='Тип задачи'
              value={filters[API_DATA_TASKS_TYPES]?.value}
              onChange={e =>
                onChangeFilter(e.target.value, API_DATA_TASKS_TYPES)
              }
              variants={filters[API_DATA_TASKS_TYPES]?.options}
            />

            <Spacer left='1.5rem' />

            <DatePicker
              placeholder='Публикации C'
              value={filters?.startdate?.value?.gt}
              onChange={date => onChangeDateFilter(date, "gt")}
            />

            <Spacer left='1.5rem' />

            <DatePicker
              placeholder='Публикации ПО'
              value={filters?.startdate?.value?.lt}
              onChange={date => onChangeDateFilter(date, "lt")}
            />
          </div>
          <Table
            columns={[
              "1fr",
              "2fr",
              "1fr",
              "1.15fr",
              "1fr",
              "1fr",
              "1fr",
              "1.5fr",
              "4rem",
              "5rem",
            ]}
            pagination={_pagination}
            headers={headers}
            row={row}
            list={tasks}
            isLoading={!tasks}
          />
        </div>
      </div>

      <Dialog show={!!deleteTaskDialog} close={() => closeDeleteTaskDialog()}>
        <div>
          <Spacer top='4rem' />

          <Text
            type={!deleteInProgress ? "md" : "lg"}
            isBold={deleteInProgress}>
            {!deleteInProgress
              ? `Вы уверены что хотите удалить Задачу: " ${deleteTaskDialog?.name} "?`
              : `Идет удаление Задачи: " ${deleteTaskDialog?.name} "`}
          </Text>

          <Spacer top='4rem' />

          <div className='d-flex justify-end'>
            <Button
              type={"outline"}
              disabled={deleteInProgress}
              onClick={() => closeDeleteTaskDialog()}>
              <Text>Отменить</Text>
            </Button>

            <Spacer left='2rem' />

            <Button
              type='primary-outline_red'
              disabled={deleteInProgress}
              onClick={() => onDeleteTask(deleteTaskDialog?.id)}>
              <Icon icon='remove-red' size='2rem' />
              <Spacer left='1rem' />
              <Text>Удалить</Text>
            </Button>
          </div>
        </div>

        <Spacer bottom='1.5rem' />
      </Dialog>
    </>
  );
};

export default TasksTable;
