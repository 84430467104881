import {
  ROUTE_TASKS,
  ROUTE_TASKS_ACTIVES,
  ROUTE_TASKS_DRAFTS,
} from "../../AppRoutes";
import { Icon, Spacer, Text } from "../common";
import LinkCard from "./LinkCard";
const Card = () => {
  return (
    <div className='dashboard-card'>
      <div className='d-flex align-center'>
        <div className='icon-card'>
          <Icon icon='document' size='3.5rem' />
        </div>
        <Spacer left='2rem' />
        <Text color='black' type='h4'>
          Задания
        </Text>
      </div>

      <Spacer top='2rem' />

      <div className='d-flex'>
        <LinkCard count='0' label='Все' link={ROUTE_TASKS} />
        <Spacer left='1.5rem' />
        <LinkCard count='0' label='Черновики' link={ROUTE_TASKS_DRAFTS} />
      </div>

      <Spacer top='2rem' />

      <div className='card' style={{ paddingBottom: ".5rem" }}>
        <LinkCard count='0' label='Активные' link={ROUTE_TASKS_ACTIVES} />

        <Spacer top='2rem' />

        <div className='dashboard-text-row'>
          <div className='d-flex'>
            <Text type='md'>Закупка</Text>
            <Spacer left='auto' />
            <Text type='md' isBold>
              0
            </Text>
          </div>
          <Spacer left='8rem' />
          <div className='d-flex'>
            <Text type='md'>Продажа</Text>
            <Spacer left='auto' />
            <Text type='md' isBold>
              0
            </Text>
          </div>
        </div>

        <div className='dashboard-text-row'>
          <div className='d-flex'>
            <Text type='md'>Обучение</Text>
            <Spacer left='auto' />
            <Text type='md' isBold>
              0
            </Text>
          </div>
          <Spacer left='8rem' />
          <div className='d-flex'>
            <Text type='md'>Мерчендайзинг</Text>
            <Spacer left='auto' />
            <Text type='md' isBold>
              0
            </Text>
          </div>
        </div>

        <Spacer top='0.5rem' />
      </div>
    </div>
  );
};

export default Card;
