import React, { useState } from "react";
import {
  Text,
  Spacer,
  FileInput,
  TextArea,
  Button,
  Icon,
  Modal,
} from "../common";
import { ROUTE_NEWS } from "../../AppRoutes";
import { useHistory, useParams } from "react-router-dom";
import APIService, { API_DATA_NEWS } from "../../services/api.service";
import useFormData, {
  useFormDataTextRequired,
} from "../../shared/hooks/useFormData";
import useDispatchError from "../../shared/hooks/useDispatchError";

const NewsModal = ({ isNew, task, back }) => {
  const [setError] = useDispatchError();
  let history = useHistory();
  const { id } = useParams();
  const _isNew = isNew && !id;

  const [disabledActions, setDisabledActions] = useState(false);

  const [formData, onChangeData, onSubmit] = useFormData({
    id: id,
    dataName: API_DATA_NEWS,
  });

  const onDelete = async e => {
    e.preventDefault();

    if (!_isNew) {
      await APIService.delDataByIDHandler(API_DATA_NEWS, id, () =>
        setError(`Не удалось удалить Новость(${id})`),
      );
    }

    history.push(ROUTE_NEWS);
  };
  const onSubmitAsDraft = () => {
    onChangeData("draft", "status");
  };
  const onSubmitAsPublish = () => {
    onChangeData("publish", "status");
  };
  const onSubmitHandler = async e => {
    if (disabledActions) {
      return;
    } else {
      setDisabledActions(true);
    }

    e.preventDefault();
    const newBlog = await onSubmit();

    if (newBlog) {
      history.push(ROUTE_NEWS);
    } else {
      setDisabledActions(false);
    }
  };

  return (
    <Modal
      back={() => history.push(ROUTE_NEWS)}
      title={isNew ? "Создание новой статьи" : "Редактирование статьи"}>
      <form onSubmit={onSubmitHandler}>
        <div className='d-flex'>
          <div className='flex-50'>
            <Text color='secondary' type='md'>
              {useFormDataTextRequired(
                formData?.name?.label,
                formData?.name?.required,
              )}
            </Text>
            <Spacer top='1.5rem' />
            <TextArea
              value={formData?.name?.value}
              required={formData?.name?.required}
              onChange={e => onChangeData(e.target.value, "name")}
              rowsMin='1'
              placeholder='Название новой статьи'
              style={{ width: "100%" }}
            />

            <Spacer top='2.25rem' />

            <Text color='secondary' type='md'>
              {useFormDataTextRequired(
                formData?.description?.label,
                formData?.description?.required,
              )}
            </Text>
            <Spacer top='1.5rem' />
            <TextArea
              value={formData?.description?.value}
              required={formData?.description?.required}
              onChange={e => onChangeData(e.target.value, "description")}
              rowsMin='9'
              placeholder='Описание статьи'
              style={{ width: "100%" }}
            />
          </div>

          <Spacer left='4rem' />

          <div className='flex-1'>
            <Text color='secondary' type='md'>
              {useFormDataTextRequired(
                formData?.article?.label,
                formData?.article?.required,
              )}
            </Text>
            <Spacer top='1.5rem' />
            <TextArea
              value={formData?.article?.value}
              required={formData?.article?.required}
              onChange={e => onChangeData(e.target.value, "article")}
              rowsMin='14'
              placeholder='Текст'
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <Spacer top='3rem' />
        <div className='d-flex'>
          <div className='flex-50'>
            <Text color='secondary' type='md'>
              {useFormDataTextRequired(
                formData?.picture?.label,
                formData?.picture?.required,
              )}
            </Text>
            <Spacer top='1.5rem' />
            <FileInput
              value={formData?.picture?.value}
              required={formData?.picture?.required}
              onChange={f => onChangeData(f, "picture")}
            />
          </div>

          <Spacer left='4rem' />

          <div className='flex-1'>
            <Spacer top='16rem' />

            <div className='d-flex'>
              <Button
                type='outline'
                onClick={onDelete}
                disabled={disabledActions}>
                <Icon icon='remove' size='2rem' />
                <Spacer left='1.5rem' />
                <Text type='md'>Удалить</Text>
              </Button>
              <Spacer left='auto' />
              <Button
                type='white'
                btnType='submit'
                onClick={onSubmitAsDraft}
                disabled={disabledActions}>
                <Text type='md'>Сохранить как черновик</Text>
              </Button>
              <Spacer left='3rem' />
              <Button
                type='primary'
                btnType='submit'
                disabled={disabledActions}
                onClick={onSubmitAsPublish}>
                <Text type='md'>Опубликовать</Text>
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default NewsModal;
