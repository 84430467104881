import queryStringify from "qs-stringify";
import React, { useEffect, useState } from "react";
import APIService, {
  API_DATA_APPROVEMENT_BUY,
  API_DATA_DISTRIBUTORS,
  API_DATA_INVOICES,
  API_REPORTS,
  API_UPLOAD_DATA_MAX_SIZE,
} from "../../services/api.service";
import useAPIData from "../../shared/hooks/useAPIData";
import useDispatchError from "../../shared/hooks/useDispatchError";
import { useFormDataPropSelectDefault } from "../../shared/hooks/useFormData";
import { StoreAppErrorTypeForm } from "../../store/app";
import {
  Table,
  Text,
  Icon,
  Spacer,
  Select,
  Button,
  DatePicker,
  Input,
  Dialog,
} from "../common";
import FileInput, {
  FileInputAcceptFilesExcel,
} from "../common/inputs/FileInput";
import Pagination from "../common/table/Pagination";
import TableChildRowPhoto from "./TableChildRowPhoto";

const ApproveBuyTable = ({ searchQuery }) => {
  const [setError] = useDispatchError();

  const [tasksFilters, setTasksFilters] = useState({
    created_date_gt: { value: null },
    created_date_lt: { value: null },
  });
  const onChangeTasksFilters = (value, filterName) => {
    const oldValue = tasksFilters[filterName].value;
    if (oldValue === value) {
      return;
    }

    const newFilters = {
      ...tasksFilters,
      [filterName]: { ...tasksFilters[filterName], value },
    };
    setTasksFilters(newFilters);
  };
  const _getTasksQueries = (_forceFilters = undefined) => {
    const _f = tasksFilters ?? _forceFilters;
    const __filters_queries_names = [
      "user.pharmacy.pharmacyname",
      "user.pharmacy.entity.contract",
      "user.username",
    ];
    const _filters = { status_ne: "new" };

    for (const _fKey of Object.keys(_f)) {
      const _fData = _f[_fKey];
      if (!_fData.value) {
        continue;
      }

      switch (_fKey) {
        case "created_date_gt": {
          const startDate = new Date(_fData?.value);
          startDate.setHours(0, 0, 0);
          const _offset = startDate.getTimezoneOffset();
          const _shift =
            (0 > _offset ? Math.abs(_offset) : _offset) * 60 * 1000;
          let date_gt = startDate.getTime();
          if (0 > _offset) {
            date_gt += _shift;
          } else {
            date_gt -= _shift;
          }
          _filters["createdAt_gt"] = date_gt;
          break;
        }

        case "created_date_lt": {
          const endDate = new Date(_fData?.value);
          endDate.setHours(23, 59, 59);
          const _offset = endDate.getTimezoneOffset();
          const _shift =
            (0 > _offset ? Math.abs(_offset) : _offset) * 60 * 1000;
          let date_lt = endDate.getTime();
          if (0 > _offset) {
            date_lt += _shift;
          } else {
            date_lt -= _shift;
          }
          _filters["createdAt_lt"] = date_lt;
        }

        default:
          break;
      }
    }

    if (searchQuery?.length) {
      _filters._where = {
        _or: __filters_queries_names.map(_f => {
          const _fname = _f + "_contains";
          return [{ [_fname]: searchQuery }];
        }),
      };
    }

    const qs = queryStringify(_filters);
    return qs;
  };
  const [buys, pagination, updateBuys, , onChangePage] = useAPIData(
    API_DATA_APPROVEMENT_BUY,
    _getTasksQueries(),
  );

  const _pagination = {
    ...pagination,
    onChange: page => onChangePage(page, _getTasksQueries()),
  };

  useEffect(() => {
    if (buys) {
      updateBuys(_getTasksQueries());
    }
  }, [searchQuery, tasksFilters]);

  const [invoicesSearch, setInvoicesSearch] = useState("");
  const onChangeInvoicesSearch = (_e, _id) => {
    _e?.preventDefault();
    const _value = _e?.target?.value;

    if (_value !== invoicesSearch) {
      setInvoicesSearch(_value);
      updateInvoices(_getInvoiceFiltersQueries(_id, _value));
    }
  };
  const [invoicesFilters, setInvoicesFilters] = useState({
    distributors: {
      query: "distributor.id",
      options: [],
      value: "",
    },
    type: {
      options: [
        { ...useFormDataPropSelectDefault },
        { value: "true", text: "Фото" },
        { value: "false", text: "Ручной ввод" },
      ],
      query: "photos",
      value: "",
    },
  });
  const onChangeInvoicesFilters = (value, filterName, _id) => {
    const oldValue = invoicesFilters[filterName].value;
    if (oldValue === value) {
      return;
    }

    const newFilters = {
      ...invoicesFilters,
      [filterName]: { ...invoicesFilters[filterName], value },
    };
    updateInvoices(_getInvoiceFiltersQueries(_id, undefined, newFilters));
    setInvoicesFilters(newFilters);
  };

  const fetchInvoicesDistributors = async () => {
    const distributors = await APIService.getDataHandler(
      API_DATA_DISTRIBUTORS,
      () => setError("Не удалось получить Дистрибьюторов"),
    );
    if (!distributors) {
      return;
    }

    const _newFilters = {
      ...invoicesFilters,
      distributors: {
        ...invoicesFilters.distributors,
        options: [
          { ...useFormDataPropSelectDefault },
          ...distributors.map(d => ({
            id: d.id,
            value: d.name,
            text: d.name,
          })),
        ],
      },
    };

    setInvoicesFilters(_newFilters);
  };
  const _getInvoiceFiltersQueries = (
    _id,
    _forceQuery = undefined,
    _forceFilters = undefined,
  ) => {
    const _f = _forceFilters ?? invoicesFilters;
    const _filters = { "task.id": _id };
    const _search = _forceQuery ?? invoicesSearch;

    for (const _fKey of Object.keys(_f)) {
      const _fData = _f[_fKey];
      if (!_fData?.value?.length) {
        continue;
      }

      switch (_fKey) {
        case "distributors":
          const _dObj = _fData.options.find(o => o.value === _fData.value);
          if (_dObj) {
            _filters[_fData.query] = _dObj.id;
          }
          break;

        case "type":
          _filters["isphoto"] = _fData.value === "true";
          break;

        default:
          break;
      }

      if (_search?.length) {
        _filters._where = {
          _or: [
            [{ number_contains: _search }],
            [{ "distributor.name_contains": _search }],
            [{ date_contains: _search }],
          ],
        };
      }
    }

    const qs = queryStringify(_filters);
    return qs;
  };
  const [
    invoices,
    iPagination,
    updateInvoices,
    ,
    onChangeInvoicesPage,
    manualInvoicesUpdate,
  ] = useAPIData(API_DATA_INVOICES, "", 10, false);

  const headers = [
    { title: "", isSortable: false },
    { title: "Аптека", isSortable: false },
    { title: "Договор", isSortable: false },
    { title: "Телефон", isSortable: false },
    { title: "", isSortable: false },
  ];

  const [uploadDialog, setUploadDialog] = useState(false);
  const onOpenUploadDialog = () => setUploadDialog(true);
  const onCloseUploadDialog = () => setUploadDialog(false);
  const [file, setFile] = useState(undefined);
  const onChangeFile = f => {
    if (f?.size > API_UPLOAD_DATA_MAX_SIZE) {
      setError("Максимальный размер файла 5Мб.", StoreAppErrorTypeForm);
      return;
    }
    setFile(f);
  };

  const onSubmitUploadFile = async () => {
    let isErrSet = false;
    const uploadedData = await APIService.postUpload(file).then(
      resp => resp.data,
      err => {
        isErrSet = true;
        setError("Не удалось загрузить Данные.");
      },
    );

    if (uploadedData?.length && uploadedData[0]) {
      const docUploadData = { document: uploadedData[0].id };
      const isSuccess = await APIService.postDataHandler(
        API_REPORTS,
        docUploadData,
        () => setError(`Не удалось загрузить Данные.`),
      );

      if (isSuccess) {
        onCloseUploadDialog();
      }
    } else {
      if (!isErrSet) {
        setError("Не удалось получить загруженные Данные.");
      }
    }
  };

  const row = item => {
    const statusClassName =
      item?.status === "done" || item?.status === "wait" ? "active" : "";

    return (
      <React.Fragment>
        <div className={`table-row__cell status-icon__cell ${statusClassName}`}>
          {item?.status?.length ? (
            <Icon
              icon={
                item?.status === "wait" ? "primary-forward" : "done-outline"
              }
              size='1.75rem'
            />
          ) : (
            <Icon icon='close-outline' size='1.75rem' />
          )}
        </div>

        <div className='table-row__cell'>
          <div>
            <Text>{item?.user?.pharmacy?.pharmacyname ?? "Неизвестно"}</Text>
            <Text type='caption' color='primary'>
              id {item?.user?.pharmacy?.id ?? "Неизвестно"}
            </Text>
          </div>
        </div>

        <div className='table-row__cell'>
          <Text>{item?.user?.pharmacy?.entity?.contract ?? "Неизвестно"}</Text>
        </div>

        <div c lassName='table-row__cell'>
          <Text>{item?.user?.username ?? "Неизвестно"}</Text>
        </div>
      </React.Fragment>
    );
  };

  return (
    <>
      <div className='card flex-1'>
        <div className='table-filters'>
          <DatePicker
            width={"20rem"}
            placeholder={"Дата С"}
            value={tasksFilters.created_date_gt.value}
            onChange={date => onChangeTasksFilters(date, "created_date_gt")}
          />

          <Spacer left='1.5rem' />

          <DatePicker
            width={"20rem"}
            placeholder={"Дата ПО"}
            value={tasksFilters.created_date_lt.value}
            onChange={date => onChangeTasksFilters(date, "created_date_lt")}
          />

          <Spacer left='auto' />
          <Button type='primary-outline' onClick={() => onOpenUploadDialog()}>
            <Icon icon='download' size='2.5rem' />
            <Spacer left='1.5rem' />
            Загрузить отчет
          </Button>
        </div>

        <Table
          // columns={['5rem', '0.75fr', '0.5fr', '0.75fr', '0.75fr', '0.75fr', '1fr', '0.75fr', '0.75fr']}
          columns={["5rem", "30rem", "30rem", "58rem", "15rem"]}
          headers={headers}
          pagination={_pagination}
          row={row}
          collapseSlot={
            <Button type='primary-outline'>
              Проверить
              <Spacer left='1.5rem' />
              <Icon icon='arrow-down' size='2.5rem' />
            </Button>
          }
          onCloseCollapseSlot={() => {
            manualInvoicesUpdate(null);
            setInvoicesSearch("");
          }}
          onOpenCollapseSlot={item => {
            updateInvoices(_getInvoiceFiltersQueries(item.id));
            if (!invoicesFilters?.length) {
              fetchInvoicesDistributors();
            }
          }}
          childrenRow={item => {
            const _updInvoices = () => {
              updateInvoices(_getInvoiceFiltersQueries(item.id));
            };
            const _iPagination = {
              ...iPagination,
              onChange: page =>
                onChangeInvoicesPage(page, _getInvoiceFiltersQueries(item.id)),
            };

            if (!invoices) {
              return (
                <div className='d-flex justify-center'>
                  <Text type='lg' isBold>
                    Загрузка...
                  </Text>
                </div>
              );
            }

            return (
              <div className='d-flex f-column'>
                <div className='table-filters'>
                  <Select
                    label='Тип подтверждения'
                    value={invoicesFilters.type.value}
                    disabled={!invoicesFilters.type.options.length}
                    onChange={e => {
                      onChangeInvoicesFilters(e.target.value, "type", item.id);
                    }}
                    variants={invoicesFilters.type.options}
                  />

                  <Spacer left='1.5rem' />

                  <Select
                    label='Дистрибьютор'
                    value={invoicesFilters.distributors.value}
                    disabled={!invoicesFilters.distributors.options.length}
                    onChange={e => {
                      onChangeInvoicesFilters(
                        e.target.value,
                        "distributors",
                        item.id,
                      );
                    }}
                    variants={invoicesFilters.distributors.options}
                  />

                  <Spacer left='auto' />

                  <Input
                    style={{ width: "35rem" }}
                    value={invoicesSearch}
                    onChange={e => onChangeInvoicesSearch(e, item.id)}
                    placeholder='Поиск'
                    leftIcon={<Icon icon='search' size='2rem' />}
                    disabled={!invoices}
                  />
                </div>

                {!invoices
                  ? null
                  : invoices?.map((invoice, idx) => {
                      return (
                        <TableChildRowPhoto
                          key={`__abi_q-${idx}`}
                          data={item}
                          updateBuys={_updInvoices}
                          invoice={invoice}
                        />
                      );
                    })}

                {invoices ? <Pagination {..._iPagination} /> : null}
              </div>
            );
          }}
          list={buys}
          isLoading={!buys}
        />
      </div>

      <Dialog show={uploadDialog} close={onCloseUploadDialog}>
        <form style={{ width: "67.5rem" }}>
          <Text type='md' color='secondary'>
            Добавление документа
          </Text>

          <Spacer top='1.5rem' />

          <FileInput
            accept={FileInputAcceptFilesExcel}
            value={file}
            onChange={onChangeFile}
            required={true}
          />

          <Spacer top='1.5rem' />

          <Text isCenter>Разрешенные типы файлов: .xls и .xlsx</Text>

          <Spacer top='1.5rem' />

          <div className='d-flex justify-center'>
            <Button
              type='primary'
              onClick={e => {
                e?.preventDefault();
                onSubmitUploadFile();
              }}>
              <Text type='sm' color='white'>
                Загрузить
              </Text>
            </Button>
          </div>
        </form>
      </Dialog>
    </>
  );
};

export default ApproveBuyTable;
