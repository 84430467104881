import React, { useState } from "react";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { InputAdornment } from "@material-ui/core";
import { Icon } from "../index";

import moment from "moment";
import MomentUtils from "@date-io/moment";

const CustomTimePicker = ({
  width,
  value = null,
  onChange = () => {},
  placeholder = "Время",
  required = false,
}) => {
  return (
    <MuiPickersUtilsProvider
      libInstance={moment}
      utils={MomentUtils}
      locale='ru'>
      <TimePicker
        required={required}
        placeholder={placeholder}
        variant='inline'
        inputVariant='outlined'
        style={{ width: width || "23.75rem" }}
        value={value}
        onChange={onChange}
        ampm={false}
        format='HH:mm'
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <Icon icon='clock' size='2rem' />
            </InputAdornment>
          ),
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default CustomTimePicker;
