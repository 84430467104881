import { Select, FormControl, InputLabel } from "@material-ui/core";

const SelectInput = ({
  label,
  value,
  onChange,
  variants,
  style,
  required = false,
  disabled = false,
}) => {
  return (
    <FormControl variant='outlined' style={style}>
      {label && <InputLabel focused={!!value}>{label}</InputLabel>}
      <Select
        required={required}
        native
        value={value}
        onChange={onChange}
        disabled={disabled}>
        {variants?.map((item, i) => (
          <option key={`${item.value}-${i}`} value={item.value}>
            {item.text}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectInput;
