import React, { useState } from "react";
import { Text, Spacer, Button, Icon, Radio, Dialog } from "../../../../common";

const TestPreview = ({ close, studies }) => {
  const [startTests, setStartTests] = useState(null);
  const onChangeStartTests = () => {
    let answersData = {};

    let _sIdx = 0;
    let _totalQuestions = 0;
    for (const _s of studies) {
      let _tIdx = 0;
      for (const _t of _s?.tests?.value) {
        answersData[`${_sIdx}-${_tIdx}`] = {
          value: "",
          correct: 0,
        };
        ++_tIdx;
      }

      _totalQuestions += _tIdx;
      ++_sIdx;
    }

    setTestsData({
      ...testsData,
      totalQuestions: _totalQuestions,
      answers: { ...answersData },
    });

    const startDate = new Date();
    setStartTests(startDate);
  };

  const [testsData, setTestsData] = useState({
    totalQuestions: 0,
    answers: {},
  });
  const addTestAnswer = (value, testIdx, options) => {
    let newAnswers = { ...testsData.answers };
    let _answerIdx = `${currentQuestionIdx}-${testIdx}`;
    if (!newAnswers[_answerIdx]) {
      newAnswers[_answerIdx] = { value: "", correct: 0 };
    }

    let _isCorrect = 0;
    const opt = options.find(o => o.value === value && o.correct);
    if (opt) {
      _isCorrect = 1;
    }

    newAnswers[_answerIdx] = {
      ...newAnswers[_answerIdx],
      value,
      correct: _isCorrect,
    };

    setTestsData({
      ...testsData,
      answers: { ...newAnswers },
    });
  };
  const getCurrentAnswer = testIdx =>
    testsData.answers[`${currentQuestionIdx}-${testIdx}`].value;

  const [isResult, setIsResult] = useState(false);
  const [currentQuestionIdx, setCurrentQuestionIdx] = useState(0);
  const onChangeCurrentQuestionIdx = newIdx => {
    if (newIdx + 1 > studies?.length) {
      setIsResult(true);
    } else {
      setCurrentQuestionIdx(newIdx);
    }
  };

  const [imagePreview, setImagePreview] = useState(null);
  const closeImagePreview = () => setImagePreview(null);
  const openImagePreview = img => setImagePreview(img);

  const _studiesLength = studies?.length;

  if (isResult) {
    return (
      <TestResult
        close={close}
        startDate={startTests}
        endDate={new Date()}
        answersData={testsData}
      />
    );
  }

  const showQuestions = studyData => {
    const desc = studyData?.description?.value;
    const img = studyData?.picture?.value;
    const imgURL = img ? URL.createObjectURL(img) : null;

    const _tests = studyData?.tests?.value;
    const _testsLength = _tests?.length;

    return (
      <div className='test-preview-card'>
        <div className='test-preview-card__left'>
          <Text color='secondary' type='h4'>
            {`Вопрос №${currentQuestionIdx + 1} / ${_studiesLength}:`}
          </Text>

          <Spacer top='1.5rem' />

          {imgURL ? (
            <img
              src={imgURL}
              alt={"Превью картинка"}
              className='test-preview-card__img'
              onClick={e => {
                e.preventDefault();
                openImagePreview(imgURL);
              }}
            />
          ) : (
            <Text color='grey' type='md'>
              Нет изображения
            </Text>
          )}

          <Spacer top='1.5rem' />

          <Text color={desc?.length ? "black" : "grey"} type='h4'>
            {desc?.length ? desc : "Нет описания вопроса."}
          </Text>

          <Spacer bottom='5rem' />

          {_testsLength
            ? _tests?.map((t, tIdx) => {
                const question = t?.question?.value;
                const _img = t?.picture?.value;
                const _imgURL = _img ? URL.createObjectURL(_img) : null;
                const variants = t?.answers?.value?.map((a, idx) => {
                  const _a = a?.answer?.value;
                  const _label = !_a?.length ? `Вариант ${idx + 1}` : _a;

                  return {
                    label: _label,
                    value: `${idx}_${_label}`,
                    correct: a?.correct?.value,
                    disabled: !_a?.length,
                  };
                });

                return (
                  <React.Fragment
                    key={`${tIdx}-ufd_form_task_preview_question_tests`}>
                    <div className='d-flex f-column'>
                      <Text
                        type='h3'
                        color={question?.length ? "primary" : "grey"}>{`${
                        question?.length ? question : "Текст вопроса не указан."
                      } (${tIdx + 1}/${_testsLength})`}</Text>

                      <Spacer top='1.5rem' />

                      {_imgURL ? (
                        <img
                          src={_imgURL}
                          alt={"Превью картинка"}
                          style={{
                            minWidth: "15rem",
                            maxWidth: "30rem",
                          }}
                          onClick={e => {
                            e.preventDefault();
                            openImagePreview(_imgURL);
                          }}
                        />
                      ) : (
                        <Text color='grey' type='md'>
                          Нет изображения
                        </Text>
                      )}

                      <Spacer top='1.5rem' />

                      <Radio
                        value={getCurrentAnswer(tIdx)}
                        variants={variants}
                        onChange={opt => addTestAnswer(opt, tIdx, variants)}
                      />

                      <Spacer bottom='5rem' />
                    </div>

                    <Dialog show={!!imagePreview} close={closeImagePreview}>
                      <img
                        src={imagePreview}
                        alt={"Превью картинка"}
                        className='test-preview-card__img-preview'
                        onClick={e => {
                          e.preventDefault();
                          closeImagePreview();
                        }}
                      />
                    </Dialog>
                  </React.Fragment>
                );
              })
            : null}
        </div>
        <div className='test-preview-card__right'>
          <Button type='flat' onClick={close}>
            <Text type='md'>Выйти</Text>
            <Spacer left='0.75rem' />
            <Icon icon='close-outline' size='2rem' />
          </Button>

          <div className='flex-1'></div>
          <Button
            type='primary'
            onClick={() => onChangeCurrentQuestionIdx(currentQuestionIdx + 1)}>
            <Text type='md'>Дальше</Text>
          </Button>
          <Spacer top='3rem' />
        </div>
      </div>
    );
  };

  if (startTests) {
    return <>{showQuestions(studies[currentQuestionIdx])}</>;
  }

  return (
    <>
      <div className='d-flex align-center'>
        <Button type='flat icon' onClick={() => close()}>
          <Icon icon='arrow-left' size='5rem' />
        </Button>
        <Spacer left='1rem' />
        <Text color='secondary' type='h3'>
          Предпросмотр теста
        </Text>
      </div>

      <Spacer top='2rem' />

      <div className='test-preview-card'>
        <div className='d-flex column flex-1 test-preview-card__gap'>
          {studies?.map((s, idx) => {
            const desc = s?.description?.value;
            const img = s?.picture?.value;
            const imgURL = img ? URL.createObjectURL(img) : null;

            return (
              <div
                className={`test-preview-card__left${
                  idx + 1 !== _studiesLength
                    ? " test-preview-card__left-border-bottom"
                    : ""
                }`}
                key={`${idx}-ufd_form_task_preview_question`}>
                <Text color='secondary' type='h4'>
                  {`Вопрос №${idx + 1} / ${_studiesLength}:`}
                </Text>

                <Spacer bottom='1.5rem' />

                {img ? (
                  <img
                    src={URL.createObjectURL(img)}
                    alt={"Превью картинка"}
                    className='test-preview-card__img'
                    onClick={e => {
                      e.preventDefault();
                      openImagePreview(imgURL);
                    }}
                  />
                ) : (
                  <Text color='grey' type='md'>
                    Нет изображения
                  </Text>
                )}

                <Spacer top='1.5rem' />

                <Text color={desc?.length ? "black" : "grey"} type='lg'>
                  {desc?.length ? desc : "Нет описания вопроса."}
                </Text>
              </div>
            );
          })}

          <Spacer top='7rem' />
        </div>

        <div className='test-preview-card__right'>
          <Button type='flat' onClick={close}>
            <Text type='md'>Выйти</Text>
            <Spacer left='0.75rem' />
            <Icon icon='close-outline' size='2rem' />
          </Button>

          <div className='flex-1'></div>

          <Button type='primary' onClick={() => onChangeStartTests()}>
            <Text type='md'>Начать тест</Text>
          </Button>
        </div>
      </div>

      <Spacer bottom='4rem' />

      <Dialog show={!!imagePreview} close={closeImagePreview}>
        <img
          src={imagePreview}
          alt={"Превью картинка"}
          className='test-preview-card__img-preview'
          onClick={e => {
            e.preventDefault();
            closeImagePreview();
          }}
        />
      </Dialog>
    </>
  );
};

export default TestPreview;

const TestResult = ({ close, startDate, endDate, answersData }) => {
  const resMs = endDate.getTime() - startDate.getTime();
  const msToTime = (duration = resMs) => {
    let seconds = Math.floor((duration / 1000) % 60);
    let minutes = Math.floor((duration / (1000 * 60)) % 60);
    let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
  };

  let countCorrectAnswers = 0;

  if (answersData?.answers) {
    for (const aKey of Object.keys(answersData.answers)) {
      const a = answersData?.answers[aKey];
      countCorrectAnswers += a?.correct ?? 0;
    }
  }

  return (
    <>
      <div className='test-result-card'>
        <div className='test-result-card__title'>
          <Text color='secondary' type='h4'>
            Ибуфен суспензия и свечи
          </Text>
        </div>
        <div className='test-result-card__content'>
          <div className='test-result-card__item'>
            <Text type='h4'>Правильных ответов: </Text>
            <Text color='primary' type='h4'>
              <b>{countCorrectAnswers}</b>/{answersData?.totalQuestions ?? 0}
            </Text>
          </div>
          <div className='test-result-card__item'>
            <Text type='h4'>Начисленно баллов: </Text>
            <Text color='primary' type='h4'>
              <b>0</b>
            </Text>
          </div>
          <div className='test-result-card__item'>
            <Text type='h4'>Время прохождения: </Text>
            <Text color='primary' type='h4'>
              {msToTime()}
            </Text>
          </div>
        </div>
      </div>
      <Spacer top='3rem' />
      <div className='d-flex justify-end'>
        <Button
          type='primary'
          onClick={() => {
            close();
          }}>
          <Text type='md'>Завершить</Text>
        </Button>
      </div>
    </>
  );
};
