import { useState } from "react";
import APIService, {
  API_DATA_SHOP_ORDERS,
} from "../../../services/api.service";
import { convertDate } from "../../../shared/functions/convertDate";
import useDispatchError from "../../../shared/hooks/useDispatchError";
import { Text, Button, Spacer, TextArea, Icon } from "../../common";

const OrderInfo = ({ order, onChangeOrderByID }) => {
  const [setError] = useDispatchError();
  const [commentary, setCommentary] = useState(order?.comment ?? "");

  const onStatusChange = async status => {
    let uploadData = { status };

    if (commentary?.length && commentary !== order?.comment) {
      uploadData.comment = commentary;
    }

    const newObj = await APIService.putDataByIDHandler(
      API_DATA_SHOP_ORDERS,
      order.id,
      uploadData,
      () =>
        setError("Не удалось изменить статус Заказа или изменить Комментарий."),
    );
    if (newObj) {
      onChangeOrderByID(order.id, newObj);
    }
  };

  return (
    <div className='order-info'>
      <div className='order-info__list'>
        <div className='order-info__row'>
          <Text color='grey'>Номер телефона:</Text>
          <Spacer left='auto' />
          <Text>{order?.user?.username ?? "Неизвестно"}</Text>
        </div>

        <div className='order-info__row'>
          <Text color='grey'>Категория товара:</Text>
          <Spacer left='auto' />
          <Text>{order?.product?.group?.name ?? "Неизвестно"}</Text>
        </div>

        <div className='order-info__row'>
          <Text color='grey'>Позиция:</Text>
          <Spacer left='auto' />
          <Text>{order?.product?.name ?? "Неизвестно"}</Text>
        </div>

        <div className='order-info__row'>
          <Text color='grey'>Количество:</Text>
          <Spacer left='auto' />
          <Text>{order?.count ?? "Неизвестно"}</Text>
        </div>

        <div className='order-info__row'>
          <Text color='grey'>Номер договора:</Text>
          <Spacer left='auto' />
          <Text>{`#${order.id ?? "Неизвестно"}`}</Text>
        </div>

        <div className='order-info__row'>
          <Text color='grey'>Дата обработки заказа:</Text>
          <Spacer left='auto' />
          <Text>{convertDate(order?.updatedAt)}</Text>
        </div>
      </div>

      <Spacer top='4rem' />

      <Text color='secondary' type='md'>
        Комментарий
      </Text>
      <Spacer top='1.5rem' />
      <TextArea
        placeholder='Напишите комментарий'
        style={{ width: "100%" }}
        value={commentary}
        onChange={e => setCommentary(e.target.value)}
      />

      <Spacer top='3rem' />

      <div className='d-flex'>
        <Spacer left='auto' />
        <Button
          disabled={order?.status === "rejected"}
          type='outline'
          onClick={e => {
            e?.preventDefault();
            onStatusChange("rejected");
          }}>
          <Icon icon='close-outline' size='2rem' />
          <Spacer left='1.5rem' />
          <Text color='grey'>Отклонить</Text>
        </Button>

        <Spacer left='1.5rem' />

        <Button
          type='primary'
          disabled={order?.status === "confirmed"}
          onClick={e => {
            e?.preventDefault();
            onStatusChange("confirmed");
          }}>
          <Text>Подтвердить</Text>
        </Button>
      </div>
    </div>
  );
};

export default OrderInfo;
