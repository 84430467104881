import React, { useEffect, useState } from "react";
import { Table, Text, Icon, Spacer, Button } from "../common";
import TableChildRowStudy from "./TableChildRowStudy";
import useAPIData from "../../shared/hooks/useAPIData";
import { convertDate } from "../../shared/functions/convertDate";
import { tasksStatuses } from "../tasks/taskProps";
import queryStringify from "qs-stringify";
import APIService from "../../services/api.service";
import useDispatchError from "../../shared/hooks/useDispatchError";

const ApproveStudyTable = ({ searchQuery }) => {
  const [setError] = useDispatchError();

  const _getFiltersQueries = () => {
    const _filters = { "type.name": "training" };

    if (searchQuery?.length) {
      _filters._q = searchQuery;
    }

    const qs = queryStringify(_filters);
    return qs;
  };

  const [tasks, pagination, updateTasks, , onChangePage, manualTasksUpdate] =
    useAPIData("tasks", _getFiltersQueries());
  const [isTasksCountsFetched, setIsTasksCountsFetched] = useState(false);

  const _pagination = {
    ...pagination,
    onChange: page => {
      if (isTasksCountsFetched) {
        setIsTasksCountsFetched(false);
      } else {
        onChangePage(page, _getFiltersQueries());
      }
    },
  };

  useEffect(() => {
    const _fetchTasksProgressData = async () => {
      const _newTasksData = [...tasks];

      let _idx = 0;
      for (const _t of tasks) {
        const _progressCount = await APIService.getDataCountHandler(
          "tasktrainingusers",
          () =>
            setError(
              `Не удалось получить общее кол-во задач для Выполнение(${_t.id})`,
            ),
          `task.id=${_t.id}`,
        );
        if (typeof _progressCount !== "number") {
          break;
        }

        const _doneCount = await APIService.getDataCountHandler(
          "tasktrainingusers",
          () =>
            setError(
              `Не удалось получить кол-во выполненных задач для Выполнение(${_t.id})`,
            ),
          `task.id=${_t.id}&status=done`,
        );
        if (typeof _doneCount !== "number") {
          break;
        }

        const _newT = { ..._t, _progressCount, _doneCount };
        _newTasksData.splice(_idx, 1, _newT);
        ++_idx;
      }

      setIsTasksCountsFetched(true);
      manualTasksUpdate(_newTasksData);
    };

    if (tasks?.length && !isTasksCountsFetched) {
      _fetchTasksProgressData();
    }
  }, [tasks]);

  useEffect(() => {
    if (tasks) {
      setIsTasksCountsFetched(false);
      updateTasks(_getFiltersQueries());
    }
  }, [searchQuery]);

  const headers = [
    { title: "#", isSortable: false },
    { title: "Название", isSortable: false },
    { title: "Статус", isSortable: false },
    { title: "Роли", isSortable: false },
    { title: "Публикация", isSortable: false },
    { title: "Конец", isSortable: false },
    { title: "Выполнение", isSortable: false },
    { title: "Автор", isSortable: false },
    { title: "", isSortable: false },
  ];

  const row = task => {
    let _authorName;
    if (task?.author?.firstname?.length && task?.author?.lastname?.length) {
      _authorName = task?.author?.firstname + " " + task?.author?.lastname;
    }

    return (
      <React.Fragment>
        <div className='table-row__cell'>
          <a title={task.id}>
            <Text color='primary'>{task.id.slice(0, 10)}</Text>
          </a>
        </div>
        <div className='table-row__cell'>
          <Text>{task?.name ?? "Неизвестно"}</Text>
        </div>
        <div className='table-row__cell status-success'>
          <Icon icon={tasksStatuses[task?.status]?.icon} size='1.75rem' />
          <Spacer left='0.5rem' />
          <Text>{tasksStatuses[task?.status]?.text}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{task?.type?.description ?? "Неизвестно"}</Text>
        </div>
        <div className='table-row__cell'>
          <Text isBold>{convertDate(task.published_at)}</Text>
        </div>
        <div className='table-row__cell'>
          <Text isBold>{convertDate(task.enddate)}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{`${task?._doneCount ?? "-"}/${
            task?._progressCount ?? "-"
          }`}</Text>
        </div>
        <div className='table-row__cell'>
          <Text color={_authorName ? "" : "grey"}>{_authorName}</Text>
        </div>
      </React.Fragment>
    );
  };
  return (
    <div className='card flex-1'>
      <Table
        columns={[
          "15rem",
          "30rem",
          "14rem",
          "12rem",
          "14rem",
          "14rem",
          "15rem",
          "20rem",
          "5rem",
        ]}
        headers={headers}
        pagination={_pagination}
        row={row}
        collapseSlot={
          <Button type='flat'>
            <Icon icon='arrow-down' size='2.5rem' />
          </Button>
        }
        childrenRow={task => {
          return <TableChildRowStudy taskId={task.id} />;
        }}
        list={tasks}
        isLoading={!tasks}></Table>
    </div>
  );
};

export default ApproveStudyTable;
