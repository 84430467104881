import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { Spacer, Icon, Button, Text } from "../../../../common/";
import { uuid } from "uuidv4";
import { useFormData_TaskNewStudyTestAnswer } from "../../../../../shared/hooks/useFormData";

const TestVariants = ({ testsIdx, answers, onChangeAnswer }) => {
  const onChangeAnswerHandler = (value, valueName, aIdx) => {
    const newAnswers = [...answers];
    const newAnswerObj = {
      ...answers[aIdx],
      [valueName]: {
        ...answers[aIdx][valueName],
        value,
      },
    };

    newAnswers.splice(aIdx, 1, newAnswerObj);
    onChangeAnswer(newAnswers);
  };
  const onAddAnswer = () => {
    const newAnswers = [
      ...answers,
      { ...useFormData_TaskNewStudyTestAnswer() },
    ];
    onChangeAnswer(newAnswers);
  };
  const onRemoveAnswer = aIdx => {
    const newAnswers = [...answers];
    newAnswers.splice(aIdx, 1);
    onChangeAnswer(newAnswers);
  };

  return (
    <>
      <FormControl
        style={{ width: "100%" }}
        className='custom-radio'
        component='fieldset'
        required={true}>
        <RadioGroup value={1} onChange={() => {}}>
          {answers?.map((v, idx) => {
            return (
              <div
                className='test-variant__item'
                key={`${testsIdx}-${idx}-ufd_tasks_answers`}>
                <FormControlLabel
                  value={v?.answer?.value}
                  control={
                    <Radio
                      required={true}
                      checked={!!v?.correct?.value}
                      onClick={e => {
                        e.preventDefault();
                        onChangeAnswerHandler(
                          !v?.correct?.value,
                          "correct",
                          idx,
                        );
                      }}
                    />
                  }
                  label={
                    <div className='d-flex align-center'>
                      <input
                        type='text'
                        style={{ outline: "none" }}
                        placeholder={`Вариант ${idx + 1}`}
                        required={v?.answer?.required}
                        value={v?.answer?.value}
                        onChange={e =>
                          onChangeAnswerHandler(e.target.value, "answer", idx)
                        }
                      />

                      <Spacer left='auto' />

                      {idx ? (
                        <div
                          className='test-variant__item__btn'
                          onClick={e => {
                            e.preventDefault();
                            onRemoveAnswer(idx);
                          }}>
                          <Icon icon='remove' size='2rem' />
                        </div>
                      ) : null}
                    </div>
                  }
                />
              </div>
            );
          })}
        </RadioGroup>
      </FormControl>

      <Spacer tpop='3rem' />

      <Button
        type='flat'
        onClick={e => {
          e?.preventDefault();
          onAddAnswer();
        }}>
        <Icon icon='plus-outline' size='2rem' />
        <Spacer left='1rem' />
        <Text>Добавить вариант ответа</Text>
      </Button>
    </>
  );
};

export default TestVariants;
