import React from "react";
import { useHistory } from "react-router-dom";
import { ROUTE_TASKS } from "../../../../../AppRoutes";
import {
  useFormDataPropDateNestedDateName,
  useFormDataPropDateNestedTimeName,
  useFormDataPropSelectDefault,
  useFormDataTextRequired,
  useFormData_TaskTypeMerch,
  useFormData_TaskTypePurchase,
  useFormData_TaskTypeSale,
  useFormData_TaskTypeTraining,
} from "../../../../../shared/hooks/useFormData";
import {
  Modal,
  Text,
  Spacer,
  Input,
  Select,
  TextArea,
  FileInput,
  DatePicker,
  TimePicker,
  Button,
  Icon,
  Autocomplete,
} from "../../../../common";
import { MerchFrom, PurchaseForm, SaleForm, StudyForm } from "./forms";

const TaskInfo = ({
  disableActions,
  onChangeUploadStatus,
  brandOptions,
  typeOptions,
  formOptions,
  onChangeFormOptions,
  formData,
  onChangeFormData,
  generalFormData,
  onChangeGeneralFormData,
  back,
  next,
}) => {
  const history = useHistory();

  const onChangeHandler = (value, valueName, nestedValueName = null) => {
    onChangeGeneralFormData(value, valueName, nestedValueName);
  };
  const onChangeSelect = (value, valueName, options) => {
    let opt = options.find(o => o?.value === value);
    if (!opt?.value) {
      opt = { ...useFormDataPropSelectDefault };
    }
    onChangeHandler(opt, valueName);
  };
  const onChangeMultiSelect = (value, valueName, options) => {
    const newValues = [];
    for (const v of value) {
      const defaultOpt = options?.find(o => o.value === v);
      if (defaultOpt?.id) {
        newValues.push(defaultOpt);
      }
    }
    onChangeHandler(newValues, valueName);
  };
  const onSelectAllOptions = (valueName, options) => {
    const newValue = JSON.parse(JSON.stringify(options));
    if (newValue?.length !== generalFormData[valueName]?.value?.length) {
      onChangeHandler(newValue, valueName);
    }
  };

  const renderTypedForm = () => {
    switch (generalFormData?.type?.value) {
      case useFormData_TaskTypeMerch:
        return (
          <MerchFrom
            formData={formData[useFormData_TaskTypeMerch]}
            onChangeFormData={onChangeFormData}
            formOptions={formOptions[useFormData_TaskTypeMerch]}
            onChangeFormOptions={onChangeFormOptions}
          />
        );
      case useFormData_TaskTypePurchase:
        return (
          <PurchaseForm
            formData={formData[useFormData_TaskTypePurchase]}
            onChangeFormData={onChangeFormData}
            formOptions={formOptions[useFormData_TaskTypePurchase]}
            onChangeFormOptions={onChangeFormOptions}
          />
        );
      case useFormData_TaskTypeSale:
        return (
          <SaleForm
            formData={formData[useFormData_TaskTypeSale]}
            onChangeFormData={onChangeFormData}
            formOptions={formOptions[useFormData_TaskTypeSale]}
            onChangeFormOptions={onChangeFormOptions}
          />
        );
      case useFormData_TaskTypeTraining:
        return (
          <StudyForm
            formData={formData[useFormData_TaskTypeTraining]}
            onChangeFormData={onChangeFormData}
            formOptions={formOptions[useFormData_TaskTypeTraining]}
            onChangeFormOptions={onChangeFormOptions}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal back={back} title='Создание нового задания'>
      <div className='flex-1 d-flex'>
        <div className='flex-50 form-full-width'>
          <Text color='secondary' type='md'>
            {useFormDataTextRequired(
              generalFormData?.name?.label,
              generalFormData?.name?.required,
            )}
          </Text>
          <Spacer top='1.5rem' />
          <Input
            placeholder='Название новой задачи'
            required={generalFormData?.name?.required}
            value={generalFormData?.name?.value}
            onChange={e => onChangeHandler(e.target.value, "name")}
          />

          <Spacer top='2rem' />

          <Text color='secondary' type='md'>
            {useFormDataTextRequired(
              generalFormData?.description?.label,
              generalFormData?.description?.required,
            )}
          </Text>
          <Spacer top='1.5rem' />
          <TextArea
            style={{ width: "100%" }}
            placeholder='Введите описание'
            rowsMin='6'
            required={generalFormData?.description?.required}
            value={generalFormData?.description?.value}
            onChange={e => onChangeHandler(e.target.value, "description")}
          />

          <Spacer top='2rem' />

          <Text color='secondary' type='md'>
            {useFormDataTextRequired(
              generalFormData?.picture?.label,
              generalFormData?.picture?.required,
            )}
          </Text>
          <Spacer top='1.5rem' />
          <FileInput
            value={generalFormData?.picture?.value}
            required={generalFormData?.picture?.required}
            onChange={f => onChangeHandler(f, "picture")}
          />

          <Spacer top='2rem' />

          <div className='d-flex align-center'>
            <Text color='secondary' type='md'>
              {useFormDataTextRequired(
                generalFormData?.startdate?.label,
                generalFormData?.startdate?.required,
              )}
            </Text>
            <Spacer left='auto' />
            <DatePicker
              width='19.2rem'
              value={generalFormData?.startdate?.value?.date}
              required={generalFormData?.startdate?.required}
              onChange={date =>
                onChangeHandler(
                  date,
                  "startdate",
                  useFormDataPropDateNestedDateName,
                )
              }
            />

            <Spacer left='1rem' />
            <Text color='secondary' type='md'>
              -
            </Text>
            <Spacer left='1rem' />

            <TimePicker
              width='14.825rem'
              value={generalFormData?.startdate?.value?.time}
              required={generalFormData?.startdate?.required}
              onChange={time =>
                onChangeHandler(
                  time,
                  "startdate",
                  useFormDataPropDateNestedTimeName,
                )
              }
            />
          </div>
          <Spacer top='3.25rem' />

          <div className='d-flex align-center'>
            <Text color='secondary' type='md'>
              {useFormDataTextRequired(
                generalFormData?.enddate?.label,
                generalFormData?.enddate?.required,
              )}
            </Text>
            <Spacer left='auto' />
            <DatePicker
              width='19.2rem'
              value={generalFormData?.enddate?.value?.date}
              required={generalFormData?.enddate?.required}
              onChange={date =>
                onChangeHandler(
                  date,
                  "enddate",
                  useFormDataPropDateNestedDateName,
                )
              }
            />

            <Spacer left='1rem' />
            <Text color='secondary' type='md'>
              -
            </Text>
            <Spacer left='1rem' />

            <TimePicker
              width='14.825rem'
              value={generalFormData?.enddate?.value?.time}
              required={generalFormData?.enddate?.required}
              onChange={time =>
                onChangeHandler(
                  time,
                  "enddate",
                  useFormDataPropDateNestedTimeName,
                )
              }
            />
          </div>
        </div>

        <Spacer left='4rem' />

        <div className='flex-50'>
          <div className='d-flex'>
            <div className='flex-50'>
              <Text color='secondary' type='md'>
                {useFormDataTextRequired(
                  generalFormData?.type?.label,
                  generalFormData?.type?.required,
                )}
              </Text>
              <Spacer top='1.5rem' />
              <Select
                disabled={!typeOptions?.length}
                variants={typeOptions}
                value={generalFormData?.type?.value}
                required={generalFormData?.type?.required}
                onChange={e =>
                  onChangeSelect(e.target.value, "type", typeOptions)
                }
                style={{ width: "25rem" }}
                label='Выберите тип'
              />
            </div>

            <div>
              <div className='d-flex align-center'>
                <Spacer left='5rem' />
                <Text color='secondary' type='md'>
                  {useFormDataTextRequired(
                    generalFormData?.brands?.label,
                    generalFormData?.brands?.required,
                  )}
                </Text>
              </div>

              <Spacer top='1.5rem' />

              <div className='d-flex align-center'>
                <Icon
                  icon={`plus-outline${brandOptions?.length ? "_blue" : ""}`}
                  size='3.2rem'
                  onClick={() => {
                    if (brandOptions?.length) {
                      onSelectAllOptions("brands", brandOptions);
                    }
                  }}
                />

                <Spacer left='1.5rem' />

                <Autocomplete
                  style={{ width: "27.5rem" }}
                  multiple={true}
                  id='brands'
                  placeholder='Выберите бренды'
                  disabled={!brandOptions?.length}
                  value={
                    generalFormData?.brands?.value?.length
                      ? generalFormData?.brands?.value?.map(v => v.value)
                      : []
                  }
                  onValueChange={option =>
                    onChangeMultiSelect(option, "brands", brandOptions)
                  }
                  options={brandOptions?.map(c => c.value)}
                  getOptionLabel={option => option}
                />
              </div>
            </div>
          </div>

          <Spacer top='2rem' />

          {renderTypedForm()}
        </div>
      </div>
      <Spacer top='2rem' />
      <div className='d-flex'>
        <Button
          type='outline'
          onClick={() => {
            history.push(ROUTE_TASKS);
          }}>
          <Icon icon='remove' size='2rem' />
          <Spacer left='1.5rem' />
          <Text type='md'>Удалить</Text>
        </Button>
        <Spacer left='auto' />
        <Button
          type='white'
          btnType='submit'
          disabled={disableActions}
          onClick={() => {
            onChangeUploadStatus("draft");
          }}>
          <Text type='md'>Сохранить как черновик</Text>
        </Button>
        <Spacer left='3rem' />
        <Button
          type='primary'
          disabled={disableActions}
          onClick={() => {
            onChangeUploadStatus("active");
            if (next) {
              next();
            }
          }}>
          <Text type='md'>Далее</Text>
        </Button>
      </div>
    </Modal>
  );
};

export default TaskInfo;
