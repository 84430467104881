import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Text, Spacer, Icon, Button } from "../common";
import { TextField, InputAdornment } from "@material-ui/core";
import { userLogin } from "../../store/user";
import { appErrorClear, selectAppError } from "../../store/app";

const LoginCard = ({ onForget }) => {
  const dispatch = useDispatch();
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const err = useSelector(selectAppError);
  const isInvalidLogin = !!err && err?.style !== "success";

  useEffect(() => {
    if (err?.style === "success") {
      setTimeout(() => {
        dispatch(appErrorClear());
      }, 5000);
    }
  }, [err]);

  return (
    <React.Fragment>
      <Text type='h4'>Введите логин и пароль </Text>
      <Spacer top='3rem' />
      <form
        onSubmit={e => {
          e.preventDefault();
          dispatch(userLogin(login, password));
        }}>
        <TextField
          error={isInvalidLogin}
          placeholder='Е-мейл'
          value={login}
          onChange={e => {
            e.preventDefault();
            setLogin(e.target.value);
          }}
          InputProps={{
            required: true,
            startAdornment: (
              <InputAdornment position='start'>
                <Icon icon='mail' size='3rem' />
              </InputAdornment>
            ),
          }}
          // type="email"
          variant='outlined'
        />
        <Spacer top='1.5rem' />
        <TextField
          error={isInvalidLogin}
          placeholder='Пароль'
          value={password}
          onChange={e => {
            e.preventDefault();
            setPassword(e.target.value);
          }}
          InputProps={{
            required: true,
            startAdornment: (
              <InputAdornment position='start'>
                <Icon icon='lock' size='3rem' />
              </InputAdornment>
            ),
          }}
          type='password'
          variant='outlined'
        />
        <Spacer top='3rem' />
        <Button btnType={"submit"} type='primary'>
          <Text isCenter type='md' color='white'>
            Вход
          </Text>
        </Button>
      </form>
      <Spacer top='8.75rem' />
      <Text type='md' color='grey'>
        Забыли пароль?
      </Text>
      <Spacer top='3rem' />
      <div onClick={onForget} className='auth-link'>
        Перейдите на форму восстановления пароля
      </div>
    </React.Fragment>
  );
};

export default LoginCard;
