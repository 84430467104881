import queryStringify from "qs-stringify";
import React, { useEffect, useState } from "react";
import APIService, {
  API_DATA_CITIES,
  API_DATA_USERS,
  API_DATA_USERS_QUERY_PHARMACIES,
} from "../../../../../../services/api.service";
import useAPIData from "../../../../../../shared/hooks/useAPIData";
import useDispatchError from "../../../../../../shared/hooks/useDispatchError";
import {
  useFormDataPropSelectDefault,
  useFormData_TaskTypePurchase,
} from "../../../../../../shared/hooks/useFormData";
import { StoreAppErrorTypeForm } from "../../../../../../store/app";
import { Spacer, Select, Table, Text, Checkbox } from "../../../../../common";
import { usersDataNew } from "../../_taskModalProps";
import TaskTablesSelectedUsers from "./TaskTablesSelectedUsers";

const PharmacyTable = ({
  taskType,
  generalFormData,
  onChangeGeneralFormData,
}) => {
  let _defaultOptions = [
    { value: "", text: "Все" },
    { value: "commercial", text: "Мелкая Сеть" },
    { value: "individual", text: "Индивидуальная" },
  ];
  let _defaultQuery = API_DATA_USERS_QUERY_PHARMACIES;
  let _isRestrictedQueries = taskType === useFormData_TaskTypePurchase;

  const _setDefaultPurchaseQueries = () =>
    queryStringify({
      _where: {
        _or: [
          { "pharmacy.type": "commercial" },
          { "pharmacy.type": "individual" },
        ],
      },
    });
  if (_isRestrictedQueries) {
    _defaultQuery = _setDefaultPurchaseQueries();
  } else {
    _defaultOptions.push({ value: "contract", text: "Контракт" });
  }

  const [setError] = useDispatchError();
  const [
    uPharmacies,
    pagination,
    updatePharmacies,
    ,
    onChangePage,
  ] = useAPIData(API_DATA_USERS, _defaultQuery, 100);

  const [filters, setFilters] = useState({
    [API_DATA_CITIES]: {
      query: "pharmacy.city.name",
      data: API_DATA_CITIES,
      options: [],
      value: "",
    },
    type: {
      query: "pharmacy.type",
      options: [..._defaultOptions],
      value: "",
    },
    category: {
      query: "pharmacy.category",
      options: [
        { value: "", text: "Все" },
        { value: "A", text: "A" },
        { value: "B", text: "B" },
        { value: "C", text: "C" },
      ],
      value: "",
    },
  });

  const isUserSelected = user => {
    if (generalFormData?.users?.value?.pharmacies?.length) {
      return !!generalFormData?.users?.value?.pharmacies.find(
        u => u?.id === user?.id,
      );
    }
    return false;
  };
  const isAllPaginatedUsersSelected = () => {
    if (uPharmacies?.length) {
      let selectedCount = 0;
      for (const up of uPharmacies) {
        if (isUserSelected(up)) {
          ++selectedCount;
        }
      }

      return selectedCount === uPharmacies?.length;
    }
    return false;
  };
  const __isAllPaginatedUsersSelected = isAllPaginatedUsersSelected();

  const onCheckUser = user => {
    let _selectedUsers = generalFormData?.users?.value?.pharmacies?.length
      ? [...generalFormData?.users?.value?.pharmacies]
      : [];
    const uid = user?.id;
    const uidIndex = _selectedUsers?.findIndex(_u => _u.id === uid);
    if (-1 !== uidIndex) {
      _selectedUsers.splice(uidIndex, 1);
    } else {
      _selectedUsers.push(usersDataNew(uid));
    }

    onChangeGeneralFormData(_selectedUsers, "users", "pharmacies");
  };
  const onCheckAllUsers = () => {
    if (!uPharmacies?.length) {
      setError("Нет пользователей для выбора.", StoreAppErrorTypeForm);
      return;
    }

    const formIDs = generalFormData?.users?.value?.pharmacies?.length
      ? [...generalFormData?.users?.value?.pharmacies]
      : [];
    let newUIDs = [...formIDs];

    if (__isAllPaginatedUsersSelected) {
      for (const up of uPharmacies) {
        const idIdx = newUIDs.findIndex(u => u.id === up?.id);
        if (-1 < idIdx) {
          newUIDs.splice(idIdx, 1);
        }
      }
    } else {
      let appendUIDs = [];
      for (const up of uPharmacies) {
        if (!isUserSelected(up)) {
          const upid = up?.id;
          appendUIDs.push(usersDataNew(upid));
        }
      }

      newUIDs = [...newUIDs, ...appendUIDs];
    }

    onChangeGeneralFormData(newUIDs, "users", "pharmacies");
  };

  const getFiltersQuery = (_f = filters) => {
    let qsQueries = {};
    for (const fKey of Object.keys(_f)) {
      const fData = _f[fKey];

      switch (fKey) {
        case "type":
          if (_isRestrictedQueries) {
            if (fData?.value?.length) {
              _defaultQuery = API_DATA_USERS_QUERY_PHARMACIES;
            } else {
              _defaultQuery = _setDefaultPurchaseQueries();
            }
          }

        default:
          if (fData?.value?.length) {
            qsQueries[fData.query] = fData.value;
          }
          break;
      }
    }

    const queryRequest = queryStringify(qsQueries);

    let resQuery = _defaultQuery;
    if (queryRequest?.length) {
      resQuery += `&${queryRequest}`;
    }

    return resQuery;
  };
  const onChangeFilter = async (value, filterName) => {
    const oldValue = filters[filterName].value;
    if (oldValue === value) {
      return;
    }

    const newFilters = {
      ...filters,
      [filterName]: { ...filters[filterName], value },
    };

    let resQuery = getFiltersQuery(newFilters);
    const isSuccess = await updatePharmacies(resQuery);
    if (isSuccess) {
      setFilters(newFilters);
    }
  };

  const onChangePageHandler = newPage => {
    onChangePage(newPage, getFiltersQuery(filters));
  };

  useEffect(() => {
    const fetchFiltersOptions = async () => {
      let isNoErr = true;
      let newFilters = { ...filters };

      for (const fKey of Object.keys(filters)) {
        const fData = filters[fKey];
        if (!fData?.data) {
          continue;
        }

        const fOptions = await APIService.getDataHandler(fData.data, () =>
          setError(`Не удалось получить опции(${fData.data}) для фильтров.`),
        );
        isNoErr = !!fOptions;

        if (!isNoErr || typeof fOptions === "string") {
          break;
        }

        newFilters[fKey].options = [
          useFormDataPropSelectDefault,
          ...fOptions?.map(f => ({
            value: f.name,
            text: f.name,
          })),
        ];
      }

      if (isNoErr) {
        setFilters(newFilters);
      }
    };

    fetchFiltersOptions();
  }, []);

  const types = {
    contract: "Контракт",
    individual: "Индивидуальная",
    commercial: "Мелкая Сеть",
  };

  const row = item => {
    let pharmacyType = "Неизвестно";
    let pharmacyTypeColor = "grey";
    if (item?.pharmacy?.type && types[item?.pharmacy?.type]) {
      pharmacyType = types[item?.pharmacy?.type];
      pharmacyTypeColor = "black";
    }

    let userName = "Не указано";
    let userNameColor = "grey";
    if (item?.firstname && item?.lastname) {
      userName = `${item?.firstname} ${item?.lastname}`;
      userNameColor = "black";
    }

    return (
      <React.Fragment>
        <div className='table-row__cell'>
          <div className='d-flex justify-center flex-1'>
            <Checkbox
              checked={isUserSelected(item)}
              onChange={() => onCheckUser(item)}
            />
          </div>
        </div>
        <div className='table-row__cell'>
          <Text color={userNameColor}>{userName}</Text>
        </div>
        <div className='table-row__cell'>
          <Text color={pharmacyTypeColor}>{pharmacyType}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{item?.pharmacy?.city?.name ?? "Неизвестно"}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{item?.pharmacy?.address ?? "Неизвестно"}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{item?.username ?? "Неизвестно"}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{item?.pharmacy?.category ?? "Неизвестно"}</Text>
        </div>
      </React.Fragment>
    );
  };

  const headers = [
    {
      title: (
        <div className='d-flex justify-center flex-1'>
          <Checkbox
            checked={__isAllPaginatedUsersSelected}
            onClick={() => onCheckAllUsers()}
          />
        </div>
      ),
      isSortable: false,
    },
    { title: "Имя", isSortable: false },
    { title: "Тип аптеки", isSortable: false },
    { title: "Город", isSortable: false },
    { title: "Адрес", isSortable: false },
    { title: "Телефон", isSortable: false },
    { title: "Категория", isSortable: false },
  ];

  return (
    <>
      <Spacer top='3rem' />

      <div className='d-flex'>
        <Select
          style={{ width: "30rem" }}
          label='Категория аптеки'
          value={filters.category.value}
          onChange={e => onChangeFilter(e.target.value, "category")}
          variants={filters.category.options}
        />
        <Spacer left='3rem' />
        <Select
          style={{ width: "30rem" }}
          label='Тип'
          value={filters.type.value}
          onChange={e => onChangeFilter(e.target.value, "type")}
          variants={filters.type.options}
        />
        <Spacer left='3rem' />
        <Select
          style={{ width: "30rem" }}
          label='Город'
          disabled={!filters[API_DATA_CITIES]?.options?.length}
          value={filters[API_DATA_CITIES].value}
          onChange={e => onChangeFilter(e.target.value, API_DATA_CITIES)}
          variants={filters[API_DATA_CITIES].options}
        />
      </div>

      <TaskTablesSelectedUsers
        pharmacies={generalFormData?.users?.value?.pharmacies}
        agents={generalFormData?.users?.value?.agents}
      />

      <Spacer top='3rem' />

      <Table
        columns={["4rem", "27rem", "16rem", "21rem", "27rem", "21rem", "2rem"]}
        headers={headers}
        pagination={{ ...pagination, onChange: onChangePageHandler }}
        row={row}
        list={uPharmacies}
        isLoading={!uPharmacies}
      />
    </>
  );
};

export default PharmacyTable;
