import { useHistory, useParams } from "react-router-dom";

import { Text, Spacer, Radio, TextArea, Button, Icon, Modal } from "../common";
import { ROUTE_FAQ } from "../../AppRoutes";
import useFormData, {
  useFormDataTextRequired,
} from "../../shared/hooks/useFormData";
import useDispatchError from "../../shared/hooks/useDispatchError";
import APIService, { API_DATA_FAQS } from "../../services/api.service";
import { useState } from "react";

const FAQModal = ({ isNew, task }) => {
  const [setError] = useDispatchError();
  const history = useHistory();
  const params = useParams();
  const { id } = params;

  const [disabledActions, setDisabledActions] = useState(false);

  const [formData, onChangeData, onSubmit] = useFormData({
    id: id,
    dataName: API_DATA_FAQS,
  });
  const onChangeInput = (value, valueName) => {
    onChangeData(value, valueName);
  };

  const onSubmitHandler = async e => {
    if (disabledActions) {
      return;
    } else {
      setDisabledActions(true);
    }

    e?.preventDefault();
    const newFaq = await onSubmit();
    if (newFaq) {
      history.push(ROUTE_FAQ);
    } else {
      setDisabledActions(false);
    }
  };

  const faqTypesOptions = [
    { label: "Общий вопрос - ответ", value: "general" },
    { label: "Контрактная сеть", value: "pharmacy" },
    { label: "Мед. представители", value: "agent" },
  ];

  const onDelete = async e => {
    e.preventDefault();
    if (!_isNew) {
      await APIService.delDataByIDHandler(API_DATA_FAQS, id, () =>
        setError(`Не удалось удалить FAQ(${id})`),
      );
    }

    history.push(ROUTE_FAQ);
  };

  return (
    <Modal
      back={() => history.push(ROUTE_FAQ)}
      title={
        isNew ? "Создать вопрос - ответ" : "Редактирование вопрос - ответ"
      }>
      <form onSubmit={onSubmitHandler}>
        <Text color='secondary' type='md'>
          {useFormDataTextRequired(
            formData?.type?.label,
            formData?.type?.required,
          )}
        </Text>
        <Spacer top='1.5rem' />
        <Radio
          value={formData?.type?.value}
          required={formData?.type?.required}
          onChange={val => onChangeInput(val, "type")}
          variants={faqTypesOptions}
        />

        <Spacer top='2.25rem' />

        <Text color='secondary' type='md'>
          {useFormDataTextRequired(
            formData?.question?.label,
            formData?.question?.required,
          )}
        </Text>
        <Spacer top='1.5rem' />
        <TextArea
          value={formData?.question?.value}
          required={formData?.question?.required}
          onChange={e => onChangeInput(e.target.value, "question")}
          rowsMin='1'
          placeholder='Напишите вопрос'
          style={{ width: "100%" }}
        />

        <Spacer top='2.25rem' />

        <Text color='secondary' type='md'>
          {useFormDataTextRequired(
            formData?.answer?.label,
            formData?.answer?.required,
          )}
        </Text>
        <Spacer top='1.5rem' />
        <TextArea
          value={formData?.answer?.value}
          required={formData?.answer?.required}
          onChange={e => onChangeInput(e.target.value, "answer")}
          rowsMin='15'
          placeholder='Напишите ответ'
          style={{ width: "100%" }}
        />

        <Spacer top='4rem' />

        <div className='d-flex'>
          <Button type='outline' onClick={onDelete} disabled={disabledActions}>
            <Icon icon='remove' size='2rem' />
            <Spacer left='1.5rem' />
            <Text type='md'>Удалить</Text>
          </Button>
          <Spacer left='auto' />
          <Button type='primary' btnType='submit' disabled={disabledActions}>
            <Text type='md'>Сохранить</Text>
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default FAQModal;
