import {
  API_DATA_AGENTLINES,
  API_DATA_CITIES,
  API_DATA_COMMANDS,
  API_DATA_USERS,
  API_DATA_USERS_QUERY_MED_MEMBERS,
  API_DATA_USERS_QUERY_PHARMACIES,
} from "../../services/api.service";
import {
  useFormData_UserRoleAdmin,
  useFormData_UserRoleAgent,
  useFormData_UserRolePharmacy,
} from "../../shared/hooks/useFormData";

export const _defaultFormRoles = [
  {
    value: useFormData_UserRoleAgent,
    text: "Мед. Представитель",
  },
  {
    value: useFormData_UserRolePharmacy,
    text: "Аптека",
  },
  {
    value: useFormData_UserRoleAdmin,
    text: "Администратор",
  },
];

const __formOptionPropInit = (APIDataName, label, APIQuery = "") => ({
  APIDataName,
  APIQuery,
  label,
});

export const _formOptionsFetchProps = {
  [useFormData_UserRoleAgent]: {
    pharmacies: __formOptionPropInit(
      API_DATA_USERS,
      "Локации",
      API_DATA_USERS_QUERY_PHARMACIES,
    ),
    agentlines: __formOptionPropInit(API_DATA_AGENTLINES, "Линия Мп"),
    cities: __formOptionPropInit(API_DATA_CITIES, "Город"),
    commands: __formOptionPropInit(API_DATA_COMMANDS, "Команда"),
  },
  [useFormData_UserRolePharmacy]: {
    cities: __formOptionPropInit(API_DATA_CITIES, "Город"),
    agents: __formOptionPropInit(
      API_DATA_USERS,
      "Медицинские представители",
      API_DATA_USERS_QUERY_MED_MEMBERS,
    ),
  },
};

export const _formOptionsDefault = {
  [useFormData_UserRoleAgent]: {
    pharmacies: [],
    agentlines: [],
    cities: [],
    commands: [],
  },
  [useFormData_UserRolePharmacy]: {
    types: [
      { value: "", text: "" },
      { value: "contract", text: "Контрактная" },
      { value: "individual", text: "Индивидуальная" },
      { value: "commercial", text: "Мелкая сеть" },
    ],
    categories: [
      { text: "", value: "" },
      { text: "A", value: "A" },
      { text: "B", value: "B" },
      { text: "C", value: "C" },
    ],
    cities: [],
    agents: [],
  },
};
