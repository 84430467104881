/* eslint-disable max-params */
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import store from "../store";
import { appErrorSet, StoreAppErrorTypeLogin } from "../store/app";
import { StoreUserDataState, userLogout } from "../store/user";

export const API_URL = process.env.REACT_APP_API_DOMAIN as string;

export const getImageURL = (imgUrl: string): string => `${API_URL}${imgUrl}`;

export const API_RESPONSE_TIMEOUT = parseInt(
  process.env.REACT_APP_API_TIMEOUT as string,
);

export const API_LOGIN = process.env.REACT_APP_API_LOGIN as string;
export const API_PASS_FORGOT = process.env.REACT_APP_API_PASS_FORGOT as string;
export const API_PASS_RESET = process.env.REACT_APP_API_PASS_RESET as string;

export const API_UPLOAD = process.env.REACT_APP_API_UPLOAD as string;
export const API_REPORTS = process.env.REACT_APP_API_REPORTS as string;

export const API_UPLOAD_MAX_SIZE = parseInt(
  process.env.REACT_APP_API_UPLOAD_MAX_SIZE as string,
);
export const API_UPLOAD_DATA_MAX_SIZE = parseInt(
  process.env.REACT_APP_API_UPLOAD_DATA_MAX_SIZE as string,
);

export const API_DATA_TASKS = process.env.REACT_APP_API_TASKS as string;
export const API_DATA_TASKS_TYPES = process.env
  .REACT_APP_API_TASKS_TYPES as string;

export const API_DATA_PROMO = process.env.REACT_APP_API_PROMO as string;
export const API_DATA_PROMO_QUERY_AGENTS = process.env
  .REACT_APP_API_PROMO_QUERY_AGENTS as string;
export const API_DATA_PROMO_QUERY_PHARMACIES = process.env
  .REACT_APP_API_PROMO_QUERY_PHARMACIES as string;
export const API_DATA_PROMO_PRODUCTS = process.env
  .REACT_APP_API_PROMO_PRODUCTS as string;

export const API_DATA_AREAS = process.env.REACT_APP_API_AREAS as string;
export const API_DATA_BRANDS = process.env.REACT_APP_API_BRANDS as string;

export const API_DATA_INVOICES = process.env.REACT_APP_API_INVOICES as string;
export const API_DATA_APPROVEMENT_BUY = process.env
  .REACT_APP_API_APPROVEMENT_BUY as string;
export const API_DATA_APPROVEMENT_BUY_REPORT = process.env
  .REACT_APP_API_APPROVEMENT_BUY_REPORT as string;
export const API_DATA_APPROVEMENT_SELL = process.env
  .REACT_APP_API_APPROVEMENT_SELL as string;
export const API_DATA_APPROVEMENT_SELL_REPORT = process.env
  .REACT_APP_API_APPROVEMENT_SELL_REPORT as string;
export const API_DATA_APPROVEMENT_MERCH = process.env
  .REACT_APP_API_APPROVEMENT_MERCH as string;
export const API_DATA_APPROVEMENT_STUDY = process.env
  .REACT_APP_API_APPROVEMENT_STUDY as string;

export const API_DATA_USERS = process.env.REACT_APP_API_USERS as string;
export const API_DATA_USERS_REPORT = process.env
  .REACT_APP_API_USERS_REPORT as string;
export const API_DATA_USERS_REPORT_PHARMACIES = process.env
  .REACT_APP_API_USERS_REPORT_PHARMACIES as string;
export const API_DATA_ENTITIES = process.env.REACT_APP_API_ENTITIES as string;
export const API_DATA_USERS_QUERY_MED_MEMBERS = process.env
  .REACT_APP_API_USERS_QUERY_MED_MEMBERS as string;
export const API_DATA_USERS_QUERY_PHARMACIES = process.env
  .REACT_APP_API_USERS_QUERY_PHARMACIES as string;
export const API_DATA_USERS_QUERY_ADMINS = process.env
  .REACT_APP_API_USERS_QUERY_ADMINS as string;

export const API_DATA_AGENTS = process.env.REACT_APP_API_AGENTS as string;
export const API_DATA_AGENTLINES = process.env
  .REACT_APP_API_AGENTLINES as string;
export const API_DATA_COMMANDS = process.env.REACT_APP_API_COMMANDS as string;
export const API_DATA_CITIES = process.env.REACT_APP_API_CITIES as string;

export const API_DATA_ROLES = process.env.REACT_APP_API_ROLES as string;
export const API_DATA_DISTRIBUTORS = process.env
  .REACT_APP_API_DISTRIBUTORS as string;

export const API_DATA_PHARMACIES = process.env
  .REACT_APP_API_PHARMACIES as string;

export const API_DATA_SHOP_PRODUCTS = process.env
  .REACT_APP_API_SHOP_PRODUCTS as string;
export const API_DATA_SHOP_GROUPS = process.env
  .REACT_APP_API_SHOP_GROUPS as string;
export const API_DATA_SHOP_ORDERS = process.env
  .REACT_APP_API_SHOP_ORDERS as string;
export const API_DATA_SHOP_ORDERS_REPORT = process.env
  .REACT_APP_API_SHOP_ORDERS_REPORT as string;
export const API_DATA_SHOP_CATEGORIES = process.env
  .REACT_APP_API_SHOP_CATEGORIES as string;

export const API_DATA_NOTIFICATIONS = process.env
  .REACT_APP_API_NOTIFICATIONS as string;
export const API_DATA_NEWS = process.env.REACT_APP_API_NEWS as string;
export const API_DATA_FAQS = process.env.REACT_APP_API_FAQ as string;

export const LOCAL_USER_TOKEN_NAME = "user";
const localUser = localStorage.getItem(LOCAL_USER_TOKEN_NAME);
const isLocalUser = !!localUser;
const localUserData = JSON.parse(isLocalUser ? (localUser as string) : "{}");

export const api = axios.create({
  baseURL: API_URL,
  headers:
    isLocalUser && localUserData.jwt
      ? { Authorization: `Bearer ${localUserData.jwt}` }
      : {},
  timeout: API_RESPONSE_TIMEOUT,
});

api.interceptors.response.use(
  (resp: AxiosResponse) => {
    const _resp = resp as AxiosResponse & { name?: string };
    if (_resp?.name === "Error") {
      store.dispatch(
        appErrorSet(
          `Какая-то ошибка ответа от сервера(${resp.status} ${resp.statusText}).`,
        ),
      );
    }
    return resp;
  },
  (err: AxiosError) => {
    const errData = err?.response?.data;
    if (401 === errData?.statusCode && errData?.error === "Unauthorized") {
      store.dispatch(
        appErrorSet(
          "Время сессии истекло или Ваш аккаунт был удален. Пожалуйста попробуйте войти в систему снова.",
          StoreAppErrorTypeLogin,
        ),
      );
      store.dispatch(userLogout());
    }
    throw err;
  },
);

export const updateAPIToken = (jwt: string): void => {
  api.defaults.headers = {
    ...api.defaults.headers,
    Authorization: `Bearer ${jwt}`,
  };
};
export const clearAPIToken = (): void => {
  if (api.defaults.headers?.Authorization) {
    delete api.defaults.headers?.Authorization;
  }
};

export type APIMethodData = Record<string, unknown> | any;
export type APIHandlerError = (err: AxiosError) => void | undefined;
export type APIHandlerResp<R = any> = (resp: AxiosResponse<R>) => R;

const _defaultAPIHandlerResp: APIHandlerResp = (resp: AxiosResponse) =>
  resp.data;

export interface APILoginResponse {
  status: boolean;
  error?: Array<Record<string, unknown>>;
  data?: {
    jwt: string;
    user: StoreUserDataState;
  };
}

export const API_LIMIT_INPUT = parseInt(
  process.env.REACT_APP_API_LIMIT_INPUT as string,
);

/**
 * Service to accessing data that will add a header with a JWT token automatically
 * when requesting authorized resources from API.
 */
class APIService {
  /**
   * Makes a GET request call.
   *
   * @param {string} url an any path
   * @param {AxiosRequestConfig} config an axios config
   *
   * @returns {Promise<AxiosResponse>}
   */
  static _getAPIRequest(
    url: string,
    config: AxiosRequestConfig = {},
  ): Promise<AxiosResponse> {
    return api.get(url, config);
  }

  /**
   * Makes a POST request call.
   *
   * @param {string} url an any path
   * @param {APIMethodData} data an any data as object
   * @param {AxiosRequestConfig} config an axios config
   *
   * @returns {Promise<AxiosResponse>}
   */
  static _postAPIRequest(
    url: string,
    data: APIMethodData = {},
    config: AxiosRequestConfig = {},
  ): Promise<AxiosResponse> {
    return api.post(url, data, config);
  }

  /**
   * Makes a PUT request call.
   *
   * @param {string} url an any path
   * @param {APIMethodData} data an any data
   * @param {AxiosRequestConfig} config an axios config
   *
   * @returns {Promise<AxiosResponse>}
   */
  static _putAPIRequest(
    url: string,
    data: APIMethodData = {},
    config: AxiosRequestConfig = {},
  ): Promise<AxiosResponse> {
    return api.put(url, data, config);
  }

  /**
   * Makes a PUT request call.
   *
   * @param {string} url an any path
   * @param {AxiosRequestConfig} config an axios config
   *
   * @returns {Promise<AxiosResponse>}
   */
  static _deleteAPIRequest(
    url: string,
    config: AxiosRequestConfig = {},
  ): Promise<AxiosResponse> {
    return api.delete(url, config);
  }

  /**
   * Login method
   *
   * @param {string} login user name \ email \ login
   * @param {string} password password
   *
   * @returns {Promise<AxiosResponse>}
   */
  static login(
    login: string,
    password: string,
  ): Promise<AxiosResponse<APILoginResponse>> {
    const data = { identifier: login, password };

    return this._postAPIRequest(API_LOGIN, { ...data });
  }

  /**
   * Get any data from API
   */
  static getData(
    APIDataName: string,
    start = 0,
    limit = 100,
    query = "",
  ): Promise<AxiosResponse> {
    return this._getAPIRequest(
      `${APIDataName}?_start=${start}&_limit=${limit}${
        query?.length ? `&${query}` : ""
      }`,
    );
  }
  /**
   * Get count of an any data from API
   */
  static getDataCount(
    APIDataName: string,
    query = "",
  ): Promise<AxiosResponse<number>> {
    return this._getAPIRequest(
      `${APIDataName}/count${query?.length ? `?${query}` : ""}`,
    );
  }
  /**
   * Get any data from API by id
   */
  static getDataByID(
    APIDataName: string,
    id: number | string,
  ): Promise<AxiosResponse> {
    return this._getAPIRequest(`${APIDataName}/${id}`);
  }

  /**
   * Post any data with any data
   */
  static postData(
    APIDataName: string,
    data: APIMethodData,
  ): Promise<AxiosResponse> {
    return this._postAPIRequest(APIDataName, data);
  }

  /**
   * Upload a file
   */
  static postUpload(file: File): Promise<AxiosResponse> {
    const formData = new FormData();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    formData.append("files", file);

    return this._postAPIRequest(API_UPLOAD, formData, config);
  }

  /**
   * Put any data to any data by ID
   */
  static putDataByID(
    APIDataName: string,
    id: number | string,
    anyData: APIMethodData,
  ): Promise<AxiosResponse> {
    return this._putAPIRequest(`${APIDataName}/${id}`, anyData);
  }

  /**
   * Delete any data by ID
   */
  static delDataByID(
    APIDataName: string,
    id: number | string,
  ): Promise<AxiosResponse> {
    return this._deleteAPIRequest(`${APIDataName}/${id}`);
  }

  /**
   * --------------------------
   * MAKE REQUEST AND HANDLE IT
   * --------------------------
   */

  static getDataCountHandler(
    APIDataName: string,
    errHandler: APIHandlerError,
    query = "",
    respHandler: APIHandlerResp = _defaultAPIHandlerResp,
  ) {
    return this.getDataCount(APIDataName, query).then(respHandler, errHandler);
  }
  static getDataHandler(
    APIDataName: string,
    errHandler: APIHandlerError,
    start = 0,
    limit = 100,
    query = "",
    respHandler: APIHandlerResp = _defaultAPIHandlerResp,
  ) {
    return this.getData(APIDataName, start, limit, query).then(
      respHandler,
      errHandler,
    );
  }
  static getDataByIDHandler(
    APIDataName: string,
    id: number | string,
    errHandler: APIHandlerError,
    respHandler: APIHandlerResp = _defaultAPIHandlerResp,
  ) {
    return this.getDataByID(APIDataName, id).then(respHandler, errHandler);
  }

  static postDataHandler(
    APIDataName: string,
    data: APIMethodData,
    errHandler: APIHandlerError,
    respHandler: APIHandlerResp = _defaultAPIHandlerResp,
  ) {
    return this.postData(APIDataName, data).then(respHandler, errHandler);
  }

  static putDataByIDHandler(
    APIDataName: string,
    id: number | string,
    anyData: APIMethodData,
    errHandler: APIHandlerError,
    respHandler: APIHandlerResp = _defaultAPIHandlerResp,
  ) {
    return this.putDataByID(APIDataName, id, anyData).then(
      respHandler,
      errHandler,
    );
  }

  static delDataByIDHandler(
    APIDataName: string,
    id: number | string,
    errHandler: APIHandlerError,
    respHandler: APIHandlerResp = _defaultAPIHandlerResp,
  ) {
    return this.delDataByID(APIDataName, id).then(respHandler, errHandler);
  }
}

export default APIService;
