import React from 'react';
import Menu from './Menu';

const Layout = ({children, topSlot}) => {
    
    return (
        <div className="page-layout">
            <Menu />
            <div className="page-content">
                <div className="page-content-top">{ topSlot }</div>
                <div className="page-content-box">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Layout;