import React from "react";
import {
  Table,
  Text,
  Icon,
  Spacer,
  Select,
  Button,
  DatePicker,
} from "../common";
import useAPIData from "../../shared/hooks/useAPIData";
import { tasksStatuses } from "../tasks/taskProps";

const _types = {
  agent: "Мед. представитель",
  pharmacy: "Аптека",
};

const TableChildRowStudy = ({ taskId }) => {
  if (!taskId?.length) return;

  const _getDefaultQueries = () => {
    const _default = `task.id=${taskId}`;

    return _default;
  };

  const [tasks, pagination, , , onChangePage] = useAPIData(
    "tasktrainingusers",
    _getDefaultQueries(),
  );

  const _pagination = {
    ...pagination,
    onChange: page => onChangePage(page, _getDefaultQueries()),
  };
  const headers = [
    { title: "Имя", isSortable: false },
    { title: "Тип", isSortable: false },
    { title: "Телефон", isSortable: false },
    { title: "Статус", isSortable: false },
    { title: "Время", isSortable: false },
    { title: "Результат", isSortable: false },
    { title: "Заработано баллов", isSortable: false },
  ];

  const _statuses = {
    new: { text: "Новое", icon: "new-order" },
    done: { text: "Сдал", icon: "done-outline-green" },
    fail: { text: "Не сдал", icon: "close-outline" },
    process: { text: "В процессе", icon: "primary-forward" },
  };

  const row = task => {
    let _username;
    if (task?.user?.firstname?.length && task?.user?.lastname?.length) {
      _username = task?.user?.firstname + " " + task?.user?.lastname;
    }

    let _timespend_str;
    if (task?.endtime && task?.starttime) {
      const _endtime = new Date(task?.endtime);
      const __endtime_minutes =
        _endtime.getHours() * 60 + _endtime.getMinutes();
      const _starttime = new Date(task?.starttime);
      const __starttime_minutes =
        _starttime.getHours() * 60 + _starttime.getMinutes();

      let _timespend = parseInt(__endtime_minutes - __starttime_minutes);
      if (0 >= _timespend) {
        _timespend = 1;
      }

      const _timespend_hh = _timespend / 60;
      const _timespend_mm = parseInt((_timespend_hh % 1) * 100);
      _timespend_str = `${
        1.0 <= _timespend_hh ? `${parseInt(_timespend_hh)} часов ` : ""
      }${_timespend_mm} минут`;
    }

    return (
      <React.Fragment>
        <div className='table-row__cell'>
          <Text color={!!_username ? "" : "grey"}>
            {_username ?? "Неизвестно"}
          </Text>
        </div>
        <div className='table-row__cell'>
          <Text>
            {task?.user?.role?.name?.length
              ? _types[task?.user?.role?.name]
              : "Неизвестно"}
          </Text>
        </div>
        <div className='table-row__cell'>
          <Text>
            {task?.user?.username?.length ? (
              <a title={task?.user?.username}>
                {task?.user?.username?.slice(0, 14)}
              </a>
            ) : (
              "Неизвестно"
            )}
          </Text>
        </div>
        <div className='table-row__cell status-success'>
          <Icon icon={_statuses[task?.status]?.icon} size='2rem' />
          <Spacer left='0.5rem' />
          <Text>{_statuses[task?.status]?.text}</Text>
        </div>
        <div className='table-row__cell'>
          <Text color={_timespend_str ? "" : "grey"}>
            {_timespend_str ?? "Неизвестно"}
          </Text>
        </div>
        <div className='table-row__cell'>
          <Text>{`${task?.rightanswers ?? "0"}/${
            task?.allquestions ?? "0"
          }`}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{task?.resultscores ?? "Неизвестно"}</Text>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className='study-child-table'>
      <Table
        columns={[
          "25rem",
          "18rem",
          "20rem",
          "17rem",
          "17rem",
          "17rem",
          "20.25rem",
        ]}
        pagination={_pagination}
        headers={headers}
        row={row}
        list={tasks}
        isLoading={!tasks}></Table>
    </div>
  );
};

export default TableChildRowStudy;
