const ICONS = {
    'mail': (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.2677 9.06116L13.0023 12.4954C12.1951 13.1283 11.0635 13.1283 10.2563 12.4954L5.95423 9.06116" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6.88787 3.5H16.3158C17.6752 3.51525 18.969 4.08993 19.896 5.0902C20.823 6.09048 21.3022 7.42903 21.222 8.79412V15.322C21.3022 16.6871 20.823 18.0256 19.896 19.0259C18.969 20.0262 17.6752 20.6009 16.3158 20.6161H6.88787C3.96796 20.6161 2 18.2407 2 15.322V8.79412C2 5.87545 3.96796 3.5 6.88787 3.5Z" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    'lock': (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.4234 9.4478V7.3008C16.4234 4.7878 14.3854 2.7498 11.8724 2.7498C9.3594 2.7388 7.3134 4.7668 7.3024 7.2808V7.3008V9.4478" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M15.6832 21.2496H8.0422C5.9482 21.2496 4.2502 19.5526 4.2502 17.4576V13.1686C4.2502 11.0736 5.9482 9.37659 8.0422 9.37659H15.6832C17.7772 9.37659 19.4752 11.0736 19.4752 13.1686V17.4576C19.4752 19.5526 17.7772 21.2496 15.6832 21.2496Z" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.8629 14.2028V16.4238" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    'menu': (
        <svg viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.5713 2.16666H16.2934C17.4197 2.16666 18.3334 3.08824 18.3334 4.22514V6.97008C18.3334 8.10612 17.4197 9.02856 16.2934 9.02856H13.5713C12.4442 9.02856 11.5305 8.10612 11.5305 6.97008V4.22514C11.5305 3.08824 12.4442 2.16666 13.5713 2.16666Z" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M3.70745 2.16666H6.42876C7.55588 2.16666 8.46953 3.08824 8.46953 4.22514V6.97008C8.46953 8.10612 7.55588 9.02856 6.42876 9.02856H3.70745C2.58034 9.02856 1.66669 8.10612 1.66669 6.97008V4.22514C1.66669 3.08824 2.58034 2.16666 3.70745 2.16666Z" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M3.70745 11.9714H6.42876C7.55588 11.9714 8.46953 12.893 8.46953 14.0308V16.7749C8.46953 17.9118 7.55588 18.8333 6.42876 18.8333H3.70745C2.58034 18.8333 1.66669 17.9118 1.66669 16.7749V14.0308C1.66669 12.893 2.58034 11.9714 3.70745 11.9714Z" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M13.5713 11.9714H16.2934C17.4197 11.9714 18.3334 12.893 18.3334 14.0308V16.7749C18.3334 17.9118 17.4197 18.8333 16.2934 18.8333H13.5713C12.4442 18.8333 11.5305 17.9118 11.5305 16.7749V14.0308C11.5305 12.893 12.4442 11.9714 13.5713 11.9714Z" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    'document': (
        <svg viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12.2806 2.80161H6.73644C5.02061 2.80161 3.54144 4.19244 3.54144 5.90911V14.8366C3.54144 16.6499 4.92394 18.0958 6.73644 18.0958H13.3939C15.1106 18.0958 16.5014 16.5541 16.5014 14.8366V7.19827L12.2806 2.80161Z" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.0617 2.79187V5.21604C12.0617 6.39937 13.0192 7.35937 14.2017 7.36187C15.2992 7.36437 16.4217 7.3652 16.4975 7.3602" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.9034 13.4649H7.40588" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.2021 9.33802H7.40546" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    'done-outline': (
        <svg viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.612 2.79187H6.38783C3.87033 2.79187 2.29199 4.57437 2.29199 7.09687V13.9035C2.29199 16.426 3.86283 18.2085 6.38783 18.2085H13.6112C16.137 18.2085 17.7087 16.426 17.7087 13.9035V7.09687C17.7087 4.57437 16.137 2.79187 13.612 2.79187Z" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.03326 10.5002L9.0116 12.4777L12.9666 8.52271" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    'done-outline-primary': (
        <svg viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.612 2.79187H6.38783C3.87033 2.79187 2.29199 4.57437 2.29199 7.09687V13.9035C2.29199 16.426 3.86283 18.2085 6.38783 18.2085H13.6112C16.137 18.2085 17.7087 16.426 17.7087 13.9035V7.09687C17.7087 4.57437 16.137 2.79187 13.612 2.79187Z" stroke="#65A5DC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.03326 10.5002L9.0116 12.4777L12.9666 8.52271" stroke="#65A5DC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    'done-outline-green': (
        <svg viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.612 2.79187H6.38783C3.87033 2.79187 2.29199 4.57437 2.29199 7.09687V13.9035C2.29199 16.426 3.86283 18.2085 6.38783 18.2085H13.6112C16.137 18.2085 17.7087 16.426 17.7087 13.9035V7.09687C17.7087 4.57437 16.137 2.79187 13.612 2.79187Z" stroke="#42D58E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.03326 10.5002L9.0116 12.4777L12.9666 8.52271" stroke="#42D58E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    'user': (
        <svg viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="9.64911" cy="6.56504" rx="3.98169" ry="3.98169" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M3.33333 16.0844C3.33226 15.8045 3.39486 15.5281 3.51639 15.2759C3.89778 14.5131 4.9733 14.1089 5.86574 13.9258C6.50938 13.7884 7.1619 13.6967 7.81845 13.6512C9.03401 13.5444 10.2566 13.5444 11.4722 13.6512C12.1286 13.6972 12.7811 13.789 13.4249 13.9258C14.3173 14.1089 15.3928 14.475 15.7742 15.2759C16.0186 15.7899 16.0186 16.3866 15.7742 16.9006C15.3928 17.7015 14.3173 18.0677 13.4249 18.2431C12.782 18.3861 12.1292 18.4805 11.4722 18.5253C10.4828 18.6092 9.4888 18.6245 8.49732 18.5711C8.26849 18.5711 8.04729 18.5711 7.81845 18.5253C7.16384 18.481 6.51358 18.3867 5.87337 18.2431C4.9733 18.0677 3.90541 17.7015 3.51639 16.9006C3.39548 16.6455 3.33294 16.3667 3.33333 16.0844Z" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    'shop': (
        <svg viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.18525 17.0169C6.53692 17.0169 6.82275 17.3028 6.82275 17.6544C6.82275 18.0061 6.53692 18.2911 6.18525 18.2911C5.83358 18.2911 5.54858 18.0061 5.54858 17.6544C5.54858 17.3028 5.83358 17.0169 6.18525 17.0169Z" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M15.5622 17.0169C15.9139 17.0169 16.1997 17.3028 16.1997 17.6544C16.1997 18.0061 15.9139 18.2911 15.5622 18.2911C15.2106 18.2911 14.9247 18.0061 14.9247 17.6544C14.9247 17.3028 15.2106 17.0169 15.5622 17.0169Z" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2.29156 3.20834L4.0249 3.50834L4.8274 13.0692C4.8924 13.8483 5.54323 14.4467 6.3249 14.4467H15.4182C16.1649 14.4467 16.7982 13.8983 16.9057 13.1583L17.6966 7.69334C17.7941 7.01918 17.2716 6.41584 16.5907 6.41584H4.30323" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.7712 9.49586H14.082" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    'data-chart': (
        <svg viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.14286 9.00143V14.7182" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.0318 6.26598V14.7182" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.8572 12.0224V14.7182" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M13.9048 2.16667H6.09526C3.37304 2.16667 1.66669 4.09341 1.66669 6.82097V14.179C1.66669 16.9066 3.3651 18.8333 6.09526 18.8333H13.9048C16.6349 18.8333 18.3334 16.9066 18.3334 14.179V6.82097C18.3334 4.09341 16.6349 2.16667 13.9048 2.16667Z" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    'bell': (
        <svg viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.91737 11.9893V11.8068C2.94415 11.2669 3.11719 10.7437 3.41865 10.2913C3.92044 9.74787 4.26394 9.08191 4.41312 8.3633C4.41312 7.80791 4.41312 7.24458 4.46163 6.68918C4.71226 4.01534 7.35609 2.16666 9.96757 2.16666H10.0322C12.6437 2.16666 15.2876 4.01534 15.5463 6.68918C15.5948 7.24458 15.5463 7.80791 15.5867 8.3633C15.7379 9.08358 16.081 9.75161 16.5812 10.2993C16.8849 10.7477 17.0582 11.2689 17.0824 11.8068V11.9813C17.1005 12.7067 16.8507 13.414 16.379 13.9728C15.7558 14.6262 14.9101 15.0327 14.002 15.1153C11.3392 15.401 8.65255 15.401 5.98971 15.1153C5.08264 15.0291 4.23815 14.6232 3.61269 13.9728C3.14835 13.4136 2.90189 12.7105 2.91737 11.9893Z" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.96246 17.8765C8.37853 18.3987 8.98951 18.7367 9.66021 18.8156C10.3309 18.8945 11.006 18.7079 11.5361 18.297C11.6991 18.1755 11.8458 18.0342 11.9727 17.8765" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    'news': (
        <svg viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.0968 14.0195H7.08014" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.0968 10.5307H7.08014" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.37609 7.05006H7.08026" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M13.2572 2.79147C13.2572 2.79147 6.85965 2.79481 6.84965 2.79481C4.54965 2.80897 3.12549 4.32231 3.12549 6.63064V14.294C3.12549 16.614 4.56049 18.1331 6.88049 18.1331C6.88049 18.1331 13.2772 18.1306 13.288 18.1306C15.588 18.1165 17.013 16.6023 17.013 14.294V6.63064C17.013 4.31064 15.5772 2.79147 13.2572 2.79147Z" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    'info': (
        <svg viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.99162 13.6635V9.98096" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.9916 7.33695H9.99993" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M13.6122 2.79187H6.38801C3.87051 2.79187 2.29218 4.57437 2.29218 7.09687V13.9035C2.29218 16.426 3.86301 18.2085 6.38801 18.2085H13.6113C16.1372 18.2085 17.7088 16.426 17.7088 13.9035V7.09687C17.7088 4.57437 16.1372 2.79187 13.6122 2.79187Z" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    'arrow-right': (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.52994 15.0528L12.5833 11.9995L9.52994 8.93951L10.4699 7.99951L14.4699 11.9995L10.4699 15.9995L9.52994 15.0528Z" fill="#A6A6A6" />
        </svg>
    ),
    'arrow-left': (
        <svg style={{ transform: 'rotate(180deg)' }} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.52994 15.0528L12.5833 11.9995L9.52994 8.93951L10.4699 7.99951L14.4699 11.9995L10.4699 15.9995L9.52994 15.0528Z" fill="#A6A6A6" />
        </svg>
    ),
    'arrow-down': (
        <svg style={{ transform: 'rotate(90deg)' }} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.52994 15.0528L12.5833 11.9995L9.52994 8.93951L10.4699 7.99951L14.4699 11.9995L10.4699 15.9995L9.52994 15.0528Z" fill="#A6A6A6" />
        </svg>
    ),
    'primary-forward': (
        <svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.15356 13.0101L2.15356 10.9899L18.2079 10.9899L12.6754 5.43434L14.1038 4L22.0706 12L14.1038 20L12.6754 18.5657L18.2079 13.0101L2.15356 13.0101Z" fill="#65A5DC" />
        </svg>
    ),
    'add': (
        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 8.85714H8.85714V14H7.14286V8.85714H2V7.14286H7.14286V2H8.85714V7.14286H14V8.85714Z" fill="white" />
        </svg>
    ),
    'search': (
        <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="7.84439" cy="8.30622" r="5.99237" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.0122 12.7853L14.3616 15.1285" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    'task-active': (
        <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7 1.16663C10.2217 1.16663 12.8333 3.7783 12.8333 6.99996C12.8333 10.2216 10.2217 12.8333 7 12.8333C3.77834 12.8333 1.16666 10.2216 1.16666 6.99996C1.16666 3.7783 3.77834 1.16663 7 1.16663Z" stroke="#65A5DC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.77922 7.27232C8.35397 7.73082 7.19664 8.50665 6.60455 8.75573C6.51005 8.7954 6.26914 8.8794 6.21722 8.88057C6.10697 8.88407 6.0008 8.82223 5.94947 8.72307C5.92964 8.68457 5.8713 8.43315 5.85264 8.32115C5.79722 7.98048 5.76864 7.4514 5.76922 6.9194C5.76864 6.36115 5.79955 5.80698 5.86139 5.46982C5.87772 5.37882 5.92555 5.1694 5.93955 5.13557C5.9658 5.07257 6.01364 5.02298 6.07139 4.99207C6.11572 4.96815 6.16647 4.95532 6.21722 4.95707C6.26914 4.95823 6.4803 5.03232 6.55264 5.0609C7.12314 5.28257 8.33005 6.0864 8.77339 6.5589C8.81305 6.60148 8.92214 6.71582 8.94022 6.73915C8.98164 6.79165 9.00205 6.85523 9.00205 6.9194C9.00205 6.9789 8.98397 7.03957 8.94664 7.09032C8.92739 7.11657 8.81597 7.23323 8.77922 7.27232Z" stroke="#65A5DC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    'task-done': (
        <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.0046 2.98876C11.2595 3.07801 11.4298 3.31835 11.4298 3.58843V7.53935C11.4298 8.6436 11.0285 9.69768 10.3197 10.5143C9.96333 10.9256 9.51241 11.2458 9.0335 11.5048L6.958 12.626L4.879 11.5043C4.3995 11.2453 3.948 10.9256 3.591 10.5138C2.88166 9.6971 2.47916 8.64243 2.47916 7.53701V3.58843C2.47916 3.31835 2.6495 3.07801 2.90441 2.98876L6.74391 1.63951C6.88041 1.59168 7.02916 1.59168 7.16508 1.63951L11.0046 2.98876Z" stroke="#42D58E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.43813 6.95181L6.54179 8.05606L8.81563 5.78223" stroke="#42D58E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    'task-draft': (
        <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.99143 11.6072H11.7113" stroke="#EE5454" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M7.49869 2.89085C7.9286 2.3431 8.62335 2.37168 9.17169 2.8016L9.98252 3.43744C10.5309 3.86735 10.7251 4.5341 10.2952 5.08302L5.45994 11.2518C5.29835 11.4583 5.0516 11.5802 4.7891 11.5831L2.92419 11.607L2.50186 9.78994C2.44236 9.53502 2.50186 9.26669 2.66344 9.0596L7.49869 2.89085Z" stroke="#EE5454" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.59318 4.04602L9.38968 6.23819" stroke="#EE5454" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    'download': (
        <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.82429 2.3418H5.38962C4.01695 2.3418 2.83362 3.45446 2.83362 4.8278V11.9858C2.83362 13.4365 3.93895 14.5771 5.38962 14.5771H10.715C12.0883 14.5771 13.2016 13.3591 13.2016 11.9858V5.85913L9.82429 2.3418Z" stroke="#65A5DC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.64917 2.33398V4.27332C9.64917 5.21998 10.4152 5.98798 11.3618 5.98998C12.2392 5.99198 13.1372 5.99265 13.1978 5.98865" stroke="#65A5DC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.76046 11.1769V6.79492" stroke="#65A5DC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.86823 9.27637L7.76023 11.177L9.65289 9.27637" stroke="#65A5DC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    'download_white': (
        <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.82429 2.3418H5.38962C4.01695 2.3418 2.83362 3.45446 2.83362 4.8278V11.9858C2.83362 13.4365 3.93895 14.5771 5.38962 14.5771H10.715C12.0883 14.5771 13.2016 13.3591 13.2016 11.9858V5.85913L9.82429 2.3418Z" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.64917 2.33398V4.27332C9.64917 5.21998 10.4152 5.98798 11.3618 5.98998C12.2392 5.99198 13.1372 5.99265 13.1978 5.98865" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.76046 11.1769V6.79492" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.86823 9.27637L7.76023 11.177L9.65289 9.27637" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    'close-outline': (
        <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.39735 5.59766L5.60202 8.39299" stroke="#EE5454" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.39825 8.39395L5.60059 5.5957" stroke="#EE5454" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M9.52846 1.60449H4.47154C2.70929 1.60449 1.60446 2.85224 1.60446 4.61799V9.38266C1.60446 11.1484 2.70404 12.3962 4.47154 12.3962H9.52788C11.296 12.3962 12.3961 11.1484 12.3961 9.38266V4.61799C12.3961 2.85224 11.296 1.60449 9.52846 1.60449Z" stroke="#EE5454" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    'plus-outline': (
        <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.99997 6.24512V11.7399" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.75 8.99255H6.25" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.5143 1.5H5.48571C3.03571 1.5 1.5 3.23406 1.5 5.68887V12.3111C1.5 14.7659 3.02857 16.5 5.48571 16.5H12.5143C14.9714 16.5 16.5 14.7659 16.5 12.3111V5.68887C16.5 3.23406 14.9714 1.5 12.5143 1.5Z" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    'plus-outline_blue': (
        <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.99997 6.24512V11.7399" stroke="#65A5DC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.75 8.99255H6.25" stroke="#65A5DC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.5143 1.5H5.48571C3.03571 1.5 1.5 3.23406 1.5 5.68887V12.3111C1.5 14.7659 3.02857 16.5 5.48571 16.5H12.5143C14.9714 16.5 16.5 14.7659 16.5 12.3111V5.68887C16.5 3.23406 14.9714 1.5 12.5143 1.5Z" stroke="#65A5DC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    'upload': (
        <svg viewBox="0 0 40 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.2037 29.5858L20.2037 6.5072" stroke="#253872" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M25.0636 23.9744L20.2036 29.5864L15.3436 23.9744" stroke="#253872" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M27.925 15.5786H29.48C32.8717 15.5786 35.62 18.7392 35.62 22.6415V32.0025C35.62 35.8934 32.8783 39.0463 29.495 39.0463H10.9283C7.53667 39.0463 4.78666 35.8838 4.78666 31.9834L4.78666 22.6204C4.78666 18.7315 7.53 15.5786 10.9117 15.5786H12.4817" stroke="#253872" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    'close': (
        <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.001 2.00637L9.99368 0.999023L6.00002 4.99268L2.00637 0.999023L0.999023 2.00637L4.99268 6.00002L0.999023 9.99368L2.00637 11.001L6.00002 7.00737L9.99368 11.001L11.001 9.99368L7.00737 6.00002L11.001 2.00637Z" fill="#6D6D6D" />
        </svg>
    ),
    'remove': (
        <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.8833 6.8125C12.8833 6.8125 12.5213 11.3025 12.3113 13.1938C12.2113 14.0972 11.6533 14.6265 10.7393 14.6432C8.99994 14.6745 7.25861 14.6765 5.51994 14.6398C4.64061 14.6218 4.09194 14.0858 3.99394 13.1985C3.78261 11.2905 3.42261 6.8125 3.42261 6.8125" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.8055 4.65999H2.50012" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.627 4.65998C11.1037 4.65998 10.653 4.28998 10.5503 3.77732L10.3883 2.96665C10.2883 2.59265 9.94968 2.33398 9.56368 2.33398H6.74168C6.35568 2.33398 6.01701 2.59265 5.91701 2.96665L5.75501 3.77732C5.65234 4.28998 5.20168 4.65998 4.67834 4.65998" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    'remove-red': (
        <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.4938 7.10156C14.4938 7.10156 14.0866 12.1528 13.8503 14.2806C13.7378 15.2968 13.1101 15.8923 12.0818 15.9111C10.1251 15.9463 8.16609 15.9486 6.21009 15.9073C5.22084 15.8871 4.60359 15.2841 4.49334 14.2858C4.25559 12.1393 3.85059 7.10156 3.85059 7.10156" stroke="#EE5454" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.5312 4.68066H2.81274" stroke="#EE5454" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.0804 4.68023C12.4917 4.68023 11.9847 4.26398 11.8692 3.68723L11.6869 2.77523C11.5744 2.35448 11.1934 2.06348 10.7592 2.06348H7.58443C7.15018 2.06348 6.76918 2.35448 6.65668 2.77523L6.47443 3.68723C6.35893 4.26398 5.85193 4.68023 5.26318 4.68023" stroke="#EE5454" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    'clock': (
        <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M14.1666 8.50016C14.1666 11.9062 11.4059 14.6668 7.99992 14.6668C4.59392 14.6668 1.83325 11.9062 1.83325 8.50016C1.83325 5.09416 4.59392 2.3335 7.99992 2.3335C11.4059 2.3335 14.1666 5.09416 14.1666 8.50016Z" stroke="#6D6D6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.2876 10.4616L7.77429 8.96229V5.73096" stroke="#6D6D6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    'calendar': (
        <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.06177 6.7698H13.9444" stroke="#6D6D6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.9614 9.37331H10.9675" stroke="#6D6D6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.0031 9.37331H8.00928" stroke="#6D6D6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.03862 9.37331H5.0448" stroke="#6D6D6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.9614 11.9641H10.9675" stroke="#6D6D6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.0031 11.9641H8.00928" stroke="#6D6D6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.03862 11.9641H5.0448" stroke="#6D6D6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.6958 1.8335V4.02735" stroke="#6D6D6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.31039 1.8335V4.02735" stroke="#6D6D6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10.8255 2.88623H5.18064C3.22285 2.88623 2 3.97685 2 5.98158V12.0147C2 14.0509 3.22285 15.1668 5.18064 15.1668H10.8193C12.7833 15.1668 14 14.0698 14 12.0651V5.98158C14.0062 3.97685 12.7895 2.88623 10.8255 2.88623Z" stroke="#6D6D6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    'edit': (
        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.13306 13.2669H13.3844" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.56991 3.30439C9.06125 2.67839 9.85525 2.71106 10.4819 3.20239L11.4086 3.92906C12.0352 4.42039 12.2572 5.18239 11.7659 5.80972L6.23991 12.8597C6.05525 13.0957 5.77325 13.2351 5.47325 13.2384L3.34191 13.2657L2.85925 11.1891C2.79125 10.8977 2.85925 10.5911 3.04391 10.3544L8.56991 3.30439Z" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.53503 4.625L10.731 7.13033" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    'edit-square': (
        <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.66153 2.35938H5.16886C3.11886 2.35938 1.83353 3.81071 1.83353 5.86538V11.408C1.83353 13.4627 3.11286 14.914 5.16886 14.914H11.0515C13.1082 14.914 14.3875 13.4627 14.3875 11.408V8.72271" stroke="#65A5DC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M5.8852 7.78098L10.8672 2.79898C11.4879 2.17898 12.4939 2.17898 13.1145 2.79898L13.9259 3.61032C14.5465 4.23098 14.5465 5.23765 13.9259 5.85765L8.91987 10.8637C8.64853 11.135 8.28053 11.2877 7.89653 11.2877H5.3992L5.46187 8.76765C5.4712 8.39698 5.62253 8.04365 5.8852 7.78098Z" stroke="#65A5DC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.1101 3.56836L13.1541 6.61236" stroke="#65A5DC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    'logout-red': (
        <svg viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.39058 7.01237H2.36667" stroke="#EE5454" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.68277 5.31152L9.39077 7.01252L7.68277 8.71352" stroke="#EE5454" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.12752 4.31044V3.76619C5.12752 2.5791 6.08943 1.61719 7.2771 1.61719H10.1261C11.3103 1.61719 12.2699 2.57677 12.2699 3.76094V10.2593C12.2699 11.4464 11.3074 12.4089 10.1203 12.4089H7.27068C6.0871 12.4089 5.12752 11.4487 5.12752 10.2651V9.7156" stroke="#EE5454" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    'new-order': (
        <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.41658 2.00715L8.76758 4.7249C8.83525 4.86198 8.96592 4.95765 9.11759 4.97982L12.1422 5.41673C12.2647 5.43307 12.3749 5.49723 12.4502 5.59523C12.5913 5.77898 12.5698 6.03915 12.4006 6.19723L10.2084 8.31707C10.097 8.42207 10.0474 8.57607 10.0766 8.72598L10.6016 11.7173C10.6383 11.9652 10.4692 12.1974 10.2213 12.2377C10.1186 12.2534 10.0136 12.2371 9.92025 12.191L7.22642 10.7787C7.09109 10.7052 6.92892 10.7052 6.79359 10.7787L4.07992 12.1986C3.853 12.3141 3.57534 12.2283 3.45167 12.0061C3.40442 11.9162 3.38809 11.8142 3.40442 11.7144L3.92942 8.72307C3.95567 8.57373 3.90609 8.42032 3.79759 8.31473L1.59375 6.19548C1.41409 6.01698 1.41292 5.72648 1.592 5.54682C1.59259 5.54623 1.59317 5.54507 1.59375 5.54448C1.66784 5.4774 1.75884 5.43248 1.85742 5.41498L4.88259 4.97807C5.03367 4.95415 5.16375 4.85965 5.23259 4.72257L6.58242 2.00715C6.63667 1.8969 6.73292 1.81232 6.84958 1.77382C6.96683 1.73473 7.09517 1.74407 7.20542 1.79948C7.29584 1.8444 7.36992 1.91732 7.41658 2.00715Z" stroke="#E8B384" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    'order-cancel': (
        <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.0046 2.98864C11.2596 3.07789 11.4299 3.31822 11.4299 3.58831V7.53922C11.4299 8.64347 11.028 9.69756 10.3198 10.5142C9.9628 10.9255 9.51247 11.2457 9.03355 11.5047L6.95805 12.6259L4.87905 11.5041C4.39897 11.2451 3.94805 10.9255 3.59105 10.5136C2.88172 9.69697 2.47922 8.64231 2.47922 7.53689V3.58831C2.47922 3.31822 2.64955 3.07789 2.90447 2.98864L6.74397 1.63939C6.88047 1.59156 7.02922 1.59156 7.16513 1.63939L11.0046 2.98864Z" stroke="#EE5454" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.05063 8.07693L5.85846 5.88477" stroke="#EE5454" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.85858 8.07693L8.05075 5.88477" stroke="#EE5454" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    'bookmark': (
        <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.74858 13.9741L4.30006 16.4112C3.94488 16.5958 3.50731 16.4651 3.3115 16.116V16.116C3.25485 16.0079 3.22429 15.888 3.22226 15.7659V4.96728C3.22226 2.90778 4.62958 2.08398 6.65475 2.08398H11.4122C13.3756 2.08398 14.8447 2.85286 14.8447 4.82998V15.7659C14.8447 15.9607 14.7673 16.1476 14.6295 16.2853C14.4918 16.4231 14.3049 16.5005 14.1101 16.5005C13.9859 16.4985 13.8637 16.468 13.7532 16.4112L9.27718 13.9741C9.11224 13.885 8.91351 13.885 8.74858 13.9741Z" stroke="#65A5DC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.27718 6.99169H11.7486" stroke="#65A5DC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    'users': (
        <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.1962 8.19953C14.3979 8.19953 15.3728 7.22534 15.3728 6.02359C15.3728 4.82184 14.3979 3.84766 13.1962 3.84766" stroke="#65A5DC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.1968 10.5645C14.5557 10.5892 14.9125 10.6401 15.2638 10.7191C15.7519 10.8147 16.3391 11.0148 16.5481 11.4527C16.6814 11.7332 16.6814 12.0598 16.5481 12.341C16.3397 12.7789 15.7519 12.9783 15.2638 13.0786" stroke="#65A5DC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.71749 8.19953C3.51574 8.19953 2.54086 7.22534 2.54086 6.02359C2.54086 4.82184 3.51574 3.84766 4.71749 3.84766" stroke="#65A5DC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.71684 10.5645C3.35797 10.5892 3.00115 10.6401 2.64984 10.7191C2.16172 10.8147 1.57459 11.0148 1.36628 11.4527C1.23222 11.7332 1.23222 12.0598 1.36628 12.341C1.5739 12.7789 2.16172 12.9783 2.64984 13.0786" stroke="#65A5DC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.95334 11.0312C11.3885 11.0312 13.4688 11.3998 13.4688 12.8744C13.4688 14.3484 11.4022 14.7307 8.95334 14.7307C6.51752 14.7307 4.43784 14.3622 4.43784 12.8875C4.43784 11.4128 6.50446 11.0312 8.95334 11.0312Z" stroke="#65A5DC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.95333 8.92869C7.34733 8.92869 6.05965 7.641 6.05965 6.03431C6.05965 4.42831 7.34733 3.14062 8.95333 3.14062C10.5593 3.14062 11.847 4.42831 11.847 6.03431C11.847 7.641 10.5593 8.92869 8.95333 8.92869Z" stroke="#65A5DC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    'eye': (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.1079 8.53573C10.1079 9.69973 9.16391 10.6431 7.99991 10.6431C6.83591 10.6431 5.89258 9.69973 5.89258 8.53573C5.89258 7.37107 6.83591 6.42773 7.99991 6.42773C9.16391 6.42773 10.1079 7.37107 10.1079 8.53573Z" stroke="#6D6D6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M7.99883 13.4035C10.5375 13.4035 12.8595 11.5781 14.1668 8.53548C12.8595 5.49281 10.5375 3.66748 7.99883 3.66748H8.0015C5.46283 3.66748 3.14083 5.49281 1.8335 8.53548C3.14083 11.5781 5.46283 13.4035 8.0015 13.4035H7.99883Z" stroke="#6D6D6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
};

function Icon({ icon, height = null, width = null, className = "", size, onClick = () => { } }) {

    return (
        <div
            onClick={e => {
                e.preventDefault();
                onClick();
            }}
            className={`icon-wrapper ${className ? className : ''}`}
            style={{
                display: 'flex',
                alignItems: 'center',
                height: height || size,
                width: width || size,
                minWidth: width || size
            }}>
            {ICONS[icon]}
        </div>
    );
}

export default Icon;
