import React, { useEffect, useState } from "react";
import { Table, Text, Icon, Button, Spacer, Switch } from "../../common";
import { Link, useHistory } from "react-router-dom";
import APIService, {
  API_DATA_SHOP_PRODUCTS,
  getImageURL,
} from "../../../services/api.service";
import {
  convertDate,
  convertDateTime,
} from "../../../shared/functions/convertDate";
import useAPIData from "../../../shared/hooks/useAPIData";
import {
  getShortenedDescription,
  getShortenedTitle,
} from "../../../shared/functions/getShortenedText";
import { ROUTE_SHOP_PRODUCTS_NEW } from "../../../AppRoutes";
import useDispatchError from "../../../shared/hooks/useDispatchError";
import queryStringify from "qs-stringify";

const ProductsTable = ({ searchQuery }) => {
  const [setError] = useDispatchError();
  const history = useHistory();
  const [
    products,
    pagination,
    updateProducts,
    deleteProduct,
    onChangePage,
    changeProducts,
  ] = useAPIData(API_DATA_SHOP_PRODUCTS);
  const _pagination = {
    ...pagination,
    onChange: page => onChangePage(page, _getFiltersQueries()),
  };

  const [selectedGroup, setSelectedGroup] = useState(null);
  const onSelectGroup = (id = null) => {
    if (id !== selectedGroup) {
      setSelectedGroup(id);
    }
  };
  const _getFiltersQueries = () => {
    let _filters = {};

    if (searchQuery?.length) {
      _filters["_q"] = searchQuery;
    }
    if (selectedGroup) {
      _filters["group.id"] = selectedGroup;
    }

    const qs = queryStringify(_filters);
    return qs;
  };

  useEffect(() => {
    updateProducts(_getFiltersQueries());
  }, [selectedGroup, searchQuery]);

  const onChangeStatus = async (id, newStatus) => {
    const updatedObj = await APIService.putDataByIDHandler(
      API_DATA_SHOP_PRODUCTS,
      id,
      {
        status: newStatus,
      },
      () => setError(`Не удалось изменить статус Товара(${id})`),
    );

    if (updatedObj) {
      const newProducts = products?.map(p => {
        let newProductStatus = p.status;
        let newUpdatedAt = p.updatedAt;
        if (p.id === updatedObj.id) {
          newProductStatus = newStatus;
          newUpdatedAt = updatedObj?.updatedAt;
        }

        return { ...p, status: newProductStatus, updatedAt: newUpdatedAt };
      });

      if (newProducts) {
        changeProducts(newProducts);
      }
    }
  };
  const onDelete = async id => {
    await deleteProduct(id);
  };
  const onToEdit = id => {
    history.push(`${ROUTE_SHOP_PRODUCTS_NEW}/${id}`);
  };

  const headers = [
    { title: "", isSortable: false },
    { title: "Название", isSortable: false },
    { title: "Баллы", isSortable: false },
    { title: "Стоимость (₸)", isSortable: false },
    { title: "Группа", isSortable: false },
    { title: "Статус", isSortable: false },
    { title: "Купили", isSortable: false },
    { title: "Изменения", isSortable: false },
  ];

  const row = item => (
    <React.Fragment>
      <div className='table-row__cell'>
        {item?.picture?.formats?.thumbnail?.url ? (
          <img
            src={getImageURL(item?.picture?.formats?.thumbnail?.url)}
            alt={item.name}
          />
        ) : null}
      </div>
      <div className='table-row__cell'>
        <div>
          <Text>{getShortenedTitle(item.name)}</Text>
          <Text type='caption' isThin>
            {getShortenedDescription(item.description)}
          </Text>
        </div>
      </div>
      <div className='table-row__cell'>
        <Text>{item.costscores}</Text>
      </div>
      <div className='table-row__cell'>
        <Text>{item.costmoney}</Text>
      </div>
      <div className='table-row__cell'>
        <Link
          to='#'
          onClick={e => {
            e?.preventDefault();
            onSelectGroup(item?.group?.id);
          }}>
          <Text color='primary'>
            {item?.group?.name ?? "Группа не указана"}
          </Text>
        </Link>
      </div>
      <div className='table-row__cell'>
        <Switch
          value={item.status}
          onChange={newStatus => onChangeStatus(item.id, newStatus)}
        />
      </div>
      <div className='table-row__cell'>
        <Text>{item.sold ?? 0}</Text>
      </div>
      <div className='table-row__cell'>
        <div>
          <Text isBold>{convertDate(item.updatedAt)}</Text>
          <Spacer top='0.25rem' />
          <Text>{convertDateTime(item.updatedAt)}</Text>
        </div>
      </div>
      <div className='table-row__cell'>
        <Button
          type='flat'
          onClick={e => {
            e?.preventDefault();
            onDelete(item.id);
          }}>
          <Icon icon='remove' size='2rem' />
        </Button>
        <Button
          type='flat'
          onClick={e => {
            e?.preventDefault();
            onToEdit(item.id);
          }}>
          <Icon icon='edit' size='2rem' />
        </Button>
      </div>
    </React.Fragment>
  );
  return (
    <div className='card flex-1'>
      <Table
        columns={[
          "17rem",
          "35.5rem",
          "14rem",
          "15rem",
          "19rem",
          "10rem",
          "8rem",
          "11rem",
          "12rem",
        ]}
        headers={headers}
        pagination={_pagination}
        row={row}
        list={products}
        isLoading={!products}
      />
    </div>
  );
};

export default ProductsTable;
