import React from "react";
import {
  useFormDataTextRequired,
  useFormData_TaskTypeTraining,
} from "../../../../../../shared/hooks/useFormData";
import { Text, Spacer, Input, TimePicker } from "../../../../../common";

const StudyForm = ({
  formData,
  onChangeFormData,
  formOptions,
  onChangeFormOptions,
}) => {
  const onChangeTrainingFormData = (value, valueName) =>
    onChangeFormData(value, valueName, useFormData_TaskTypeTraining);

  const onChangeHandler = (value, valueName) => {
    onChangeTrainingFormData(value, valueName);
  };
  const onChangeNumericValue = (value, valueName) => {
    let newValue = formData[valueName].value;

    if (!value?.length) {
      newValue = "";
    } else {
      const vlen = value.length;
      if (value[vlen - 1] === "." && vlen > 1 && value[vlen - 2] !== ".") {
        newValue = value;
      } else {
        const _num_pattern = /-?\d*\.?\d+/g;
        const _matchers = value?.match(_num_pattern);

        if (_matchers?.length) {
          newValue = _matchers?.join("");
        }
      }
    }

    if (newValue !== formData[valueName].value) {
      onChangeHandler(newValue, valueName);
    }
  };

  return (
    <>
      <div className='d-flex align-center'>
        <Text color='secondary' type='md'>
          {useFormDataTextRequired(
            formData?.timetopass?.label,
            formData?.timetopass?.required,
          )}
        </Text>
        <Spacer left='auto' />
        <TimePicker
          width='19rem'
          placeholder='12(ч):00(м)'
          value={formData?.timetopass?.value}
          required={formData?.timetopass?.required}
          onChange={time => onChangeHandler(time, "timetopass")}
        />
      </div>

      <Spacer top='2rem' />

      <div className='d-flex align-center'>
        <Text color='secondary' type='md'>
          {useFormDataTextRequired(
            formData?.testminimum?.label,
            formData?.testminimum?.required,
          )}
        </Text>
        <Spacer left='auto' />
        <Input
          style={{ width: "14rem" }}
          placeholder='0'
          value={formData?.testminimum?.value}
          required={formData?.testminimum?.required}
          onChange={e => onChangeNumericValue(e.target.value, "testminimum")}
        />
      </div>

      <Spacer top='2rem' />

      <div className='d-flex align-center'>
        <Text color='secondary' type='md'>
          {useFormDataTextRequired(
            formData?.scores?.label,
            formData?.scores?.required,
          )}
        </Text>
        <Spacer left='auto' />
        <Input
          style={{ width: "14rem" }}
          placeholder='0'
          value={formData?.scores?.value}
          required={formData?.scores?.required}
          onChange={e => onChangeNumericValue(e.target.value, "scores")}
        />
      </div>

      <Spacer top='3rem' />

      <Text color='secondary' type='md' isBold>
        Экстра баллы
      </Text>

      <Spacer top='2rem' />

      <div className='d-flex align-center'>
        <Text color='secondary' type='md'>
          {useFormDataTextRequired(
            formData?.firstpassed?.label,
            formData?.firstpassed?.required,
          )}
        </Text>
        <Spacer left='auto' />
        <Input
          style={{ width: "14rem" }}
          placeholder='0'
          value={formData?.firstpassed?.value}
          required={formData?.firstpassed?.required}
          onChange={e => onChangeNumericValue(e.target.value, "firstpassed")}
        />
      </div>

      <Spacer top='2rem' />

      <div className='d-flex align-center'>
        <Text color='secondary' type='md'>
          {useFormDataTextRequired(
            formData?.rate?.label,
            formData?.rate?.required,
          )}
        </Text>
        <Spacer left='auto' />
        <Input
          style={{ width: "14rem" }}
          placeholder='0'
          value={formData?.rate?.value}
          required={formData?.rate?.required}
          onChange={e => onChangeNumericValue(e.target.value, "rate")}
        />
      </div>
    </>
  );
};

export default StudyForm;
