import NewsModal from "../components/news/NewsModal";
import NewsTable from "../components/news/NewsTable";
import Layout from "../components/layout/Layout";
import { Switch, Route, Link } from "react-router-dom";
import { Icon, Button, Spacer, Text } from "../components/common";

import { ROUTE_NEWS, ROUTE_NEWS_EDIT, ROUTE_NEWS_NEW } from "../AppRoutes";

const NewsPage = () => {
  return (
    <Layout
      topSlot={
        <Link to={ROUTE_NEWS_NEW}>
          <Button type='primary'>
            <Icon icon='add' size='2rem' />
            <Spacer left='0.5rem' />
            <Text type='sm' color='white'>
              Создать
            </Text>
          </Button>
        </Link>
      }>
      <div className='news-page'>
        <Switch>
          <Route exact path={ROUTE_NEWS}>
            <NewsTable open={() => setModal("edit")} />
          </Route>
          <Route path={ROUTE_NEWS_EDIT}>
            <NewsModal back={() => setModal(null)} isNew />
          </Route>
        </Switch>
      </div>
    </Layout>
  );
};

export default NewsPage;
