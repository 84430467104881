import Icon from './Icon';
const Avatar = ({ img, alt }) => {

    return (
        <div className="avatar-wrapper">
            {img ? <img src={img} alt={alt} /> : <Icon icon="user" size="3rem" />}
        </div>
    )
}

export default Avatar;