import {
  API_DATA_SHOP_PRODUCTS,
  API_DATA_SHOP_GROUPS,
  API_DATA_NEWS,
  API_DATA_FAQS,
  API_DATA_NOTIFICATIONS,
  API_DATA_USERS,
  API_DATA_TASKS,
} from "../../../services/api.service";

export const useFormDataPropTypeInput = "ufd/input";
export const useFormDataPropTypeRichInput = "ufd/richInput";
export const useFormDataPropTypeSelect = "ufd/select";
export const useFormDataPropTypeMultiSelect = "ufd/mutliselect";
export const useFormDataPropTypeSwitch = "ufd/switch";
export const useFormDataPropTypeRadio = "ufd/radio";
export const useFormDataPropTypeImage = "ufd/image";
export const useFormDataPropTypeAuthor = "ufd/author";
export const useFormDataPropTypeDate = "ufd/date";
export const useFormDataPropTypeDateSingle = "ufd/single_date";
export const useFormDataPropTypeTaskUsers = "ufd/taskusers";
export const useFormDataPropTypeTaskTests = "ufd/tasktests";

export const useFormDataPropSelectDefault = { value: "", text: "Все" };

export const useFormDataPropDateNestedTimeName = "time";
export const useFormDataPropDateNestedDateName = "date";
export const useFormDataPropDateDefault = {
  value: {
    [useFormDataPropDateNestedDateName]: null,
    [useFormDataPropDateNestedTimeName]: null,
  },
};

export const useFormData_TaskNewStudyTestAnswer = () => ({
  answer: __ufdPropInit(
    useFormDataPropTypeInput,
    "Текст варианта ответа",
    false,
  ),
  correct: __ufdPropInit(useFormDataPropTypeSwitch, "Правильный ответ", false),
});
export const useFormData_TaskNewStudyTest = () => ({
  question: __ufdPropInit(useFormDataPropTypeInput, "Текст вопроса", false),
  picture: __ufdPropInit(useFormDataPropTypeInput, "Изображение", false),
  answers: __ufdPropInit(
    useFormDataPropTypeMultiSelect,
    "Варианты ответов",
    false,
    { value: [{ ...useFormData_TaskNewStudyTestAnswer() }] },
  ),
});
export const useFormData_TaskNewStudy = () => ({
  description: __ufdPropInit(useFormDataPropTypeInput, "Текст", false, {
    value: undefined,
  }),
  picture: __ufdPropInit(useFormDataPropTypeImage, "Изображение", false),
  tests: __ufdPropInit(
    useFormDataPropTypeMultiSelect,
    "Варианты ответов",
    false,
    { value: [{ ...useFormData_TaskNewStudyTest() }] },
  ),
});

const __ufdPropTypeDefaults = _type => {
  switch (_type) {
    case useFormDataPropTypeInput:
      return { value: "" };

    case useFormDataPropTypeTaskTests:
      return {
        value: [{ ...JSON.parse(JSON.stringify(useFormData_TaskNewStudy())) }],
      };

    case useFormDataPropTypeMultiSelect:
      return { value: [] };

    case useFormDataPropTypeSelect:
      return { ...useFormDataPropSelectDefault };

    case useFormDataPropTypeSwitch:
      return { value: false };

    case useFormDataPropTypeDate:
      return { ...useFormDataPropDateDefault };

    default:
      return { value: undefined };
  }
};

const __ufdPropInit = (type, label, required = true, additional = {}) => ({
  ...__ufdPropTypeDefaults(type),
  type,
  label,
  required,
  ...additional,
});

export const useFormData_UserRoleAdmin = "administrator";
export const useFormData_UserRoleAgent = "agent";
export const useFormData_UserRolePharmacy = "pharmacy";
export const useFormData_UserRolesValid = [
  useFormData_UserRoleAdmin,
  useFormData_UserRoleAgent,
  useFormData_UserRolePharmacy,
];

export const useFormData_TaskTypeMerch = "merchandising";
export const useFormData_TaskTypePurchase = "purchase";
export const useFormData_TaskTypeSale = "sale";
export const useFormData_TaskTypeTraining = "training";
export const useFormData_TaskTypesValid = [
  useFormData_TaskTypeMerch,
  useFormData_TaskTypePurchase,
  useFormData_TaskTypeSale,
  useFormData_TaskTypeTraining,
];
export const useFormData_TaskStepInfo = "info";
export const useFormData_TaskStepUsers = "users";
export const useFormData_TaskStepTest = "test";
export const useFormData_TaskStepsValid = [
  useFormData_TaskStepInfo,
  useFormData_TaskStepUsers,
  useFormData_TaskStepTest,
];
export const useFormData_TaskGeneralInfo = "general_info";

export const __dataPropsDefault = {
  [API_DATA_TASKS]: {
    [useFormData_TaskGeneralInfo]: {
      step: __ufdPropInit(useFormDataPropTypeInput, "Шаг", false, {
        value: useFormData_TaskStepInfo,
      }),
      name: __ufdPropInit(useFormDataPropTypeInput, "Название"),
      description: __ufdPropInit(useFormDataPropTypeInput, "Описание"),
      picture: __ufdPropInit(useFormDataPropTypeImage, "Изображение"),
      type: __ufdPropInit(useFormDataPropTypeSelect, "Тип"),
      brands: __ufdPropInit(useFormDataPropTypeMultiSelect, "Бренды"),

      startdate: __ufdPropInit(useFormDataPropTypeDate, "Публикация"),
      enddate: __ufdPropInit(useFormDataPropTypeDate, "Конец"),

      // STEP: 2. Users
      users: __ufdPropInit(
        useFormDataPropTypeTaskUsers,
        "Выберите тип нужных пользователей",
        true,
        { value: { pharmacies: [], agents: [] } },
      ),
    },

    [useFormData_TaskTypeMerch]: {
      rewardagent: __ufdPropInit(
        useFormDataPropTypeInput,
        "Вознаграждение для Мед. представителя",
      ),
      rewardpharmacy: __ufdPropInit(
        useFormDataPropTypeInput,
        "Вознаграждение для Аптеки",
      ),
      areas: __ufdPropInit(useFormDataPropTypeMultiSelect, "Зоны выкладки"),
      requirements: __ufdPropInit(
        useFormDataPropTypeInput,
        "Требования к выкладке",
      ),
    },
    [useFormData_TaskTypePurchase]: {
      month: __ufdPropInit(useFormDataPropTypeSelect, "Акционные продукты"),
    },
    [useFormData_TaskTypeSale]: {
      month: __ufdPropInit(useFormDataPropTypeSelect, "Акционные продукты"),
    },

    // activates STEP: 3. Test;
    [useFormData_TaskTypeTraining]: {
      timetopass: __ufdPropInit(
        useFormDataPropTypeDateSingle,
        "Время на прохождение",
      ),
      testminimum: __ufdPropInit(
        useFormDataPropTypeInput,
        "Минимальное количество правильных ответов",
      ),
      scores: __ufdPropInit(
        useFormDataPropTypeInput,
        "Баллы за успешное прохождение задания",
      ),

      firstpassed: __ufdPropInit(
        useFormDataPropTypeInput,
        "Участники выполнившие тест первыми",
        false,
      ),
      rate: __ufdPropInit(useFormDataPropTypeInput, "Коэффициент", false),

      studiings: __ufdPropInit(
        useFormDataPropTypeTaskTests,
        "Создание обучения",
      ),
    },
  },
  [API_DATA_USERS]: {
    role: __ufdPropInit(useFormDataPropTypeSelect, "Тип пользователя"),

    [useFormData_UserRoleAdmin]: {
      fullname: __ufdPropInit(useFormDataPropTypeInput, "Имя и фамилия"),
      username: __ufdPropInit(useFormDataPropTypeInput, "Телефон"),
      email: __ufdPropInit(useFormDataPropTypeInput, "E-mail"),
    },

    [useFormData_UserRoleAgent]: {
      fullname: __ufdPropInit(useFormDataPropTypeInput, "Имя и фамилия"),
      username: __ufdPropInit(useFormDataPropTypeInput, "Телефон"),
      email: __ufdPropInit(useFormDataPropTypeInput, "E-mail"),

      city: __ufdPropInit(useFormDataPropTypeSelect, "Город"),
      command: __ufdPropInit(useFormDataPropTypeSelect, "Команда", false),
      agentline: __ufdPropInit(useFormDataPropTypeSelect, "Линия МП"),

      pharmacies: __ufdPropInit(useFormDataPropTypeInput, "Локации", false, {
        value: [],
      }),
    },

    [useFormData_UserRolePharmacy]: {
      main: __ufdPropInit(
        useFormDataPropTypeSwitch,
        "Главный пользователь сети",
        false,
      ),
      type: __ufdPropInit(useFormDataPropTypeInput, "Тип аптеки", false),

      // USER DATA
      fullname: __ufdPropInit(useFormDataPropTypeInput, "Имя и фамилия", false),
      username: __ufdPropInit(useFormDataPropTypeInput, "Телефон"),
      email: __ufdPropInit(useFormDataPropTypeInput, "E-mail"),
      address: __ufdPropInit(useFormDataPropTypeInput, "Адрес"),

      // ENTITY DATA
      city: __ufdPropInit(useFormDataPropTypeSelect, "Город"),
      entityname: __ufdPropInit(
        useFormDataPropTypeInput,
        "Юридическое лицо",
        false,
      ),
      bin: __ufdPropInit(useFormDataPropTypeInput, "БИН"),
      contract: __ufdPropInit(useFormDataPropTypeInput, "Номер договора"),
      pharmacyname: __ufdPropInit(useFormDataPropTypeInput, "Название аптеки"),
      category: __ufdPropInit(
        useFormDataPropTypeInput,
        "Категория аптеки",
        false,
      ),
      entityphone: __ufdPropInit(useFormDataPropTypeInput, "Телефон юр. лица"),

      // AGENTS DATA
      agents: __ufdPropInit(
        useFormDataPropTypeInput,
        "Медицинские представители",
        false,
        {
          value: [],
        },
      ),
    },
  },
  [API_DATA_SHOP_PRODUCTS]: {
    name: __ufdPropInit(useFormDataPropTypeInput, "Название"),
    description: __ufdPropInit(useFormDataPropTypeRichInput, "Описание"),
    costscores: __ufdPropInit(useFormDataPropTypeInput, "Стоимость (баллы)"),
    costmoney: __ufdPropInit(useFormDataPropTypeInput, "Стоимость (деньгы)"),
    group: __ufdPropInit(useFormDataPropTypeSelect, "Группа"),
    picture: __ufdPropInit(useFormDataPropTypeImage, "Изображение", false),
  },
  [API_DATA_SHOP_GROUPS]: {
    name: __ufdPropInit(useFormDataPropTypeInput, "Название"),
    roles: __ufdPropInit(useFormDataPropTypeMultiSelect, "Роль"),
    category: __ufdPropInit(useFormDataPropTypeSelect, "Тип категории"),
    picture: __ufdPropInit(useFormDataPropTypeImage, "Изображение", false),
  },
  [API_DATA_NOTIFICATIONS]: {
    type: __ufdPropInit(useFormDataPropTypeRadio, "Тип"),
    name: __ufdPropInit(useFormDataPropTypeInput, "Заголовок"),
    description: __ufdPropInit(useFormDataPropTypeInput, "Описание"),
    user: __ufdPropInit(useFormDataPropTypeSelect, "Пользователь", false),
  },
  [API_DATA_NEWS]: {
    name: __ufdPropInit(useFormDataPropTypeInput, "Название"),
    description: __ufdPropInit(useFormDataPropTypeRichInput, "Описание"),
    article: __ufdPropInit(useFormDataPropTypeRichInput, "Статья"),
    picture: __ufdPropInit(useFormDataPropTypeImage, "Изображение", false),
    author: __ufdPropInit(useFormDataPropTypeAuthor, "Автор", false),
    status: __ufdPropInit(useFormDataPropTypeInput, "Статус", false),
  },
  [API_DATA_FAQS]: {
    type: __ufdPropInit(useFormDataPropTypeRadio, "Тип"),
    question: __ufdPropInit(useFormDataPropTypeInput, "Вопрос"),
    answer: __ufdPropInit(useFormDataPropTypeInput, "Ответ"),
  },
};
