import React from "react";
import { Spacer, Text } from "../../../../../common";

const TaskTablesSelectedUsers = ({ pharmacies, agents }) => {
  if (!pharmacies?.length && !agents?.length) {
    return null;
  }

  return (
    <>
      <Spacer top='2rem' />

      <div className='card'>
        <Spacer top='2rem' />

        <div className='d-flex'>
          <Spacer left='4.5rem' />

          <Text color='secondary' type='md'>{`Выбрано: ${
            pharmacies?.length ? `${pharmacies?.length} Аптек` : ""
          } ${pharmacies?.length && agents?.length ? ` и ` : ""}
            ${
              agents?.length ? `${agents?.length} Мед. представителей.` : ""
            }`}</Text>
        </div>

        <Spacer bottom='2rem' />
      </div>
    </>
  );
};

export default TaskTablesSelectedUsers;
