import { useDispatch } from "react-redux";

import { StoreRootDispatch } from "../../../store";
import {
  appErrorClear,
  appErrorSet,
  StoreAppErrorStyle,
  StoreAppErrorStyleError,
  StoreAppErrorType,
  StoreAppErrorTypeServer,
} from "../../../store/app";

type useDispatchErrorHandlerSetError = (
  msg: string,
  type?: StoreAppErrorType,
  style?: StoreAppErrorStyle,
) => void;
type useDispatchErrorHandlerClearError = () => void;

const useDispatchError = (): [
  useDispatchErrorHandlerSetError,
  useDispatchErrorHandlerClearError,
] => {
  const dispatch = useDispatch<StoreRootDispatch>();

  const setError: useDispatchErrorHandlerSetError = async (
    msg,
    type = StoreAppErrorTypeServer,
    style = StoreAppErrorStyleError,
  ) => {
    await dispatch(appErrorSet(msg, type, style));
  };
  const clearError: useDispatchErrorHandlerClearError = async () => {
    await dispatch(appErrorClear());
  };

  return [setError, clearError];
};

export default useDispatchError;
