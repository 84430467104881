import TasksCard from "../components/dashboard/TasksCard";
import ApprovementCard from "../components/dashboard/ApprovementCard";
import UsersCard from "../components/dashboard/UsersCard";
import ShopCard from "../components/dashboard/ShopCard";
import DataCard from "../components/dashboard/DataCard";
import Layout from "../components/layout/Layout";
import { Button, Icon, Spacer, Text } from "../components/common";
import { useDispatch, useSelector } from "react-redux";
import { selectUserPermissions, userLogout } from "../store/user";
import {
  ROUTE_APPROVEMENT,
  ROUTE_DATA,
  ROUTE_SHOP_,
  ROUTE_TASKS,
  ROUTE_USERS,
} from "../AppRoutes";

const _getTopCards = (route, id) => {
  return {
    [ROUTE_TASKS]: <TasksCard key={`app-home-card-${id}`} />,
    [ROUTE_APPROVEMENT]: <ApprovementCard key={`app-home-card-${id}`} />,
  }[route];
};
const _getBottomCards = (route, id) => {
  return {
    [ROUTE_USERS]: <UsersCard key={`app-home-card-${id}`} />,
    [ROUTE_SHOP_]: <ShopCard key={`app-home-card-${id}`} />,
    [ROUTE_DATA]: <DataCard key={`app-home-card-${id}`} />,
  }[route];
};

const MainPage = () => {
  const dispatch = useDispatch();
  const onClickLogout = e => {
    e?.preventDefault();
    dispatch(userLogout());
  };
  const userPermissions = useSelector(selectUserPermissions) ?? [];

  const topCards = userPermissions
    .map(up => _getTopCards(up.name, up.id))
    .filter(up => !!up);
  const bottomCards = userPermissions
    .map(up => _getBottomCards(up.name, up.id))
    .filter(up => !!up);

  return (
    <Layout
      topSlot={
        <Button type='outline' onClick={onClickLogout}>
          <Icon icon='logout-red' size='2rem' />
          <Spacer left='0.5rem' />
          <Text type='sm' color='white'>
            Выход
          </Text>
        </Button>
      }>
      <div className='main-page'>
        <div className='dashboard-row'>
          {topCards?.length ? topCards[0] : null}
          {2 <= topCards?.length ? <Spacer left='3rem' /> : null}
          {2 <= topCards?.length ? topCards[1] : null}
        </div>

        <div className='dashboard-row'>
          {bottomCards?.length ? bottomCards[0] : null}
          {2 <= bottomCards?.length ? <Spacer left='3rem' /> : null}
          {2 <= bottomCards?.length ? bottomCards[1] : null}
          {3 <= bottomCards?.length ? <Spacer left='3rem' /> : null}
          {3 <= bottomCards?.length ? bottomCards[2] : null}
        </div>
      </div>
    </Layout>
  );
};

export default MainPage;
