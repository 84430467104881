import React from "react";
import { useHistory } from "react-router";
import { ROUTE_FAQ_NEW } from "../../AppRoutes";
import { API_DATA_FAQS } from "../../services/api.service";
import useAPIData from "../../shared/hooks/useAPIData";
import { Table, Text, Icon, Button } from "../common";

const FAQTable = () => {
  const history = useHistory();
  const [faqs, pagination, , deleteFAQ] = useAPIData(API_DATA_FAQS);

  const onDelete = async id => {
    await deleteFAQ(id);
  };
  const onToEdit = id => {
    history.push(`${ROUTE_FAQ_NEW}/${id}`);
  };

  const headers = [
    { title: "ID", isSortable: false },
    { title: "Тип", isSortable: false },
    { title: "Вопрос", isSortable: false },
    { title: "Ответ", isSortable: false },
    { title: "", isSortable: false },
  ];
  const faqTypes = {
    general: "Общий ВиО",
    agent: "Мед. представители",
    pharmacy: "Контрактная сеть",
  };

  const row = item => {
    let typeName = "Неизвестно";
    if (faqTypes[item.type]) {
      typeName = faqTypes[item.type];
    }
    return (
      <React.Fragment>
        <div className='table-row__cell'>
          <Text>{item.id?.slice(0, 4)}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{typeName}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{item.question}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{item.answer}</Text>
        </div>
        <div className='table-row__cell'>
          <Button
            type='flat'
            onClick={e => {
              e?.preventDefault();
              onDelete(item.id);
            }}>
            <Icon icon='remove' size='2rem' />
          </Button>
          <Button
            type='flat'
            onClick={e => {
              e?.preventDefault();
              onToEdit(item.id);
            }}>
            <Icon icon='edit' size='2rem' />
          </Button>
        </div>
      </React.Fragment>
    );
  };
  return (
    <Table
      pagination={pagination}
      columns={["10rem", "19rem", "32rem", "68rem", "12rem"]}
      headers={headers}
      row={row}
      list={faqs}
      isLoading={!faqs}
    />
  );
};

export default FAQTable;
