import React, { useEffect, useState } from "react";
import APIService, {
  API_DATA_USERS,
  API_DATA_USERS_QUERY_MED_MEMBERS,
} from "../../services/api.service";
import getShortenedText from "../../shared/functions/getShortenedText";
import useDispatchError from "../../shared/hooks/useDispatchError";
import { StoreAppErrorTypeApp } from "../../store/app";
import { Autocomplete, Button, Icon, Spacer, Text } from "../common";

const TeamsTableInfo = ({ team, updateTeams }) => {
  const [setError] = useDispatchError();
  const [usersOptions, setUsersOptions] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const onChangeMultiSelect = value => {
    const newSelectedUsers = [];
    for (const v of value) {
      const defaultUserOpt = usersOptions?.find(o => o?.value === v);
      if (defaultUserOpt?.id) {
        newSelectedUsers.push(defaultUserOpt);
      }
    }
    setSelectedUsers(newSelectedUsers);
  };

  const onRemoveUserFromCommand = async id => {
    if (typeof id !== "string") {
      setError(
        "Похоже у этого пользователя не валидные данные, у него нет ID.",
        StoreAppErrorTypeApp,
      );
      return;
    }

    const isSuccess = await APIService.putDataByIDHandler(
      API_DATA_USERS,
      id,
      { command: null },
      () => setError("Не удалось удалить пользователя из команды."),
    );

    if (isSuccess) {
      setSelectedUsers([]);
      await updateTeams();
    }
  };
  const onAssignTeamToSelectedUsers = async () => {
    if (!selectedUsers?.length) {
      setError(
        "Выберите каких пользователей нужно добавить в команду.",
        StoreAppErrorTypeApp,
      );
      return;
    }

    for (const su of selectedUsers) {
      const isSuccess = await APIService.putDataByIDHandler(
        API_DATA_USERS,
        su?.id,
        {
          command: team?.id,
        },
        () =>
          setError(
            `Не удалось добавить пользователя '${su?.value}' в команду '${team?.name}'.`,
          ),
      );
      if (!isSuccess) {
        return;
      }
    }

    await updateTeams();
    setSelectedUsers([]);
  };

  useEffect(() => {
    const fetchOptions = async () => {
      const total = await APIService.getDataCountHandler(
        API_DATA_USERS,
        () => setError("Не удалось получить количество пользователей."),
        API_DATA_USERS_QUERY_MED_MEMBERS,
      );

      let page = 1;
      const limit = 100;
      let start = (page - 1) * limit;

      let newUsersOptions = [];

      while (start < total) {
        const usersPagedData = await APIService.getDataHandler(
          API_DATA_USERS,
          () => setError("Не удалось получить пользователей"),
          start,
          limit,
          API_DATA_USERS_QUERY_MED_MEMBERS,
        );
        if (!usersPagedData) {
          return;
        } else if (!usersPagedData?.length) {
          break;
        }

        newUsersOptions = [
          ...newUsersOptions,
          ...usersPagedData.map((u, idx) => {
            let fullname = `Не указано - ${idx + 1}`;

            if (u?.firstname && u?.lastname) {
              fullname = `${u?.firstname} ${u?.lastname}`;
            }

            return { id: u.id, value: fullname, command: u?.agent?.command };
          }),
        ];

        start = (++page - 1) * limit;
      }

      if (team?.agents?.length) {
        newUsersOptions = newUsersOptions.filter(
          u => !!team?.agents?.find(a => !u?.command && a?.user?.id !== u?.id),
        );
      }

      setUsersOptions(newUsersOptions);
    };

    fetchOptions();
  }, [team]);

  let _validUsersTeam = [];
  if (team?.agents?.length) {
    _validUsersTeam = team?.agents?.filter(a => !!a?.user?.id?.length);
  }

  return (
    <div className='order-info'>
      <div className='data-page-agentlines-brands'>
        {_validUsersTeam?.length ? (
          _validUsersTeam.map((a, idx) => {
            let fullname = "Неизвестно";
            let fullnameClr = "grey";
            if (a?.user?.firstname && a?.user?.lastname) {
              fullname = `${a?.user?.firstname} ${a?.user?.lastname}`;
              fullnameClr = "";
            }

            return (
              <div
                className='data-page-agentlines-brands-name'
                key={`${idx}-dptufn__`}>
                <Text type='md' color={fullnameClr}>
                  {getShortenedText(fullname, 15)}
                </Text>
                <Spacer left={"1rem"} />
                <Icon
                  icon='close'
                  size='2.0rem'
                  onClick={() => onRemoveUserFromCommand(a?.user?.id)}
                />
              </div>
            );
          })
        ) : (
          <Text type='md' color='grey'>
            Нет пользователей в этой команде
          </Text>
        )}
      </div>

      <Spacer top='4rem' />

      <div className='d-flex'>
        <Autocomplete
          id='users'
          placeholder='Имя и фамилия'
          style={{ width: "35rem" }}
          multiple={true}
          disabled={!usersOptions?.length}
          value={selectedUsers?.length ? selectedUsers?.map(v => v.value) : []}
          options={usersOptions?.length ? usersOptions?.map(u => u.value) : []}
          getOptionLabel={o => o}
          onValueChange={option => onChangeMultiSelect(option)}
        />
        <Spacer left='4rem' />
        <Button
          type={selectedUsers?.length ? "primary" : "outline"}
          onClick={e => {
            e?.preventDefault();
            onAssignTeamToSelectedUsers();
          }}>
          <Text type='sm'>Добавить</Text>
        </Button>
      </div>

      <Spacer bottom='2rem' />
    </div>
  );
};

export default TeamsTableInfo;
