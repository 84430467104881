import React from "react";
import { useHistory } from "react-router";
import { ROUTE_NEWS_NEW } from "../../AppRoutes";
import { API_DATA_NEWS, getImageURL } from "../../services/api.service";
import {
  convertDate,
  convertDateTime,
} from "../../shared/functions/convertDate";
import useAPIData from "../../shared/hooks/useAPIData";
import { Table, Text, Icon, Button, Spacer } from "../common";

const NewsTable = () => {
  const history = useHistory();
  const [news, pagination, , deleteNews] = useAPIData(API_DATA_NEWS);

  const statuses = {
    publish: {
      icon: "done-outline-primary",
      text: "Опубликовано",
    },
    draft: {
      icon: "task-draft",
      text: "Черновик",
    },
  };
  const headers = [
    { title: "", isSortable: false },
    { title: "Название", isSortable: false },
    { title: "Автор", isSortable: false },
    { title: "Статус", isSortable: false },
    { title: "Просмотры", isSortable: false },
    { title: "Создана", isSortable: false },
  ];

  const onDelete = async id => {
    await deleteNews(id);
  };
  const onToEdit = id => {
    history.push(`${ROUTE_NEWS_NEW}/${id}`);
  };

  const row = item => {
    let statusData = statuses[item?.status];
    if (!statusData) {
      statusData = { text: "Ошибка", icon: "close-outline" };
    }

    let authorName = "Неизвестно";
    if (item?.author?.firstname && item?.author?.lastname) {
      authorName = `${item?.author?.firstname} ${item?.author?.lastname}`;
    }

    return (
      <React.Fragment>
        <div className='table-row__cell'>
          {item?.picture?.formats?.thumbnail?.url ? (
            <img
              src={getImageURL(item.picture.formats.thumbnail.url)}
              alt={"news-picture"}
            />
          ) : null}
        </div>
        <div className='table-row__cell'>
          <Text>{item.name}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{authorName}</Text>
        </div>
        <div className='table-row__cell'>
          <Icon icon={statusData.icon} size='1.75rem' />
          <Spacer left='0.25rem' />
          <Text>{statusData.text}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{item.views}</Text>
        </div>
        <div className='table-row__cell'>
          <div>
            <Text isBold>{convertDate(item.createdAt)}</Text>
            <Spacer top='0.25rem' />
            <Text>{convertDateTime(item.createdAt)}</Text>
          </div>
        </div>
        <div className='table-row__cell'>
          <Button
            type='flat'
            onClick={e => {
              e?.preventDefault();
              onDelete(item.id);
            }}>
            <Icon icon='remove' size='2rem' />
          </Button>
          <Button
            type='flat'
            onClick={e => {
              e?.preventDefault();
              onToEdit(item.id);
            }}>
            <Icon icon='edit' size='2rem' />
          </Button>
        </div>
      </React.Fragment>
    );
  };
  return (
    <Table
      columns={[
        "17rem",
        "43.5rem",
        "17rem",
        "18rem",
        "16rem",
        "16rem",
        "12rem",
      ]}
      headers={headers}
      pagination={pagination}
      row={row}
      list={news}
      isLoading={!news}
    />
  );
};

export default NewsTable;
