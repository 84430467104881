import Layout from "../components/layout/Layout";
import {
  Icon,
  Button,
  Spacer,
  Text,
  Modal,
  Input,
  Select,
} from "../components/common";
import { useDispatch } from "react-redux";
import { userLogout } from "../store/user";

const ProfilePage = () => {
  const dispatch = useDispatch();
  const onClickLogout = e => {
    e.preventDefault();
    dispatch(userLogout());
  };

  return (
    <Layout>
      <div className='profile-page'>
        <Modal title='Учетная запись'>
          <div className='d-flex align-center'>
            <Text type='md' color='secondary'>
              Регистрация
            </Text>
            <Spacer left='15rem' />
            <Text type='md'>23.02.2021</Text>
          </div>

          <Spacer top='3rem' />

          <div className='d-flex'>
            <div className='flex-50'>
              <div className='d-flex align-center'>
                <Text type='md' color='secondary'>
                  Имя
                </Text>
                <Spacer left='auto' />
                <Input value='Гришин Эрик Валериевич' />
              </div>
              <Spacer top='3rem' />
              <div className='d-flex align-center'>
                <Text type='md' color='secondary'>
                  Тип
                </Text>
                <Spacer left='auto' />
                <Select
                  value={"Аптека"}
                  onChange={() => {}}
                  variants={[
                    { value: "Аптека", text: "Аптека" },
                    { value: "не Аптека", text: "не Аптека" },
                    { value: "не Аптека 2", text: "не Аптека 2" },
                  ]}
                />
              </div>
              <Spacer top='3rem' />
              <div className='d-flex align-center'>
                <Text type='md' color='secondary'>
                  Уровень доступа
                </Text>
                <Spacer left='auto' />
                <Select
                  value={"Пользователь"}
                  onChange={() => {}}
                  variants={[
                    { value: "Пользователь", text: "Пользователь" },
                    { value: "не Аптека", text: "не Аптека" },
                    { value: "не Аптека 2", text: "не Аптека 2" },
                  ]}
                />
              </div>
              <Spacer top='3rem' />
              <div className='d-flex align-center'>
                <Text type='md' color='secondary'>
                  Название аптеки
                </Text>
                <Spacer left='auto' />
                <Input value='ТОО Зерде-Фарма' />
              </div>
              <Spacer top='3rem' />
              <div className='d-flex align-center'>
                <Text type='md' color='secondary'>
                  Адрес
                </Text>
                <Spacer left='auto' />
                <Input value='Киев, ул. Панфилова 29а' />
              </div>
              <Spacer top='3rem' />
              <div className='d-flex align-center'>
                <Text type='md' color='secondary'>
                  Юридеческое лицо
                </Text>
                <Spacer left='auto' />
                <Input value='ТОО Зерде-Фарма' />
              </div>
            </div>
            <Spacer left='4rem' />
            <div className='flex-50'>
              <div className='d-flex align-center'>
                <Text type='md' color='secondary'>
                  E-mail
                </Text>
                <Spacer left='auto' />
                <Input value='didara-82d@mail.ru' />
              </div>
              <Spacer top='3rem' />
              <div className='d-flex align-center'>
                <Text type='md' color='secondary'>
                  Телефон
                </Text>
                <Spacer left='auto' />
                <Input value='380 (93) 111 11 11' />
              </div>
              <Spacer top='3rem' />
              <div className='d-flex align-center'>
                <Text type='md' color='secondary'>
                  БИН
                </Text>
                <Spacer left='auto' />
                <Input value='380 (93) 111 11 11' />
              </div>
              <Spacer top='3rem' />
              <div className='d-flex align-center'>
                <Text type='md' color='secondary'>
                  Тип
                </Text>
                <Spacer left='auto' />
                <Input
                  style={{ maxWidth: "15rem" }}
                  value={"10001"}
                  onChange={() => {}}
                />
                <Spacer left='7.25rem' />
                <Text type='md' color='secondary'>
                  Категория аптеки
                </Text>
                <Spacer left='3rem' />
                <Input
                  style={{ maxWidth: "6rem" }}
                  value={"C"}
                  onChange={() => {}}
                />
              </div>
              <Spacer top='3rem' />
              <div className='d-flex align-center'>
                <Text type='md' color='secondary'>
                  Линия "МП"
                </Text>
                <Spacer left='auto' />
                <Select
                  value={"Cardio (кардио аппараты)"}
                  onChange={() => {}}
                  variants={[
                    {
                      value: "Cardio (кардио аппараты)",
                      text: "Cardio (кардио аппараты)",
                    },
                    { value: "не Аптека", text: "не Аптека" },
                    { value: "не Аптека 2", text: "не Аптека 2" },
                  ]}
                />
              </div>
              <Spacer top='3rem' />
              <div className='d-flex align-center' style={{ height: "6rem" }}>
                <Text type='md' color='secondary'>
                  Количество балов:
                </Text>
                <Spacer left='10rem' />
                <Text type='lg' color='secondary' isBold>
                  29 034
                </Text>
              </div>
            </div>
          </div>

          <div className='flex-1' />
          <Spacer top='3rem' />

          <div className='d-flex'>
            <Spacer left='auto' />
            <Button type='outline' onClick={onClickLogout}>
              <Icon icon='logout-red' size='2rem' />
              <Spacer left='1.5rem' />
              <Text type='md'>Выйти</Text>
            </Button>
            <Spacer left='3rem' />
            <Button type='primary'>
              <Text type='md'>Сохранить</Text>
            </Button>
          </div>
        </Modal>
      </div>
    </Layout>
  );
};

export default ProfilePage;
