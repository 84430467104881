import React from "react";
import { API_UPLOAD_MAX_SIZE } from "../../../../../services/api.service";
import useDispatchError from "../../../../../shared/hooks/useDispatchError";
import { useFormDataTextRequired } from "../../../../../shared/hooks/useFormData";
import { StoreAppErrorTypeForm } from "../../../../../store/app";
import { Spacer, Text, FileInput, TextArea } from "../../../../common";

const TaskTestStudyTab = ({ idx, allBlocks, data, onChangeData }) => {
  const [setError] = useDispatchError();

  const onChangeHandler = (value, valueName) => {
    if (valueName === "picture" && value?.size > API_UPLOAD_MAX_SIZE) {
      setError(
        "Файл слишком большой. Максимальный размер: 1Мб.",
        StoreAppErrorTypeForm,
      );
      return;
    }

    const newBlock = {
      ...allBlocks[idx],
      [valueName]: { ...allBlocks[idx][valueName], value },
    };
    allBlocks?.splice(idx, 1, newBlock);

    onChangeData(allBlocks, "studiings");
  };

  return (
    <>
      <Text color='secondary' type='md'>
        {useFormDataTextRequired(data?.picture?.label, data?.picture?.required)}
      </Text>

      <Spacer top='1.5rem' />

      <div style={{ width: "66rem" }}>
        <FileInput
          value={data?.picture?.value}
          required={data?.picture?.required}
          onChange={f => onChangeHandler(f, "picture")}
        />
      </div>

      <Spacer top='3rem' />

      <Text color='secondary' type='md'>
        {useFormDataTextRequired(
          data?.description?.label,
          data?.description?.required,
        )}
      </Text>

      <Spacer top='1.5rem' />

      <TextArea
        placeholder='Опишите задание как можно детальнее'
        rowsMin='9'
        value={data?.description?.value}
        required={data?.description?.required}
        onChange={e => onChangeHandler(e.target.value, "description")}
      />
    </>
  );
};

export default TaskTestStudyTab;
