import React from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { InputAdornment } from "@material-ui/core";
import { Icon } from "../index";

import moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/locale/ru";

const CustomDatePicker = ({
  width,
  value = null,
  onChange = () => {},
  placeholder = "Дата",
  required = false,
}) => {
  moment.locale("ru");

  return (
    <MuiPickersUtilsProvider
      libInstance={moment}
      utils={MomentUtils}
      locale='ru'>
      <DatePicker
        required={required}
        placeholder={placeholder}
        variant='inline'
        inputVariant='outlined'
        style={{ width: width || "23.75rem" }}
        value={value}
        onChange={onChange}
        format={"DD.MM.yyy"}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <Icon icon={"calendar"} size='2rem' />
            </InputAdornment>
          ),
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default CustomDatePicker;
