import FAQModal from "../components/faq/FAQModal";
import FAQTable from "../components/faq/FAQTable";
import Layout from "../components/layout/Layout";
import { Switch, Route, Link } from "react-router-dom";
import { Icon, Button, Spacer, Text } from "../components/common";

import { ROUTE_FAQ, ROUTE_FAQ_NEW, ROUTE_FAQ_EDIT } from "../AppRoutes";

const FAQPage = () => {
  return (
    <Layout
      topSlot={
        <Link to={ROUTE_FAQ_NEW}>
          <Button type='primary'>
            <Icon icon='add' size='2rem' />
            <Spacer left='0.5rem' />
            <Text type='sm' color='white'>
              Создать
            </Text>
          </Button>
        </Link>
      }>
      <div className='faq-page'>
        <Switch>
          <Route exact path={ROUTE_FAQ}>
            <FAQTable open={() => setModal("edit")} />
          </Route>
          <Route path={ROUTE_FAQ_EDIT}>
            <FAQModal back={() => setModal(null)} isNew />
          </Route>
        </Switch>
      </div>
    </Layout>
  );
};

export default FAQPage;
