import queryStringify from "qs-stringify";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { ROUTE_USERS_NEW } from "../../AppRoutes";
import {
  API_DATA_USERS,
  API_DATA_USERS_QUERY_ADMINS,
} from "../../services/api.service";
import useAPIData from "../../shared/hooks/useAPIData";
import { Table, Text, Spacer, Icon, Button } from "../common";

const AdminTable = ({ searchQuery }) => {
  const history = useHistory();

  const _getFiltersQueries = () => {
    const _filters = { _q: searchQuery };

    const _default = API_DATA_USERS_QUERY_ADMINS;
    const _qs = queryStringify(_filters);

    let out = _default;
    if (_qs?.length) {
      out += `&${_qs}`;
    }

    return out;
  };

  const [admins, pagination, updateAdmins, deleteAdmins, onChangePage] =
    useAPIData(API_DATA_USERS, _getFiltersQueries());

  useEffect(() => updateAdmins(_getFiltersQueries()), [searchQuery]);

  const onDelete = async id => {
    await deleteAdmins(id, API_DATA_USERS_QUERY_ADMINS);
  };
  const onToEdit = id => {
    history.push(`${ROUTE_USERS_NEW}/${id}`);
  };

  const headers = [
    { title: "Имя", isSortable: false },
    { title: "email", isSortable: false },
    { title: "Телефон", isSortable: false },
    { title: "Статус", isSortable: false },
    { title: "", isSortable: false },
  ];
  const statuses = {
    active: {
      icon: "done-outline-green",
      text: "Активирован",
    },
    new: {
      icon: "logout-red",
      text: "Новая регистрация",
    },
  };

  const onChangePageHandler = value => {
    onChangePage(value, API_DATA_USERS_QUERY_ADMINS);
  };

  const row = item => {
    let statusData = statuses[item?.status];
    if (!statusData) {
      statusData = { text: "Ошибка", icon: "close-outline" };
    }
    return (
      <React.Fragment>
        <div className='table-row__cell'>
          <Spacer left='0.25rem' />
          <Text>{`${item.firstname} ${item.lastname}`}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{item.email}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{item.username}</Text>
        </div>
        <div className='table-row__cell'>
          <Icon icon={statusData?.icon} size='1.75rem' />
          <Spacer left='0.25rem' />
          <Text>{statusData?.text}</Text>
        </div>
        <div className='table-row__cell'>
          <Button
            type='flat'
            onClick={e => {
              e?.preventDefault();
              onDelete(item.id);
            }}>
            <Icon icon='remove' size='2rem' />
          </Button>
          <Button
            type='flat'
            onClick={e => {
              e.preventDefault();
              onToEdit(item.id);
            }}>
            <Icon icon='edit' size='2rem' />
          </Button>
        </div>
      </React.Fragment>
    );
  };
  return (
    <div className='card flex-1'>
      <Table
        columns={["25rem", "25rem", "25rem", "48rem", "8rem"]}
        headers={headers}
        pagination={{ ...pagination, onChange: onChangePageHandler }}
        row={row}
        list={admins}
        isLoading={!admins}
      />
    </div>
  );
};

export default AdminTable;
