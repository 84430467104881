import React from "react";

import { Icon } from "..";

const defaultDelimiter = "...";

interface PaginationPageProps {
  isActive?: boolean;
  onChange?: (value: React.ReactNode) => void;
  isDisabled?: boolean;
  isClickable?: boolean;
  className?: string;
}

const PaginationPage: React.FC<PaginationPageProps> = ({
  children,
  isActive = false,
  isDisabled = false,
  isClickable = true,
  className = "pagination-outline-btn",
  onChange = () => {},
}) => {
  const onClickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDisabled && isClickable && !isActive) {
      onChange(children);
    } else {
      e.preventDefault();
    }
  };

  return (
    <div
      onClick={onClickHandler}
      className={`${className} ${isActive ? "active" : ""} ${
        isDisabled ? "disabled" : ""
      }`}>
      {children}
    </div>
  );
};

interface PaginationProps {
  page: number;
  limit: number;
  total: number;
  onChange: (selected: number) => void;
  pageRangeDisplayed?: number;
}

const Pagination: React.VFC<PaginationProps> = ({
  page,
  limit,
  total,
  onChange,
  pageRangeDisplayed = 5,
}) => {
  const totalPages = Math.ceil(total / limit);
  const currentPage = page < 1 ? 1 : page > totalPages ? totalPages : page;
  let startPage = 1;
  let endPage = totalPages;
  if (totalPages > pageRangeDisplayed) {
    const pageRangeDisplayedBeforeCurrentPage = Math.floor(
      pageRangeDisplayed / 2,
    );
    const pageRangeDisplayedAfterCurrentPage =
      Math.ceil(pageRangeDisplayed / 2) - 1;
    if (currentPage <= pageRangeDisplayedBeforeCurrentPage) {
      startPage = 1;
      endPage = pageRangeDisplayed;
    } else if (currentPage + pageRangeDisplayedAfterCurrentPage >= totalPages) {
      startPage = totalPages - pageRangeDisplayed + 1;
      endPage = totalPages;
    } else {
      startPage = currentPage - pageRangeDisplayedBeforeCurrentPage;
      endPage = currentPage + pageRangeDisplayedAfterCurrentPage;
    }
  }

  const pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
    i => startPage + i,
  );

  if (total <= limit) {
    return null;
  }

  return (
    <div className='pagination'>
      <PaginationPage
        className={"pagination-btn"}
        isDisabled={currentPage === 1}
        onChange={() => onChange(page - 1)}>
        <Icon icon='arrow-left' size='3rem' />
      </PaginationPage>

      {startPage > 1 ? (
        <>
          <PaginationPage
            key={`pagination-page=${1}`}
            onChange={selected => onChange(selected as number)}>
            1
          </PaginationPage>
          <PaginationPage isActive={false} className={"pagination-btn"}>
            {defaultDelimiter}
          </PaginationPage>
        </>
      ) : null}

      {pages.map(p => (
        <PaginationPage
          key={`pagination-page=${p}`}
          isActive={currentPage === p}
          onChange={selected => onChange(selected as number)}>
          {p}
        </PaginationPage>
      ))}

      {totalPages > endPage ? (
        <>
          <PaginationPage isActive={false} className={"pagination-btn"}>
            {defaultDelimiter}
          </PaginationPage>
          <PaginationPage onChange={selected => onChange(selected as number)}>
            {totalPages}
          </PaginationPage>
        </>
      ) : null}

      <PaginationPage
        isDisabled={totalPages <= currentPage}
        className='pagination-btn'
        onChange={() => onChange(page + 1)}>
        <Icon icon='arrow-right' size='3rem' />
      </PaginationPage>
    </div>
  );
};

export default Pagination;
