import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Text, Spacer, Table, Button, Icon } from "../common";
import { convertDate } from "../../shared/functions/convertDate";
import useDispatchError from "../../shared/hooks/useDispatchError";
import APIService, { API_DATA_USERS } from "../../services/api.service";
import { ROUTE_USERS_NEW } from "../../AppRoutes";

const TableChildRowPharmacy = ({ item }) => {
  const history = useHistory();
  const [setError] = useDispatchError();
  const [assignedPharmacies, setAssignedPharmacies] = useState(null);

  const onDelete = async id => {
    await APIService.delDataByIDHandler(API_DATA_USERS, id, () =>
      setError("Не удалось удалить пользователя."),
    );
  };
  const onToEditPharmacy = async id => {
    history.push(`${ROUTE_USERS_NEW}/${id}`);
  };

  useEffect(() => {
    const parseAgents = () => {
      const pharmacies = item?.pharmacies;
      const newPharmaciesData = [];

      if (pharmacies?.length) {
        for (const ph of pharmacies) {
          const phData = {
            main: !!ph?.main,
            id: ph.id,
            name:
              ph?.user?.firstname && ph?.user?.lastname
                ? `${ph?.user?.firstname} ${ph?.user?.lastname}`
                : "Не указано",
            city: ph?.city?.name ?? "Не указано",
            address: ph?.address ?? "Не указано",
            phone: ph?.user?.username ?? "Не указано",
            status: ph?.user?.status,
            scores: {
              total: ph?.scores ?? -1,
              sell: ph?.scorespurchase ?? -1,
              merch: ph?.scoresmerchandising ?? -1,
              tests: ph?.scorestraining ?? -1,
            },
            activity: convertDate(ph?.updatedAt),
            creator:
              ph?.creator?.firstname && ph?.creator?.lastname
                ? `(${ph?.creator?.firstname} ${ph?.creator?.lastname})`
                : `(Не указано)`,

            _actionID: ph?.user?.id,
          };

          newPharmaciesData.push(phData);
        }

        setAssignedPharmacies(newPharmaciesData);
      }
    };

    if (!assignedPharmacies) {
      parseAgents();
    }
  }, []);

  const headers = [
    { title: "Имя", isSortable: false },
    { title: "Город", isSortable: false },
    { title: "Адрес", isSortable: false },
    { title: "Телефон", isSortable: false },
    { title: "Статус", isSortable: false },
    {
      title: (
        <div>
          <div>Баллы</div>
          <Text type='caption'>(закупка/мерчерндайзинг/тесты)</Text>
        </div>
      ),
      isSortable: false,
    },
    { title: "Активность", isSortable: false },
    { title: "", isSortable: false },
  ];
  const statuses = {
    active: {
      icon: "done-outline-green",
      text: "Активирован",
    },
    new: {
      icon: "logout-red",
      text: "Новая регистрация",
    },
  };

  const row = item => {
    let statusData = statuses[item?.status];
    if (!statusData) {
      statusData = { text: "Ошибка", icon: "close-outline" };
    }

    return (
      <React.Fragment>
        <div className='table-row__cell'>
          <Text color={item?.main ? "primary" : ""}>{item.name}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{item.city}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{item.address}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{item.phone}</Text>
        </div>
        <div className='table-row__cell'>
          <Icon icon={statusData.icon} size='1.75rem' />
          <Spacer left='0.5rem' />
          <div>
            <Text>{statusData.text}</Text>
            {item.status === "new" && (
              <Text type='caption' color='primary'>
                {item?.creator}
              </Text>
            )}
          </div>
        </div>
        <div className='table-row__cell'>
          <Text>{item.scores.total}</Text>
          <Spacer left='0.75rem' />
          <Text type='caption'>{`(${item.scores.sell}/${item.scores.merch}/${item.scores.tests})`}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{item.activity}</Text>
        </div>
        <div className='table-row__cell'>
          <Button
            type='flat'
            onClick={e => {
              e?.preventDefault();
              onDelete(item._actionID);
            }}>
            <Icon icon='remove' size='2rem' />
          </Button>
          <Button
            type='flat'
            onClick={e => {
              e?.preventDefault();
              onToEditPharmacy(item._actionID);
            }}>
            <Icon icon='edit' size='2rem' />
          </Button>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div style={{ margin: "-1.5rem -2.5rem" }}>
      <Table
        hidePagination
        columns={[
          "20rem",
          "16rem",
          "18rem",
          "15rem",
          "21rem",
          "26rem",
          "15rem",
          "10rem",
        ]}
        headers={headers}
        row={row}
        list={assignedPharmacies}
      />
    </div>
  );
};

export default TableChildRowPharmacy;
