import { Checkbox, FormControlLabel } from "@material-ui/core";

const CustomCheckbox = props => {
  return (
    <FormControlLabel
      control={<Checkbox color='primary' {...props} />}
      label={props.label}
    />
  );
};

export default CustomCheckbox;
