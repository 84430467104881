import React from "react";
import { getImageURL } from "../../../services/api.service";
import getShortenedText from "../../../shared/functions/getShortenedText";
import { Text, Spacer, Icon, Button } from "../index";

/**
 * to \c accept argument of \c FileInput; for accepting only Excel files
 */
export const FileInputAcceptFilesExcel = ".xlsx, .xls";
export const FileInputAcceptFilesImages = "image/*";

const FileInput = ({
  value,
  onChange,
  required,
  accept = FileInputAcceptFilesImages,
}) => {
  return (
    <React.Fragment>
      {!value ? (
        <div className='file-input'>
          <Icon icon='upload' width='5rem' height='5.75rem' />
          <Spacer top='1.5rem' />
          <Text type='md' isCenter color='black'>
            <div className='d-flex justify-center'>
              <span style={{ marginRight: "0.5rem" }}>Перетащите или </span>
              <Text type='md' color='primary'>
                выберите файл
              </Text>
            </div>
          </Text>
          <input
            accept={accept}
            onChange={e => {
              if (e?.target?.files?.length && onChange) {
                onChange(e.target.files[0]);
              }
            }}
            type='file'
            required={required}
          />
        </div>
      ) : (
        <FileView
          key={value.name}
          file={value}
          onRemove={() => onChange(null)}
          onChange={onChange}
        />
      )}
    </React.Fragment>
  );
};

export default FileInput;

function FileView({ file, onRemove, onChange }) {
  return (
    <div className='file-input file-preview'>
      <div className='file-preview-image'>
        <img
          src={file?.id ? getImageURL(file.url) : URL.createObjectURL(file)}
          alt='file'
        />
      </div>
      <Spacer left='2.25rem' />
      <div className='d-flex f-column'>
        <div className='file-preview-controll'>
          <Text type='sm'>{getShortenedText(file.name, 26)}</Text>
          <Spacer left='auto' />
          <Button onClick={onRemove}>
            <Icon icon='close' size='1.5rem'></Icon>
          </Button>
        </div>
        <div className='file-input'>
          <Icon icon='upload' width='3rem' height='3.75rem' />
          <Spacer top='1.5rem' />
          <Text type='md' isCenter color='black'>
            <div className='d-flex justify-center'>
              <span style={{ marginRight: "0.5rem" }}>Перетащите или </span>
              <Text type='md' color='primary'>
                выберите файл
              </Text>
            </div>
          </Text>
          <input
            onChange={e => {
              onChange(e.target.files[0]);
            }}
            type='file'
          />
        </div>
      </div>
    </div>
  );
}
